import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { WorkStep } from '../../../Dialog/WorkStepDefinition';
import WorkStepItem from './WorkStepItem';
import Typo from '../../../Typo';
import Button from '../../../Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '68vh',
      overflow: 'hidden',
    },
    droppable: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      minHeight: 150,
      width: '100%',
      overflow: 'auto',
      padding: theme.spacing(1),
    },
  })
);

export interface WorkStepsPanelProps {
  workSteps: WorkStep[];
  handleAddWorkStep: () => void;
  handleEditWorkStep: (workStep: WorkStep) => void;
}

const WorkStepsPanel: React.FC<React.PropsWithChildren<WorkStepsPanelProps>> = ({
  workSteps,
  handleAddWorkStep,
  handleEditWorkStep,
}) => {
  const classes = useStyles();

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
  };

  return (
    <Box className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="workStepPanelList">
          {(provided) => (
            <div
              className={classes.droppable}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {workSteps.length ? (
                workSteps.map((workStep: WorkStep) => (
                  <Draggable
                    isDragDisabled //FIXME: disable DnD
                    key={workStep.id}
                    draggableId={workStep.id}
                    index={workStep.sequence ?? 0}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <WorkStepItem
                          workStep={workStep}
                          handleEdit={handleEditWorkStep}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <Typo t="page.worksteps.tabs.workSteps.empty" />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box display="flex" justifyContent="flex-end" marginTop={3}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          t="page.worksteps.tabs.workSteps.add"
          type="submit"
          onClick={() => handleAddWorkStep()}
        />
      </Box>
    </Box>
  );
};

WorkStepsPanel.propTypes = {
  handleAddWorkStep: PropTypes.func.isRequired,
  handleEditWorkStep: PropTypes.func.isRequired,
};
WorkStepsPanel.defaultProps = {};

export default WorkStepsPanel;
