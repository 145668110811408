import {
  ProductOrderMachine,
  ProductOrderContext,
  ProductOrderData,
} from '../subOrders/productOrder/types';
import {
  RepairContext,
  RepairData,
  RepairMachine,
} from '../subOrders/repair/types';

export enum SubOrderType {
  REPAIR = 'REPAIR',
  ORDER = 'ORDER',
}
export interface SubOrdersSchema {
  states: {
    editing: {};
  };
}

export interface SubOrderRepair extends RepairContext {
  index: number;
  ref: RepairMachine;
}

export interface SubOrderProductOrder extends ProductOrderContext {
  index: number;
  ref: ProductOrderMachine;
}

export type Setting = {
  key: string;
  value: string | number;
};

export interface SubOrdersContext {
  index: number;
  repairs: SubOrderRepair[];
  orders: SubOrderProductOrder[];
  tenantSettings: Setting[] | null;
}

export type SubOrdersEvent =
  | { type: 'done.invoke.getSettings'; data: Setting[] }
  | { type: 'SUBORDER_SPAWN_REPAIR' }
  | { type: 'SUBORDER_REMOVE_REPAIR'; id: string }
  | { type: 'SUBORDER_COMPLETE_REPAIR'; id: string; data: RepairData }
  | { type: 'SUBORDER_UNCOMPLETE_REPAIR'; id: string }
  | { type: 'SUBORDER_SPAWN_ORDER' }
  | { type: 'SUBORDER_REMOVE_ORDER'; id: string }
  | { type: 'SUBORDER_COMPLETE_ORDER'; id: string; data: ProductOrderData }
  | { type: 'SUBORDER_UNCOMPLETE_ORDER'; id: string };
