import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from '..';
import PrintCustomerOrder from '../../PrintCustomerOrder';

export interface PrintCustomerOrderDialogProps {
  printData: any;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  costEstimateRef?: any;
}

const PrintCustomerOrderDialog: React.FC<React.PropsWithChildren<PrintCustomerOrderDialogProps>> = ({
  costEstimateRef,
  printData,
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <DialogBase
      open={open}
      title="app.dialog.printCustomerOrder.title"
      hideSecondaryAction
      maxWidth="sm"
      fullWidth
      primaryAction={onSubmit || onClose}
      primaryActionLabel="app.common.done"
      secondaryAction={onClose}
    >
      <PrintCustomerOrder
        costEstimationPrintRef={costEstimateRef}
        repairs={printData}
      />
    </DialogBase>
  );
};

PrintCustomerOrderDialog.propTypes = {
  printData: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  costEstimateRef: PropTypes.shape({}),
};
PrintCustomerOrderDialog.defaultProps = {
  costEstimateRef: undefined,
};

export default PrintCustomerOrderDialog;
