import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import BuildIcon from '@mui/icons-material/Build';
// import StoreIcon from '@mui/icons-material/Store';

import Paper from '../../../components/Paper';
import Typo from '../../../components/Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 48,
      width: 250,
      height: 100,
    },
  })
);

export interface SettingsListProps {}

const SettingsList: React.FC<
  React.PropsWithChildren<SettingsListProps>
> = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Box mb={3}>
        <ButtonBase
          color="secondary"
          onClick={() => history.push('/settings/worksteps')}
        >
          <Paper padding>
            <Box className={classes.settingsButton}>
              <Box>
                <PlaylistAddCheckIcon color="primary" fontSize="inherit" />
              </Box>
              <Typo variant="h6" t="page.settings.workStepDefinitions" />
            </Box>
          </Paper>
        </ButtonBase>
      </Box>

      <Box mb={3}>
        <ButtonBase
          color="secondary"
          onClick={() => history.push('/settings/products')}
        >
          <Paper padding>
            <Box className={classes.settingsButton}>
              <Box>
                <BuildIcon color="primary" fontSize="inherit" />
              </Box>
              <Typo variant="h6" t="page.settings.products" />
            </Box>
          </Paper>
        </ButtonBase>
      </Box>

      {/* <Box mb={3}>
        <ButtonBase
          color="secondary"
          onClick={() => history.push('/settings/locations')}
        >
          <Paper padding>
            <Box className={classes.settingsButton}>
              <Box>
                <StoreIcon color="primary" fontSize="inherit" />
              </Box>
              <Typo variant="h6" t="page.settings.locations" />
            </Box>
          </Paper>
        </ButtonBase>
      </Box> */}
    </Box>
  );
};

SettingsList.propTypes = {};
SettingsList.defaultProps = {};

export default SettingsList;
