import React from 'react';
import ReactToPrint from 'react-to-print';

import brandedPaper from '../../assets/img/brandedPaper.png';

interface UsePrintProps {
  printRef: any;
  isBrandedDefaultPaper?: boolean;
  onAfterPrint?: () => void;
  props?: any;
}

export default function usePrint(params: UsePrintProps) {
  // heavily "inspired" by the implementation of their own hook
  // https://github.com/gregnb/react-to-print/blob/d6349a0c27e85887eddfe8b0f0e2832600b48869/src/index.tsx#L446

  //default styling
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultProps = {
    copyStyles: true,
    pageStyle:
      '@page {  margin: 2mm; size: 90mm 29mm; } @media print { body { -webkit-print-color-adjust: exact; } }', // remove date/time from top
    removeAfterPrint: false,
    suppressErrors: false,
  };

  // if brandedPaper apply default top and bottom margins
  if (!!params.isBrandedDefaultPaper) {
    defaultProps.pageStyle = `@page { margin-top: 1.5cm; margin-bottom: 1.5cm; size: 210mm 297mm; } 
      @media print { 
        body { 
          width: 210mm;
          height: 297mm;
          padding-top: 5cm;
          padding-left: 1cm;
          padding-right: 1cm;
          padding-bottom: 2cm;
          -webkit-print-color-adjust:exact !important;
          print-color-adjust:exact !important;
          background-image: url(${brandedPaper});
          background-size: 210mm 297mm;
      } } `;
  }

  //create reactPrint entity
  const entity = React.useMemo(
    () =>
      new ReactToPrint({
        ...defaultProps,
        ...params.props,
        content: () => params.printRef?.current,
        onAfterPrint: params.onAfterPrint,
      }),
    [defaultProps, params.onAfterPrint, params.printRef, params.props]
  );

  return React.useCallback(() => entity.handleClick(), [entity]);
}
