/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Properties by which customer orders can be ordered.
 */
export enum CustomerOrderSortField {
  acceptedDate = "acceptedDate",
  createdAt = "createdAt",
  id = "id",
  status = "status",
  updatedAt = "updatedAt",
}

/**
 * CustomerOrderStatus
 */
export enum CustomerOrderStatus {
  CANCELED = "CANCELED",
  COSTESTIMATION = "COSTESTIMATION",
  INVOICED = "INVOICED",
  OPEN = "OPEN",
}

/**
 * Properties by which customers can be ordered.
 */
export enum CustomerSortField {
  createdAt = "createdAt",
  email = "email",
  firstname = "firstname",
  id = "id",
  lastname = "lastname",
  updatedAt = "updatedAt",
}

/**
 * Customer Gender
 */
export enum Gender {
  DIVERSE = "DIVERSE",
  FEMALE = "FEMALE",
  MALE = "MALE",
}

/**
 * Note status
 */
export enum NoteStatus {
  DONE = "DONE",
  OPEN = "OPEN",
}

/**
 * Possible directions in which to order a list of items when provided an `orderBy` argument.
 */
export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

/**
 * Payment methods
 */
export enum PaymentMethod {
  BANKOMAT = "BANKOMAT",
  BAR = "BAR",
  KREDITKARTE = "KREDITKARTE",
  KREDITRECHNUNG = "KREDITRECHNUNG",
  SUM_UP_EC = "SUM_UP_EC",
}

/**
 * Properties by which product orders can be ordered.
 */
export enum ProductOrderSortField {
  acceptedDate = "acceptedDate",
  createdAt = "createdAt",
  id = "id",
  status = "status",
  updatedAt = "updatedAt",
}

/**
 * ProductOrder Status / Progress
 */
export enum ProductOrderStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  COSTESTIMATION = "COSTESTIMATION",
  INVOICED = "INVOICED",
  IN_PROGRESS = "IN_PROGRESS",
  OPEN = "OPEN",
}

/**
 * ProductQuantityEntity Type
 */
export enum ProductQuantityEntityType {
  PRODUCT_ORDER = "PRODUCT_ORDER",
  REPAIR = "REPAIR",
}

/**
 * Properties by which product quantities can be ordered.
 */
export enum ProductQuantitySortField {
  createdAt = "createdAt",
  id = "id",
  productId = "productId",
  productOrderId = "productOrderId",
  productPrice = "productPrice",
  quantity = "quantity",
  repairId = "repairId",
  status = "status",
  updatedAt = "updatedAt",
  workStepDefinitionId = "workStepDefinitionId",
  workStepId = "workStepId",
}

/**
 * ProductQuantity Status / Progress
 */
export enum ProductQuantityStatus {
  AVAILABLE = "AVAILABLE",
  CANCELED = "CANCELED",
  INSTALLED = "INSTALLED",
  INVOICED = "INVOICED",
  OPEN = "OPEN",
  ORDERED = "ORDERED",
}

/**
 * Status Summary of all products in repair
 */
export enum ProductQuantityStatusSummary {
  ALL_AVAILABLE = "ALL_AVAILABLE",
  ALL_INSTALLED = "ALL_INSTALLED",
  ALL_ORDERED_OR_AVAILABLE = "ALL_ORDERED_OR_AVAILABLE",
  NO_PRODUCTS = "NO_PRODUCTS",
  SOME_OPEN = "SOME_OPEN",
}

/**
 * Properties by which customers can be ordered.
 */
export enum RepairSortField {
  acceptedDate = "acceptedDate",
  bikeId = "bikeId",
  completedDate = "completedDate",
  createdAt = "createdAt",
  customerOrderId = "customerOrderId",
  estimatedPickupDate = "estimatedPickupDate",
  id = "id",
  mechanicUserId = "mechanicUserId",
  notes = "notes",
  price = "price",
  repairCode = "repairCode",
  skillLevel = "skillLevel",
  status = "status",
  updatedAt = "updatedAt",
  workloadDiscount = "workloadDiscount",
  workloadPrice = "workloadPrice",
  workloadRate = "workloadRate",
  workloadSum = "workloadSum",
}

/**
 * Repair Status / Progress
 */
export enum RepairStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  COSTESTIMATION = "COSTESTIMATION",
  INVOICED = "INVOICED",
  IN_PROGRESS = "IN_PROGRESS",
  OPEN = "OPEN",
  PAUSED = "PAUSED",
  WORK_DONE = "WORK_DONE",
}

/**
 * User role
 */
export enum Role {
  INTERN = "INTERN",
  MANAGER = "MANAGER",
  MECHANIC = "MECHANIC",
}

/**
 * Mechanic skill level
 */
export enum SkillLevel {
  LEVEL1 = "LEVEL1",
  LEVEL2 = "LEVEL2",
  LEVEL3 = "LEVEL3",
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface CreateAddressForExistingCustomerInput {
  city: string;
  postalCode: string;
  street1: string;
  street2?: string | null;
  countryCode?: string | null;
  region?: string | null;
  customerId: string;
}

export interface CreateAddressInput {
  city: string;
  postalCode: string;
  street1: string;
  street2?: string | null;
  countryCode?: string | null;
  region?: string | null;
}

export interface CreateBikeForExistingCustomerInput {
  type: string;
  brand: string;
  color: string;
  model?: string | null;
  frameNumber?: string | null;
  isActive?: boolean | null;
  isEbike?: boolean | null;
  notes?: any | null;
  customerId: string;
}

export interface CreateBikeInput {
  type: string;
  brand: string;
  color: string;
  model?: string | null;
  frameNumber?: string | null;
  isActive?: boolean | null;
  isEbike?: boolean | null;
  notes?: any | null;
}

export interface CreateCustomerInput {
  firstname: string;
  lastname: string;
  gender: Gender;
  notes?: any | null;
  email?: string | null;
  phone?: string | null;
  phone2?: string | null;
  isActive?: boolean | null;
  externalId?: number | null;
  address?: CreateAddressInput | null;
  bikes?: CreateBikeInput[] | null;
}

export interface CreateCustomerOrder {
  customerId: string;
  invoiceInfo?: string | null;
  repairs?: CreateRepair[] | null;
  productOrders?: CreateProductOrderInput[] | null;
  status?: CustomerOrderStatus | null;
}

export interface CreateNoteComment {
  content: string;
  noteId: string;
}

export interface CreateNoteInput {
  content: any;
  customerId?: string | null;
  customerOrderId?: number | null;
}

export interface CreateProductCategoryInput {
  name: string;
  description: string;
  isRootCategory?: boolean | null;
  isRepairProductsRootCategory?: boolean | null;
  isOneTimeProductsRootCategory?: boolean | null;
  parentCategory?: string | null;
  sequence?: number | null;
  color?: string | null;
}

export interface CreateProductInput {
  name: string;
  description?: string | null;
  price?: number | null;
  manufacturer?: string | null;
  categoryId: string;
}

export interface CreateProductOrderInput {
  status?: ProductOrderStatus | null;
  products: CreateProductQuantity[];
  notes?: any | null;
  history?: any | null;
  locationId?: string | null;
}

export interface CreateProductQuantity {
  id?: string | null;
  productId: string;
  quantity: number;
  status?: ProductQuantityStatus | null;
}

export interface CreateRepair {
  bikeId: string;
  estimatedPickupDate: any;
  workloadDiscount?: number | null;
  status?: RepairStatus | null;
  skillLevel: SkillLevel;
  notes?: any | null;
  history?: any | null;
  locationId?: string | null;
  products?: CreateProductQuantity[] | null;
  workSteps: CreateWorkStep[];
}

export interface CreateRepairSum {
  workloadDiscount?: number | null;
  products?: CreateProductQuantity[] | null;
  workSteps?: CreateWorkStep[] | null;
}

export interface CreateWorkStep {
  workStepDefinitionId: string;
  setWorkload: number;
  repairId?: string | null;
  requiredProducts?: CreateProductQuantity[] | null;
  notes?: any | null;
}

export interface CreateWorkStepCategory {
  name: string;
  description?: string | null;
  sequence?: number | null;
  color?: string | null;
  isRootCategory?: boolean | null;
  workStepCategory?: string | null;
}

export interface CreateWorkStepDefinition {
  name: string;
  description?: string | null;
  sequence?: number | null;
  color?: string | null;
  expectedWorkload: number;
  proposedProducts?: CreateProductQuantity[] | null;
  proposedProductCategoryId?: string | null;
  skillLevel: SkillLevel;
  checklist?: any | null;
  categoryIds: string[];
}

export interface NotificationArgs {
  message: string;
  phonenumber: string;
  customerOrderId: string;
}

export interface UpdateAddressInput {
  city?: string | null;
  postalCode?: string | null;
  street1?: string | null;
  street2?: string | null;
  countryCode?: string | null;
  region?: string | null;
  id: string;
}

export interface UpdateBikeInput {
  id: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  type?: string | null;
  brand?: string | null;
  color?: string | null;
  model?: string | null;
  frameNumber?: string | null;
  isActive?: boolean | null;
  isEbike?: boolean | null;
  notes?: any | null;
}

export interface UpdateCustomerInput {
  firstname?: string | null;
  lastname?: string | null;
  gender?: Gender | null;
  notes?: any | null;
  email?: string | null;
  phone?: string | null;
  phone2?: string | null;
  isActive?: boolean | null;
  externalId?: number | null;
  id: string;
}

export interface UpdateCustomerOrder {
  customerId?: string | null;
  invoiceInfo?: string | null;
  id: number;
  status?: CustomerOrderStatus | null;
  invoiceDate?: any | null;
  invoiceId?: string | null;
  invoiceNumber?: string | null;
  invoicedLocationId?: string | null;
  paymentMethod?: string | null;
  cancelationReason?: string | null;
}

export interface UpdateNoteInput {
  content?: any | null;
  customerId?: string | null;
  customerOrderId?: number | null;
  id: string;
  status?: NoteStatus | null;
}

export interface UpdateProductCategoryInput {
  name?: string | null;
  description?: string | null;
  isRootCategory?: boolean | null;
  isRepairProductsRootCategory?: boolean | null;
  isOneTimeProductsRootCategory?: boolean | null;
  parentCategory?: string | null;
  sequence?: number | null;
  color?: string | null;
  id: string;
  isActive?: boolean | null;
}

export interface UpdateProductInput {
  name?: string | null;
  description?: string | null;
  price?: number | null;
  manufacturer?: string | null;
  categoryId?: string | null;
  id: string;
  isActive?: boolean | null;
}

export interface UpdateProductOrderInput {
  status?: ProductOrderStatus | null;
  notes?: any | null;
  history?: any | null;
  locationId?: string | null;
  id: string;
  customerOrderId: number;
  removeProductIDs?: string[] | null;
  addProducts?: CreateProductQuantity[] | null;
  cancelationReason?: string | null;
}

export interface UpdateProductQuantity {
  id: string;
  productId?: string | null;
  quantity?: number | null;
  status?: ProductQuantityStatus | null;
}

export interface UpdateRepair {
  estimatedPickupDate?: any | null;
  workloadDiscount?: number | null;
  status?: RepairStatus | null;
  skillLevel?: SkillLevel | null;
  notes?: any | null;
  history?: any | null;
  locationId?: string | null;
  id: string;
  mechanicId?: string | null;
  completedDate?: any | null;
  removeProductIDs?: string[] | null;
  cancelationReason?: string | null;
  pauseReason?: string | null;
  addProducts?: CreateProductQuantity[] | null;
}

export interface UpdateUserInput {
  firstname?: string | null;
  lastname?: string | null;
  role?: Role | null;
  skillLevel?: SkillLevel | null;
  isActive?: boolean | null;
  defaultLocationId?: string | null;
}

export interface UpdateWorkStep {
  workStepDefinitionId?: string | null;
  setWorkload?: number | null;
  repairId?: string | null;
  notes?: any | null;
  id: string;
  isDone?: boolean | null;
  removeRequiredProductIDs?: string[] | null;
  addRequiredProducts?: CreateProductQuantity[] | null;
}

export interface UpdateWorkStepCategory {
  name?: string | null;
  description?: string | null;
  sequence?: number | null;
  color?: string | null;
  isRootCategory?: boolean | null;
  workStepCategory?: string | null;
  id: string;
  isActive?: boolean | null;
}

export interface UpdateWorkStepDefinition {
  name?: string | null;
  description?: string | null;
  sequence?: number | null;
  color?: string | null;
  expectedWorkload?: number | null;
  proposedProductCategoryId?: string | null;
  skillLevel?: SkillLevel | null;
  checklist?: any | null;
  categoryIds?: string[] | null;
  id: string;
  isActive?: boolean | null;
  removeProposedProductIDs?: string[] | null;
  addProposedProducts?: CreateProductQuantity[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
