import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Chip } from '@mui/material';
import cslx from 'clsx';

import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      // width: 90,
      padding: theme.spacing(0, 1),
      color: '#fff',
    },
    LEVEL1: {
      backgroundColor: 'green',
    },
    LEVEL2: {
      backgroundColor: 'teal',
    },
    LEVEL3: {
      backgroundColor: 'orange',
    },
    small: {
      height: 18,
      width: 70,
    },
    smallFont: {
      fontSize: 12,
    },
  })
);

export interface SkillLevelChipProps {
  type: 'LEVEL1' | 'LEVEL2' | 'LEVEL3';
  small?: boolean;
}

const SkillLevelChip: React.FC<React.PropsWithChildren<SkillLevelChipProps>> = ({ type, small }) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      className={cslx(classes.base, classes[type], {
        [classes.small]: !!small,
      })}
      // className={`${classes.base} ${classes[type]}`}
      label={
        !!type ? (
          <Typo
            className={cslx({ [classes.smallFont]: !!small })}
            t={`model.common.skillLevel.${type}`}
          />
        ) : (
          ''
        )
      }
    />
  );
};

SkillLevelChip.propTypes = {
  // type: PropTypes.oneOf(['LEVEL1' | 'LEVEL2' | 'LEVEL3']).isRequired, TODO: Check why it doesnt work lol
};
SkillLevelChip.defaultProps = {};

export default SkillLevelChip;
