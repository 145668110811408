import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import EuroIcon from '@mui/icons-material/Euro';
// import InputAdornment from '@mui/material/InputAdornment';

import Typo from '../Typo';
import NumberUpdateField from '../NumberUpdateField';
import SkillLevelChip from '../SkillLevelChip';
import { formattedCurrency } from '../../lib/helper/numbers';
import { ProductQuantityStatus } from '../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid',
      borderColor: theme.palette.grey[400],
      backgroundColor: '#fff',
      overflow: 'auto',
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: theme.spacing(2),
      minWidth: 200,
      maxHeight: 80,
      overflow: 'auto',
    },
    right: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    deleteIcon: {
      color: 'red',
    },
    countUpdate: {
      width: 140,
      marginRight: theme.spacing(3),
    },
    price: {
      width: 140,
      marginRight: theme.spacing(3),
    },
    availableSwitch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 56,
      borderRadius: theme.shape.borderRadius,
      border: '1px solid',
      borderColor: theme.palette.grey[400],
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(2),
      //borderColor: 'orange',
    },
    subTitle: {
      marginBottom: theme.spacing(0.5),
    },
  })
);

export interface SlectedItemProps {
  name: string;
  id: string;
  count: number;
  countRate: number;
  countLabel: string;
  setCount: (id: string, value: number) => void;
  RemoveItem: (id: string) => void;
  skillLevel?: 'LEVEL1' | 'LEVEL2' | 'LEVEL3';
  manufacturer?: string | null;
  isValidating: boolean;
  isAvailable?: boolean;
  onAvailableChange?: (id: string, status: boolean) => void;
}

const SlectedItem: React.FC<React.PropsWithChildren<SlectedItemProps>> = ({
  name,
  id,
  count,
  countRate,
  countLabel,
  setCount,
  RemoveItem,
  skillLevel,
  isValidating,
  manufacturer = undefined,
  isAvailable = undefined,
  onAvailableChange = () => null,
}) => {
  const classes = useStyles();

  const handleDelete = () => {
    RemoveItem(id);
  };

  const handleCountIncrease = () => {
    setCount(id, count + 1);
  };

  const handleCountDecrease = () => {
    if (count === 1) {
      return;
    }
    setCount(id, count - 1);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAvailableChange(id, e.target.checked);
  };

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        {skillLevel && (
          <div className={classes.subTitle}>
            <SkillLevelChip type={skillLevel} small />
          </div>
        )}
        {manufacturer && (
          <div className={classes.subTitle}>
            <Typography>
              <i>{manufacturer}</i>
            </Typography>
          </div>
        )}
        <Typography>
          <b>{name}</b>
        </Typography>
      </div>
      <div className={classes.right}>
        {isAvailable !== undefined && (
          <div className={classes.availableSwitch}>
            <FormControlLabel
              value={ProductQuantityStatus.AVAILABLE}
              control={
                <Switch
                  color="primary"
                  checked={isAvailable}
                  onChange={handleStatusChange}
                />
              }
              label={<Typo t="model.product.status.isAvailable" />}
              labelPlacement="start"
            />
          </div>
        )}

        {/* count update */}
        <div className={classes.countUpdate}>
          <NumberUpdateField
            disabled={isValidating}
            label={countLabel}
            value={count}
            handleValueDecrease={handleCountDecrease}
            handleValueIncrease={handleCountIncrease}
          />
        </div>

        {/* price display */}
        <TextField
          label={<Typo t="model.repair.price" />}
          className={classes.price}
          value={formattedCurrency(countRate)}
          disabled
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <EuroIcon />
          //     </InputAdornment>
          //   ),
          // }}
          variant="outlined"
        />

        {/* remove button */}
        <div>
          <IconButton
            className={classes.deleteIcon}
            onClick={handleDelete}
            disabled={isValidating}
            size="large">
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

SlectedItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  countLabel: PropTypes.string.isRequired,
  setCount: PropTypes.func.isRequired,
  RemoveItem: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
};
SlectedItem.defaultProps = {};

export default React.memo(SlectedItem);
