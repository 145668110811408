import { Machine } from 'xstate';
import {
  getRepair,
  getRepairPriceAndWorkload,
  checkUserSkillLevel,
  checkIfUserIsCurrentMechanic,
  updateRepair,
  updateWorkStepDone,
  checkRepairStatus,
  updateWorkStep,
  updateProductQuantity,
  createWorkStep,
  deleteWorkStep,
  updateRepairLocation,
} from './helpers';

import {
  setRepairData,
  // setUserSkillLevel,
  updateRepairData,
  toggleIsRepairUpdating,
  toggleIsWorkStepUpdating,
  // updateWorkStepDoneData,
  updateWorkStepData,
  updateProductQuantityData,
  updateWorkStepDoneData,
} from './actions';
import { RepairStatus, Role } from '../../../types/graphql-global-types';
import {
  RepairProcessingContext,
  RepairProcessingEvent,
  RepairProcessingSchema,
} from './types';
import assertEventType from '../../../lib/helper/xstateHelper';

const initialContext = {
  repairCode: '',
  repairData: undefined,
  user: undefined,
  isRepairUpdating: false,
  isWorkStepUpdating: false,
};

const repairProcessingMachine = Machine<
  RepairProcessingContext,
  RepairProcessingSchema,
  RepairProcessingEvent
>(
  {
    id: 'repairProcessing',
    initial: 'initial',
    context: initialContext,
    on: {
      ADD_WORKSTEP: {
        target: 'addingWorkStep',
      },
      DELETE_WORKSTEP: {
        target: 'deletingWorkStep',
      },
      UPDATE_NOTES: {
        target: 'updatingRepair',
      },
      UPDATE_WORKSTEP_NOTES: {
        target: 'editing.updatingWorkStep',
      },
      UPDATE_REPAIR_LOCATION: {
        target: 'updateLocation',
      },
    },
    states: {
      initial: {
        invoke: [
          {
            id: 'getRepairData',
            src: (ctx) => getRepair(ctx.repairCode),
            onDone: [
              {
                cond: 'repairPausedGuard',
                actions: ['setRepairData'],
                target: 'paused',
              },
              {
                cond: 'repairCanceledGuard',
                actions: ['setRepairData'],
                target: 'canceled',
              },
              {
                cond: 'repairInvoicedGuard',
                actions: ['updateRepairData'],
                target: 'invoiced',
              },
              {
                cond: 'mechanicGuard',
                actions: ['setRepairData'],
                target: 'editing',
              },
              {
                actions: ['setRepairData'],
                target: 'viewing',
              },
            ],
            onError: {
              target: 'error',
            },
          },
        ],
      },

      updateLocation: {
        entry: 'toggleIsRepairUpdating',
        invoke: [
          {
            id: 'updateRepairLocation',
            src: (ctx, e: any) =>
              updateRepairLocation(ctx.repairData?.id || '', e.locationId),
            onDone: {
              actions: 'toggleIsRepairUpdating',
              target: 'initial',
            },
            onError: {
              target: 'error',
            },
          },
        ],
      },

      addingWorkStep: {
        entry: 'toggleIsRepairUpdating',
        invoke: [
          {
            id: 'addWorkStep',
            src: (ctx, e: any) =>
              createWorkStep({ ...e.data, repairId: ctx.repairData?.id || '' }),
            onDone: {
              actions: 'toggleIsRepairUpdating',
              target: 'initial',
            },
            onError: {
              target: 'error',
            },
          },
        ],
      },

      deletingWorkStep: {
        entry: 'toggleIsRepairUpdating',
        invoke: [
          {
            id: 'deleteWorkStep',
            src: (_, e: any) => deleteWorkStep(e.id),
            onDone: {
              actions: 'toggleIsRepairUpdating',
              target: 'initial',
            },
            onError: {
              target: 'error',
            },
          },
        ],
      },

      updatingRepair: {
        entry: 'toggleIsRepairUpdating',
        invoke: [
          {
            id: 'updatingRepair',
            src: (ctx, e: any) =>
              updateRepair({ ...e.data, id: ctx.repairData?.id || '' }),
            onDone: [
              {
                cond: 'repairPausedGuard',
                actions: ['updateRepairData', 'toggleIsRepairUpdating'],
                target: 'paused',
              },
              {
                cond: 'repairCanceledGuard',
                actions: ['updateRepairData', 'toggleIsRepairUpdating'],
                target: 'canceled',
              },
              {
                cond: 'repairInvoicedGuard',
                actions: ['updateRepairData', 'toggleIsRepairUpdating'],
                target: 'invoiced',
              },
              {
                target: 'editing',
                actions: ['updateRepairData', 'toggleIsRepairUpdating'],
              },
            ],
            onError: {
              target: 'error',
            },
          },
        ],
      },

      viewing: {
        on: {
          UPDATE_REPAIR_STATUS: {
            target: 'updatingRepair',
          },
        },
      },

      editing: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              UPDATE_WORKSTEP_DONE: {
                target: 'updatingWorkStepDone',
              },
              UPDATE_WORKSTEP: {
                target: 'updatingWorkStep',
              },
              UPDATE_PRODUCT_QUANTITY: {
                target: 'updatingProductQuantity',
              },
            },
          },
          error: {},
          updatingRepairPriceAndWorkload: {
            entry: 'toggleIsRepairUpdating',
            invoke: [
              {
                id: 'updatingRepairPriceAndWorkload',
                src: (ctx: RepairProcessingContext) =>
                  getRepairPriceAndWorkload(ctx.repairCode),
                onDone: [
                  {
                    target: 'idle',
                    actions: ['updateRepairData', 'toggleIsRepairUpdating'],
                  },
                ],
                onError: {
                  target: 'error',
                },
              },
            ],
          },
          updatingWorkStep: {
            entry: 'toggleIsWorkStepUpdating',
            invoke: [
              {
                id: 'updatingWorkStep',
                src: (_, e: any) => updateWorkStep({ ...e.data, id: e.id }),
                onDone: [
                  {
                    target: 'updatingRepairPriceAndWorkload',
                    actions: ['updateWorkStepData', 'toggleIsWorkStepUpdating'],
                  },
                ],
                onError: {
                  target: 'error',
                },
              },
            ],
          },

          updatingWorkStepDone: {
            entry: 'toggleIsWorkStepUpdating',
            invoke: [
              {
                id: 'updatingWorkStepDone',
                src: (_, e: RepairProcessingEvent) => {
                  assertEventType(e, 'UPDATE_WORKSTEP_DONE');
                  return updateWorkStepDone(e.id, e.isDone);
                },
                onDone: [
                  {
                    target: 'idle',
                    actions: [
                      'updateWorkStepDoneData',
                      'toggleIsWorkStepUpdating',
                    ],
                  },
                ],
                onError: {
                  target: 'error',
                },
              },
            ],
          },
          updatingProductQuantity: {
            entry: 'toggleIsWorkStepUpdating',
            invoke: [
              {
                id: 'updatingProductQuantity',
                src: (_, e: any) =>
                  updateProductQuantity({ ...e.data, id: e.id }),
                onDone: [
                  {
                    target: 'updatingRepairPriceAndWorkload',
                    actions: [
                      'updateProductQuantityData',
                      'toggleIsWorkStepUpdating',
                    ],
                  },
                ],
                onError: {
                  target: 'error',
                },
              },
            ],
          },
        },
        on: {
          UPDATE_REPAIR_STATUS: {
            target: 'updatingRepair',
          },
        },
      },

      paused: {
        // all functions disabled
        // unpause, cancel,
        on: {
          UPDATE_REPAIR_STATUS: {
            target: 'updatingRepair',
          },
        },
      },

      canceled: {
        on: {
          // UPDATE_REPAIR_STATUS: {
          //   target: 'updatingRepair',
          // },
        },
      },

      invoiced: {
        on: {},
      },

      error: {},
    },
  },
  {
    actions: {
      setRepairData: setRepairData,
      // setUserSkillLevel: setUserSkillLevel,
      updateRepairData: updateRepairData,
      toggleIsRepairUpdating: toggleIsRepairUpdating,
      toggleIsWorkStepUpdating: toggleIsWorkStepUpdating,
      updateWorkStepData: updateWorkStepData,
      updateProductQuantityData: updateProductQuantityData,
      updateWorkStepDoneData: updateWorkStepDoneData,
    },
    guards: {
      skillLevelGuard: (ctx) =>
        checkUserSkillLevel(ctx.user?.skillLevel, ctx.repairData?.skillLevel),
      mechanicGuard: (ctx, e: any) =>
        checkIfUserIsCurrentMechanic(ctx.user?.id || '', e.data?.mechanic?.id),
      repairPausedGuard: (_, e: any) =>
        checkRepairStatus(e.data?.status, RepairStatus.PAUSED),
      repairCanceledGuard: (_, e: any) =>
        checkRepairStatus(e.data?.status, RepairStatus.CANCELED),
      repairDoneGuard: (_, e: any) =>
        checkRepairStatus(e.data?.status, [
          RepairStatus.CANCELED,
          RepairStatus.WORK_DONE,
        ]),
      managerGuard: (ctx) => ctx.user?.role === Role.MANAGER,
      repairInvoicedGuard: (_, e: any) =>
        checkRepairStatus(e.data?.status, RepairStatus.INVOICED),
    },
  }
);

export default repairProcessingMachine;
