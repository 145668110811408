import { gql } from '@apollo/client';

export const GET_REPAIRS = gql`
  query getRepairs(
    $status: [RepairStatus!]
    $offset: Int
    $limit: Int
    $field: RepairSortField
    $direction: OrderDirection
    $search: String
  ) {
    getRepairs(
      status: $status
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
      search: $search
    ) {
      items {
        id
        repairCode
        status
        cancelationReason
        pauseReason
        createdAt
        mechanic {
          id
          firstname
          lastname
        }
        skillLevel
      }
      totalCount
    }
  }
`;

export const SEARCH_REPAIR = gql`
  query searchRepair($id: String, $repairCode: String) {
    getRepair(id: $id, repairCode: $repairCode) {
      id
      repairCode
      skillLevel
    }
  }
`;

export const GET_REPAIR_PRICE_AND_WORKLOAD = gql`
  query getRepairPriceAndWorkload($id: String, $repairCode: String) {
    getRepair(id: $id, repairCode: $repairCode) {
      id
      price
      workloadRate
      workloadSum
    }
  }
`;

export const GET_REPAIR = gql`
  query getRepair($id: String, $repairCode: String) {
    getRepair(id: $id, repairCode: $repairCode) {
      id
      repairCode
      skillLevel
      status
      cancelationReason
      completedDate
      pauseReason
      createdAt
      updatedAt
      estimatedPickupDate
      customerOrderId
      notes
      locationId
      productQuantityStatusSummary
      customerOrder {
        id
        createdUser {
          id
          firstname
          lastname
        }
      }
      mechanic {
        id
        firstname
        lastname
      }
      customer {
        id
        gender
        firstname
        lastname
        phone
        phone2
        email
        address {
          id
          street1
          street2
          postalCode
          city
        }
      }
      bike {
        id
        type
        brand
        model
        color
        notes
      }
      price
      workloadRate
      workloadSum
      workloadPrice
      workSteps {
        id
        workStepDefinition {
          id
          name
          description
          checklist
          skillLevel
        }
        isDone
        setWorkload
        notes
        requiredProducts {
          id
          quantity
          status
          product {
            id
            name
            price
            description
          }
        }
      }
      notes
      products {
        id
        quantity
        status
        product {
          id
          name
          price
          description
        }
      }
    }
  }
`;

export const GET_REPAIRS_COUNT = gql`
  query getRepairsCount(
    $status: [RepairStatus!]
    $offset: Int
    $limit: Int
    $field: RepairSortField
    $direction: OrderDirection
  ) {
    getRepairs(
      status: $status
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
    ) {
      items {
        id
        status
      }
      totalCount
    }
  }
`;

export const GET_REPAIRS_DETAILED = gql`
  query getRepairsDetailed(
    $status: [RepairStatus!]
    $offset: Int
    $limit: Int
    $field: RepairSortField
    $direction: OrderDirection
  ) {
    getRepairs(
      status: $status
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
    ) {
      items {
        id
        repairCode
        status
        cancelationReason
        pauseReason
        createdAt
        productQuantityStatusSummary
        mechanic {
          id
          firstname
          lastname
        }
        skillLevel
        workloadSum
        workSteps {
          id
          isDone
          setWorkload
          workStepDefinition {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;
