import React from 'react';
import PropTypes from 'prop-types';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

export interface TypoProps extends TypographyProps {
  t: string;
  values?: Record<string, any>;
  options?: object;
  description?: string;
  defaultMessage?: string;
  bold?: boolean;
}

/**
 * Wraps react-intl FormattedMessage with Material-ui Typography.
 */
const Typo: React.FC<React.PropsWithChildren<TypoProps>> = ({
  t,
  values,
  variant,
  description,
  defaultMessage,
  bold,
  ...props
}) => {
  return (
    <Typography variant={variant} {...props}>
      {bold ? (
        <b>
          <FormattedMessage
            id={t}
            values={values}
            description={description}
            defaultMessage={defaultMessage}
          />
        </b>
      ) : (
        <FormattedMessage
          id={t}
          values={values}
          description={description}
          defaultMessage={defaultMessage}
        />
      )}
    </Typography>
  );
};

Typo.propTypes = {
  t: PropTypes.string.isRequired,
  // values: PropTypes.shape({}),
  // UPDATE FIX
  // variant: PropTypes.oneOf([
  //   'h1',
  //   'h2',
  //   'h3',
  //   'h4',
  //   'h5',
  //   'h6',
  //   'subtitle1',
  //   'subtitle2',
  //   'body1',
  //   'body2',
  //   'caption',
  //   'button',
  //   'overline',
  //   'srOnly',
  //   'inherit',
  // ]),
  options: PropTypes.shape({}),
  description: PropTypes.string,
  defaultMessage: PropTypes.string,
  bold: PropTypes.bool,
};
Typo.defaultProps = {
  values: undefined,
  // variant: undefined,
  options: undefined,
  defaultMessage: undefined,
  description: undefined,
  bold: false,
};

export default Typo;
