import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Paper from '../../Paper';
import AppleStyleTabs from '../../Tabs/AppleStyleTabs';
import TabPanel from '../../Tabs/TabPanel';
import SubCategoriesPanel from './SubCategoriesPanel';
import WorkStepsPanel from './WorkStepsPanel';
import DetailPanel from './DetailsPanel';
import Typo from '../../Typo';
import { WorkStep } from '../../Dialog/WorkStepDefinition';
import { Category } from '../../../lib/helper/treeViewHelper';

export interface CategoryDetailProps {
  selectedCategory: Category | undefined;
  isLoading: boolean;
  onCategoryUpdate: (data: any) => void;
  handleAddCategory: () => void;
  handleAddWorkStep: () => void;
  handleEditWorkStep: (workStep: WorkStep) => void;
}

const CategoryDetail: React.FC<React.PropsWithChildren<CategoryDetailProps>> = ({
  isLoading,
  selectedCategory,
  onCategoryUpdate,
  handleAddCategory,
  handleAddWorkStep,
  handleEditWorkStep,
}) => {
  const [current, setCurrent] = React.useState<number>(0);
  return (
    <Paper padding fullHeight isLoading={isLoading}>
      <Box>
        <Box marginBottom={2}>
          <Typo gutterBottom t="page.worksteps.categories.selected" bold />
          <Typography variant="h5">{selectedCategory?.name ?? ''}</Typography>
        </Box>
        <AppleStyleTabs
          current={current}
          setValue={setCurrent}
          tabs={[
            { label: 'page.worksteps.tabs.category' },
            { label: 'page.worksteps.tabs.workSteps' },
            { label: 'page.worksteps.tabs.subCategories' },
          ]}
        />
        <Box>
          <TabPanel index={current} value={0}>
            <Box marginTop={3}>
              <DetailPanel
                selectedCategory={selectedCategory}
                onCategoryUpdate={onCategoryUpdate}
              />
            </Box>
          </TabPanel>
          <TabPanel index={current} value={1}>
            <Box marginTop={3}>
              <WorkStepsPanel
                workSteps={selectedCategory?.workSteps || []}
                handleAddWorkStep={handleAddWorkStep}
                handleEditWorkStep={handleEditWorkStep}
              />
            </Box>
          </TabPanel>
          <TabPanel index={current} value={2}>
            <Box marginTop={3}>
              <SubCategoriesPanel
                subCategories={selectedCategory?.subCategories || []}
                handleAddCategory={handleAddCategory}
              />
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Paper>
  );
};

CategoryDetail.propTypes = {
  // selectedCategory: PropTypes.shape({
  // id: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  // isActive: PropTypes.bool.isRequired,
  // sequence: PropTypes.number,
  // color: PropTypes.string,
  // subCategories: PropTypes.arrayOf({})
  // subCategories: Category[];
  // workSteps?: WorkStep[];
  // }),
  onCategoryUpdate: PropTypes.func.isRequired,
  handleAddWorkStep: PropTypes.func.isRequired,
  handleEditWorkStep: PropTypes.func.isRequired,
};
CategoryDetail.defaultProps = {};

export default React.memo(CategoryDetail);
