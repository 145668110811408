import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useLazyQuery } from '@apollo/client';
import IconButton from '@mui/material/IconButton';

import { CUSTOMER_DETAIL } from '../../../../../../gql/customer/queries';
import EditBikeDialog from '../../../../../Dialog/EditBike';
import AddBikeDialog from '../../../../../Dialog/CreateBike';
import BikesList from '../../../../../BikesList';
import Typo from '../../../../../Typo';
import {
  getCustomer,
  getCustomerVariables,
  getCustomer_getCustomer_bikes,
} from '../../../../../../gql/customer/types/getCustomer';
import { createCustomerBike_createCustomerBike_bikes } from '../../../../../../gql/customer/mutations/types/createCustomerBike';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(2),
    },
    container: {
      marginBottom: theme.spacing(3),
      minHeight: 100,
    },
    unit: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
  })
);

export interface BikeSelectionProps {
  customerId?: string;
  bikeId?: string;
  handleUpdateBike: (bike: getCustomer_getCustomer_bikes | null) => void;
}

const BikeSelection: React.FC<React.PropsWithChildren<BikeSelectionProps>> = ({
  customerId = undefined,
  bikeId,
  handleUpdateBike,
}) => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState<string | undefined>(bikeId);
  const [EditBike, setEditBike] = React.useState<
    getCustomer_getCustomer_bikes | undefined
  >(undefined);
  const [addBike, setAddBike] = React.useState<string | undefined>(undefined);
  const [createdBike, setCreatedBike] = React.useState<
    getCustomer_getCustomer_bikes | undefined
  >();

  const handleBikeClose = () => setEditBike(undefined);

  const handleAddBikeClose = (
    bike?:
      | createCustomerBike_createCustomerBike_bikes
      | getCustomer_getCustomer_bikes
  ) => {
    setAddBike(undefined);

    if (bike) setCreatedBike(bike as getCustomer_getCustomer_bikes);

    refetch && refetch();
  };
  const handleAddBikeOpen = () => setAddBike(customerId);

  const [getBikes, { data, loading, refetch }] = useLazyQuery<
    getCustomer,
    getCustomerVariables
  >(CUSTOMER_DETAIL, {
    onCompleted: () => {
      if (!!createdBike) {
        handleUpdateBike(createdBike);
        setCreatedBike(undefined);
      }
    },
  });

  const customer = data?.getCustomer;

  const handleBikeSelect = (bike: getCustomer_getCustomer_bikes) => {
    setSelected(bike.id);
    handleUpdateBike(bike);
  };

  React.useEffect(() => {
    if (customerId) {
      getBikes({ variables: { id: customerId } });
    }
    // disabled because it adds handleUpdateBike which will cause rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  React.useEffect(() => {
    setSelected(bikeId);
  }, [bikeId]);

  return (
    <div className={classes.container}>
      <EditBikeDialog bike={EditBike} onClose={handleBikeClose} />
      <AddBikeDialog customerId={addBike} onClose={handleAddBikeClose} />
      <div className={classes.unit} data-test="bikeSelection">
        <Box p={2} paddingBottom={0}>
          <Box display="flex" justifyContent="space-between">
            <Typo variant="h6" t="model.bike" />
            {!!customerId && (
              <IconButton onClick={handleAddBikeOpen} size="small">
                <AddBoxIcon color="primary" />
              </IconButton>
            )}
          </Box>
          <Divider />
        </Box>
        {customerId ? (
          <BikesList
            isLoading={loading}
            hideTitleBar
            bikes={customer?.bikes}
            onEdit={(bike: getCustomer_getCustomer_bikes) => setEditBike(bike)}
            onAdd={handleAddBikeOpen}
            onSelect={(bike: getCustomer_getCustomer_bikes) =>
              handleBikeSelect(bike)
            }
            selectedId={selected}
          />
        ) : (
          <Box p={2}>
            <Typo t="app.customerOrderDraft.subOrders.repair.noBike" />
          </Box>
        )}
      </div>
    </div>
  );
};

BikeSelection.propTypes = {
  handleUpdateBike: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  bikeId: PropTypes.string,
};
BikeSelection.defaultProps = {
  bikeId: undefined,
  customerId: undefined,
};

export default BikeSelection;
