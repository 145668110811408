import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Typo from '../Typo';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '80vh',
    },
    list: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    borderRadius: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
  })
);

interface Category {
  id: string;
  name: string;
  subCategories?: Category[];
}

export interface CategoriesTreeViewProps {
  title?: string;
  treeData: Category[];
  onCategorySelect: (id: string) => void;
  onItemSelect?: (id: string) => void;
  isLoading?: boolean;
  selectedId?: string | null;
  hasRemoveCategory?: boolean;
  height?: number;
}

const CategoriesTreeView: React.FC<React.PropsWithChildren<CategoriesTreeViewProps>> = ({
  treeData,
  onCategorySelect,
  onItemSelect,
  title = null,
  isLoading = false,
  selectedId = null,
  hasRemoveCategory = false,
  height = undefined,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const intl = useIntl();

  const removeCategory = {
    id: 'null',
    name: intl.formatMessage({
      id: 'app.form.workStepDefinition.product.proposedProductCategory.empty',
    }),
  };

  const handleNodeSelect = (e: React.ChangeEvent<{}>, value: string) => {
    const isExpanded = expanded.find((id: string) => id === value);

    if (!isExpanded) {
      setExpanded((pref: string[]) => [...pref, value]);
      onCategorySelect && onCategorySelect(value);
      return;
    }

    setExpanded((pref) => pref.filter((id: string) => id !== value));
  };

  const renderTree = (category: Category) => {
    return (
      <TreeItem
        key={category.id}
        nodeId={category.id}
        label={category.name}
        expandIcon={
          category.id === selectedId ? <BeenhereIcon color="primary" /> : null
        }
        endIcon={
          category.id === selectedId ? <BeenhereIcon color="primary" /> : null
        }
        collapseIcon={
          category.id === selectedId ? <BeenhereIcon color="primary" /> : null
        }
      >
        {Array.isArray(category.subCategories) ? (
          category.subCategories.map((c: Category) => renderTree(c))
        ) : (
          <div />
        )}
      </TreeItem>
    );
  };

  return (
    <>
      {isLoading && (
        <Box position="absolute" top={0} width="100%" ml={-2}>
          <LinearProgress className={classes.borderRadius} />
        </Box>
      )}
      <Box className={classes.container} maxHeight={height}>
        <Box display="flex" justifyContent="space-between">
          {title ? <Typo variant="h5" t={title} /> : null}
        </Box>
        <Box marginTop={2} className={classes.list}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<ChevronRightIcon />}
            onNodeSelect={handleNodeSelect}
            expanded={expanded}
          >
            {hasRemoveCategory ? (
              <TreeItem
                key={removeCategory.id}
                nodeId={removeCategory.id}
                label={removeCategory.name}
                expandIcon={
                  !selectedId ? <BeenhereIcon color="primary" /> : null
                }
                endIcon={!selectedId ? <BeenhereIcon color="primary" /> : null}
                collapseIcon={
                  !selectedId ? <BeenhereIcon color="primary" /> : null
                }
              ></TreeItem>
            ) : null}
            {treeData.length
              ? treeData.map((category: Category) => renderTree(category))
              : null}
          </TreeView>
        </Box>
      </Box>
    </>
  );
};

CategoriesTreeView.propTypes = {
  title: PropTypes.string,
  // treeData: Category[];
  onCategorySelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
CategoriesTreeView.defaultProps = {
  isLoading: false,
};

export default CategoriesTreeView;
