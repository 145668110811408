import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { RawDraftContentState } from 'draft-js';

import WYSIWYG from '../../../WYSIWYG';
import Typo from '../../../Typo';

export interface FormNotesProps {
  name: string;
  defaultValue?: RawDraftContentState;
  disabled?: boolean;
}

const FormNotes: React.FC<React.PropsWithChildren<FormNotesProps>> = ({
  name,
  defaultValue = undefined,
  disabled = false,
}) => {
  const { register, setValue } = useFormContext();

  const handleUpdate = (content: RawDraftContentState) => {
    setValue(name, content);
  };

  React.useEffect(() => {
    register({ name: name, type: 'custom' });
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, register, setValue]);

  return (
    <Box marginTop={1} marginBottom={1}>
      <Box marginBottom={0.5}>
        <Typo t="app.form.bike.notes.label" />
      </Box>
      <WYSIWYG
        inDialog
        initialContent={defaultValue}
        onContentChange={handleUpdate}
      />
    </Box>
  );
};

FormNotes.propTypes = {
  // defaultValue, // TODO: PropTypes
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
FormNotes.defaultProps = {
  disabled: false,
};

export default FormNotes;
