import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useService } from '@xstate/react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Paper from '../../../../Paper';
import ButtonRow from '../../../../ButtonRow';
import Typo from '../../../../Typo';
import Products from './Products';
import ConfirmationDialog from '../../../../Dialog/ConfirmationDialog';
import { formattedCurrency } from '../../../../../lib/helper/numbers';
import SubOrderCompleted from '../components/SubOrderCompleted';
import { SubOrderType } from '../../../machines/subOrdersMachine/types';
import { ProductOrderMachine } from '../../../machines/subOrders/productOrder/types';
import { CreateProduct } from '../../../machines/orderDraftMachine/types';
import { ProductQuantityStatus } from '../../../../../types/graphql-global-types';

interface Product {
  id: string;
  name: string;
  price: number;
  quantity: number;
  manufacturer: string | null;
}

export interface OrderProps {
  machine: ProductOrderMachine;
}

const Order: React.FC<React.PropsWithChildren<OrderProps>> = ({ machine }) => {
  const [state, transition, service] = useService(machine);
  const [open, setOpen] = React.useState<boolean>(false);
  const isCompleted = state.matches('completed');

  const { data } = state.context;

  const { products } = data;

  /**
   * calculate price based on prices of products
   */
  const price =
    (products.length &&
      products.reduce(
        (acc: number, curr: Product) => acc + (curr.price * curr.quantity || 1),
        0
      )) ??
    0;

  /**
   * list of product names to display in completed infoText
   */
  const productNames = products
    .map((product: Product) => product.name)
    .join(',');

  const handleRemove = () => {
    transition('REMOVE_ORDER', { id: machine.id });
  };

  const handleEdit = () => {
    transition('EDIT_ORDER', { id: machine.id });
  };

  const handleAddProduct = (data: CreateProduct) => {
    transition('ADD_PRODUCT', { data });
  };

  const handleRemoveProduct = (id: string) => {
    transition('REMOVE_PRODUCT', { id });
  };

  const handleUpdateProductCount = (id: string, quantity: number) => {
    transition('UPDATE_PRODUCT_COUNT', { id, quantity });
  };

  const handleUpdateProductStatus = (
    id: string,
    status: ProductQuantityStatus
  ) => {
    transition('UPDATE_PRODUCT_STATUS', {
      id,
      status,
    });
  };

  React.useEffect(() => {
    service.onTransition((state) =>
      console.log('ProduktOrder TRANSITION', state)
    );
  }, [service]);

  if (isCompleted) {
    return (
      <>
        <ConfirmationDialog
          open={open}
          onConfirmed={handleRemove}
          onDeclined={() => setOpen(false)}
          message="app.customerOrderDraft.subOrders.order.remove.confirmation"
        />
        <SubOrderCompleted
          subOrder={SubOrderType.ORDER}
          infoText={productNames}
          price={price}
          editRepair={handleEdit}
          removeRepair={() => setOpen(true)}
        />
      </>
    );
  }

  return <>
    <ConfirmationDialog
      open={open}
      onConfirmed={handleRemove}
      onDeclined={() => setOpen(false)}
      message="app.customerOrderDraft.subOrders.order.remove.confirmation"
    />
    <Paper padding>
      <Typo
        variant="h5"
        t="app.customerOrderDraft.subOrders.order"
        gutterBottom
      />

      <Box
        bgcolor="background.default"
        borderRadius={1}
        padding={2}
      >
        <Typo variant="h6" t="model.repair.products" />

        <Box marginBottom={2}>
          <Divider />
        </Box>

        <Products
          selected={products}
          handleAddProduct={handleAddProduct}
          handleRemoveProduct={handleRemoveProduct}
          handleUpdateProductCount={handleUpdateProductCount}
          handleUpdateProductStatus={handleUpdateProductStatus}
          isValidating={false}
        />
      </Box>

      <Box marginTop={3}>
        <Typo variant="h6" t="app.customerOrderDraft.overview.title" />

        <Box marginBottom={2}>
          <Divider />
        </Box>

        <Typography variant="h5" color="primary" align="right">
          {formattedCurrency(price)}
        </Typography>
      </Box>

      <div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          marginTop={6}
          marginBottom={1}
          color="red"
        >
          {!products.length && (
            <code color="inherit">
              <FormattedMessage id="app.customerOrderDraft.subOrders.order.validation.noProduct" />
            </code>
          )}
        </Box>
        <ButtonRow
          isSecondaryDelete
          isElevationDisabled
          primaryLabel="app.customerOrderDraft.subOrders.order.save"
          secondaryLabel="app.customerOrderDraft.subOrders.order.remove"
          onPrimaryAction={() =>
            transition('COMPLETE_ORDER', { id: machine.id })
          }
          isPrimaryDisabled={!products.length}
          onSecondaryAction={() => setOpen(true)}
        />
      </div>
    </Paper>
  </>;
};

Order.propTypes = {};
Order.defaultProps = {};

export default Order;
