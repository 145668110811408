import React from 'react';
import PropTypes from 'prop-types';
import TableBase from '../../TableBase';
import { useLazyQuery } from '@apollo/client';
import {
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import { GET_PRODUCT_QUANTITIES } from '../../../../gql/product/queries';
import RepairProductStatusChip from '../../../RepairProductStatusChip';
import { ProductQuantityStatus } from '../../../../types/graphql-global-types';
import PriceRow from '../../Rows/PriceRow';
import {
  defaultRowsPerPageOptions,
  DEFAULT_PRODUCT_QUANTITITES_SORTMODEL,
} from '../../../../lib/constants';
import DateRow from '../../Rows/DateRow';
import usePersistedState from '../../../../lib/helper/usePersistedState';
import { getActiveFilters } from '../../../../lib/helper/filterModel';
import CheckBoxGroup from '../../../CheckBoxGroup';
import Link from '../../../Link';
import {
  getProductQuantities as getProductQuantitiesI,
  getProductQuantitiesVariables,
} from '../../../../gql/product/types/getProductQuantities';

export interface ProductQuantitiesTableProps {
  onRowClick?: (row: GridRowParams) => void;
  rowsPerPageOptions?: number[];
  ref?: any;
  initialLimit?: number;
  initialFilterModel: any;
  persistedKey: string;
}

type TableHandle = {
  refetch: () => void;
};

const ProductQuantitiesTable = React.forwardRef(
  (
    {
      onRowClick,
      rowsPerPageOptions,
      initialLimit = 10,
      persistedKey,
      initialFilterModel,
    }: ProductQuantitiesTableProps,
    ref: React.Ref<TableHandle>
  ) => {
    const intl = useIntl();

    // persisted sortModel
    const [sortModel, setSortModel] = usePersistedState(
      `${persistedKey}SortModel`,
      DEFAULT_PRODUCT_QUANTITITES_SORTMODEL
    );

    // persisted filterModel
    const [filterModel, setFilterModel] = usePersistedState(
      `${persistedKey}FilterModel`,
      initialFilterModel
    );

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(initialLimit);
    const [debounce, setDebounce] = React.useState<boolean>(false);

    const [getProductQuantities, { data, loading, refetch }] = useLazyQuery<
      getProductQuantitiesI,
      getProductQuantitiesVariables
    >(GET_PRODUCT_QUANTITIES, {
      notifyOnNetworkStatusChange: true,
      variables: {
        offset: 0,
        limit,
        field: sortModel?.[0]?.field,
        direction: sortModel?.[0]?.sort,
        status: getActiveFilters(filterModel) as ProductQuantityStatus[],
      },
      onCompleted: () => setDebounce(false),
    });

    const columns: GridColDef[] = [
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.createdAt',
        }),
        field: 'createdAt',
        flex: 1,
        renderCell: (params: GridCellParams) => (
          <DateRow date={params.value as string} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.customerOrder',
        }),
        field: 'customerOrderId',
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <Link
            to={`/customerOrders/${params.value}`}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
          >
            {params.value}
          </Link>
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.repairCode',
        }),
        field: 'repairCode',
        flex: 1,
        sortable: false, //needs to added to ProductQuantitySortField args
        renderCell: (params: GridCellParams) => (
          <Link
            to={`/repairs/${params.value}`}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
          >
            {params.value}
          </Link>
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.name',
        }),
        field: 'product.name',
        flex: 2,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <Typography>{params.row?.product?.name}</Typography>
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.quantity',
        }),
        field: 'quantity',
        flex: 0.8,
        sortable: true,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.price',
        }),
        field: 'product.price',
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <PriceRow price={params.row?.product?.price as number} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.productQuantities.status',
        }),
        field: 'status',
        flex: 1,
        sortable: true,
        renderCell: (params: GridCellParams) => (
          <RepairProductStatusChip
            status={params.value as ProductQuantityStatus}
          />
        ),
      },
    ];

    const handlePageDebounce = (page: number) => {
      if (debounce) return;
      setPage(page);
    };

    const handlePageChange = (page: number) => {
      handlePageDebounce(page);
    };

    const handlePageSizeChange = (pageSize: number) => {
      setLimit(pageSize);
    };

    //UPDATE FIX
    const handleSortModelChange = React.useCallback(
      (sortModel: GridSortModel) => {
        setSortModel(sortModel);
      },
      [setSortModel]
    );

    const handleRefetch = () => {
      refetch && refetch();
    };

    // updateFilterModel
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterModel({
        ...filterModel,
        [event.target.name]: event.target.checked,
      });
    };

    React.useImperativeHandle(ref, () => ({
      refetch() {
        handleRefetch();
      },
    }));

    React.useEffect(() => {
      // make sure request is debounced to prevent multiple requests
      if (!debounce) {
        let offset = 0;

        if (page > 0) {
          offset = page * limit;
        }

        getProductQuantities({
          variables: {
            offset,
            limit,
            field: sortModel?.[0]?.field,
            direction: sortModel?.[0]?.sort,
            status: getActiveFilters(filterModel) as ProductQuantityStatus[],
          },
        });
      }
    }, [debounce, limit, page, sortModel, getProductQuantities, filterModel]);

    // refetch
    React.useEffect(() => {
      refetch && refetch();
    }, [refetch]);

    return (
      <TableBase
        onReload={handleRefetch}
        onPaper
        title="app.table.colDef.productQuantities"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingOrder={['asc', 'desc']}
        columns={columns}
        rows={data?.getProductQuantities?.items || []}
        loading={loading}
        page={page}
        pageSize={limit}
        rowCount={data?.getProductQuantities?.totalCount || 0}
        onRowClick={onRowClick}
        onPageChange={handlePageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={handlePageSizeChange}
        FilterComponent={
          <CheckBoxGroup
            checkBoxes={Object.keys(filterModel).map((status: string) => ({
              checked: filterModel[status],
              name: status,
              onChange: handleChange,
              label: `model.product.status.${status}`,
            }))}
          />
        }
      />
    );
  }
);

ProductQuantitiesTable.propTypes = {
  onRowClick: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  initialLimit: PropTypes.number,
};
ProductQuantitiesTable.defaultProps = {
  onRowClick: undefined,
  rowsPerPageOptions: defaultRowsPerPageOptions,
  initialLimit: 10,
};

export default React.memo(ProductQuantitiesTable);
