import React from 'react';
import Box from '@mui/material/Box';

import Paper from '../Paper';
import Typo from '../Typo';
import Date from '../Date';
import CustomerInformation from './CustomerInformation';
import CostsTable, { ProductOrderI, RepairI } from '../CostsTable';
import { getCustomerOrder_getCustomerOrder } from '../../gql/customerOrder/types/getCustomerOrder';
import { createCustomerOrder_createCustomerOrder } from '../../gql/customerOrder/types/createCustomerOrder';
import { TAX } from '../../lib/constants';

import WorkshopInformation from './LocationInformation';
import { getLocation_getLocation } from '../../gql/location/types/getLocation';

export interface CostEstimationProps {
  customerOrder:
    | getCustomerOrder_getCustomerOrder
    | createCustomerOrder_createCustomerOrder;
  location?: getLocation_getLocation | null; // TODO remove optional
  printRef: any;
}

const CostEstimation: React.FC<
  React.PropsWithChildren<CostEstimationProps>
> = ({ customerOrder, location, printRef }) => {
  const {
    repairs,
    productOrders,
    createdUser,
    customer,
    createdAt,
    subtotal,
    taxAmount,
    total,
    id,
  } = customerOrder;

  return (
    <Paper>
      <div ref={printRef}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          padding={2}
        >
          <div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <CustomerInformation customer={customer} />
              {location && <WorkshopInformation location={location} />}
            </Box>

            <Date date={createdAt} />
            <Typo
              gutterBottom
              variant="h5"
              t="app.customerOrderDraft.costEstimation"
            />

            <Typo
              gutterBottom
              t="app.customerOrderDraft.customerOrderId"
              values={{ customerOrderId: id }}
            />

            <Box marginY={2}>
              <CostsTable
                repairs={repairs as RepairI[]}
                productOrders={productOrders as ProductOrderI[]}
                subTotal={subtotal}
                taxAmount={taxAmount}
                total={total}
                tax={TAX}
              />
            </Box>
          </div>

          <Box marginTop={2}>
            <Typo
              t="app.customerOrderDraft.costEstimation.overview.user"
              values={{
                user: `${createdUser?.firstname ?? ''} ${
                  createdUser?.lastname ?? ''
                }`,
              }}
            />
          </Box>
        </Box>
      </div>
    </Paper>
  );
};

CostEstimation.propTypes = {};
CostEstimation.defaultProps = {};

export default CostEstimation;
