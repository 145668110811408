import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import PageContainer from '../../../components/PageContainer';
import CustomerForm from '../../../components/Form/CustomerForm';
import { CREATE_CUSTOMER } from '../../../gql/customer/mutations/customer';
import PageActionButtons from '../../../components/PageActionButtons';
import { CreateCustomerInput } from '../../../types/graphql-global-types';
import {
  createCustomer as createCustomerI,
  createCustomerVariables,
} from '../../../gql/customer/mutations/types/createCustomer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerContainerPadding: {
      padding: theme.spacing(1),
    },
  })
);

export interface NewCustomerPageProps {}

const NewCustomerPage: React.FC<React.PropsWithChildren<NewCustomerPageProps>> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [createCustomer, { loading }] = useMutation<
    createCustomerI,
    createCustomerVariables
  >(CREATE_CUSTOMER, {
    onCompleted: ({ createCustomer }) => history.push(`${createCustomer.id}`),
    onError: () => {},
  });

  const handleSubmit = (data: CreateCustomerInput) => {
    createCustomer({
      variables: { data },
    });
  };

  return (
    <PageContainer page="newCustomer" loading={loading}>
      <PageActionButtons hasNavigateBack />
      <Paper className={classes.customerContainerPadding}>
        <CustomerForm data={{}} onSubmit={handleSubmit} />
      </Paper>
    </PageContainer>
  );
};

NewCustomerPage.propTypes = {};
NewCustomerPage.defaultProps = {};

export default NewCustomerPage;
