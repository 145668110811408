import React from 'react';
import PropTypes from 'prop-types';
import TableBase from '../../TableBase';
import { useLazyQuery } from '@apollo/client';
import {
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@mui/material';

import AddressRow from '../../Rows/AddressRow';
import { ALL_CUSTOMERS } from '../../../../gql/customer/queries';
import {
  defaultRowsPerPageOptions,
  DEFAULT_CUSTOMERS_SORTMODEL,
} from '../../../../lib/constants';
import DateRow from '../../Rows/DateRow';
import usePersistedState from '../../../../lib/helper/usePersistedState';
import {
  CustomersOverview,
  CustomersOverviewVariables,
} from '../../../../gql/customer/types/CustomersOverview';

export interface CustomersTableProps {
  onRowClick?: (row: GridRowParams) => void;
  rowsPerPageOptions?: number[];
  ref?: any;
  initialLimit?: number;
  persistedKey: string;
}

type TableHandle = {
  refetch: () => void;
};

const CustomersTable = React.forwardRef(
  (
    {
      onRowClick,
      rowsPerPageOptions,
      initialLimit = 10,
      persistedKey,
    }: CustomersTableProps,
    ref: React.Ref<TableHandle>
  ) => {
    const intl = useIntl();

    // persisted sortModel
    const [sortModel, setSortModel] = usePersistedState(
      `${persistedKey}SortModel`,
      DEFAULT_CUSTOMERS_SORTMODEL
    );

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(initialLimit);
    const [debounce, setDebounce] = React.useState<boolean>(false);
    const [usernameSearch, setUsernameSearch] = React.useState<
      string | undefined
    >(undefined);

    const [getCustomers, { data, loading, refetch }] = useLazyQuery<
      CustomersOverview,
      CustomersOverviewVariables
    >(ALL_CUSTOMERS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        offset: 0,
        limit,
        field: sortModel?.[0]?.field,
        direction: sortModel?.[0]?.sort,
        lastname: usernameSearch?.length ? usernameSearch : undefined,
        firstname: usernameSearch?.length ? usernameSearch : undefined,
      },
      onCompleted: () => setDebounce(false),
    });

    const columns: GridColDef[] = [
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.createdAt',
        }),
        field: 'createdAt',
        flex: 1,
        renderCell: (params: GridCellParams) => (
          <DateRow date={params.value as string} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.gender',
        }),
        field: 'gender',
        flex: 1,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
          if (!params.value) return '-';
          return intl.formatMessage({
            id: `model.customer.gender.${params.value}`,
          });
        },
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.firstname',
        }),
        field: 'firstname',
        flex: 1,
        sortable: true,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.lastname',
        }),
        field: 'lastname',
        flex: 1,
        sortable: true,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.email',
        }),
        field: 'email',
        flex: 1,
        sortable: true,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.phone',
        }),
        field: 'phone',
        flex: 1,
        sortable: false,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customers.address',
        }),
        field: 'address',
        flex: 1,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <AddressRow address={params.value as any} />
        ),
      },
    ];

    const handlePageDebounce = (page: number) => {
      if (debounce) return;
      setPage(page);
    };

    const handlePageChange = (page: number) => {
      handlePageDebounce(page);
    };

    const handlePageSizeChange = (pageSize: number) => {
      setLimit(pageSize);
    };

    //UPDATE FIX
    const handleSortModelChange = React.useCallback(
      (sortModel: GridSortModel) => {
        setSortModel(sortModel);
      },
      [setSortModel]
    );

    const handleRefetch = () => {
      refetch && refetch();
    };

    React.useImperativeHandle(ref, () => ({
      refetch() {
        handleRefetch();
      },
    }));

    React.useEffect(() => {
      // make sure request is debounced to prevent multiple requests
      if (!debounce) {
        let offset = 0;

        if (page > 0) {
          offset = page * limit;
        }

        getCustomers({
          variables: {
            offset,
            limit,
            field: sortModel?.[0]?.field,
            direction: sortModel?.[0]?.sort,
            lastname: usernameSearch?.length ? usernameSearch : undefined,
            firstname: usernameSearch?.length ? usernameSearch : undefined,
          },
        });
      }
    }, [debounce, limit, page, sortModel, usernameSearch, getCustomers]);

    // refetch
    React.useEffect(() => {
      refetch && refetch();
    }, [refetch]);

    return (
      <TableBase
        onReload={handleRefetch}
        onPaper
        title="app.table.colDef.customers"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingOrder={['asc', 'desc']}
        columns={columns}
        rows={data?.getCustomers?.items || []}
        loading={loading}
        page={page}
        pageSize={limit}
        rowCount={data?.getCustomers?.totalCount || 0}
        onRowClick={onRowClick}
        onPageChange={handlePageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={handlePageSizeChange}
        FilterComponent={
          <TextField
            fullWidth
            margin="dense"
            label={<FormattedMessage id={'app.common.search'} />}
            variant="outlined"
            onChange={(event) => setUsernameSearch(event.target.value)}
          />
        }
      />
    );
  }
);

CustomersTable.propTypes = {
  onRowClick: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  initialLimit: PropTypes.number,
};
CustomersTable.defaultProps = {
  onRowClick: undefined,
  initialLimit: 10,
  rowsPerPageOptions: defaultRowsPerPageOptions,
};

export default React.memo(CustomersTable);
