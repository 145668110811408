import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { formattedCurrency } from '../../../../lib/helper/numbers';

export interface PriceRowProps {
  price: number;
}

/**
 * Renders Price with currency.
 */
const PriceRow: React.FC<React.PropsWithChildren<PriceRowProps>> = ({ price }) => (
  <Typography>{formattedCurrency(price)}</Typography>
);

PriceRow.propTypes = {
  price: PropTypes.number.isRequired,
};
PriceRow.defaultProps = {};

export default PriceRow;
