import React from 'react';
import PropTypes from 'prop-types';
import { RawDraftContentState } from 'react-draft-wysiwyg';

import DialogBase from '..';
import WYSIWYG from '../../WYSIWYG';
import Box from '@mui/material/Box';

export interface WYSIWYGDialogProps {
  open: boolean;
  initialContent?: RawDraftContentState;
  onSubmit: (content: RawDraftContentState) => void;
  onCancel: () => void;
  title?: string;
  readOnly?: boolean;
}

const WYSIWYGDialog: React.FC<React.PropsWithChildren<WYSIWYGDialogProps>> = ({
  open,
  title = 'app.dialog.notes.title',
  onSubmit,
  onCancel,
  initialContent,
  readOnly = false,
}) => {
  const ref = React.useRef<any>();

  const handleSubmit = () => {
    if (ref?.current) {
      onSubmit(ref?.current?.getContent());
    }
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      title={title}
      open={open}
      primaryAction={!readOnly ? handleSubmit : undefined}
      secondaryAction={onCancel}
    >
      <Box p={2} bgcolor="background.default" borderRadius={1}>
        <WYSIWYG
          inDialog
          readOnly={readOnly}
          initialContent={initialContent}
          ref={ref}
        />
      </Box>
    </DialogBase>
  );
};

WYSIWYGDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  // initialContent: PropTypes.shape({}),
};
WYSIWYGDialog.defaultProps = {};

export default WYSIWYGDialog;
