import React from 'react';

import PageContainer from '../../components/PageContainer';
import PageActionButtons from '../../components/PageActionButtons';
import Button from '../../components/Button';

export interface LocationsPageProps {}

const LocationsPage: React.FC<
  React.PropsWithChildren<LocationsPageProps>
> = () => {
  const handleAddLocation = () => {};

  return (
    <PageContainer page="locations">
      <PageActionButtons hasNavigateBack>
        <Button
          variant="contained"
          color="primary"
          t="page.locations.button.add"
          onClick={handleAddLocation}
          disabled
        />
      </PageActionButtons>
    </PageContainer>
  );
};

LocationsPage.propTypes = {};
LocationsPage.defaultProps = {};

export default LocationsPage;
