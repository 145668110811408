import { VALIDATE_REPAIR } from '../../../../../../gql/repair/mutations';
import { validateRepair as validateRepairI } from '../../../../../../gql/repair/types/validateRepair';
import client from '../../../../../../lib/apolloClient';
import { getNumberFromString } from '../../../../../../lib/helper/numbers';
import { SkillLevel } from '../../../../../../types/graphql-global-types';
import { formatRepairValidation } from '../../../orderDraftMachine/helpers';
import {
  CreateProduct,
  CreateWorkStep,
} from '../../../orderDraftMachine/types';
import { RepairContext } from '../types';

export const getWorkLoadSum = (workSteps: CreateWorkStep[]): number =>
  workSteps?.reduce(
    (acc: number, curr: CreateWorkStep) => acc + curr.setWorkload,
    0
  ) || 0;

export const getWorkloadPrice = (sum: number, rate: number): number =>
  sum * rate || 0;

export const getRepairTotal = (
  // products: CreateProducts[],
  workSteps: CreateWorkStep[],
  workloadPrice: number
) => {
  // additional products
  let productsTotal = 0;

  // products for workSteps
  let requiredProductsTotal = 0;

  //pice: workStep requiredProducts
  // eslint-disable-next-line array-callback-return
  workSteps?.map((workStep: CreateWorkStep) => {
    if (workStep.requiredProducts.length) {
      requiredProductsTotal =
        requiredProductsTotal +
        workStep.requiredProducts.reduce(
          (acc: number, curr: CreateProduct) =>
            acc + curr.price * curr.quantity,
          0
        );
    }
  });

  // price: additional products
  // if (products.length) {
  //   productsTotal = products.reduce(
  //     (acc: number, curr: CreateProduct) => acc + curr.price * curr.quantity,
  //     0
  //   );
  // }
  return productsTotal + requiredProductsTotal + workloadPrice;
};

export const getRepairSkillLevel = (
  workSteps: CreateWorkStep[]
): SkillLevel => {
  let updated;

  // set initial skillLevel if no workstep is selected.
  if (!workSteps.length) {
    return SkillLevel.LEVEL1;
  }

  // find highst skilllevel of repair
  updated = workSteps.reduce((acc: CreateWorkStep, curr: CreateWorkStep) =>
    getNumberFromString(curr?.skillLevel as string) >
    getNumberFromString(acc?.skillLevel as string)
      ? curr
      : acc
  );

  // set updated skillLevel
  return updated?.skillLevel || SkillLevel.LEVEL1;
};

export const validateRepair = (repairData: RepairContext) => {
  const formattedRepair = formatRepairValidation(repairData);

  return client
    .mutate<validateRepairI>({
      mutation: VALIDATE_REPAIR,
      variables: { data: formattedRepair },
    })
    .then((result) => result?.data?.validateRepair)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};
