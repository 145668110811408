import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

import {
  getLocations,
  getLocations_getLocations,
} from '../../gql/location/types/getLocations';
import { GET_LOCATIONS_SELECT } from '../../gql/location/queries';
import Button from '../Button';
import Box from '@mui/material/Box';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import Typo from '../Typo';

export enum LocationUpdateType {
  // USER_DEFAULT_LOCATION = 'USER_DEFAULT_LOCATION',
  REPAIR_LOCATION = 'REPAIR_LOCATION',
}

export interface LocationSelectProps {
  locationUpdateType: LocationUpdateType;
  locationId?: string;
  showConfirmationDialog?: boolean;
  onSubmit?: (locationId?: string) => void;
}

const LocationSelect: React.FC<
  React.PropsWithChildren<LocationSelectProps>
> = ({
  showConfirmationDialog = true,
  locationUpdateType,
  onSubmit = () => null,
  locationId = null,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<getLocations_getLocations>();

  const { data, loading } = useQuery<getLocations>(GET_LOCATIONS_SELECT, {
    onError: () => {},
  });

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLocationSelect = (location: getLocations_getLocations) => {
    setSelected(location);

    if (showConfirmationDialog) {
      setOpenConfirmation(true);
    }
  };

  const handleUpdateLocation = () => {
    setOpenConfirmation(false);

    if (selected) {
      setAnchorEl(null);
      onSubmit(selected.id);
    }
  };

  const defaultLocationId = () => {
    return locationId;
  };

  return (
    <>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open={openConfirmation}
          onConfirmed={handleUpdateLocation}
          onDeclined={() => setOpenConfirmation(false)}
          message={
            locationUpdateType === LocationUpdateType.REPAIR_LOCATION
              ? 'app.locationSelect.repair.confirmation.message'
              : 'app.locationSelect.user.confirmation.message'
          }
          messageValues={{ locationName: selected?.name }}
        />
      )}
      <Button
        onClick={handleOpenMenu as any}
        t={`app.locationSelect.button.${locationUpdateType}`}
        variant="outlined"
        color="primary"
        endIcon={<ExpandMoreIcon />}
        fullWidth
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {loading ? (
          <MenuItem disabled>
            <Box minWidth="160px">
              <CircularProgress size={20} />
            </Box>
          </MenuItem>
        ) : null}
        {data?.getLocations?.map((location: getLocations_getLocations) => (
          <MenuItem
            key={location.id}
            onClick={() => handleLocationSelect(location)}
            disabled={location.id === defaultLocationId()}
          >
            {location.id === defaultLocationId() ? (
              <Typo
                t="app.locationSelect.menuItem.currentLocation"
                values={{ locationName: location.name }}
              />
            ) : (
              <Typography>{location.name}</Typography>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LocationSelect.propTypes = {
  onSubmit: PropTypes.func,
  locationId: PropTypes.string,
  showConfirmationDialog: PropTypes.bool,
  locationUpdateType: PropTypes.oneOf<LocationUpdateType>([
    // LocationUpdateType.USER_DEFAULT_LOCATION,
    LocationUpdateType.REPAIR_LOCATION,
  ]).isRequired,
};
LocationSelect.defaultProps = {
  showConfirmationDialog: true,
  locationId: '',
};

export default LocationSelect;
