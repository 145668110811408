import { GET_LOCATION } from '../gql/location/queries';
import { getLocation } from '../gql/location/types/getLocation';
import { GET_TENANT_SETTINGS } from '../gql/tenant/queries';
import { getTenantSettings } from '../gql/tenant/types/getTenantSettings';
import client from './apolloClient';
import { isLoggedInVar } from './apolloClient/cache';
import { TOKEN_NAME } from './constants';
import history from './history';

/**
 * Performs Login. Takes access token, stores it to localStorage
 * @param token string
 */
export const handleAuth = (token: string) => {
  // store auth token
  localStorage.setItem(TOKEN_NAME, token);

  // set login
  isLoggedInVar(true);

  // move to index
  history.push('/');

  // get tenant settings (Such as workRate price etc.)
  client.query<getTenantSettings>({ query: GET_TENANT_SETTINGS });

  // get location (address, phone etc.)
  client.query<getLocation>({ query: GET_LOCATION });
};

/**
 * Clears apollo-client store and performes logut and removes auth token.
 */
export const logout = async () => {
  // remove all stored information from store
  await client.clearStore();

  // garbage collector
  client.cache.gc();

  // clear token to logout
  // localStorage.removeItem(TOKEN_NAME);
  // localStorage.removeItem(DRAWER_STATE);
  localStorage.clear();

  // logout and
  isLoggedInVar(false);
};
