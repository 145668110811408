import React from 'react';
import PropTypes from 'prop-types';
import TableBase from '../../TableBase';
import { useLazyQuery } from '@apollo/client';
import {
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

import DateRow from '../../Rows/DateRow';
import NameRow, { NameRowName } from '../../Rows/NameRow';
import SkillLevelChip from '../../../SkillLevelChip';
import { GET_REPAIRS } from '../../../../gql/repair/queries';
import RepairStatusChip from '../../../RepairStatusChip';
import {
  defaultRowsPerPageOptions,
  DEFAULT_REPAIRS_SORTMODEL,
} from '../../../../lib/constants';
import CheckBoxGroup from '../../../CheckBoxGroup';
import usePersistedState from '../../../../lib/helper/usePersistedState';
import { getActiveFilters } from '../../../../lib/helper/filterModel';
import {
  getRepairs as getRepairsI,
  getRepairsVariables,
} from '../../../../gql/repair/types/getRepairs';
import { RepairStatus } from '../../../../types/graphql-global-types';

export interface RepairsTableProps {
  rowsPerPageOptions?: number[];
  initialLimit?: number;
  initialFilterModel: any;
  persistedKey: string;
  onRowClick?: (row: GridRowParams) => void;
  ref?: any;
}

type TableHandle = {
  refetch: () => void;
};

const RepairsTable = React.forwardRef(
  (
    {
      onRowClick,
      rowsPerPageOptions,
      initialLimit = 5,
      persistedKey,
      initialFilterModel,
    }: RepairsTableProps,
    ref: React.Ref<TableHandle>
  ) => {
    const intl = useIntl();

    // persisted sortModel
    const [sortModel, setSortModel] = usePersistedState(
      `${persistedKey}SortModel`,
      DEFAULT_REPAIRS_SORTMODEL
    );

    // persisted filterModel
    const [filterModel, setFilterModel] = usePersistedState(
      `${persistedKey}FilterModel`,
      initialFilterModel
    );

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(initialLimit);
    const [debounce, setDebounce] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>();

    const [getRepairs, { data, loading, refetch }] = useLazyQuery<
      getRepairsI,
      getRepairsVariables
    >(GET_REPAIRS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        offset: 0,
        limit,
        field: sortModel?.[0]?.field,
        direction: sortModel?.[0]?.sort,
        status: getActiveFilters(filterModel) as RepairStatus[],
        search: search,
      },
      onCompleted: () => setDebounce(false),
    });

    const columns: GridColDef[] = [
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.repairs.createdAt',
        }),
        field: 'createdAt',
        flex: 1,
        sortable: true,
        renderCell: (params: GridCellParams) => (
          <DateRow date={params.value as string} time />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.repairs.repairCode',
        }),
        field: 'repairCode',
        flex: 1,
        sortable: true,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.repairs.status',
        }),
        field: 'status',
        flex: 1,
        sortable: true,
        renderCell: (params: GridCellParams) => (
          <RepairStatusChip status={params.value as RepairStatus} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.repairs.mechanic',
        }),
        field: 'mechanic',
        flex: 1,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <NameRow name={params.value as NameRowName} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.repairs.skillLevel',
        }),
        field: 'skillLevel',
        flex: 1,
        sortable: true,
        renderCell: (params: GridCellParams) => (
          <SkillLevelChip type={params.value as any} />
        ),
      },
    ];

    const handlePageDebounce = (page: number) => {
      if (debounce) return;
      setPage(page);
    };

    const handlePageChange = (page: number) => {
      handlePageDebounce(page);
    };

    const handlePageSizeChange = (pageSize: number) => {
      setLimit(pageSize);
    };

    //UPDATE FIX
    const handleSortModelChange = React.useCallback(
      (sortModel: GridSortModel) => {
        setSortModel(sortModel);
      },
      [setSortModel]
    );

    const handleRefetch = () => {
      refetch && refetch();
    };

    // updateFilterModel
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterModel({
        ...filterModel,
        [event.target.name]: event.target.checked,
      });
    };

    const handleSetSearch = (input: string) => {
      setSearch(input);
    };

    React.useImperativeHandle(ref, () => ({
      refetch() {
        handleRefetch();
      },
      setSearch(input: string) {
        handleSetSearch(input);
      },
    }));

    React.useEffect(() => {
      // make sure request is debounced to prevent multiple requests
      if (!debounce) {
        let offset = 0;

        if (page > 0) {
          offset = page * limit;
        }

        getRepairs({
          variables: {
            offset,
            limit,
            field: sortModel?.[0]?.field,
            direction: sortModel?.[0]?.sort,
            status: getActiveFilters(filterModel) as RepairStatus[],
            search: search,
          },
        });
      }
    }, [debounce, limit, page, sortModel, getRepairs, filterModel, search]);

    // refetch
    React.useEffect(() => {
      refetch && refetch();
    }, [refetch]);

    return (
      <TableBase
        onReload={handleRefetch}
        onPaper
        title="app.table.colDef.repairs"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingOrder={['asc', 'desc']}
        columns={columns}
        rows={data?.getRepairs?.items || []}
        loading={loading}
        page={page}
        pageSize={limit}
        rowCount={data?.getRepairs?.totalCount || 0}
        onRowClick={onRowClick}
        onPageChange={handlePageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={handlePageSizeChange}
        FilterComponent={
          <CheckBoxGroup
            checkBoxes={Object.keys(filterModel).map((status: string) => ({
              checked: filterModel[status],
              name: status,
              onChange: handleChange,
              label: `model.repair.status.${status}`,
            }))}
          />
        }
      />
    );
  }
);

RepairsTable.propTypes = {
  onRowClick: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  // status: RepairStatus[];
  initialLimit: PropTypes.number,
};
RepairsTable.defaultProps = {
  onRowClick: undefined,
  initialLimit: 5,
  rowsPerPageOptions: defaultRowsPerPageOptions,
};

export default React.memo(RepairsTable);
