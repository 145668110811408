import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import Typo from '../Typo';

interface CheckboxI {
  checked: boolean;
  name: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string;
  disabled?: boolean;
}

export interface CheckBoxGroupProps {
  row?: boolean;
  checkBoxes: CheckboxI[];
}

const CheckBoxGroup: React.FC<React.PropsWithChildren<CheckBoxGroupProps>> = ({
  row = true,
  checkBoxes,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent={matches ? 'flex-end' : 'flex-start'}
      minWidth={checkBoxes.length * 120}
    >
      <FormGroup row={row}>
        {checkBoxes?.map((box: CheckboxI) => (
          <FormControlLabel
            key={box.name}
            control={
              <Checkbox
                disabled={box.disabled}
                color="primary"
                checked={box.checked}
                onChange={box.onChange}
                name={box.name}
              />
            }
            label={<Typo t={box.label} />}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

CheckBoxGroup.propTypes = {
  row: PropTypes.bool,
  checkBoxes: PropTypes.arrayOf<CheckboxI>(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
CheckBoxGroup.defaultProps = {
  row: true,
};

export default CheckBoxGroup;
