import { GridSortModel } from '@mui/x-data-grid';
import { ProductQuantityStatus } from '../types/graphql-global-types';

export const DRAWER_WIDTH = 240;
export const DRAWER_WIDTH_MOBILE = 68;

export const TOKEN_NAME = 'token';

export const REPAIR_CODE_EXPRESSION = /\d{5}-R\d{2}/;

export const HISTORY_CHANGETYPE_SMS = 'customer.notified';
export const SMS_CHARACTER_LIMIT = 160;

export const TAX = 19; // FIXME: taxes should not be constant

export const DEFAULT_PRODUCT_STATUS: ProductQuantityStatus =
  ProductQuantityStatus.OPEN;

export const DEFAULT_CUSTOMER_ORDER_SORTMODEL: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

export const DEFAULT_CUSTOMERS_SORTMODEL: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

export const DEFAULT_PRODUCT_QUANTITITES_SORTMODEL: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

export const DEFAULT_REPAIRS_SORTMODEL: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

export const DEFAULT_NOTES_SORTMODEL: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

export const DEFAULT_REPAIRS_FILTERS = {
  OPEN: true,
  IN_PROGRESS: true,
  WORK_DONE: true,
  PAUSED: false,
};

export const defaultRowsPerPageOptions = [5, 10];
