import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';

import Typo from '../../../../../components/Typo';
import { NoteStatus } from '../../../../../types/graphql-global-types';

export interface EditNoteProps {
  status: NoteStatus;
  onEdit: () => void;
  onStatusUpdate: () => void;
}

const EditNote: React.FC<React.PropsWithChildren<EditNoteProps>> = ({
  status,
  onEdit,
  onStatusUpdate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  const handleStatusUpdate = () => {
    onStatusUpdate();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleOpen} size="large">
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleStatusUpdate}>
          {status === NoteStatus.OPEN ? (
            <>
              <ListItemIcon>
                <DoneIcon color="primary" />
              </ListItemIcon>
              <Typo t="page.notes.note.update.done" />
            </>
          ) : null}
          {status === NoteStatus.DONE ? (
            <>
              <ListItemIcon>
                <UndoIcon color="primary" />
              </ListItemIcon>
              <Typo t="page.notes.note.update.open" />
            </>
          ) : null}
        </MenuItem>
        {status === NoteStatus.OPEN ? (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typo t="page.notes.note.update.edit" />
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
};

EditNote.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
};
EditNote.defaultProps = {};

export default EditNote;
