import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Bike from './Bike';
import Typo from '../Typo';
import LinearProgress from '@mui/material/LinearProgress';
import { getCustomer_getCustomer_bikes } from '../../gql/customer/types/getCustomer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      width: '100%',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      minHeight: 150,
    },
    item: {
      width: 440,
      marginRight: theme.spacing(1),
    },
    loading: {
      position: 'absolute',
      top: 0,
      marginLeft: -theme.spacing(2),
      width: '100%',
    },
  })
);

export interface BikesListProps {
  bikes?: getCustomer_getCustomer_bikes[] | null;
  onAdd?: () => void;
  onEdit: (bike: getCustomer_getCustomer_bikes) => void;
  onSelect?: (bike: getCustomer_getCustomer_bikes) => void;
  selectedId?: string;
  hideTitleBar?: boolean;
  isLoading?: boolean;
}

const BikesList: React.FC<React.PropsWithChildren<BikesListProps>> = ({
  bikes,
  onEdit,
  onAdd = undefined,
  onSelect = undefined,
  selectedId = undefined,
  hideTitleBar = false,
  isLoading = false,
}) => {
  const classes = useStyles();

  return (
    <Box>
      {hideTitleBar ? null : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typo variant="h5" color="primary" t="app.bikeList.title" />
          {!!onAdd && (
            <IconButton onClick={() => onAdd()} size="large">
              <AddBoxIcon color="primary" />
            </IconButton>
          )}
        </Box>
      )}

      <div className={classes.list}>
        {isLoading && (
          <div className={classes.loading}>
            <LinearProgress />
          </div>
        )}

        {bikes?.map((bike: getCustomer_getCustomer_bikes) => (
          <div key={bike.id}>
            <Bike
              bike={bike}
              onEdit={onEdit}
              onSelect={onSelect}
              isSelected={selectedId === bike.id}
            />
          </div>
        ))}

        {!isLoading && !bikes?.length && <Typo t="app.bikeList.bike.empty" />}
      </div>
    </Box>
  );
};

BikesList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onSelect: PropTypes.func,
  selectedId: PropTypes.string,
  hideTitleBar: PropTypes.bool,
  isLoading: PropTypes.bool,
};
BikesList.defaultProps = {
  hideTitleBar: false,
  isLoading: false,
  onAdd: undefined,
  onSelect: undefined,
  selectedId: undefined,
};

export default BikesList;
