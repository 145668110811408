import React from 'react';
import Grid from '@mui/material/Grid';
import { RawDraftContentState } from 'draft-js';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import ActionButtonsRow from './components/ActionButtonsRow';
import RepairOverview from './components/RepairOverview';
import RepairContent from './components/RepairContent';
import AddWorkStepDialog from '../Dialog/AddWorkStep';
import { RepairStatus, Role } from '../../types/graphql-global-types';
import {
  getRepair_getRepair_workSteps,
  getRepair_getRepair_workSteps_workStepDefinition,
} from '../../gql/repair/types/getRepair';
import {
  getWorkStepDefinition_getWorkStepDefinition,
  getWorkStepDefinition_getWorkStepDefinition_proposedProducts,
} from '../../gql/workStep/types/getWorkStepDefinition';
import { DEFAULT_PRODUCT_STATUS } from '../../lib/constants';
import { RepairProcessingMachineContext } from '.';
import InfoBanner from '../InfoBanner';

export interface RepairProcessingProps {}

const RepairProcessing: React.FC<React.PropsWithChildren<RepairProcessingProps>> = () => {
  const [openAddWorkStep, setOpenAddWorkStep] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<
    getRepair_getRepair_workSteps_workStepDefinition[]
  >([]);

  const { enqueueSnackbar } = useSnackbar();

  // if machine is in one of these states, data is fetching
  const LOADING_STATES = [
    'editing.updatingProductQuantity',
    'editing.updatingWorkStepDone',
    'editing.updatingWorkStep',
  ];

  const [state, transition] = RepairProcessingMachineContext();

  const { repairData, isRepairUpdating, user } = state.context;

  const isManager = user?.role === Role.MANAGER || false;

  const onAddWorkStep = () => {
    setOpenAddWorkStep(true);
  };

  const handleWorkStepSubmit = (
    workStep: getWorkStepDefinition_getWorkStepDefinition
  ) => {
    setOpenAddWorkStep(false);

    const requiredProducts = workStep.proposedProducts.map(
      (p: getWorkStepDefinition_getWorkStepDefinition_proposedProducts) => {
        return {
          productId: p.product.id,
          quantity: p.quantity,
          status: DEFAULT_PRODUCT_STATUS,
        };
      }
    );

    transition('ADD_WORKSTEP', {
      data: {
        workStepDefinitionId: workStep.id,
        setWorkload: workStep.expectedWorkload,
        requiredProducts: requiredProducts,
      },
    });
  };

  const handleUpdateRepairStatus = (status: RepairStatus) => {
    transition('UPDATE_REPAIR_STATUS', {
      data: {
        status,
      },
    });
  };

  const handleUpdateRepairStatusAndMechanic = (
    status: RepairStatus,
    mechanicId: string,
    pauseReason?: string,
    cancelationReason?: string
  ) => {
    transition('UPDATE_REPAIR_STATUS', {
      data: {
        status,
        mechanicId,
        ...(pauseReason && { pauseReason }),
        ...(cancelationReason && { cancelationReason }),
      },
    });
  };

  const handleUpdateRepairLocation = (locationId?: string) => {
    transition('UPDATE_REPAIR_LOCATION', {
      locationId: locationId || '',
    });
  };

  const handleUpdateNotes = (notes: RawDraftContentState) => {
    transition('UPDATE_NOTES', { data: { notes } });
  };

  React.useEffect(() => {
    if (repairData?.workSteps) {
      setSelected(
        repairData?.workSteps?.map(
          (w: getRepair_getRepair_workSteps) => w.workStepDefinition
        )
      );
    }
  }, [repairData]);

  // show snackbar if in error state
  React.useEffect(() => {
    if (state.value === 'error') {
      enqueueSnackbar(<FormattedMessage id="app.machine.generic.error" />, {
        variant: 'error',
        persist: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    }
  });

  return (
    <div>
      <AddWorkStepDialog
        open={openAddWorkStep}
        onClose={() => setOpenAddWorkStep(false)}
        onSubmit={handleWorkStepSubmit}
        selected={selected as getWorkStepDefinition_getWorkStepDefinition[]}
      />
      <ActionButtonsRow
        stateValue={state.value}
        repairData={repairData}
        isManager={isManager}
        userId={user?.id}
        handleUpdateRepairStatus={handleUpdateRepairStatus}
        handleUpdateRepairStatusAndMechanic={
          handleUpdateRepairStatusAndMechanic
        }
        handleUpdateRepairLocation={handleUpdateRepairLocation}
        onAddWorkStep={onAddWorkStep}
      />

      <InfoBanner
        key="paused"
        isVisible={repairData?.status === RepairStatus.PAUSED}
        title="app.repairProcessing.infoBanner.repairPaused"
        message={repairData?.pauseReason}
      />

      <InfoBanner
        key="canceled"
        isVisible={repairData?.status === RepairStatus.CANCELED}
        title="app.repairProcessing.infoBanner.repairCanceled"
        message={repairData?.cancelationReason}
        isError
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <RepairOverview
            repairData={repairData}
            isManager={isManager}
            handleUpdateNotes={handleUpdateNotes}
            loading={isRepairUpdating}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <RepairContent
            repairData={repairData}
            user={user}
            editState={state.toStrings()[0] === 'editing'}
            isLoading={LOADING_STATES.includes(state.toStrings()[1])}
          />
        </Grid>
      </Grid>
    </div>
  );
};

RepairProcessing.propTypes = {};
RepairProcessing.defaultProps = {};

export default RepairProcessing;
