import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Typo from '../../../Typo';
import SelectedItem from '../../../SelectedItem';

export interface ProductQty {
  id: string;
  product: {
    name: string;
    price: number;
    manufacturer: string;
    description: string;
  };
  quantity: number;
}

export interface ProductsProps {
  products?: ProductQty[] | null;
  updateProduct: (id: string, quantity: number) => void;
  removeProduct: (id: string) => void;
}

const Products: React.FC<React.PropsWithChildren<ProductsProps>> = ({
  products = undefined,
  updateProduct,
  removeProduct,
}) => {
  return (
    <Box
      // border="1px solid"
      // borderColor="grey.400"
      // borderRadius="borderRadius"
      p={1}
    >
      {products && products.length ? (
        products.map((product: ProductQty) => (
          <SelectedItem
            key={product.id}
            id={product.id}
            name={product.product.name}
            count={product.quantity}
            countRate={product.product.price}
            setCount={updateProduct}
            RemoveItem={removeProduct}
            countLabel="app.customerOrderDraft.subOrders.repair.productCount"
            isValidating={false}
          />
        ))
      ) : (
        <Typo t="app.dialog.workStepDefinition.create.steps.proposedProducts.empty" />
      )}
    </Box>
  );
};

Products.propTypes = {
  updateProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};
Products.defaultProps = {};

export default Products;
