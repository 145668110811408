import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import DialogBase from '..';

export interface EditInvoiceInfoDialogProps {
  open: boolean;
  initialContent: string | null;
  onSubmit: (invoiceInfo: string | null) => void;
  onCancel: () => void;
  title?: string;
  readOnly?: boolean;
}

const EditInvoiceInfoDialog: React.FC<
  React.PropsWithChildren<EditInvoiceInfoDialogProps>
> = ({
  open,
  title = 'app.dialog.notes.title',
  onSubmit,
  onCancel,
  initialContent,
  readOnly = false,
}) => {
  const [invoiceInfo, setInvoiceInfo] = React.useState<string | null>(
    initialContent
  );

  const handleSubmit = () => {
    onSubmit(invoiceInfo ? invoiceInfo : null);
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      title={title}
      open={open}
      primaryAction={!readOnly ? handleSubmit : undefined}
      secondaryAction={onCancel}
    >
      <Box p={2} bgcolor="background.default" borderRadius={1}>
        <TextField
          fullWidth
          multiline
          minRows={4}
          maxRows={4}
          style={{ backgroundColor: '#fff' }}
          value={invoiceInfo ?? ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInvoiceInfo(event.target.value);
          }}
        />
      </Box>
    </DialogBase>
  );
};

EditInvoiceInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  initialContent: PropTypes.string,
};
EditInvoiceInfoDialog.defaultProps = {};

export default EditInvoiceInfoDialog;
