import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material/styles';

export interface FormTextFieldProps {
  label: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formInputOptions?: object;
  type?: string;
  defaultValue?: string | number;
  inputProps?: any;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  InputProps?: any;
}

const StyledMuiTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      borderRadius: theme.shape.borderRadius,
    },
  })
)(MuiTextField);

const FormTextField: React.FC<React.PropsWithChildren<FormTextFieldProps>> = ({
  label,
  name,
  onChange,
  formInputOptions,
  type,
  defaultValue,
  multiline,
  rows,
  InputProps = undefined,
  ...props
}) => {
  const { register, errors } = useFormContext();

  return (
    <StyledMuiTextField
      fullWidth
      variant="outlined"
      margin="dense"
      label={<FormattedMessage id={label} />}
      type={type}
      name={name}
      multiline={multiline}
      rows={rows}
      error={!!errors[name]}
      defaultValue={defaultValue}
      InputProps={InputProps}
      helperText={
        errors[name] && <FormattedMessage id={errors[name].message} />
      }
      onChange={onChange}
      inputRef={!!formInputOptions ? register(formInputOptions) : undefined}
      {...props}
    />
  );
};

FormTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  formInputOptions: PropTypes.shape({}),
  type: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};
FormTextField.defaultProps = {
  type: undefined,
  onChange: undefined,
  formInputOptions: undefined,
  multiline: false,
  rows: undefined,
};

export default FormTextField;
