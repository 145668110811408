import { EventObject } from 'xstate';

/**
 * Helper  to use type safe events in xstate. This is a workaround, xstate team says they're working on a better way.
 * read: https://github.com/davidkpiano/xstate/discussions/1591#discussioncomment-111941
 * @param event event
 * @param eventType event type to filter for
 */
export default function assertEventType<
  TE extends EventObject,
  TType extends TE['type']
>(event: TE, eventType: TType): asserts event is TE & { type: TType } {
  if (event.type !== eventType) {
    throw new Error(
      `Invalid event: expected "${eventType}", got "${event.type}"`
    );
  }
}
