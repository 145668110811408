import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation createNote($data: CreateNoteInput!) {
    createNote(data: $data) {
      id
      createdAt
      status
      content
      user {
        id
        firstname
        lastname
      }
      customerOrderId
      locationId
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($data: UpdateNoteInput!) {
    updateNote(data: $data) {
      id
      createdAt
      status
      content
      user {
        id
        firstname
        lastname
      }
      customerOrderId
      locationId
    }
  }
`;

export const ADD_NOTE_COMMENT = gql`
  mutation addComment($data: CreateNoteComment!) {
    addComment(data: $data) {
      id
      content
    }
  }
`;
