import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import DialogBase from '..';
import { UPDATE_PRODUCT } from '../../../gql/product/mutations';
import Button from '../../Button';
import EditProductForm from '../../Form/EditProductForm';
import { getPriceFloat } from '../../../lib/helper/numbers';
import {
  updateProduct as updateProductI,
  updateProductVariables,
} from '../../../gql/product/types/updateProduct';

export interface Product {
  id: string;
  name: string;
  manufacturer: string;
  description: string;
  price: number;
  // categoryId: string;
  isActive: boolean;
  sequence?: number;
  color?: string;
}

export interface EditProductProps {
  product?: Product;
  onEdited: () => void;
  onClose: () => void;
}

const EditProduct: React.FC<React.PropsWithChildren<EditProductProps>> = ({
  product,
  onEdited,
  onClose,
}) => {
  const [updateProduct, { loading }] = useMutation<
    updateProductI,
    updateProductVariables
  >(UPDATE_PRODUCT, {
    onCompleted: () => {
      onEdited();
    },
    onError: () => {},
  });

  const handleEditProduct = (data: any) => {
    const d = { ...data, price: getPriceFloat(data.price) };
    updateProduct({ variables: { data: d } });
  };

  return (
    <DialogBase
      fullWidth
      scroll="paper"
      maxWidth="sm"
      open={!!product}
      onClose={onClose}
      isForm
      title="app.dialog.editProduct.title"
      isLoading={loading}
    >
      <Box m={2} p={2} bgcolor="background.default" borderRadius={1}>
        <EditProductForm product={product} onSubmit={handleEditProduct} />
      </Box>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          t="app.common.defaultSecondary"
        />
        <Button
          variant="contained"
          color="primary"
          t="app.common.defaultPrimary"
          type="submit"
          form="createProduct-form"
        />
      </DialogActions>
    </DialogBase>
  );
};

EditProduct.propTypes = {
  // parentCategory: PropTypes.string,
  onEdited: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
EditProduct.defaultProps = {
  // parentCategory: null,
};

export default EditProduct;
