import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { isLoggedInVar } from '../../lib/apolloClient/cache';

export interface ProtectedRouteProps {
  children: React.ReactNode;
  path: string;
  exact: boolean;
}

const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  path,
  exact,
  ...props
}) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <Route
      {...props}
      path={path}
      exact={exact}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
};

ProtectedRoute.defaultProps = {};

export default ProtectedRoute;
