import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CallIcon from '@mui/icons-material/PhoneForwarded';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import IconButton from '@mui/material/IconButton';

import Typo from '../Typo';
import { getRepair_getRepair_customer } from '../../gql/repair/types/getRepair';
import { getCustomer_getCustomer } from '../../gql/customer/types/getCustomer';

export interface CustomerInformationProps {
  customer?: getRepair_getRepair_customer | getCustomer_getCustomer;
}

const CustomerInformation: React.FC<
  React.PropsWithChildren<CustomerInformationProps>
> = ({ customer }) => {
  return (
    <Box>
      {customer?.gender && (
        <Typo t={`model.customer.gender.${customer?.gender}`} />
      )}
      <Typography>{`${customer?.firstname ?? '-'} ${
        customer?.lastname ?? '-'
      } `}</Typography>
      <Typography>{customer?.address?.street1 ?? '-'}</Typography>
      {customer?.address?.street2 && (
        <Typography>{customer?.address?.street2}</Typography>
      )}
      <Typography gutterBottom>{`${customer?.address?.postalCode ?? '-'} ${
        customer?.address?.city ?? '-'
      } `}</Typography>
      <Box marginTop={2}>
        <Typo bold t="app.customerInformation.customerContact" />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typo
            t="app.customerInformation.customerPhone"
            values={{ phone: customer?.phone ?? '-' }}
          />
          {customer?.phone ? (
            <Box marginLeft={1}>
              <IconButton color="primary" href={`tel:${customer?.phone}`}>
                <CallIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typo
            t="app.customerInformation.customerPhone2"
            values={{ phone: customer?.phone2 ?? '-' }}
          />
          {customer?.phone2 ? (
            <Box marginLeft={1}>
              <IconButton color="primary" href={`tel: ${customer?.phone2}`}>
                <CallIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typo
            t="app.customerInformation.customerEmail"
            values={{ email: customer?.email ?? '-' }}
          />
          {customer?.email ? (
            <Box marginLeft={1}>
              <IconButton color="primary" href={`mailto: ${customer?.email}`}>
                <MailIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

CustomerInformation.propTypes = {};
CustomerInformation.defaultProps = {};

export default CustomerInformation;
