import React from 'react';
import PropTypes from 'prop-types';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    paperDefaultPadding: {
      padding: theme.spacing(2),
    },
    paperLoadingOverlay: {
      position: 'absolute',
      backgroundColor: theme.palette.grey[500],
      borderRadius: theme.shape.borderRadius,
      opacity: 0.6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      zIndex: 100,
    },
    fullHeight: {
      height: '100%',
    },
    borderRadius: {
      borderTopRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
  })
);

export interface PaperProps extends MuiPaperProps {
  isLoading?: boolean;
  padding?: boolean;
  fullHeight?: boolean;
}
/**
 * Wraps MUIPaper and adds a loading indicator
 */
const Paper: React.FC<React.PropsWithChildren<PaperProps>> = ({
  children,
  isLoading = false,
  fullHeight = false,
  padding = false,
  className = '',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiPaper
      className={`${classes.paperContainer} ${
        fullHeight ? classes.fullHeight : ''
      } ${className}`}
      {...rest}
    >
      {isLoading && (
        <Box position="absolute" top={0} width="100%">
          <LinearProgress className={classes.borderRadius} />
        </Box>
      )}
      <Box p={padding ? 2 : 0}>{children}</Box>
    </MuiPaper>
  );
};

Paper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
};
Paper.defaultProps = {
  isLoading: false,
};

export default Paper;
