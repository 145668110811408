import React from 'react';

import PageContainer from '../../components/PageContainer';
import WorkstepEdit from '../../components/WorkstepEdit';

export interface WorkstepsPageProps {}

const WorkstepsPage: React.FC<React.PropsWithChildren<WorkstepsPageProps>> = () => {
  return (
    <PageContainer page="worksteps">
      <WorkstepEdit />
    </PageContainer>
  );
};

WorkstepsPage.propTypes = {};
WorkstepsPage.defaultProps = {};

export default WorkstepsPage;
