import React from 'react';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import CallIcon from '@mui/icons-material/PhoneForwarded';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import IconButton from '@mui/material/IconButton';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import Typo from '../../Typo';
import FormRadioGroup from '../Components/FormRadioGroup';
import { Gender } from '../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerButtonRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
    customerFieldPadding: {
      padding: theme.spacing(0, 1),
    },
    customerContainerPadding: {
      padding: theme.spacing(1),
    },
    customerAddressContainer: {
      marginTop: theme.spacing(3),
    },
    customerFormButtonRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
    },
    customerSecondaryAction: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    loadingSpinner: {
      position: 'absolute',
    },
  })
);

export interface CustomerFormProps {
  data?: any;
  onSubmit: any;
  onCancel?: () => void | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
}

const CustomerForm: React.FC<React.PropsWithChildren<CustomerFormProps>> = ({
  data,
  onSubmit,
  onCancel = undefined,
  disabled = false,
  fullWidth = false,
}) => {
  const classes = useStyles();
  const customer = data;

  return (
    <FormContainer onSubmit={onSubmit} dataTest="customerForm">
      <Box bgcolor="background.default" borderRadius={1} p={2}>
        <Grid container>
          <Grid container>
            <Grid item xs={12} className={classes.customerFieldPadding}>
              <FormRadioGroup
                name="gender"
                defaultValue={customer?.gender || Object.values(Gender)[0]}
              >
                {Object.values(Gender).map((gender) => (
                  <FormControlLabel
                    data-test={`customerForm-gender-${gender}`}
                    disabled={disabled}
                    key={gender}
                    value={gender}
                    control={<Radio color="primary" />}
                    label={
                      <FormattedMessage
                        id={`model.customer.gender.${gender}`}
                      />
                    }
                  />
                ))}
              </FormRadioGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-firstname"
                disabled={disabled}
                label="model.common.firstname"
                name="firstname"
                defaultValue={customer?.firstname}
                formInputOptions={{
                  required: {
                    value: true,
                    message: 'app.form.errors.required',
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-lastname"
                disabled={disabled}
                label="model.common.lastname"
                name="lastname"
                defaultValue={customer?.lastname}
                formInputOptions={{
                  required: {
                    value: true,
                    message: 'app.form.errors.required',
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-phone"
                disabled={disabled}
                label="model.common.phone"
                name="phone"
                defaultValue={customer?.phone}
                formInputOptions={{}}
                InputProps={{
                  endAdornment: customer?.phone ? (
                    <IconButton
                      color="primary"
                      href={`tel: ${customer?.phone}`}
                    >
                      <CallIcon fontSize="small" />
                    </IconButton>
                  ) : null,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-phone"
                disabled={disabled}
                label="model.common.phone2"
                name="phone2"
                defaultValue={customer?.phone2}
                formInputOptions={{}}
                InputProps={{
                  endAdornment: customer?.phone2 ? (
                    <IconButton
                      color="primary"
                      href={`tel: ${customer?.phone2}`}
                    >
                      <CallIcon fontSize="small" />
                    </IconButton>
                  ) : null,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-email"
                disabled={disabled}
                label="model.common.email"
                name="email"
                defaultValue={customer?.email}
                formInputOptions={{}}
                type="email"
                InputProps={{
                  endAdornment: customer?.email ? (
                    <Box marginLeft={1}>
                      <IconButton
                        color="primary"
                        href={`mailto: ${customer?.email}`}
                      >
                        <MailIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : null,
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.customerAddressContainer}>
            <Grid item xs={12} className={classes.customerFieldPadding}>
              <Typo
                t="model.customer.address.label"
                variant="h5"
                gutterBottom
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-address-postalCode"
                disabled={disabled}
                label="model.customer.address.postalCode"
                name="address.postalCode"
                defaultValue={customer?.address?.postalCode}
                formInputOptions={{}}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-address-city"
                disabled={disabled}
                label="model.customer.address.city"
                name="address.city"
                defaultValue={customer?.address?.city}
                formInputOptions={{}}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-address-street1"
                disabled={disabled}
                label="model.customer.address.street1"
                name="address.street1"
                defaultValue={customer?.address?.street1}
                formInputOptions={{}}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={fullWidth ? 12 : 6}
              className={classes.customerFieldPadding}
            >
              <TextField
                data-test="customerForm-address-street2"
                disabled={disabled}
                label="model.customer.address.street2"
                name="address.street2"
                defaultValue={customer?.address?.street2}
                formInputOptions={{}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.customerFormButtonRow}>
        {!!onCancel && (
          <Button
            data-test="customerForm-button-cancel"
            disabled={disabled}
            variant="outlined"
            color="primary"
            onClick={onCancel}
            className={classes.customerSecondaryAction}
          >
            <FormattedMessage id="app.common.defaultSecondary" />
          </Button>
        )}
        <Button
          data-test="customerForm-button-submit"
          disabled={disabled}
          disableElevation
          variant="contained"
          color="primary"
          type="submit"
        >
          <FormattedMessage id="app.common.defaultPrimary" />
          {disabled && (
            <CircularProgress
              color="primary"
              size={24}
              className={classes.loadingSpinner}
            />
          )}
        </Button>
      </div>
    </FormContainer>
  );
};

CustomerForm.propTypes = {};
CustomerForm.defaultProps = {};

export default CustomerForm;
