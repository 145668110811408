import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import Switch from '@mui/material/Switch';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import FormColorPicker from '../Components/FormColorPicker';
import Typo from '../../Typo';
import FormSetValue from '../Components/FormSetValue';
import { CreateWorkStepCategory } from '../../../types/graphql-global-types';

export interface WorkStepCategoryFormProps {
  onSubmit: (data: CreateWorkStepCategory) => void;
  parentCategory: string;
}

const WorkStepCategoryForm: React.FC<React.PropsWithChildren<WorkStepCategoryFormProps>> = ({
  onSubmit,
  parentCategory,
}) => {
  const today = new Date().toUTCString();
  return (
    <FormContainer onSubmit={onSubmit} id="createWorkStepCategory-form">
      <FormSetValue name="workStepCategory" value={parentCategory} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          {/* Just display the switch but not include isActive value in form (not needed for creation, always true) */}
          <Switch color="primary" checked disabled />
          <label>
            <Typo
              t="app.form.workStepCategory.isActive.true"
              display="inline"
            />
          </label>
        </Box>
        <CreatedUpdatedDates createdAt={today} updatedAt={today} />
      </Box>

      <TextField
        label="app.form.workStepCategory.name"
        name="name"
        defaultValue={''}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />

      <TextField
        label="app.form.workStepCategory.description"
        name="description"
        defaultValue={''}
        multiline
        rows={3}
        formInputOptions={{}}
      />

      <Box>
        <FormColorPicker name="color" showPreview />
      </Box>

      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Box display="flex" alignItems="center">
          <WarningIcon />
          <Box marginLeft={1}>
            <Typo
              t="app.form.workStepCategory.isActiveHint"
              variant="caption"
            />
          </Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

WorkStepCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  parentCategory: PropTypes.string.isRequired,
};
WorkStepCategoryForm.defaultProps = {};

export default WorkStepCategoryForm;
