import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import FormWatchValue from '../Components/FormWatchValue';
import ButtonRow from '../../ButtonRow';
import { ChangePasswordInput } from '../../../types/graphql-global-types';

export interface ChangePasswordProps {
  onSubmit: (data: ChangePasswordInput) => void;
}

const ChangePassword: React.FC<React.PropsWithChildren<ChangePasswordProps>> = ({ onSubmit }) => {
  const [newPassword, setNewPassword] = React.useState<string>();
  const [revealPasswords, setRevealPasswords] = React.useState<boolean>(false);

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormWatchValue
        watchName="newPassword"
        onChange={(value: string) => setNewPassword(value)}
      />
      <TextField
        label="app.form.user.password.old"
        name="oldPassword"
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
          minLength: {
            value: 8,
            message: 'app.form.errors.password.length.min',
          },
          maxLength: {
            value: 100,
            message: 'app.form.errors.password.length.max',
          },
        }}
        type={revealPasswords ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => setRevealPasswords((prev) => !prev)}
                edge="end"
                size="small"
              >
                {revealPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="app.form.user.password.new"
        name="newPassword"
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
          minLength: {
            value: 8,
            message: 'app.form.errors.password.length.min',
          },
          maxLength: {
            value: 100,
            message: 'app.form.errors.password.length.max',
          },
        }}
        type={revealPasswords ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => setRevealPasswords((prev) => !prev)}
                edge="end"
                size="small"
              >
                {revealPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="app.form.user.password.newRepeat"
        name="oldPassword_repeat"
        formInputOptions={{
          validate: (value: string) =>
            value === newPassword || 'app.form.errors.password.new.noMatch',
        }}
        type={revealPasswords ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => setRevealPasswords((prev) => !prev)}
                edge="end"
                size="small"
              >
                {revealPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box marginTop={1}>
        <ButtonRow
          primaryType="submit"
          onPrimaryAction={() => null}
          isElevationDisabled
        />
      </Box>
    </FormContainer>
  );
};

ChangePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
ChangePassword.defaultProps = {};

export default ChangePassword;
