import { gql } from '@apollo/client';

export const GET_NOTES = gql`
  query getNotes(
    $status: [NoteStatus!]
    $offset: Int
    $limit: Int
    $onlyMyNotes: Boolean
  ) {
    getNotes(
      status: $status
      offset: $offset
      limit: $limit
      onlyMyNotes: $onlyMyNotes
    ) {
      items {
        id
        createdAt
        status
        content
        user {
          id
          firstname
          lastname
        }
        customerOrderId
        location {
          id
          name
        }
        customer {
          id
          firstname
          lastname
          email
          phone
          gender
          address {
            id
            city
            postalCode
            street1
          }
        }
        comments {
          id
          createdAt
          content
          user {
            id
            firstname
            lastname
          }
        }
      }
      totalCount
    }
  }
`;
