import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import FormSwitch from '../Components/FormSwitch';
import FormSetValue from '../Components/FormSetValue';

export interface Product {
  id: string;
  name: string;
  manufacturer: string;
  description: string;
  price: number;
  // categoryId: string;
  isActive: boolean;
  sequence?: number;
  color?: string;
}

export interface CreateProductFormProps {
  product?: Product;
  onSubmit: (data: any) => void;
}

const CreateProductForm: React.FC<React.PropsWithChildren<CreateProductFormProps>> = ({
  product,
  onSubmit,
}) => {
  const today = new Date().toUTCString();
  return (
    <FormContainer onSubmit={onSubmit} id="createProduct-form">
      <FormSetValue name="id" value={product?.id} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <FormSwitch
            name="isActive"
            label={`app.form.workStepCategory.isActive.${
              product?.isActive ? 'true' : 'false'
            }`}
            defaultValue={product?.isActive}
          />
        </Box>
        <CreatedUpdatedDates createdAt={today} updatedAt={today} />
      </Box>

      <TextField
        label="app.form.workStepdefinition.product.name"
        name="name"
        defaultValue={product?.name}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        label="app.form.workStepdefinition.product.manufacturer"
        name="manufacturer"
        defaultValue={product?.manufacturer}
        formInputOptions={{
          required: {
            value: false,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        label="app.form.workStepdefinition.product.description"
        name="description"
        defaultValue={product?.description}
        multiline
        rows={3}
        formInputOptions={{
          required: {
            value: false,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        name="price"
        label="model.repair.product.price"
        defaultValue={product?.price && product.price / 100}
        type="number"
        inputProps={{
          step: '0.01',
        }}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
          min: {
            value: 0.01,
            message: 'app.form.errors.min.one',
          },
          max: 9999,
        }}
      />
    </FormContainer>
  );
};

CreateProductForm.propTypes = {
  // parentCategory: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
CreateProductForm.defaultProps = {};

export default CreateProductForm;
