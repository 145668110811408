import LogRocket from 'logrocket';
import { loginMutation_login_user } from '../gql/auth/types/loginMutation';

export const identify = (user: loginMutation_login_user) => {
  if (!user || !user?.id) return;

  LogRocket.identify(user?.id, {
    name: `${user?.firstname} ${user?.lastname}`,
    email: user?.email,
    role: user?.role,
    skillLevel: user?.skillLevel,
  });
};

const logger = () => {
  if (!!process.env.REACT_APP_LOGROCKET) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET);
    LogRocket.getSessionURL((sessionURL) => {
      console.log('LogRocket sessionURL', sessionURL);
    });
  }
};

export default logger;
