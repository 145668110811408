import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from '..';
import AddProduct from '../../AddProduct';
import { getProduct_getProduct } from '../../../gql/product/types/getProduct';
export interface AddProductDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: getProduct_getProduct) => void;
  selectedCategoryId?: string;
  multiSelect?: boolean;
}

const AddProductDialog: React.FC<React.PropsWithChildren<AddProductDialogProps>> = ({
  open,
  onClose,
  onSubmit,
  selectedCategoryId = undefined,
  multiSelect = false,
}) => {
  return (
    <DialogBase
      fullWidth
      maxWidth="xl"
      title="app.dialog.product.add.title"
      primaryAction={undefined}
      secondaryAction={() => onClose()}
      open={open}
      keepMounted={false}
    >
      <AddProduct
        onSubmit={onSubmit}
        selectedCategoryId={selectedCategoryId}
        multiSelect={multiSelect}
      />
    </DialogBase>
  );
};

AddProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.string,
};
AddProductDialog.defaultProps = {};

export default AddProductDialog;
