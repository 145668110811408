import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';

import { formattedCurrency } from '../../../lib/helper/numbers';
import ProductRow from '../ProductRow';
import TableCell from '../StyledTableCell';
import { ProductI } from '..';

export interface WorkStepRowProps {
  id: string;
  name: string;
  setWorkload: number;
  workloadRate: number;
  requiredProducts?: ProductI[];
}

const WorkStepRow: React.FC<React.PropsWithChildren<WorkStepRowProps>> = ({
  id,
  name,
  setWorkload,
  workloadRate,
  requiredProducts,
}) => {
  return (
    <>
      <TableRow key={id}>
        <TableCell>{name}</TableCell>
        <TableCell align="right">{setWorkload}</TableCell>
        <TableCell align="right">
          <FormattedMessage id="app.customerOrderDraft.costEstimation.table.row.workload" />
        </TableCell>
        <TableCell align="right">
          {formattedCurrency(workloadRate || 0)}
        </TableCell>
        <TableCell align="right">
          {formattedCurrency(setWorkload * workloadRate)}
        </TableCell>
      </TableRow>
      {/* add ProductRows for required products */}
      {requiredProducts?.map((product: ProductI) => (
        <ProductRow
          isWorkStepProduct
          key={product.id}
          product={product.product}
          quantity={product.quantity}
        />
      ))}
    </>
  );
};

WorkStepRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setWorkload: PropTypes.number.isRequired,
  workloadRate: PropTypes.number.isRequired,
  requiredProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
      quantity: PropTypes.number.isRequired,
    }).isRequired
  ),
};
WorkStepRow.defaultProps = {};

export default WorkStepRow;
