import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typo from '../Typo';
import {
  getProductCategory_getProductCategory_products,
  getProductCategory_getProductCategory_subCategories,
} from '../../gql/product/types/getProductCategory';
import {
  getWorkStepCategory_getWorkStepCategory_subCategories,
  getWorkStepCategory_getWorkStepCategory_workSteps,
} from '../../gql/workStep/types/getWorkStepCategory';
import { CreateWorkStep } from '../CustomerOrderDraft/machines/orderDraftMachine/types';
import { getWorkStepDefinition_getWorkStepDefinition } from '../../gql/workStep/types/getWorkStepDefinition';
import History from './components/History';
import Category from './components/categoryItems/Category';
import ContentContainer from './components/ContentContainer';
import WorkStep from './components/categoryItems/WorkStep';
import Product from './components/categoryItems/Product';

export enum SelectionType {
  CATEGORY = 'CATEGORY',
  WORKSTEP = 'WORKSTEP',
  PRODUCT = 'PRODUCT',
}
export interface HistoryObject {
  id: string;
  name: string;
  type: SelectionType;
}

interface Variables {
  variables: {
    id: string;
  };
}

export interface SelectedItem {
  id: string;
  name: string;
  type: SelectionType;
}

export type SubCategory =
  | getProductCategory_getProductCategory_subCategories
  | getWorkStepCategory_getWorkStepCategory_subCategories;

export type Selected =
  | getWorkStepDefinition_getWorkStepDefinition
  | CreateWorkStep;

export interface CategoryContentProps {
  description?: string;
  loading: boolean;
  workSteps?: getWorkStepCategory_getWorkStepCategory_workSteps[] | null;
  products?: getProductCategory_getProductCategory_products[] | null;
  subCategories?: SubCategory[] | null;
  selected?: Selected[];
  getCategoryDetail: (variables: Variables) => void;
  getItemDetail: (Variables: Variables) => void;
  onHistoryUpdate?: (history: HistoryObject[]) => void;
  itemLabel: string;
  itemEmptyLabel: string;
  type: SelectionType;
}

const CategoryContent: React.FC<
  React.PropsWithChildren<CategoryContentProps>
> = ({
  loading,
  workSteps,
  products,
  subCategories,
  description = '',
  selected,
  getCategoryDetail,
  getItemDetail,
  onHistoryUpdate,
  itemLabel,
  itemEmptyLabel,
  type,
}) => {
  const [history, setHistory] = React.useState<HistoryObject[]>([]);

  const [orderdSubCategories, setOrderedSubCategories] = React.useState<
    SubCategory[]
  >([]);

  React.useEffect(() => {
    // need to clone subCategories to sort it (mutate it)
    let ordered = subCategories?.map((x: SubCategory) => x);

    if (ordered?.length) {
      setOrderedSubCategories(
        ordered.sort((a: SubCategory, b: SubCategory) => {
          if (!!a.sequence && !!b.sequence) {
            return a.sequence - b.sequence;
          }
          return 0;
        })
      );
    } else {
      setOrderedSubCategories(subCategories ?? []);
    }
  }, [subCategories]);

  /**
   * handles history updates and informes onHistoryUpdate if prop is set
   * @param history HistoryObject[]
   */
  const handleHistoryUpdate = (newHistory: HistoryObject[]) => {
    setHistory(newHistory);
    !!onHistoryUpdate && onHistoryUpdate(newHistory);
  };

  /**
   * handles history step back
   */
  const handleHistoryBack = () => {
    if (history.length) {
      const mutableHistory = history.map((h: HistoryObject) => h);
      const lastItem = mutableHistory.pop();
      !!lastItem && handleHistoryReset(lastItem.id);
    }
  };

  /**
   * handles History reset: sets currently selected categoryId, history array and selectedWorkStepId
   * @param id string
   */
  const handleHistoryReset = (id: string) => {
    // get selected index
    const index = history.findIndex((h: HistoryObject) => h.id === id);

    // if rootCategory selected, reset categoryId
    if (index === 0) {
      handleHistoryUpdate([]);
      return;
    }

    // slice history
    const reset = history.slice(0, index);

    // set last category of "new" history as selected
    const resetTo = reset[reset.length - 1];

    // "refetch" data
    getCategoryDetail({ variables: { id: resetTo.id } });

    // update history
    handleHistoryUpdate(reset);
  };

  /**
   * handles the selection of a subCategory or an item
   */
  const handleSelection = ({ id, name, type }: SelectedItem) => {
    if (type === SelectionType.WORKSTEP || type === SelectionType.PRODUCT) {
      getItemDetail({ variables: { id: id } });
      return;
    }

    // fetch category Data
    handleHistoryUpdate([...history, { id, name, type }]);
    getCategoryDetail({ variables: { id } });
  };

  const CheckSelection = (check: string) => {
    return !!selected?.find((s: Selected) => s.id === check);
  };

  return (
    <>
      <History history={history} handleHistoryReset={handleHistoryReset} />

      <Box bgcolor="background.default" borderRadius={1} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ContentContainer
              title="model.common.subCategories"
              loading={loading}
              onHistoryBack={handleHistoryBack}
              historyDisabled={history.length === 0}
            >
              <Grid container spacing={2}>
                {!loading ? (
                  orderdSubCategories?.length ? (
                    orderdSubCategories?.map((c: SubCategory) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={c.id}
                      >
                        <Box display="flex" flex={1}>
                          <Category category={c} onSelect={handleSelection} />
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typo t="app.dialog.workStepCategory.emptyCategory" m={2} />
                  )
                ) : null}
              </Grid>
            </ContentContainer>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ContentContainer title={itemLabel}>
              <Grid container spacing={2}>
                {type === SelectionType.WORKSTEP && workSteps?.length ? (
                  workSteps.map((workStep) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={workStep.id}
                    >
                      <Box display="flex" flex={1}>
                        <WorkStep
                          type={type}
                          workStep={workStep}
                          onSelect={handleSelection}
                          disabled={CheckSelection(workStep.id)}
                        />
                      </Box>
                    </Grid>
                  ))
                ) : type === SelectionType.WORKSTEP ? (
                  <Typo t={itemEmptyLabel} m={2} />
                ) : null}
                {type === SelectionType.PRODUCT && products?.length ? (
                  products.map((product) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={product.id}
                    >
                      <Box display="flex" flex={1}>
                        <Product
                          type={type}
                          product={product}
                          onSelect={handleSelection}
                          disabled={CheckSelection(product.id)}
                        />
                      </Box>
                    </Grid>
                  ))
                ) : type === SelectionType.PRODUCT ? (
                  <Typo t={itemEmptyLabel} m={2} />
                ) : null}
              </Grid>
            </ContentContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CategoryContent.propTypes = {
  description: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  itemLabel: PropTypes.string.isRequired,
  itemEmptyLabel: PropTypes.string.isRequired,
  onHistoryUpdate: PropTypes.func,
  getCategoryDetail: PropTypes.func.isRequired,
  getItemDetail: PropTypes.func.isRequired,
};
CategoryContent.defaultProps = {
  description: '',
  subCategories: [],
};

export default CategoryContent;
