import { gql } from '@apollo/client';

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($data: CreateCustomerInput!) {
    createCustomer(data: $data) {
      id
      gender
      lastname
      firstname
      phone
      phone2
      email
      notes
      address {
        id
        postalCode
        city
        street1
        street2
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation createCustomerAddress(
    $data: CreateAddressForExistingCustomerInput!
  ) {
    createCustomerAddress(data: $data) {
      address {
        id
        postalCode
        city
        street1
        street2
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      gender
      lastname
      firstname
      phone
      phone2
      email
      notes
      address {
        id
        postalCode
        city
        street1
        street2
      }
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation updateCustomerAddress($data: UpdateAddressInput!) {
    updateCustomerAddress(data: $data) {
      address {
        id
        city
        postalCode
        street1
        street2
      }
    }
  }
`;
