import { gql } from '@apollo/client';

export const GET_LOCATIONS_SELECT = gql`
  query getLocationsSelect {
    getLocations {
      id
      name
    }
  }
`;

export const GET_LOCATION = gql`
  query getLocation {
    getLocation {
      id
      tenantId
      createdAt
      updatedAt
      name
      compName
      shortCode
      description
      email
      phone
      phone2
      street1
      street2
      city
      postalCode
      region
      countryCode
      description
      settings {
        id
        createdAt
        updatedAt
        key
        value
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query getLocations {
    getLocations {
      id
      tenantId
      createdAt
      updatedAt
      name
      description
      settings {
        id
        createdAt
        updatedAt
        key
        value
      }
    }
  }
`;

export const GET_LOCATION_NAME = gql`
  query getLocationName($id: String) {
    getLocation(id: $id) {
      id
      name
    }
  }
`;
