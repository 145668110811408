import React from 'react';

import CustomerOrderDraft from './CustomerOrderDraft';
import {
  OrderDraftContext,
  OrderDraftEvent,
} from './machines/orderDraftMachine/types';
import { useMachine, useService } from '@xstate/react';
import { Interpreter } from 'xstate';
import constate from 'constate';

import oDMachine from './machines/orderDraftMachine/orderDraftMachine';
import {
  SubOrdersContext,
  SubOrdersEvent,
  SubOrdersSchema,
} from './machines/subOrdersMachine/types';
import {
  CustomerContext,
  CustomerEvent,
  CustomerSchema,
} from './machines/customerMachine/types';

export interface CustomerOrderDraftProviderProps {}

export function useCustomerOrderDraftMachines() {
  const orderDraftMachine = useMachine<OrderDraftContext, OrderDraftEvent>(
    oDMachine
  );

  const [, , service] = orderDraftMachine;

  // SubOrders Machine
  const subOrdersMachine = useService(
    service.children.get('subOrdersMachine') as unknown as Interpreter<
      SubOrdersContext,
      SubOrdersSchema,
      SubOrdersEvent
    >
  );

  // Customer Machine
  const customerMachine = useService(
    service.children.get('customerMachine') as unknown as Interpreter<
      CustomerContext,
      CustomerSchema,
      CustomerEvent
    >
  );

  const [, , customerService] = customerMachine;
  const [, , subOrdersService] = subOrdersMachine;

  React.useEffect(() => {
    service.onTransition((state) =>
      console.log('DraftMachine TRANSITION', state)
    );
    // .onEvent((event) => console.log('DraftMachine EVENT', event));
  }, [service]);

  React.useEffect(() => {
    subOrdersService.onTransition((state) =>
      console.log('SubOrders TRANSITION', state)
    );
    // .onEvent((event) => console.log('SubOrders EVENT', event));
  }, [subOrdersService]);

  React.useEffect(() => {
    customerService.onTransition((state) =>
      console.log('Customer TRANSITION', state)
    );
    // .onEvent((event) => console.log('CUSTOMER EVENT', event));
  }, [customerService]);

  return {
    orderDraftMachine,
    subOrdersMachine,
    customerMachine,
  };
}

export const [
  CustomerDraftMachinesProvider,
  CustomerDraftMachinesContext,
]: any = constate(useCustomerOrderDraftMachines);

const CustomerOrderDraftProvider: React.FC<
  React.PropsWithChildren<CustomerOrderDraftProviderProps>
> = () => {
  return (
    <CustomerDraftMachinesProvider>
      <CustomerOrderDraft />
    </CustomerDraftMachinesProvider>
  );
};

CustomerOrderDraftProvider.propTypes = {};
CustomerOrderDraftProvider.defaultProps = {};

export default CustomerOrderDraftProvider;
