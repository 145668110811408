import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PowerIcon from '@mui/icons-material/Power';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';

import Typo from '../../Typo';
import Date from '../../Date';
import { getCustomer_getCustomer_bikes } from '../../../gql/customer/types/getCustomer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 440,
      minHeight: 150,
      backgroundColor: '#fff',
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      border: '1px solid #f4f4f4',
      borderRadius: theme.shape.borderRadius,
    },
    spaceRight: {
      marginRight: theme.spacing(0.5),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    button: {
      cursor: 'pointer',
    },
    selected: {
      borderColor: theme.palette.primary.main,
    },
  })
);

// export interface BikeI {
//   id: string;
//   createdAt: string;
//   isActive: boolean;
//   type: string;
//   brand: string;
//   color: string;
//   model: string;
//   frameNumber: string;
//   isEbike: boolean;
// }

export interface BikeProps {
  bike: getCustomer_getCustomer_bikes;
  onEdit: (bike: getCustomer_getCustomer_bikes) => void;
  onSelect?: (bike: getCustomer_getCustomer_bikes) => void;
  isSelected?: boolean;
}

const Bike: React.FC<React.PropsWithChildren<BikeProps>> = ({
  bike,
  onEdit,
  onSelect = undefined,
  isSelected = false,
}) => {
  const classes = useStyles();

  return (
    <Box
      data-test={bike.brand}
      className={clsx(classes.container, {
        [classes.button]: !!onSelect,
        [classes.selected]: isSelected,
      })}
      onClick={!!onSelect ? () => onSelect(bike) : undefined}
    >
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography
            variant="h6"
            color="primary"
            display="inline"
            className={classes.spaceRight}
          >
            <b>{bike?.brand}</b>
          </Typography>
          <Typography variant="h6" color="primary" display="inline">
            <b>{bike?.model}</b>
          </Typography>
        </Box>
        <Box>
          {bike?.isEbike && (
            <Chip
              size="small"
              icon={<PowerIcon />}
              color="primary"
              label={<Typo t="app.bikeList.bike.isEbike" />}
              className={classes.spaceRight}
            />
          )}
          <IconButton onClick={() => onEdit(bike)} size="large">
            <EditIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Content */}
      <Box className={classes.content} alignItems="flex-start">
        <div>
          <Typo
            bold
            display="inline"
            t="app.bikeList.bike.type"
            className={classes.spaceRight}
          />
          <Typography display="inline">
            {bike?.type ? bike.type : '-'}
          </Typography>
        </div>
        <div>
          <Typo
            bold
            display="inline"
            t="app.bikeList.bike.color"
            className={classes.spaceRight}
          />
          <Typography display="inline">
            {bike?.color ? bike.color : '-'}
          </Typography>
        </div>
        <div>
          <Typo
            bold
            display="inline"
            t="app.bikeList.bike.frameNumber"
            className={classes.spaceRight}
          />
          <Typography display="inline">
            {bike?.frameNumber ? bike.frameNumber : '-'}
          </Typography>
        </div>
      </Box>

      <Box paddingTop={1} display="flex" justifyContent="flex-end">
        <Typo
          bold
          display="inline"
          t="app.bikeList.bike.createdAt"
          className={classes.spaceRight}
        />
        <Date date={bike?.createdAt} />
      </Box>
    </Box>
  );
};

Bike.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};
Bike.defaultProps = {};

export default Bike;
