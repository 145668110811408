import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import FormSwitch from '../Components/FormSwitch';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import FormNotes from '../Components/FormNotes';
import FormSetValue from '../Components/FormSetValue';
import { CreateBikeForExistingCustomerInput } from '../../../types/graphql-global-types';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
export interface CreateBikeDialogFormProps {
  onClose: () => void;
  onSubmit: (data: CreateBikeForExistingCustomerInput) => void;
  customerId?: string;
}

const CreateBikeDialogForm: React.FC<React.PropsWithChildren<CreateBikeDialogFormProps>> = ({
  onClose,
  onSubmit,
  customerId = '',
}) => {
  const today = new Date().toUTCString();

  return (
    <FormContainer onSubmit={onSubmit} id="createBike-form">
      <FormSetValue name="customerId" value={customerId} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <FormSwitch name="isEbike" label="model.bike.isEbike" />
        </Box>
        <CreatedUpdatedDates createdAt={today} updatedAt={today} />
      </Box>

      <TextField
        name="brand"
        label="model.bike.brand"
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />

      <TextField name="model" label="model.bike.model" formInputOptions={{}} />

      <TextField
        name="type"
        label="model.bike.type"
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />

      <TextField
        name="color"
        label="model.bike.color"
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        name="frameNumber"
        label="model.bike.frameNumber"
        formInputOptions={{}}
      />

      <FormNotes name="notes" />
    </FormContainer>
  );
};

CreateBikeDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customerId: PropTypes.string,
};
CreateBikeDialogForm.defaultProps = {
  customerId: '',
};

export default CreateBikeDialogForm;
