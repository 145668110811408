import React from 'react';
import { createRoot } from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import dayjs from 'dayjs';

// UPDATE FIX
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import 'dayjs/locale/de';

import './index.css';
import App from './App';
import theme from './theme';
import client from './lib/apolloClient';
import { messages, locale } from './lib/intl';
import { SnackbarUtilsConfigurator } from './lib/SnackbarUtils';
import logging from './lib/logging';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (process.env.NODE_ENV === 'production') {
  logging();
}

dayjs.locale(locale);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <IntlProvider locale={locale} messages={messages[locale]}>
    <SnackbarProvider maxSnack={3}>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <SnackbarUtilsConfigurator />
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </IntlProvider>
);
