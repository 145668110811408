import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';

import PrintItem from './PrintItem';
import { createCustomerOrder_createCustomerOrder_repairs } from '../../gql/customerOrder/types/createCustomerOrder';
export interface PrintCustomerOrderProps {
  costEstimationPrintRef: any;
  repairs: createCustomerOrder_createCustomerOrder_repairs[];
}

const PrintCustomerOrder: React.FC<React.PropsWithChildren<PrintCustomerOrderProps>> = ({
  costEstimationPrintRef,
  repairs,
}) => {
  return <>
    <Box mb={2} bgcolor="background.default" borderRadius={1}>
      {/* CostEstiation */}
      <PrintItem
        costEstimationPrintRef={costEstimationPrintRef}
        label={
          <FormattedMessage id="app.customerOrderDraft.costEstimation" />
        }
      />
    </Box>

    <Box mb={1} bgcolor="background.default" borderRadius={1}>
      {/* repairCodes */}
      {repairs?.map(
        (repair: createCustomerOrder_createCustomerOrder_repairs) => (
          <PrintItem
            key={repair.repairCode}
            repairCode={repair.repairCode}
            label={`${repair.bike.brand} ${repair.bike.model ?? ''}`}
            subLabel={repair.repairCode}
          />
        )
      )}
    </Box>
  </>;
};

PrintCustomerOrder.propTypes = {
  costEstimationPrintRef: PropTypes.shape({}).isRequired,
};
PrintCustomerOrder.defaultProps = {};

export default PrintCustomerOrder;
