import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

export interface FormSetValueProps {
  name: string;
  value?: string | string[];
}

const FormSetValue: React.FC<React.PropsWithChildren<FormSetValueProps>> = ({ name, value = '' }) => {
  const { register, setValue } = useFormContext();

  React.useEffect(() => {
    register(name);
    setValue(name, value);
  }, [name, register, setValue, value]);

  return null;
};

FormSetValue.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ]),
};
FormSetValue.defaultProps = {
  value: '',
};

export default FormSetValue;
