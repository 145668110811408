import React from 'react';
import PropTypes from 'prop-types';
import MagicGrid from 'magic-grid';

export interface MagicGridWrapperProps {
  children: any;
  items: number;
  animate?: boolean;
  gutter?: number;
}

const MagicGridWrapper: React.FC<React.PropsWithChildren<MagicGridWrapperProps>> = ({
  children,
  items,
  animate = false,
  gutter = 40,
}) => {
  const container = React.useRef<any>(null);

  React.useEffect(() => {
    let grid: any = null;
    let timeout: any;
    // magic-grid handles resizing via its own `listen` method
    // unfortunately event listener it creates is not being cleaned up
    // that's why we don't use it and have our own instead
    // see: https://github.com/e-oj/Magic-Grid/issues/24
    const resize = () => {
      if (!timeout)
        timeout = setTimeout(() => {
          grid && grid.positionItems();
          timeout = null;
        }, 50);
    };

    if (!grid) {
      grid = new MagicGrid({
        container: container.current,
        items,
        animate,
        gutter,
        static: false,
      });
      window.addEventListener('resize', resize);
    }

    grid.positionItems();

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  return <div ref={container}>{children}</div>;
};

MagicGridWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  items: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  gutter: PropTypes.number,
};
MagicGridWrapper.defaultProps = {};

export default MagicGridWrapper;
