import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import cslx from 'clsx';

import Paper from '../Paper';
import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.primary.main,
      flexDirection: 'column',
      color: '#fff',
    },
    isError: {
      backgroundColor: theme.palette.error.main,
    },
  })
);

export interface InfoBannerProps {
  isVisible: boolean;
  isError?: boolean;
  title: string;
  message?: string | null;
}

const InfoBanner: React.FC<React.PropsWithChildren<InfoBannerProps>> = ({
  isVisible,
  title,
  message = undefined,
  isError = false,
}) => {
  const classes = useStyles();

  return (
    <Collapse in={isVisible}>
      <Box marginBottom={3}>
        <Paper
          padding
          className={cslx(classes.paper, {
            [classes.isError]: isError,
          })}
        >
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="large" color="secondary" />

            <Box marginLeft={2}>
              <Typo color="secondary" variant="h5" t={title} />
              {message && <Typography>{message}</Typography>}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Collapse>
  );
};

InfoBanner.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  isError: PropTypes.bool,
};
InfoBanner.defaultProps = {
  isError: false,
  message: undefined,
};

export default InfoBanner;
