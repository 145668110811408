import React from 'react';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@mui/material/Button';

import Typo from '../Typo';

export interface ButtonProps extends MuiButtonProps {
  t: string;
  onClick?: () => void;
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({ t, ...props }) => {
  return (
    <MuiButton disableElevation {...props}>
      <Typo t={t} variant="button" noWrap />
    </MuiButton>
  );
};

Button.propTypes = {};
Button.defaultProps = {};

export default Button;
