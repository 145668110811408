import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
// import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Typography from '@mui/material/Typography';

import { getProductCategory_getProductCategory_subCategories } from '../../../../../gql/product/types/getProductCategory';
import { getWorkStepCategory_getWorkStepCategory_subCategories } from '../../../../../gql/workStep/types/getWorkStepCategory';
import { SelectedItem, SelectionType } from '../../..';
import useCommonStyles from '../Items.styles';

export type SubCategory =
  | getProductCategory_getProductCategory_subCategories
  | getWorkStepCategory_getWorkStepCategory_subCategories;

export interface CategoryProps {
  category?: SubCategory;
  onSelect: (item: SelectedItem) => void;
}

const Category: React.FC<React.PropsWithChildren<CategoryProps>> = ({ category, onSelect }) => {
  const classes = useCommonStyles();

  const handleSelect = () => {
    if (category) {
      onSelect({
        id: category.id,
        name: category.name,
        type: SelectionType.CATEGORY,
      });
    }
  };

  return (
    <ButtonBase className={classes.container} onClick={handleSelect}>
      <Box
        className={classes.top}
        bgcolor={category?.color ?? 'background.default'}
      >
        <Box className={classes.header}>
          <FolderOutlinedIcon fontSize="small" />
        </Box>
        <Box>
          <Typography className={classes.title}>
            <b>{category?.name}</b>
          </Typography>
        </Box>
      </Box>
      {/* <Box className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          {category?.description}
        </Typography>
      </Box> */}
    </ButtonBase>
  );
};

Category.propTypes = {
  onSelect: PropTypes.func.isRequired,
  // category: SubCategory;
};
Category.defaultProps = {};

export default Category;
