import React from 'react';
import PropTypes from 'prop-types';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface FormAccordionSummaryProps {
  children: React.ReactNode;
  id: string;
}

const FormAccordionSummary: React.FC<React.PropsWithChildren<FormAccordionSummaryProps>> = ({
  id,
  children,
}) => {
  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
      {children}
    </AccordionSummary>
  );
};

FormAccordionSummary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
FormAccordionSummary.defaultProps = {};

export default FormAccordionSummary;
