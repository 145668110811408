import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';

let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#1976D2',
        light: 'rgba(25,118,210, 0.2)',
      },
      secondary: {
        main: '#fff',
      },
      error: {
        main: '#ff1744',
      },
      background: {
        default: '#F3F7FC',
        paper: '#fff',
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      button: {
        fontWeight: 'bold',
      },
      h1: {
        color: '#1976D2',
      },
      h2: {
        color: '#1976D2',
      },
      h3: {
        color: '#1976D2',
      },
      h4: {
        color: '#1976D2',
      },
      h5: {
        color: '#1976D2',
        fontWeight: 'bold',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#fff',
          },
        },
      },
      // MuiPaper: {
      //   defaultProps: {
      //     elevation: 0,
      //   },
      // },
    },
  },
  deDE
);

theme = responsiveFontSizes(theme);

export default theme;
