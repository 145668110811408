import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';

import { formattedCurrency } from '../../../lib/helper/numbers';
import TableCell from '../StyledTableCell';

export interface ProductRowProps {
  product: {
    id: string;
    name: string;
    price: number;
  };
  quantity: number;
  isWorkStepProduct?: boolean;
}

const ProductRow: React.FC<React.PropsWithChildren<ProductRowProps>> = ({
  product,
  quantity,
  isWorkStepProduct = false,
}) => {
  const total = product?.price * quantity || 0;

  return (
    <TableRow>
      <TableCell>
        <Box marginLeft={isWorkStepProduct ? 2 : 0}>{product.name}</Box>
      </TableCell>
      <TableCell align="right">{quantity}</TableCell>
      <TableCell align="right">
        <FormattedMessage id="app.customerOrderDraft.costEstimation.table.row.pieces" />
      </TableCell>
      <TableCell align="right">
        {formattedCurrency(product.price || 0)}
      </TableCell>
      <TableCell align="right">{formattedCurrency(total)}</TableCell>
    </TableRow>
  );
};

ProductRow.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  isWorkStepProduct: PropTypes.bool,
};
ProductRow.defaultProps = {
  isWorkStepProduct: false,
};

export default React.memo(ProductRow);
