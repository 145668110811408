import React from 'react';
import PropTypes from 'prop-types';
import { FormContext, useForm } from 'react-hook-form';

export interface FormContainerProps {
  id?: string;
  dataTest?: string;
  children: React.ReactNode;
  onSubmit: (data: any) => void;
}

const FormContainer: React.FC<React.PropsWithChildren<FormContainerProps>> = ({
  id = undefined,
  children,
  onSubmit,
  dataTest,
}) => {
  const methods = useForm({ mode: 'onBlur' });

  const handleFormSubmit = (data: object): void => {
    onSubmit(data);
    methods.reset();
  };

  return (
    <FormContext {...methods}>
      <form
        id={id}
        onSubmit={methods.handleSubmit(handleFormSubmit)}
        data-test={dataTest}
      >
        {children}
      </form>
    </FormContext>
  );
};

FormContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  dataTest: PropTypes.string,
};
FormContainer.defaultProps = {
  id: undefined,
  dataTest: undefined,
};

export default FormContainer;
