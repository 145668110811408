import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';

import DialogBase from '..';
import CreateWorkStepCategoryForm from '../../Form/CreateWorkStepCategoryForm';
import { CREATE_WORKSTEP_CATEGORY } from '../../../gql/workStep/mutations';
import Button from '../../Button';
import {
  createWorkStepCategory as createWorkStepCategoryI,
  createWorkStepCategoryVariables,
} from '../../../gql/workStep/types/createWorkStepCategory';
import { CreateWorkStepCategory as CreateWorkStepCategoryI } from '../../../types/graphql-global-types';

export interface CreateWorkStepCategoryProps {
  parentCategory?: string | null;
  onCreated: (data: createWorkStepCategoryI) => void;
  onClose: () => void;
}

const CreateWorkStepCategory: React.FC<React.PropsWithChildren<CreateWorkStepCategoryProps>> = ({
  parentCategory,
  onCreated,
  onClose,
}) => {
  const [createWorkStepCategory, { loading }] = useMutation<
    createWorkStepCategoryI,
    createWorkStepCategoryVariables
  >(CREATE_WORKSTEP_CATEGORY, {
    onCompleted: (data) => {
      onCreated(data);
    },
    onError: () => {},
  });

  const handleCreateWorkStepCategory = (data: CreateWorkStepCategoryI) => {
    createWorkStepCategory({ variables: { data } });
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      open={!!parentCategory}
      onClose={onClose}
      isForm
      title="app.dialog.workStepCategory.add.title"
      isLoading={loading}
    >
      <Box m={2} p={2} borderRadius={1} bgcolor="background.default">
        {parentCategory ? (
          <CreateWorkStepCategoryForm
            parentCategory={parentCategory}
            onSubmit={handleCreateWorkStepCategory}
          />
        ) : null}
      </Box>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          t="app.common.defaultSecondary"
        />
        <Button
          disableElevation
          variant="contained"
          color="primary"
          t="app.common.defaultPrimary"
          type="submit"
          form="createWorkStepCategory-form"
        />
      </DialogActions>
    </DialogBase>
  );
};

CreateWorkStepCategory.propTypes = {
  parentCategory: PropTypes.string,
  onCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
CreateWorkStepCategory.defaultProps = {};

export default CreateWorkStepCategory;
