import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { SketchPicker } from 'react-color';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';

import Typo from '../../../Typo';
import Category from '../../../CategoryContent/components/categoryItems/Category';
import { SubCategory } from '../../../CategoryContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: 'none !important',
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  })
);

interface Color {
  hex: string;
}

export interface FormColorPickerProps {
  name: string;
  defaultValue?: string | null;
  showPreview?: boolean;
  previewName?: string;
  disabled?: boolean;
  showLabel?: boolean;
}

const FormColorPicker: React.FC<React.PropsWithChildren<FormColorPickerProps>> = ({
  name,
  defaultValue,
  showPreview = false,
  showLabel = true,
  previewName = undefined,
  disabled = false,
}) => {
  const classes = useStyles();
  const [color, setColor] = React.useState<string | undefined>(
    defaultValue ?? '#fff'
  );

  const dummyCategory = {
    id: previewName || '-',
    name: previewName || 'Kategorie',
    color: color || null,
    subCategories: null,
  };

  const { register, setValue } = useFormContext();

  const handleColorChange = (color: Color) => {
    if (disabled) return;

    setColor(color.hex);
  };

  const handleComplete = () => {
    if (disabled) return;
    setValue(name, color);
  };

  React.useEffect(() => {
    register({ name: name, type: 'custom' });
    setValue(name, color);
  }, [color, name, register, setValue]);

  return (
    <Box display="flex" alignItems="center" marginTop={1} marginBottom={1}>
      <Box display="flex" flexWrap="wrap">
        <Box>
          {showLabel && (
            <Box marginBottom={1}>
              <Typo bold t="app.form.component.colorPicker.label" />
            </Box>
          )}
          <SketchPicker
            className={classes.container}
            disableAlpha
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleComplete}
          />
        </Box>
      </Box>
      {showPreview ? (
        <Box display="flex" marginLeft={3} alignSelf="center" width={150}>
          <Category
            category={dummyCategory as unknown as SubCategory}
            onSelect={() => null}
          />
        </Box>
      ) : null}
    </Box>
  );
};

FormColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  showPreview: PropTypes.bool,
  previewName: PropTypes.string,
  disabled: PropTypes.bool,
};
FormColorPicker.defaultProps = {
  defaultValue: undefined,
  showPreview: false,
  previewName: undefined,
  disabled: false,
};

export default FormColorPicker;
