import React from 'react';

import PageContainer from '../../components/PageContainer';
import ProductsEdit from '../../components/ProductsEdit';

export interface ProductsPageProps {}

const ProductsPage: React.FC<React.PropsWithChildren<ProductsPageProps>> = () => {
  return (
    <PageContainer page="products">
      <ProductsEdit />
    </PageContainer>
  );
};

ProductsPage.propTypes = {};
ProductsPage.defaultProps = {};

export default ProductsPage;
