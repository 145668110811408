import { assign, spawn } from 'xstate';
import assertEventType from '../../../../../lib/helper/xstateHelper';
import customerMachine from '../../customerMachine/customerMachine';
import subOrdersMachine from '../../subOrdersMachine/subOrdersMachine';
import { OrderDraftContext, OrderDraftEvent } from '../types';

export const spawnSubMachines = assign<OrderDraftContext, OrderDraftEvent>({
  customerMachine: () => spawn(customerMachine, 'customerMachine'),
  subOrdersMachine: () => spawn(subOrdersMachine, 'subOrdersMachine'),
});

export const setCostEstimationData = assign<OrderDraftContext, OrderDraftEvent>(
  {
    costEstimationData: (_: OrderDraftContext, e: OrderDraftEvent) => {
      assertEventType(e, 'done.invoke.createCustomerOrder');
      return e?.data;
    },
  }
);

export const setCostEstimationFalse = assign<
  OrderDraftContext,
  OrderDraftEvent
>({
  isCostEstimation: () => false,
});

export const updateInvoiceInfo = assign<OrderDraftContext, OrderDraftEvent>({
  invoiceInfo: (_: OrderDraftContext, e: OrderDraftEvent) => {
    assertEventType(e, 'UPDATE_INVOICE_INFO');
    return e?.data;
  },
});
