import { gql } from '@apollo/client';

/**
 * LOGIN USER MUTATION
 */
export const LOGIN_USER = gql`
  mutation loginMutation($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      accessToken
      user {
        id
        email
        firstname
        lastname
        username
        role
        skillLevel
      }
    }
  }
`;
