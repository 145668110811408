import client from '../../../../lib/apolloClient';
import { GET_USER_ID } from '../../../../gql/user/queries';
import {
  CREATE_CUSTOMER_ORDER,
  UPDATE_CUSTOMER_ORDER_COST_ESTIMATION,
} from '../../../../gql/customerOrder/mutations';
import {
  CreateCustomerOrder,
  CustomerOrderStatus,
} from '../../../../types/graphql-global-types';
import { me } from '../../../../gql/user/types/me';
import { updateCustomerOrderCostEstimation as updateCustomerOrderCostEstimationI } from '../../../../gql/customerOrder/types/updateCustomerOrderCostEstimation';
import { createCustomerOrder as createCustomerOrderI } from '../../../../gql/customerOrder/types/createCustomerOrder';
import { RepairContext } from '../subOrders/repair/types';
import { ProductOrderContext } from '../subOrders/productOrder/types';
import { CreateProduct, CreateRepair, CreateWorkStep } from './types';

/**
 * fetches userID
 */
export const getUserId = () =>
  client
    .query<me>({ query: GET_USER_ID })
    .then((result) => result?.data?.me?.id)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });

/**
 * updates the status of a given customerOrder
 * @param customerOrderId string
 */
export const updateCustomerOrderCostEstimation = (customerOrderId?: number) => {
  return client
    .mutate<updateCustomerOrderCostEstimationI>({
      mutation: UPDATE_CUSTOMER_ORDER_COST_ESTIMATION,
      variables: {
        data: { id: customerOrderId, status: CustomerOrderStatus.OPEN },
      },
    })
    .then((result) => result?.data?.updateCustomerOrder)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * formats a product for validation or createCustomerOrder
 * @param product
 */
const formatProduct = (product: CreateProduct) => ({
  productId: product.id,
  quantity: product.quantity,
  status: product.status,
});

/**
 * formats a workstep for validation or createCustomerOrder
 * @param rawWorkStep
 */
const formatWorkStep = (rawWorkStep: CreateWorkStep) => {
  return {
    workStepDefinitionId: rawWorkStep.id,
    setWorkload: rawWorkStep.setWorkload,
    requiredProducts: rawWorkStep?.requiredProducts?.map((p: CreateProduct) =>
      formatProduct(p)
    ),
    notes: rawWorkStep?.notes || undefined,
  };
};

/**
 * formats a repair for createCustomerOrder
 * @param rawRepair
 */
export const formatRepair = (rawRepair: RepairContext) => {
  let {
    bike,
    workloadSum,
    workloadPrice,
    workloadRate,
    productSum,
    total,
    price,
    __typename,
    ...repair
  } = rawRepair.data;

  const createRepair: CreateRepair = {
    bikeId: rawRepair?.data?.bike?.id ?? '',
    ...repair,
  };

  const workSteps = createRepair?.workSteps?.map((w: CreateWorkStep) =>
    formatWorkStep(w)
  );
  // const products = repair?.products?.map((p: CreateProduct) => formatProduct(p));

  return {
    ...createRepair,
    workSteps,
  };
};

/**
 * formats a productOrder for createCustomerOrder
 * @param rawOrder
 */
const formatProductOrder = (rawOrder: ProductOrderContext) => {
  let order = rawOrder.data;

  const products = order?.products?.map((p: CreateProduct) => formatProduct(p));

  return {
    ...order,
    products,
  };
};

/**
 * formats a repair for validation
 * @param rawRepair
 */
export const formatRepairValidation = (rawRepair: RepairContext) => {
  const repair = rawRepair.data;

  const workSteps = repair?.workSteps?.map((w: CreateWorkStep) =>
    formatWorkStep(w)
  );
  // const products = repair?.products?.map((p: CreateProduct) => formatProduct(p));

  return {
    workloadDiscount: repair?.workloadDiscount,
    workSteps,
    // products,
  };
};

/**
 * creates a customerOrder
 * @param customerId string
 * @param repairs RepairContext
 * @param orders ProductOrderContext
 * @param invoiceInfo InvoiceInfo
 * @returns createCustomerOrder_createCustomerOrder
 */
export const createCustomerOrder = (
  customerId: string,
  repairs: RepairContext[],
  orders: ProductOrderContext[],
  invoiceInfo: string | null
) => {
  const formattedRepairs = repairs?.map((r: RepairContext) => formatRepair(r));
  const formattedOrders = orders?.map((o: ProductOrderContext) =>
    formatProductOrder(o)
  );

  const customerOrder: CreateCustomerOrder = {
    customerId: customerId,
    repairs: formattedRepairs,
    productOrders: formattedOrders,
    status: CustomerOrderStatus.COSTESTIMATION,
    invoiceInfo,
  };

  return client
    .mutate<createCustomerOrderI>({
      mutation: CREATE_CUSTOMER_ORDER,
      variables: { data: customerOrder },
    })
    .then((result) => {
      return result?.data?.createCustomerOrder;
    })
    .catch((e) => {
      throw e;
    });
};
