import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from '..';
import Typo from '../../Typo';
import Box from '@mui/material/Box';

export interface ConfirmationDialogProps {
  title?: string;
  message?: string;
  messageValues?: Record<string, any>;
  primaryLabel?: string;
  secondaryLabel?: string;
  open: boolean;
  onDeclined: () => void;
  onConfirmed: () => void;
}

const ConfirmationDialog: React.FC<React.PropsWithChildren<ConfirmationDialogProps>> = ({
  title,
  message = 'app.dialog.confirmation.message',
  messageValues = undefined,
  primaryLabel,
  secondaryLabel,
  open,
  onDeclined,
  onConfirmed,
}) => {
  return (
    <DialogBase
      data-test="confirmationDialog"
      fullWidth
      maxWidth="sm"
      title={title}
      open={open}
      primaryAction={onConfirmed}
      secondaryAction={onDeclined}
      keepMounted={false}
      primaryActionLabel={primaryLabel}
      secondaryActionLabel={secondaryLabel}
    >
      <Box
        minHeight={100}
        p={2}
        mb={1}
        bgcolor="background.default"
        borderRadius={1}
      >
        <Typo t={message} values={{ ...messageValues }} />
      </Box>
    </DialogBase>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onDeclined: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
};
ConfirmationDialog.defaultProps = {
  title: 'app.dialog.confirmation.title',
  message: 'app.dialog.confirmation.message',
  primaryLabel: 'app.dialog.confirmation.primary',
  secondaryLabel: 'app.dialog.confirmation.secondary',
};

export default ConfirmationDialog;
