import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import Typo from '../../Typo';
import FormSetValue from '../Components/FormSetValue';
import FormSelect from '../Components/FormSelect';
import { SkillLevel } from '../../../types/graphql-global-types';
import FormSwitch from '../Components/FormSwitch';
import ContentAccordion from './ContentAccordion';
import { WorkStep } from '../../Dialog/WorkStepDefinition';

export interface WorkStepDefinitionFormProps {
  onSubmit: (data: any) => void;
  parentCategory?: string;
  workStep?: WorkStep;
}

const WorkStepDefinitionForm: React.FC<React.PropsWithChildren<WorkStepDefinitionFormProps>> = ({
  onSubmit,
  parentCategory,
  workStep,
}) => {
  const today = new Date().toUTCString();
  const categories = workStep?.categories?.map((c: any) => c.id);

  return (
    <FormContainer onSubmit={onSubmit} id="workStepDefinition-form">
      <FormSetValue name="categoryIds" value={categories || [parentCategory]} />
      {!!workStep?.id ? <FormSetValue name="id" value={workStep.id} /> : null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          {!!workStep ? (
            <FormSwitch
              name="isActive"
              defaultValue={workStep.isActive}
              label={`app.form.workStepCategory.isActive.${workStep.isActive}`}
            />
          ) : (
            <>
              <Switch color="primary" checked disabled />
              <label>
                <Typo
                  t="app.form.workStepCategory.isActive.true"
                  display="inline"
                />
              </label>
            </>
          )}
        </Box>
        <CreatedUpdatedDates createdAt={today} updatedAt={today} />
      </Box>

      <TextField
        label="app.form.workStepCategory.name"
        name="name"
        defaultValue={workStep?.name || ''}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />

      <TextField
        label="app.form.workStepCategory.description"
        name="description"
        defaultValue={workStep?.description || ''}
        multiline
        rows={3}
        formInputOptions={{}}
      />

      <TextField
        label="app.form.workStepDefinition.expectedWorkload"
        name="expectedWorkload"
        defaultValue={workStep?.expectedWorkload || 0}
        type="number"
        inputProps={{
          step: '1',
        }}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
          min: {
            value: 1,
            message: 'app.form.errors.min.one',
          },
          max: 9999,
        }}
      />

      <Box marginTop={1} marginBottom={1}>
        <FormSelect
          name="skillLevel"
          defaultValue={workStep?.skillLevel || Object.values(SkillLevel)[0]}
        >
          {Object.values(SkillLevel).map((status: string) => (
            <MenuItem key={status} value={status}>
              <Typo t={`model.common.skillLevel.${status}`} />
            </MenuItem>
          ))}
        </FormSelect>
      </Box>

      <ContentAccordion workStep={workStep} />
    </FormContainer>
  );
};

WorkStepDefinitionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  parentCategory: PropTypes.string,
};
WorkStepDefinitionForm.defaultProps = {};

export default WorkStepDefinitionForm;
