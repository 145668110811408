import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Radio from '@mui/material/Radio';

import { getWorkStepCategory_getWorkStepCategory_workSteps } from '../../../../../gql/workStep/types/getWorkStepCategory';
import { SelectedItem, SelectionType } from '../../..';
import Typo from '../../../../Typo';
import SkillLevelChip from '../../../../SkillLevelChip';
import useCommonStyles from '../Items.styles';

export interface WorkStepProps {
  type: SelectionType.WORKSTEP;
  workStep: getWorkStepCategory_getWorkStepCategory_workSteps;
  onSelect: (item: SelectedItem) => void;
  selected?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
}

const WorkStep: React.FC<React.PropsWithChildren<WorkStepProps>> = ({
  type,
  workStep,
  onSelect,
  selected = false,
  disabled = false,
  multiSelect = false,
}) => {
  const classes = useCommonStyles();

  return (
    <ButtonBase
      className={classes.container}
      onClick={() => onSelect({ id: workStep.id, name: workStep.name, type })}
      disabled={disabled}
    >
      <Box
        className={classes.top}
        // bgcolor={workStep.color ?? 'background.default'}
        bgcolor="background.default"
      >
        <Box className={classes.header}>
          <Typo
            t="model.common.workLoad"
            values={{ aw: workStep.expectedWorkload }}
            className={classes.subTitle}
          />
          <SkillLevelChip type={workStep.skillLevel} small />
        </Box>
        <Typography className={classes.title}>
          <b>{workStep.name}</b>
        </Typography>
      </Box>
      {/* <Box className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          {workStep.description}
        </Typography>
      </Box>
      {multiSelect ? (
        <Box mt={-1} display="flex" width="100%" justifyContent="center">
          <Radio checked={selected} />
        </Box>
      ) : null} */}
    </ButtonBase>
  );
};

WorkStep.propTypes = {
  onSelect: PropTypes.func.isRequired,
  // type: PropTypes.oneOf<SelectionType.WORKSTEP>([SelectionType.WORKSTEP])
  //   .isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool,
};
WorkStep.defaultProps = {};

export default WorkStep;
