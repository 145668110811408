import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export interface NameRowName {
  firstname?: string | null;
  lastname?: string | null;
}

export interface NameRowProps {
  name?: NameRowName | null;
}

const NameRow: React.FC<React.PropsWithChildren<NameRowProps>> = ({ name }) => {
  if (!name) return <Typography>-</Typography>;
  return (
    <Typography display="inline">{`${name?.firstname ? name?.firstname : ''} ${
      name?.lastname ? name?.lastname : ''
    }`}</Typography>
  );
};

NameRow.propTypes = {
  name: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
};
NameRow.defaultProps = {
  name: undefined,
};

export default NameRow;
