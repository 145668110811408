import { makeVar, InMemoryCache } from '@apollo/client';

/**
 * Declaring all local cached variables here.
 * importart: makeVar returns a function
 * calling it with no arg = getter
 * calling it with arg = setter
 */

// indicates if user is successfully logged in
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'));

// applications local cache
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar();
        },
      },
    },
  },
});
