import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import {
  getNotes_getNotes_items_location,
  getNotes_getNotes_items_customer,
} from '../../../../../gql/notes/types/getNotes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      fontSize: 12,
    },
  })
);

export interface NoteInfosProps {
  location: getNotes_getNotes_items_location;
  customer?: getNotes_getNotes_items_customer | null;
  customerOrderId?: string | null;
}

const NoteInfos: React.FC<React.PropsWithChildren<NoteInfosProps>> = ({
  location,
  customer = null,
  customerOrderId = undefined,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const handleCustomerClick = () => {
    if (customer?.id) {
      history.push(`/customers/${customer.id}`);
    }
  };

  const handleCustomerOrderClick = () => {
    if (customerOrderId) {
      history.push(`/customerOrders/${customerOrderId}`);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      mt={1}
    >
      {/* location */}
      <Box display="flex" flex={1} flexDirection="column">
        <Box display="flex">
          <Box display="flex" alignItems="center">
            <LocationOnIcon fontSize="small" color="primary" />
            <Box ml={1}>
              <Typography className={classes.text}>{location.name}</Typography>
            </Box>
          </Box>
        </Box>

        {/* customerOrder */}
        {customerOrderId ? (
          <Box
            mt={1}
            display="flex"
            alignItems="center"
            className={classes.container}
            role="button"
            onClick={handleCustomerOrderClick}
          >
            <InsertDriveFileIcon fontSize="small" color="primary" />
            <Box ml={1}>
              <Typography className={classes.text}>
                {intl.formatMessage({ id: 'model.customerOrder' })}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>

      {/* Customer */}
      <Box
        display="flex"
        flex={1}
        className={customer ? classes.container : ''}
        role="button"
        onClick={customer ? handleCustomerClick : undefined}
      >
        <Box display="flex" flex={1}>
          <AssignmentIndIcon fontSize="small" color="primary" />
          <Box ml={1}>
            {/* Name */}
            <Typography className={classes.text}>
              {customer?.gender
                ? intl.formatMessage({
                    id: `model.customer.gender.${customer?.gender}`,
                  })
                : '-'}
              {customer?.firstname ? ` ${customer.firstname}` : null}
              {customer?.lastname ? ` ${customer.lastname}` : null}
            </Typography>

            {/* Address */}
            <Typography className={classes.text}>
              {customer?.address?.street1
                ? `${customer?.address?.street1},`
                : null}
              {customer?.address?.postalCode
                ? ` ${customer?.address?.postalCode}`
                : null}
              {customer?.address?.city ? ` ${customer?.address?.city}` : null}
            </Typography>

            {/* contact */}
            <Typography className={classes.text}>
              {customer?.phone ? ` ${customer?.phone}` : null}
            </Typography>
            <Typography className={classes.text}>
              {customer?.email ? ` ${customer?.email}` : null}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

NoteInfos.propTypes = {
  customerOrderId: PropTypes.string,
};
NoteInfos.defaultProps = {};

export default NoteInfos;
