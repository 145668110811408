import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getCustomerOrder_getCustomerOrder_customer } from '../../../gql/customerOrder/types/getCustomerOrder';
import { createCustomerOrder_createCustomerOrder_customer } from '../../../gql/customerOrder/types/createCustomerOrder';

export interface CustomerInformationProps {
  customer:
    | getCustomerOrder_getCustomerOrder_customer
    | createCustomerOrder_createCustomerOrder_customer;
}

const CustomerInformation: React.FC<
  React.PropsWithChildren<CustomerInformationProps>
> = ({ customer }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight={50} marginBottom={6}>
      <Typography>{`${customer?.firstname} ${customer?.lastname}`}</Typography>
      {!!customer?.address && (
        <>
          <Typography>{customer?.address?.street1}</Typography>
          {!!customer?.address?.street2 && (
            <Typography>{customer?.address?.street2}</Typography>
          )}
          <Typography>{`${customer?.address?.postalCode ?? ''} ${
            customer?.address?.city ?? ''
          }`}</Typography>
        </>
      )}
    </Box>
  );
};

CustomerInformation.propTypes = {};
CustomerInformation.defaultProps = {};

export default CustomerInformation;
