import { ApolloClient, from } from '@apollo/client';

import { cache } from './cache';
import { authMiddleware, httpLink, logoutLink } from './links';

const Client = new ApolloClient({
  link: from([authMiddleware, logoutLink, httpLink]),
  cache: cache,
  connectToDevTools: true,
  defaultOptions: {},
});

export default Client;
