import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Typography from '@mui/material/Typography';

import { GET_CUSTOMER_ORDER_CHECKOUT_PREVIEW } from '../../../gql/customerOrder/queries';
import DialogBase from '..';
import { CHECKOUT } from '../../../gql/checkout/mutation';
import CostsTable, { ProductOrderI, RepairI } from '../../CostsTable';
import {
  CustomerOrderStatus,
  PaymentMethod,
} from '../../../types/graphql-global-types';
import Typo from '../../Typo';
import Date from '../../Date';
import {
  getCustomerOrder_getCustomerOrder_customer,
  getCustomerOrder_getCustomerOrder_repairs,
} from '../../../gql/customerOrder/types/getCustomerOrder';
import {
  checkout as checkoutI,
  checkoutVariables,
} from '../../../gql/checkout/types/checkout';
import {
  getCustomerOrderCheckoutPreview,
  getCustomerOrderCheckoutPreviewVariables,
  getCustomerOrderCheckoutPreview_getCustomerOrderCheckoutPreview,
} from '../../../gql/customerOrder/types/getCustomerOrderCheckoutPreview';
import { TAX } from '../../../lib/constants';

export interface Order {
  id: string;
  repairs?: RepairI[];
  productOrders?: ProductOrderI[];
  subtotal: number;
  taxAmount: number;
  tax: number;
  total: number;
  status: CustomerOrderStatus;
}

interface Invoice {
  invoice_id: string;
  invoice_payment: string;
  invoice_total: number;
  invoice_timestamp: string;
  invoice_number: string;
}

export interface CheckoutPreviewDialogProps {
  customerOrderId: string;
  onCancel: () => void;
  onSubmit: () => void;
  unDoneRepairs?: getCustomerOrder_getCustomerOrder_repairs[];
  customer: getCustomerOrder_getCustomerOrder_customer;
}

const CheckoutPreviewDialog: React.FC<
  React.PropsWithChildren<CheckoutPreviewDialogProps>
> = ({ customerOrderId, onSubmit, onCancel, unDoneRepairs, customer }) => {
  const [order, setOrder] =
    React.useState<getCustomerOrderCheckoutPreview_getCustomerOrderCheckoutPreview>();
  // const [unDoneRepairs, setUnDoneRepairs] = React.useState<RepairI[]>();
  const [invoice, setInvoice] = React.useState<Invoice>();
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(
    Object.values(PaymentMethod)[0]
  );

  const { loading } = useQuery<
    getCustomerOrderCheckoutPreview,
    getCustomerOrderCheckoutPreviewVariables
  >(GET_CUSTOMER_ORDER_CHECKOUT_PREVIEW, {
    variables: { id: parseInt(customerOrderId) },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOrder(data.getCustomerOrderCheckoutPreview);
    },
    onError: () => {},
  });

  const [checkout, { loading: loadingCheckout }] = useMutation<
    checkoutI,
    checkoutVariables
  >(CHECKOUT, {
    onCompleted: (data) => {
      setInvoice(data.checkout);
    },
    onError: () => {},
  });

  const handleCheckout = () => {
    if (order) {
      checkout({
        variables: {
          customerOrderId: order.id,
          paymentMethod: paymentMethod,
        },
      });
    }
  };

  // set the paymentMethod
  // UPDATE FIX
  const handlePaymentChange = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as PaymentMethod);
  };

  // this beauty checks for missing customer contact data
  const checkEmptyCustomerContact = (
    customer: getCustomerOrder_getCustomerOrder_customer
  ) => {
    if (
      !customer.email ||
      !customer.phone ||
      !customer.firstname ||
      !customer.lastname ||
      !customer.address ||
      !customer.address.street1 ||
      !customer.address.postalCode ||
      !customer.address.city
    ) {
      return true;
    } else return false;
  };

  const checkDisabled = () => {
    if (!paymentMethod) return true;

    // check if customer contact is complete
    if (paymentMethod === PaymentMethod.KREDITRECHNUNG) {
      if (checkEmptyCustomerContact(customer)) return true;
    }

    // no repairs
    if (!order?.repairs?.length) {
      // np productOrder
      if (!order?.productOrders?.length) {
        return true;
      }

      // TODO: this will all be removed once the review includes nonBillable items
      const p: any = order?.productOrders.map((o) =>
        o.products?.map((p) => p.id)
      );
      const products = [].concat.apply([], p);

      // productOrder with no products
      if (!products || !products.length) {
        return true;
      }
    }

    return false;
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="md"
      title="app.dialog.customerOrder.checkout.title"
      open={!!customerOrderId}
      onClose={onCancel}
      secondaryAction={onCancel}
      primaryAction={!invoice ? handleCheckout : onSubmit}
      primaryActionLabel={
        !invoice
          ? 'app.dialog.customerOrder.checkout.createInvoice'
          : 'app.common.done'
      }
      primaryDisabled={!order || loading || loadingCheckout || checkDisabled()}
      isLoading={loading || loadingCheckout}
    >
      {order && !invoice && (
        <Box minHeight={250}>
          <Typo
            variant="h6"
            gutterBottom
            t="app.dialog.customerOrder.checkout.table.invoicable"
          />
          <Box marginBottom={3}>
            <CostsTable
              repairs={order.repairs as RepairI[]}
              productOrders={order.productOrders as ProductOrderI[]}
              total={order.total}
              subTotal={order.subtotal}
              taxAmount={order.taxAmount}
              tax={TAX}
              filterNonBillable
              showRepairCodeOnWorkStep
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Typo bold t="model.checkout.paymentMethod" mb={1} />
            <Box minWidth="250px">
              <Select
                variant="outlined"
                margin="dense"
                value={paymentMethod}
                onChange={handlePaymentChange}
                disabled={loadingCheckout}
                fullWidth
              >
                {Object.values(PaymentMethod).map((paymentMethod) => (
                  <MenuItem key={paymentMethod} value={paymentMethod}>
                    <Typo t={`model.checkout.paymentMethod.${paymentMethod}`} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
      )}

      {/* invoice created */}
      {invoice && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginBottom={1}>
            <DoneAllIcon color="primary" fontSize="large" />
          </Box>

          <Typo
            variant="h5"
            t="app.dialog.customerOrder.checkout.invoice.success"
            gutterBottom
          />
          <Typo
            variant="h6"
            t="app.dialog.customerOrder.checkout.invoice.sub"
          />

          <Box
            display="flex"
            flexDirection="column"
            border="1px solid"
            minWidth="300px"
            borderRadius={1}
            p={1}
            m={2}
            paddingX={1}
            bgcolor="#f4f4f4"
          >
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              flexGrow={1}
              color="primary.main"
            >
              <Typo t="app.dialog.customerOrder.checkout.invoice.id" />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://myhellocash.com/intern/cash-register/invoice/search?q=${invoice.invoice_number}`}
              >
                <Typography>{invoice.invoice_number}</Typography>
              </a>
            </Box>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Typo t="app.dialog.customerOrder.checkout.invoice.payment" />
              <Typography>{invoice.invoice_payment}</Typography>
            </Box>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Typo t="app.dialog.customerOrder.checkout.invoice.timestamp" />
              <Date
                date={invoice.invoice_timestamp}
                format="DD. MMMM YYYY HH:mm"
              />
            </Box>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Typo t="app.dialog.customerOrder.checkout.invoice.total" />
              <Typography>{`${invoice.invoice_total} €`}</Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" color="red" marginTop={2}>
        {unDoneRepairs?.length ? (
          <Typo
            t="app.dialog.customerOrder.checkout.error.repairs"
            values={{
              repairCodes: unDoneRepairs
                ?.map((repair) => repair.repairCode)
                .join(', '),
            }}
          />
        ) : null}
      </Box>

      {paymentMethod === PaymentMethod.KREDITRECHNUNG &&
      checkEmptyCustomerContact(customer) ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          color="red"
          marginTop={2}
        >
          <Typo t="app.dialog.customerOrder.checkout.error.InclompleteCustomerdata" />
          <Typo t="app.dialog.customerOrder.checkout.error.InclompleteCustomerdata.list" />
        </Box>
      ) : null}
    </DialogBase>
  );
};

CheckoutPreviewDialog.propTypes = {
  customerOrderId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // unDoneRepairs:
};
CheckoutPreviewDialog.defaultProps = {};

export default CheckoutPreviewDialog;
