import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';

import { useNotes } from '../../NotesContext';
import Typo from '../../../../components/Typo';

export interface OnlyMyNotesToggleProps {}

const OnlyMyNotesToggle: React.FC<React.PropsWithChildren<OnlyMyNotesToggleProps>> = () => {
  const { onlyMyNotes, setOnlyMyNotes } = useNotes();

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={onlyMyNotes}
          onChange={(_, checked) => setOnlyMyNotes(checked)}
          name="onlyMyNotes"
        />
      }
      label={<Typo t="page.notes.onlyMyNotes" />}
    />
  );
};

OnlyMyNotesToggle.propTypes = {};
OnlyMyNotesToggle.defaultProps = {};

export default OnlyMyNotesToggle;
