import { assign, spawn } from 'xstate';
import assertEventType from '../../../../../lib/helper/xstateHelper';
import orderMachine, {
  initialOrder,
} from '../../subOrders/productOrder/productOrderMachine';
import { ProductOrderMachine } from '../../subOrders/productOrder/types';
import {
  SubOrderProductOrder,
  SubOrdersContext,
  SubOrdersEvent,
} from '../types';

export const spawnOrder = assign<SubOrdersContext, SubOrdersEvent>({
  orders: (ctx: SubOrdersContext) => {
    const newOrder: SubOrderProductOrder = {
      ...initialOrder,
      index: ctx.index,
      ref: spawn(orderMachine.withContext(initialOrder)) as ProductOrderMachine,
    };

    return ctx.orders.concat(newOrder);
  },
});

export const removeOrder = assign<SubOrdersContext, SubOrdersEvent>({
  orders: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_REMOVE_ORDER');

    return ctx.orders.filter(
      (order: SubOrderProductOrder) => order.ref.id !== e.id
    );
  },
});

export const completeOrder = assign<SubOrdersContext, SubOrdersEvent>({
  orders: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_COMPLETE_ORDER');

    return ctx.orders.map((order: SubOrderProductOrder) => {
      return order.ref.id === e.id
        ? { ...order, completed: true, data: e.data }
        : order;
    });
  },
});

export const unCompleteOrder = assign<SubOrdersContext, SubOrdersEvent>({
  orders: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_UNCOMPLETE_ORDER');

    return ctx.orders.map((order: SubOrderProductOrder) => {
      return order.ref.id === e.id ? { ...order, completed: false } : order;
    });
  },
});
