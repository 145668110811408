import React from 'react';
import { GridRowParams } from '@mui/x-data-grid';

import PageContainer from '../../components/PageContainer';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import PageActionButtons from '../../components/PageActionButtons';
import CustomersTable from '../../components/Table/Tables/CustomersTable';

export interface CustomersPageProps {}

const CustomersPage: React.FC<React.PropsWithChildren<CustomersPageProps>> = () => {
  const history = useHistory();

  const handleRowClick = (rowData: GridRowParams) => {
    history.push(`/customers/${rowData?.row?.id}`);
  };

  return (
    <PageContainer page="customers">
      <PageActionButtons>
        <Button
          color="primary"
          variant="contained"
          t="page.customers.button.addCustomer"
          onClick={() => history.push('/customers/new')}
        />
      </PageActionButtons>

      <CustomersTable
        onRowClick={handleRowClick}
        persistedKey="customersOverview"
      />
    </PageContainer>
  );
};

CustomersPage.propTypes = {};
CustomersPage.defaultProps = {};

export default CustomersPage;
