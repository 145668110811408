import React from 'react';
import { GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import TableBase from '../../../components/Table/TableBase';
import RepairStatusChip from '../../../components/RepairStatusChip';
import { RepairStatus } from '../../../types/graphql-global-types';
import NameRow, { NameRowName } from '../../../components/Table/Rows/NameRow';
import LocationRow from '../../../components/Table/Rows/LocationRow';
import { getCustomerOrder_getCustomerOrder_repairs } from '../../../gql/customerOrder/types/getCustomerOrder';

export interface RepairsTableProps {
  repairs?: getCustomerOrder_getCustomerOrder_repairs[] | null;
  loading: boolean;
}

const RepairsTable: React.FC<React.PropsWithChildren<RepairsTableProps>> = ({
  repairs,
  loading,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleRepairClick = (params: GridRowParams) => {
    history.push(`/repairs/${params.row.repairCode}`);
  };

  const columns: GridColDef[] = [
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.repairs.repairCode',
      }),
      field: 'repairCode',
      flex: 1,
      sortable: true,
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.repairs.status',
      }),
      field: 'status',
      flex: 1,
      sortable: true,
      renderCell: (params: GridCellParams) => (
        <RepairStatusChip status={params.value as RepairStatus} />
      ),
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.repairs.mechanic',
      }),
      field: 'mechanic',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <NameRow name={params.value as NameRowName} />
      ),
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.repairs.location',
      }),
      field: 'locationId',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <LocationRow locationId={params.value as string} />
      ),
    },
    // {
    //   headerName: intl.formatMessage({
    //     id: 'app.table.colDef.repairs.skillLevel',
    //   }),
    //   field: 'skillLevel',
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params: GridCellParams) => (
    //     <SkillLevelChip type={params.value} />
    //   ),
    // },
  ];

  return (
    <TableBase
      onPaper
      title="app.table.colDef.repairs"
      columns={columns}
      rows={repairs ?? []}
      loading={loading}
      rowsPerPageOptions={[3]}
      pageSize={3}
      rowCount={repairs?.length || 0}
      onRowClick={handleRepairClick}
      sortingMode="client"
      filterMode="client"
    />
  );
};

RepairsTable.propTypes = {};
RepairsTable.defaultProps = {};

export default RepairsTable;
