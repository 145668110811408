import React from 'react';
import PropTypes from 'prop-types';
import Dialog, {
  DialogProps as MuiDialogProps,
} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '../Button';
import Typo from '../Typo';
import Box from '@mui/material/Box';

export interface DialogBaseProps extends MuiDialogProps {
  title?: string;
  primaryActionLabel?: string;
  primaryAction?: () => void;
  secondaryActionLabel?: string;
  secondaryAction?: () => void;
  hideSecondaryAction?: boolean;
  children: React.ReactNode;
  isForm?: boolean;
  open: boolean;
  isLoading?: boolean;
  primaryDisabled?: boolean;
  primaryType?: 'button' | 'reset' | 'submit' | undefined;
  primaryForm?: string | undefined;
}

const DialogBase: React.FC<React.PropsWithChildren<DialogBaseProps>> = ({
  title = 'app.dialog.default.title',
  primaryActionLabel = 'app.common.defaultPrimary',
  secondaryActionLabel = 'app.common.defaultSecondary',
  secondaryAction,
  primaryAction,
  children,
  isForm,
  open,
  isLoading = false,
  hideSecondaryAction,
  primaryDisabled,
  primaryType,
  primaryForm,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={secondaryAction} {...props}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typo variant="h5" t={title} />
          {isLoading && <CircularProgress color="primary" size={26} />}
        </Box>
      </DialogTitle>
      {isForm ? (
        children
      ) : (
        <>
          <DialogContent>{children}</DialogContent>

          <Box padding={2} paddingTop={0}>
            <DialogActions>
              {!hideSecondaryAction && (
                <Button
                  data-test="dialogSecondary"
                  onClick={secondaryAction}
                  color="primary"
                  variant="outlined"
                  t={secondaryActionLabel}
                />
              )}

              {!!primaryAction && (
                <Button
                  data-test="dialogPrimary"
                  disableElevation
                  onClick={primaryAction}
                  variant="contained"
                  color="primary"
                  t={primaryActionLabel}
                  disabled={primaryDisabled}
                  type={primaryType}
                  form={primaryForm}
                />
              )}
            </DialogActions>
          </Box>
        </>
      )}
    </Dialog>
  );
};

DialogBase.propTypes = {
  title: PropTypes.string,
  primaryActionLabel: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  secondaryAction: PropTypes.func,
  primaryAction: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isForm: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideSecondaryAction: PropTypes.bool,
  primaryDisabled: PropTypes.bool,
  primaryType: PropTypes.oneOf(['button', 'reset', 'submit', undefined]),
  primaryForm: PropTypes.string,
};
DialogBase.defaultProps = {
  title: 'app.dialog.default.title',
  primaryActionLabel: 'app.common.defaultPrimary',
  secondaryActionLabel: 'app.common.defaultSecondary',
  isForm: false,
  isLoading: false,
  hideSecondaryAction: false,
  primaryDisabled: false,
  primaryType: undefined,
  primaryForm: undefined,
} as Partial<DialogBaseProps>;

export default DialogBase;
