import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      width: '100%',
    },
    select: {
      backgroundColor: '#fff',
    },
  })
);

export interface FormSelectProps {
  name: string;
  defaultValue?: string;
  children: any;
}

const FormSelect: React.FC<React.PropsWithChildren<FormSelectProps>> = ({
  name,
  defaultValue,
  children,
}) => {
  const classes = useStyles();
  const { errors, control } = useFormContext();

  return (
    <FormControl
      variant="standard"
      error={!!errors[name]}
      className={classes.control}
      hiddenLabel>
      <Controller
        as={
          <Select
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.select}
          >
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>
        {errors[name] && <FormattedMessage id={errors[name].message} />}
      </FormHelperText>
    </FormControl>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
FormSelect.defaultProps = {
  defaultValue: '',
};

export default FormSelect;
