import React from 'react';
import PropTypes from 'prop-types';
import Typography, { TypographyProps } from '@mui/material/Typography';
import dayjs from 'dayjs';

export interface DateProps extends TypographyProps {
  date?: string | null;
  format?: string;
}

const Date: React.FC<React.PropsWithChildren<DateProps>> = ({ date, format, ...props }) => {
  if (!date) return <Typography>-</Typography>;
  return <Typography {...props}>{dayjs(date).format(format)}</Typography>;
};

Date.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
};
Date.defaultProps = {
  date: undefined,
  format: 'DD. MMMM YYYY',
};

export default Date;
