import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LocationIcon from '@mui/icons-material/LocationOn';

import DrawerItem from './DrawerItem';
import VisibleCheck from '../../../VisibleCheck';
import { logout } from '../../../../lib/auth';
import UpdateLocationDialog from '../../../Dialog/UpdateLocation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
  })
);

export interface DrawerContentProps {
  isManager: boolean;
  isMobile?: boolean;
  page: string;
  handleDrawerToggle?: () => void;
}

const DrawerContent: React.FC<React.PropsWithChildren<DrawerContentProps>> = ({
  isManager,
  isMobile = false,
  page,
  handleDrawerToggle = () => {},
}) => {
  const classes = useStyles();
  const [openLocation, setOpenLocation] = React.useState(false);

  /**
   * Checks if one of the given pages is the current active page
   * @param toCheckPages array
   */
  const checkIfActive = (toCheckPages: string[]): boolean =>
    toCheckPages.includes(page);

  /** */
  const handleOpenLocation = () => {
    handleDrawerToggle();
    setOpenLocation(true);
  };

  return (
    <div>
      {/* Header height */}
      <div className={classes.toolbar} />

      <Divider />

      {/* Navigation list */}
      <List>
        <VisibleCheck isVisible={isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.dashboard"
            selected={checkIfActive(['dashboard', 'customerOrder'])}
            Icon={<DashboardIcon fontSize="large" />}
            path="/"
            dataTest="nav-item-dashboard"
          />
        </VisibleCheck>

        <VisibleCheck isVisible={!isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.repairs"
            selected={checkIfActive(['repairs', 'repair'])}
            Icon={<BuildIcon fontSize="large" color="inherit" />}
            path="/"
            dataTest="nav-item-repairs"
          />
        </VisibleCheck>

        <VisibleCheck isVisible={isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.add"
            selected={checkIfActive(['newOrder'])}
            Icon={<AddCircleOutlineIcon fontSize="large" />}
            path="/customerOrders/new"
            dataTest="nav-item-createCustomerOrder"
          />
        </VisibleCheck>

        <VisibleCheck isVisible={isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.repairs"
            selected={checkIfActive(['repairs', 'repair'])}
            Icon={<BuildIcon fontSize="large" />}
            path="/repairs"
            dataTest="nav-item-repairs"
          />
        </VisibleCheck>

        <VisibleCheck isVisible={isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.customers"
            selected={checkIfActive(['customers', 'customer', 'newCustomer'])}
            Icon={<GroupIcon fontSize="large" />}
            path="/customers"
            dataTest="nav-item-customers"
          />
        </VisibleCheck>

        <DrawerItem
          isMobile={isMobile}
          label="app.drawer.notes"
          selected={checkIfActive(['notes'])}
          Icon={<SpeakerNotesIcon fontSize="large" />}
          path="/notes"
          dataTest="nav-item-notes"
        />

        <Box paddingY={1}>
          <Divider />
        </Box>

        <VisibleCheck isVisible={isManager}>
          <DrawerItem
            isMobile={isMobile}
            label="app.drawer.settings"
            selected={checkIfActive(['settings', 'worksteps', 'products'])}
            Icon={<SettingsIcon fontSize="large" />}
            path="/settings"
            dataTest="nav-item-settings"
          />
        </VisibleCheck>

        <Hidden smUp>
          {openLocation ? (
            <UpdateLocationDialog
              open={openLocation}
              onClose={() => setOpenLocation(false)}
            />
          ) : null}
          <DrawerItem
            isMobile={isMobile}
            label="app.appbar.profile"
            selected={checkIfActive(['profile'])}
            Icon={<AccountCircleIcon fontSize="large" />}
            path="/profile"
            dataTest="nav-item-profile"
          />

          <DrawerItem
            isMobile={isMobile}
            label="app.appbar.location"
            selected={openLocation}
            Icon={<LocationIcon fontSize="large" />}
            onClick={handleOpenLocation}
            dataTest="nav-item-profile"
          />

          <DrawerItem
            isMobile={isMobile}
            label="app.appBar.logout"
            selected={checkIfActive([])}
            Icon={<ExitToAppIcon fontSize="large" />}
            onClick={logout}
            dataTest="nav-item-location"
          />
        </Hidden>
      </List>
    </div>
  );
};

DrawerContent.propTypes = {
  isManager: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  page: PropTypes.string.isRequired,
};
DrawerContent.defaultProps = {
  isMobile: false,
};

export default DrawerContent;
