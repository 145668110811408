import { assign } from 'xstate';
import assertEventType from '../../../../../lib/helper/xstateHelper';
import { SubOrdersContext, SubOrdersEvent } from '../types';

export {
  spawnRepair,
  removeRepair,
  completeRepair,
  unCompleteRepair,
} from './repair';
export { spawnOrder, removeOrder } from './productOrder';

export const getSettings = assign<SubOrdersContext, SubOrdersEvent>({
  tenantSettings: (_: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'done.invoke.getSettings');
    return e.data;
  },
});

export const incrementIndex = assign<SubOrdersContext, SubOrdersEvent>({
  index: (ctx: SubOrdersContext) => ctx.index + 1,
});
