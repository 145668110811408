import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Divider from '@mui/material/Divider';

import Typo from '../../Typo';
import Products, { ProductQty } from './Products';
import AddProduct from '../../AddProduct';
import {
  CreateProductQuantity,
  ProductQuantityStatus,
} from '../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
  })
);

export interface UpdateWorkStepDefinitionProductsProps {
  products?: ProductQty[] | null;
  addProduct: (data: CreateProductQuantity) => void;
  updateProduct: (id: string, quantity: number) => void;
  removeProduct: (id: string) => void;
}

const UpdateWorkStepDefinitionProducts: React.FC<React.PropsWithChildren<UpdateWorkStepDefinitionProductsProps>> = ({ products, addProduct, updateProduct, removeProduct }) => {
  const classes = useStyles();
  const [showProductSelect, setShowProductSelect] =
    React.useState<boolean>(false);

  const handleAddProduct = (data: any) => {
    const product = {
      productId: data.id,
      quantity: 1,
      status: ProductQuantityStatus.OPEN, // FIXME: remove Status
    } as CreateProductQuantity;
    addProduct(product);
    // setShowProductSelect(false);
  };

  return <>
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typo
          variant="h6"
          t="app.dialog.workStepDefinition.create.steps.proposedProducts"
        />
        <IconButton
          size="small"
          onClick={() => setShowProductSelect((prev: boolean) => !prev)}
        >
          {showProductSelect ? (
            <HighlightOffIcon color="primary" />
          ) : (
            <AddBoxIcon color="primary" />
          )}
        </IconButton>
      </Box>
      <Divider />

      {/* products */}
      <Products
        products={products}
        updateProduct={updateProduct}
        removeProduct={removeProduct}
      />
    </Box>

    {/* Product selection*/}
    {showProductSelect && (
      <Box p={1} borderRadius={1}>
        <AddProduct onSubmit={handleAddProduct} />
      </Box>
    )}
  </>;
};

UpdateWorkStepDefinitionProducts.propTypes = {
  // products?: ProductQty[] | null; FIXME: PropType
  addProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};
UpdateWorkStepDefinitionProducts.defaultProps = {};

export default UpdateWorkStepDefinitionProducts;
