import { assign } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import { CreateProduct } from '../../../orderDraftMachine/types';
import { ProductOrderContext, ProductOrderEvent } from '../types';
export { completeOrder, unCompleteOrder, removeOrder } from './subOrder';

export const addProduct = assign<ProductOrderContext, ProductOrderEvent>({
  data: (ctx: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'ADD_PRODUCT');

    return {
      ...ctx.data,
      products: [
        ...ctx.data.products,
        { ...e.data, quantity: e.data.quantity || 1 },
      ],
    };
  },
});

export const removeProduct = assign<ProductOrderContext, ProductOrderEvent>({
  data: (ctx: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'REMOVE_PRODUCT');

    return {
      ...ctx.data,
      products: ctx.data.products.filter(
        (product: CreateProduct) => product.id !== e.id
      ),
    };
  },
});

export const updateProductCount = assign<
  ProductOrderContext,
  ProductOrderEvent
>({
  data: (ctx: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'UPDATE_PRODUCT_COUNT');

    return {
      ...ctx.data,
      products: ctx.data.products.map((product: CreateProduct) => {
        return product.id === e.id
          ? { ...product, quantity: e.quantity }
          : product;
      }),
    };
  },
});

export const updateProductStatus = assign<
  ProductOrderContext,
  ProductOrderEvent
>({
  data: (ctx: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'UPDATE_PRODUCT_STATUS');

    return {
      ...ctx.data,
      products: ctx.data.products.map((product: CreateProduct) => {
        return product.id === e.id ? { ...product, status: e.status } : product;
      }),
    };
  },
});
