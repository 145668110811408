import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';

import Typo from '../../../../../../Typo';
import RepairProductStatusChip from '../../../../../../RepairProductStatusChip';
import Button from '../../../../../../Button';
import AddProductDialog from '../../../../../../Dialog/AddProduct';
import CreateOneTimeProduct, {
  OneTimeProduct,
} from '../../../../../../Dialog/CreateOneTimeProduct';
import { GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY } from '../../../../../../../gql/product/queries';
import EditProductQuantityStatus from '../../../../../../Dialog/EditProductQuantityStatus';
import ConfirmationDialog from '../../../../../../Dialog/ConfirmationDialog';
import VisibleCheck from '../../../../../../VisibleCheck';
import { formattedCurrency } from '../../../../../../../lib/helper/numbers';
import { getProduct_getProduct } from '../../../../../../../gql/product/types/getProduct';
import { DEFAULT_PRODUCT_STATUS } from '../../../../../../../lib/constants';
import { getRepair_getRepair_workSteps_requiredProducts } from '../../../../../../../gql/repair/types/getRepair';
import { EditedProduct } from '../../../../../../Form/EditProductQuantityStatusForm';
import { getRepairOneTimeProductsRoot } from '../../../../../../../gql/product/types/getRepairOneTimeProductsRoot';
import { RepairProcessingMachineContext } from '../../../../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    requiredProducts: {
      minHeight: 50,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
    },
    header: {
      padding: theme.spacing(0.5, 0),
    },
    last: {
      textAlign: 'right',
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      overflow: 'scroll',
    },
    bold: {
      fontWeight: 'bold',
    },
    emptyText: {
      marginTop: theme.spacing(2),
    },
    createButton: {
      marginLeft: theme.spacing(1),
    },
  })
);

export interface RequiredProductsProps {
  products: getRepair_getRepair_workSteps_requiredProducts[];
  workStep: string;
  editState: boolean;
  disableActions: boolean;
}

const RequiredProducts: React.FC<React.PropsWithChildren<RequiredProductsProps>> = ({
  products,
  workStep,
  editState,
  disableActions,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openOneTime, setOpenOneTime] = React.useState<boolean>(false);
  const [removeProductId, setRemoveProductId] = React.useState<string>();
  const [productEdit, setProductEdit] =
    React.useState<getRepair_getRepair_workSteps_requiredProducts>();

  const [, transition] = RepairProcessingMachineContext();

  // get OneTimeProductRootCategory
  const {
    data: repairOneTimeRootCategory,
    loading: loadingOneTimeRootCategory,
  } = useQuery<getRepairOneTimeProductsRoot>(
    GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY
  );
  const oneTimeRootCategoryId =
    repairOneTimeRootCategory?.getProductCategories[0]?.id;

  /**
   * adds a product to requiredProducts if not already been added. If already added, updates quantity + 1
   * @param product object
   */
  const handleAddProduct = (product: getProduct_getProduct) => {
    const alreadyAdded = products?.find(
      (p: getRepair_getRepair_workSteps_requiredProducts) =>
        p?.product?.id === product?.id
    );

    if (alreadyAdded) {
      transition('UPDATE_PRODUCT_QUANTITY', {
        id: alreadyAdded.id,
        data: { quantity: alreadyAdded.quantity + 1 },
      });
    } else {
      transition('UPDATE_WORKSTEP', {
        id: workStep,
        data: {
          addRequiredProducts: {
            productId: product.id,
            quantity: 1,
            status: DEFAULT_PRODUCT_STATUS,
          },
        },
      });
    }

    setOpen(false);
  };

  /**
   * adds a OneTimeProduct to a WorkStep
   * @param data productData
   */
  const handleCreateOneTimeProduct = (product: OneTimeProduct) => {
    const alreadyAdded = products?.find(
      (p: getRepair_getRepair_workSteps_requiredProducts) =>
        p?.product?.id === product?.id
    );

    if (alreadyAdded) {
      transition('UPDATE_PRODUCT_QUANTITY', {
        id: alreadyAdded.id,
        data: { quantity: alreadyAdded.quantity + (product?.quantity ?? 1) },
      });
    } else {
      transition('UPDATE_WORKSTEP', {
        id: workStep,
        data: {
          addRequiredProducts: {
            productId: product.id,
            quantity: product.quantity || 1,
            status: product.status,
          },
        },
      });
    }

    setOpenOneTime(false);
  };

  const handleEdit = (editData: EditedProduct) => {
    const productQuantity = products?.find(
      (product: getRepair_getRepair_workSteps_requiredProducts) =>
        product.id === editData.productQuantityId
    );

    // update ProductQuantity: status & quantity
    if (productQuantity?.id) {
      transition('UPDATE_PRODUCT_QUANTITY', {
        id: productQuantity.id,
        data: {
          quantity: parseInt(editData?.quantity),
          status: editData?.status,
        },
      });
    }
    // update product: price, name etc. maybe add in the future

    setProductEdit(undefined);
  };

  const handleRemoveProduct = (id: string | undefined) => {
    if (id) {
      transition('UPDATE_WORKSTEP', {
        id: workStep,
        data: { removeRequiredProductIDs: [id] },
      });
      setRemoveProductId(undefined);
    }
  };

  return <>
    <AddProductDialog
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={handleAddProduct}
    />
    {oneTimeRootCategoryId && (
      <CreateOneTimeProduct
        open={openOneTime && !loadingOneTimeRootCategory}
        rootCategory={oneTimeRootCategoryId}
        onClose={() => setOpenOneTime(false)}
        onSubmit={handleCreateOneTimeProduct}
      />
    )}
    {productEdit ? (
      <EditProductQuantityStatus
        product={productEdit}
        onClose={() => setProductEdit(undefined)}
        onSubmit={handleEdit}
      />
    ) : null}

    <ConfirmationDialog
      title="app.repairProcessing.content.deleteConfirmation.title"
      message="app.repairProcessing.content.deleteConfirmation.message"
      primaryLabel="app.repairProcessing.content.deleteConfirmation.primaryLabel"
      secondaryLabel="app.repairProcessing.content.deleteConfirmation.secondaryLabel"
      open={!!removeProductId}
      onConfirmed={() => handleRemoveProduct(removeProductId)}
      onDeclined={() => setRemoveProductId(undefined)}
    />
    <div className={classes.requiredProducts}>
      <Grid container spacing={0}>
        {/* HEADER */}
        <Grid item className={classes.header} xs={4}>
          <Typo
            className={classes.bold}
            t="app.repairProcessing.content.product"
          />
          <Divider />
        </Grid>
        <Grid item className={classes.header} xs={2}>
          <Typo
            className={classes.bold}
            t="app.repairProcessing.content.productCount"
          />
          <Divider />
        </Grid>
        <Grid item className={classes.header} xs={2}>
          <Typo
            className={classes.bold}
            t="app.repairProcessing.content.productPrice"
          />
          <Divider />
        </Grid>
        <Grid item className={classes.header} xs={2}>
          <Typo
            className={classes.bold}
            t="app.repairProcessing.content.productStatus"
          />
          <Divider />
        </Grid>
        <Grid item className={`${classes.header} ${classes.last}`} xs={2}>
          <Typo
            className={classes.bold}
            t="app.repairProcessing.content.actions"
          />
          <Divider />
        </Grid>
      </Grid>

      {/* PRODUCTS */}
      {products?.map(
        (product: getRepair_getRepair_workSteps_requiredProducts) => (
          <Grid container spacing={0} key={product.id}>
            {/* HEADER */}
            <Grid item className={classes.cell} xs={4}>
              <Typography>{product.product.name}</Typography>
            </Grid>
            <Grid item className={classes.cell} xs={2}>
              <Typography>{product.quantity}</Typography>
            </Grid>
            <Grid item className={classes.cell} xs={2}>
              <Typography>
                {formattedCurrency(product.product.price)}
              </Typography>
            </Grid>
            <Grid item className={classes.cell} xs={2}>
              {product.status && (
                <RepairProductStatusChip status={product.status} />
              )}
              {/* <Typography>{product.status}</Typography> */}
            </Grid>
            <Grid item xs={2}>
              <VisibleCheck isVisible={editState}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={() => setProductEdit(product)}
                    disabled={disableActions}
                    size="large">
                    <EditIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    onClick={() => setRemoveProductId(product.id)}
                    disabled={disableActions}
                    size="large">
                    <DeleteIcon
                      fontSize="small"
                      color={disableActions ? 'inherit' : 'error'}
                    />
                  </IconButton>
                </Box>
              </VisibleCheck>
            </Grid>
          </Grid>
        )
      )}

      {!products?.length && (
        <Typo
          t="app.repairProcessing.content.emptyProducts"
          className={classes.emptyText}
        />
      )}

      {/* Add products button */}
      <Box marginTop={2}>
        <VisibleCheck isVisible={editState}>
          <Button
            t="app.customerOrderDraft.subOrders.repair.addProduct"
            onClick={() => setOpen(true)}
            variant="contained"
            disableElevation
            color="primary"
            disabled={disableActions}
          />
          <Button
            t="app.customerOrderDraft.subOrders.repair.addOneTimeProduct"
            onClick={() => setOpenOneTime(true)}
            variant="outlined"
            color="primary"
            className={classes.createButton}
            disabled={disableActions}
          />
        </VisibleCheck>
      </Box>
    </div>
  </>;
};

RequiredProducts.propTypes = {
  workStep: PropTypes.string.isRequired,
  editState: PropTypes.bool.isRequired,
  disableActions: PropTypes.bool.isRequired,
};
RequiredProducts.defaultProps = {};

export default RequiredProducts;
