import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { RawDraftContentState } from 'draft-js';

import { CUSTOMER_DETAIL } from '../../../../gql/customer/queries';
import CustomerSearch from './CustomerSearch';
import Typo from '../../../Typo';
import CustomerEdit from './CustomerEdit';
import Paper from '../../../Paper';
import {
  getCustomer,
  getCustomer_getCustomer_address,
  getCustomer_getCustomer_bikes,
} from '../../../../gql/customer/types/getCustomer';
import { Gender } from '../../../../types/graphql-global-types';
import { CustomerDraftMachinesContext } from '../..';

export interface CustomerDataProps {}

export interface Customer {
  id: string;
  gender: Gender;
  lastname: string;
  firstname: string;
  phone: string | null;
  email: string | null;
  notes: RawDraftContentState | null;
  address?: getCustomer_getCustomer_address | null;
  bikes?: getCustomer_getCustomer_bikes[] | null;
}

const CustomerData: React.FC<React.PropsWithChildren<CustomerDataProps>> = () => {
  const { customerMachine } = CustomerDraftMachinesContext();

  const [state, transition] = customerMachine;
  const [customer, setCustomer] = React.useState<Customer>();

  const [getCustomerDetail, { loading: detailLoading }] =
    useLazyQuery<getCustomer>(CUSTOMER_DETAIL, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCustomer(data.getCustomer);
        transition('SET_CUSTOMER', { value: data.getCustomer.id });
      },
    });

  const isSearching = state.matches('searching');
  const isSelected = state.matches('selected');
  const isCreating = state.matches('creating');

  const handleSetCustomer = (customerId: string) => {
    transition('SET_CUSTOMER', { value: customerId });
  };

  const handleClearCustomer = () => {
    transition('CLEAR_CUSTOMER');
  };

  // show createCustomerForm
  const handleSetCreateCustomer = () => {
    transition('CREATE_CUSTOMER');
  };

  return (
    <Paper isLoading={detailLoading} padding data-test="customerData">
      <Typo
        variant="h5"
        t="app.customerOrderDraft.customer.title"
        gutterBottom
      />

      {isSearching && (
        <CustomerSearch
          handleSetCreateCustomer={handleSetCreateCustomer}
          getCustomerDetail={getCustomerDetail}
        />
      )}
      {isSelected && customer && (
        <CustomerEdit
          customer={customer}
          handleSetCustomer={handleSetCustomer}
          handleClearCustomer={handleClearCustomer}
        />
      )}
      {isCreating && (
        <CustomerEdit
          customer={undefined}
          handleSetCustomer={handleSetCustomer}
          handleClearCustomer={handleClearCustomer}
          handleCreateCustomer={setCustomer}
        />
      )}
    </Paper>
  );
};

CustomerData.propTypes = {};
CustomerData.defaultProps = {};

export default CustomerData;
