import { gql } from '@apollo/client';

export const GET_WORKSTEP_ROOT_CATEGORIES = gql`
  query getWorkStepCategories {
    getWorkStepCategories(isRootCategory: true) {
      isRootCategory
      id
      createdAt
      updatedAt
      name
      description
      sequence
      color
      isActive
      subCategories {
        id
        name
        sequence
        description
        color
        isActive
      }
      workSteps {
        id
        name
        expectedWorkload
        description
        sequence
        color
        isActive
        skillLevel
        categories {
          id
        }
        proposedProductCategory {
          id
          name
        }
        proposedProducts {
          id
          quantity
          product {
            id
            name
            description
            price
            manufacturer
          }
        }
      }
    }
  }
`;

export const GET_WORKSTEP_CATEGORY = gql`
  query getWorkStepCategory($id: String!) {
    getWorkStepCategory(id: $id) {
      id
      isRootCategory
      createdAt
      updatedAt
      name
      description
      sequence
      color
      isActive
      workSteps {
        id
        name
        expectedWorkload
        description
        sequence
        color
        isActive
        skillLevel
        categories {
          id
        }
        proposedProductCategory {
          id
          name
        }
        proposedProducts {
          id
          quantity
          product {
            id
            name
            description
            price
            manufacturer
          }
        }
      }
      subCategories {
        id
        name
        description
        sequence
        color
        isActive
      }
    }
  }
`;

//TODO: rename to GET_WORKSTEP_DEFINITION
export const GET_WORKSTEP_DETAIL = gql`
  query getWorkStepDefinition($id: String!) {
    getWorkStepDefinition(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      isActive
      proposedProducts {
        quantity
        product {
          id
          name
          description
          price
          manufacturer
        }
      }
      proposedProductCategory {
        id
        name
      }
      expectedWorkload
      skillLevel
      checklist
      categories {
        id
      }
    }
  }
`;
