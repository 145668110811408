import React from 'react';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';

import PageContainer from '../../components/PageContainer';
import Paper from '../../components/Paper';
import ChangePasswordForm from '../../components/Form/ChangePasswordForm';
import Typo from '../../components/Typo';
import { CHANGE_PASSWORD } from '../../gql/user/mutations';
import SnackbarUtils from '../../lib/SnackbarUtils';
import { ChangePasswordInput } from '../../types/graphql-global-types';
import {
  changePassword as changePasswordI,
  changePasswordVariables,
} from '../../gql/user/types/changePassword';

export interface ProfilePageProps {}

const ProfilePage: React.FC<React.PropsWithChildren<ProfilePageProps>> = () => {
  const [changePassword, { loading }] = useMutation<
    changePasswordI,
    changePasswordVariables
  >(CHANGE_PASSWORD, {
    fetchPolicy: 'no-cache',
    onCompleted: () =>
      SnackbarUtils.success('page.profile.changePassword.success'),
    onError: () => {},
  });

  const handleChangePassword = (data: ChangePasswordInput) => {
    changePassword({
      variables: {
        data: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      },
    });
  };

  return (
    <PageContainer page="profile" loading={loading}>
      <Box maxWidth={600}>
        <Paper padding>
          <Typo gutterBottom variant="h5" t="page.profile.changePassword" />
          <ChangePasswordForm onSubmit={handleChangePassword} />
        </Paper>
      </Box>
    </PageContainer>
  );
};

ProfilePage.propTypes = {};
ProfilePage.defaultProps = {};

export default ProfilePage;
