import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';

import Button from '../../../../../Button';
import Typo from '../../../../../Typo';
import AddProductDialog from '../../../../../Dialog/AddProduct';
import SlectedItem from '../../../../../SelectedItem';
import CreateOneTimeProduct, {
  OneTimeProduct,
} from '../../../../../Dialog/CreateOneTimeProduct';
import { GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY } from '../../../../../../gql/product/queries';
import { DEFAULT_PRODUCT_STATUS } from '../../../../../../lib/constants';
import { ProductQuantityStatus } from '../../../../../../types/graphql-global-types';
import { CreateProduct } from '../../../../machines/orderDraftMachine/types';
import { getProduct_getProduct } from '../../../../../../gql/product/types/getProduct';
import { getRepairOneTimeProductsRoot } from '../../../../../../gql/product/types/getRepairOneTimeProductsRoot';

export interface ProductsProps {
  selected: CreateProduct[];
  isValidating: boolean;
  hideAddProduct?: boolean;
  handleAddProduct: (data: CreateProduct) => void;
  handleRemoveProduct: (id: string) => void;
  handleUpdateProductCount: (id: string, quantity: number) => void;
  handleUpdateProductStatus: (
    id: string,
    status: ProductQuantityStatus
  ) => void;
}

const Products: React.FC<React.PropsWithChildren<ProductsProps>> = ({
  selected,
  isValidating,
  hideAddProduct,
  handleAddProduct,
  handleRemoveProduct,
  handleUpdateProductCount,
  handleUpdateProductStatus,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openOneTime, setOpenOneTime] = React.useState<boolean>(false);

  // get OneTimeProductRootCategory
  const {
    data: repairOneTimeRootCategory,
    loading: loadingOneTimeRootCategory,
  } = useQuery<getRepairOneTimeProductsRoot>(
    GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY
  );
  const oneTimeRootCategoryId =
    repairOneTimeRootCategory?.getProductCategories[0]?.id;

  /**
   * handles Add Product. if Product is aready added, the quantity is updated. If not the product is added to the repair
   */
  const submitProduct = (data: getProduct_getProduct) => {
    const alreadyAdded = selected.find((p: CreateProduct) => p.id === data.id);

    if (alreadyAdded) {
      handleUpdateProductCount(data.id, alreadyAdded.quantity + 1);
    } else {
      handleAddProduct({
        ...data,
        quantity: 1,
        status: DEFAULT_PRODUCT_STATUS,
      });
    }
    setOpen(false);
  };

  /**
   * adds a OneTimeProduct to a WorkStep
   * @param data productData
   */
  const handleCreateOneTimeProduct = (data: OneTimeProduct) => {
    const alreadyAdded = selected.find((p: CreateProduct) => p.id === data.id);

    if (alreadyAdded) {
      handleUpdateProductCount(data.id, alreadyAdded.quantity + 1);
    } else {
      handleAddProduct({
        ...data,
        quantity: data.quantity || 1,
        status: data?.status || DEFAULT_PRODUCT_STATUS,
      });
    }
    setOpen(false);

    setOpenOneTime(false);
  };

  const handleAvailableUpdate = (id: string, isAvailable: boolean) => {
    const status = isAvailable
      ? ProductQuantityStatus.AVAILABLE
      : ProductQuantityStatus.OPEN;

    handleUpdateProductStatus(id, status);
  };

  return (
    <Box display="flex" flexDirection="column">
      {/* add product dialog */}
      {open ? (
        <AddProductDialog
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={submitProduct}
        />
      ) : null}

      <CreateOneTimeProduct
        open={openOneTime && !loadingOneTimeRootCategory}
        rootCategory={oneTimeRootCategoryId}
        onClose={() => setOpenOneTime(false)}
        onSubmit={handleCreateOneTimeProduct}
      />

      {/* list of alread selected products */}
      {selected?.length ? (
        selected.map((product: CreateProduct) => (
          <SlectedItem
            key={product.id}
            id={product.id}
            name={product.name}
            count={product.quantity}
            countRate={product.price}
            manufacturer={product.manufacturer}
            setCount={handleUpdateProductCount}
            RemoveItem={handleRemoveProduct}
            countLabel="app.customerOrderDraft.subOrders.repair.productCount"
            isValidating={isValidating}
            isAvailable={product.status === ProductQuantityStatus.AVAILABLE}
            onAvailableChange={handleAvailableUpdate}
          />
        ))
      ) : (
        <Typo t="app.customerOrderDraft.subOrders.repair.noProducts" />
      )}

      {/* button to add more */}
      <Box display="flex" justifyContent="flex-end">
        {!hideAddProduct ? (
          <Button
            t="app.customerOrderDraft.subOrders.repair.addProduct"
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            disabled={isValidating}
            disableElevation
          />
        ) : null}

        <Box marginLeft={1}>
          <Button
            t="app.customerOrderDraft.subOrders.repair.addOneTimeProduct"
            onClick={() => setOpenOneTime(true)}
            variant="outlined"
            color="primary"
            disabled={isValidating}
          />
        </Box>
      </Box>
    </Box>
  );
};

Products.propTypes = {
  isValidating: PropTypes.bool.isRequired,
  hideAddProduct: PropTypes.bool,
};
Products.defaultProps = {
  hideAddProduct: false,
};

export default React.memo(Products);
