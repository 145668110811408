import React from 'react';
import PropTypes from 'prop-types';

export interface TabPanelProps {
  children?: React.ReactNode;
  /** current active value  */
  index: number;
  /** active value of the Panel. if index === value panel is active and shown */
  value: number;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...rest}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = {};

export default TabPanel;
