import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import AppleStyleTabs from '../../../components/Tabs/AppleStyleTabs';
import { useNotes } from '../NotesContext';
import Button from '../../../components/Button';
import { NoteStatus } from '../../../types/graphql-global-types';
import OnlyMyNotesToggle from './OnlyMyNotesToggle';

export interface NotesTabsProps {}

const getTabFromStatus = (check: NoteStatus): number => {
  if (check === NoteStatus.OPEN) {
    return 0;
  }
  if (check === NoteStatus.DONE) {
    return 1;
  }

  return 0;
};

const NotesTabs: React.FC<React.PropsWithChildren<NotesTabsProps>> = () => {
  const {
    notes,
    limit,
    status,
    page,
    setPage,
    onStatusChange,
    onCreateNote,
    onlyMyNotes,
    loading,
  } = useNotes();
  const [tab, setTab] = React.useState<number>(getTabFromStatus(status));
  const [totalCount, setTotalCount] = React.useState<number>(
    notes?.getNotes.totalCount || 0
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setTab(getTabFromStatus(status));
  }, [status, setTab]);

  React.useEffect(() => {
    setTotalCount(notes?.getNotes.totalCount || 0);
  }, [notes, totalCount, onlyMyNotes]);

  React.useEffect(() => {
    if (status === NoteStatus.OPEN && tab === 1) {
      onStatusChange(NoteStatus.DONE);
      return;
    }

    if (status === NoteStatus.DONE && tab === 0) {
      onStatusChange(NoteStatus.OPEN);
      return;
    }
  }, [tab, status, onStatusChange]);

  return <>
    <Hidden smUp>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <AppleStyleTabs
          current={tab}
          setValue={setTab}
          tabs={[
            { label: 'model.note.status.OPEN' },
            { label: 'model.note.status.DONE' },
          ]}
        />
        {loading ? (
          <Box ml={1}>
            <CircularProgress size={24} />
          </Box>
        ) : null}
        <IconButton onClick={onCreateNote} size="large">
          <AddIcon color="primary" />
        </IconButton>
      </Box>
    </Hidden>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      overflow="scroll"
    >
      <Hidden smDown>
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="flex-start"
        >
          <AppleStyleTabs
            current={tab}
            setValue={setTab}
            tabs={[
              { label: 'model.note.status.OPEN' },
              { label: 'model.note.status.DONE' },
            ]}
          />
          {loading ? (
            <Box ml={1}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
        </Box>
      </Hidden>

      <Hidden mdDown>
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          {totalCount ? (
            <Pagination
              count={Math.ceil(totalCount / limit)}
              onChange={handleChange}
              page={page || 1}
              siblingCount={0}
            />
          ) : null}
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box ml={1}>
          <OnlyMyNotesToggle />
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box
          display="flex"
          flex={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box display="flex">
            <OnlyMyNotesToggle />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              t="page.notes.add"
              onClick={onCreateNote}
            />
          </Box>
        </Box>
      </Hidden>
    </Box>
    <Hidden mdUp>
      <Box mt={1} display="flex" justifyContent="center" alignItems="center">
        {notes?.getNotes && notes.getNotes.totalCount ? (
          <Pagination
            count={Math.ceil(notes.getNotes.totalCount / limit)}
            onChange={handleChange}
            page={page || 1}
            siblingCount={0}
            size="small"
          />
        ) : null}
      </Box>
    </Hidden>
  </>;
};

NotesTabs.propTypes = {};
NotesTabs.defaultProps = {};

export default NotesTabs;
