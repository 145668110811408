import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import UnCheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import Typo from '../../../../../../components/Typo';
import { getRepairsDetailed_getRepairs_items_workSteps } from '../../../../../../gql/repair/types/getRepairsDetailed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noBorder: {
      borderBottom: 'none',
    },
    container: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
  })
);

export interface WorkStepsTableProps {
  workSteps: getRepairsDetailed_getRepairs_items_workSteps[];
}

const WorkStepsTable: React.FC<React.PropsWithChildren<WorkStepsTableProps>> = ({ workSteps }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typo bold t="app.dashboard.repairs.workStepsTable.workSteps" />
            </TableCell>
            <TableCell align="right">
              <Typo bold t="app.dashboard.repairs.workStepsTable.workload" />
            </TableCell>
            <TableCell align="right">
              <Typo bold t="app.dashboard.repairs.workStepsTable.isDone" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workSteps?.map(
            (
              step: getRepairsDetailed_getRepairs_items_workSteps,
              index: number
            ) => (
              <TableRow key={step.id}>
                <TableCell
                  className={
                    index === workSteps.length - 1
                      ? classes.noBorder
                      : undefined
                  }
                >
                  {step?.workStepDefinition?.name}
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    index === workSteps.length - 1
                      ? classes.noBorder
                      : undefined
                  }
                >
                  {step?.setWorkload}
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    index === workSteps.length - 1
                      ? classes.noBorder
                      : undefined
                  }
                >
                  {step?.isDone ? <CheckedIcon /> : <UnCheckedIcon />}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

WorkStepsTable.propTypes = {};
WorkStepsTable.defaultProps = {};

export default WorkStepsTable;
