import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from '@mui/material/Switch';

import Typo from '../../../Typo';

export interface FormSwitchProps {
  name: string;
  defaultValue?: boolean;
  label?: string;
  disabled?: boolean;
}

const FormSwitch: React.FC<React.PropsWithChildren<FormSwitchProps>> = ({
  name,
  defaultValue,
  label = undefined,
  disabled = false,
}) => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        as={<Switch color="primary" disabled={disabled} />}
      />
      {!!label ? (
        <label>
          <Typo t={label} display="inline" />
        </label>
      ) : null}
    </div>
  );
};

FormSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
FormSwitch.defaultProps = {
  defaultValue: false,
  label: undefined,
  disabled: false,
};

export default FormSwitch;
