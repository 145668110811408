import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const cCommonStyles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      border: '1px solid',
      borderColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      boxSizing: 'border-box',
      textAlign: 'left',
      // color: theme.palette.primary.main,
    },
    top: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 20,
    },
    title: {
      fontSize: 16,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 60,
      lineHeight: 1.3,
      overflow: 'auto',
      paddingTop: theme.spacing(1),
    },
    subTitle: {
      fontSize: 14,
      fontStyle: 'italic',
    },
    price: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    descriptionContainer: {
      display: 'flex',
      flex: 1,
      padding: theme.spacing(1),
      width: '100%',
      // margin: theme.spacing(1),
      // backgroundColor: theme.palette.background.default,
      // borderRadius: theme.shape.borderRadius,
      overflow: 'auto',
    },
    description: {
      display: 'flex',
      flex: 1,
      width: '100%',
      fontSize: 12,
    },
  });

export default makeStyles(cCommonStyles);
