import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '../Button';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageActionButtons: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
      overflow: 'auto',
    },
    primaryActions: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    backButton: {
      minWidth: 100,
      marginRight: theme.spacing(2),
    },
  })
);

export interface PageActionButtonsProps {
  hasNavigateBack?: boolean;
  navigateBackTo?: string;
  children?: React.ReactNode;
}

const PageActionButtons: React.FC<React.PropsWithChildren<PageActionButtonsProps>> = ({
  hasNavigateBack = false,
  navigateBackTo = undefined,
  children,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleNavigateBack = () =>
    !!navigateBackTo ? history.push(navigateBackTo) : history.goBack();

  return (
    <div className={classes.pageActionButtons}>
      {hasNavigateBack && (
        <Button
          color="primary"
          variant="outlined"
          t="app.common.navigateBack"
          onClick={handleNavigateBack}
          className={classes.backButton}
        />
      )}
      <div className={classes.primaryActions}>{children}</div>
    </div>
  );
};

PageActionButtons.propTypes = {
  hasNavigateBack: PropTypes.bool,
  navigateBackTo: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
PageActionButtons.defaultProps = {
  hasNavigateBack: false,
  navigateBackTo: undefined,
  children: undefined,
};

export default PageActionButtons;
