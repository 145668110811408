import { Machine } from 'xstate';
import client from '../../../../lib/apolloClient';

import {
  spawnRepair,
  removeRepair,
  completeRepair,
  unCompleteRepair,
  spawnOrder,
  removeOrder,
  getSettings,
  incrementIndex,
} from './actions';
import { completeOrder, unCompleteOrder } from './actions/productOrder';
import { SubOrdersContext, SubOrdersEvent, SubOrdersSchema } from './types';
import { getTenantSettings } from '../../../../gql/tenant/types/getTenantSettings';
import { GET_TENANT_SETTINGS } from '../../../../gql/tenant/queries';

const queryTenantSettings = () =>
  client
    .query<getTenantSettings>({ query: GET_TENANT_SETTINGS })
    .then((result) => result.data.getTenant.settings)
    .catch((e) => console.error('e', e));

const initialContext = {
  index: 0,
  repairs: [],
  orders: [],
  tenantSettings: null,
};

const subOrdersMachine = Machine<
  SubOrdersContext,
  SubOrdersSchema,
  SubOrdersEvent
>(
  {
    id: 'subOrders',
    initial: 'editing',
    context: initialContext,
    invoke: {
      id: 'getSettings',
      src: () => queryTenantSettings,
      onDone: {
        actions: ['getSettings', 'spawnRepair', 'incrementIndex'],
      },
    },
    states: {
      editing: {
        on: {
          SUBORDER_SPAWN_REPAIR: {
            actions: ['spawnRepair', 'incrementIndex'],
          },

          SUBORDER_REMOVE_REPAIR: {
            actions: 'removeRepair',
          },

          SUBORDER_COMPLETE_REPAIR: {
            actions: 'completeRepair',
          },

          SUBORDER_UNCOMPLETE_REPAIR: {
            actions: 'unCompleteRepair',
          },

          SUBORDER_SPAWN_ORDER: {
            actions: ['spawnOrder', 'incrementIndex'],
          },

          SUBORDER_REMOVE_ORDER: {
            actions: 'removeOrder',
          },

          SUBORDER_COMPLETE_ORDER: {
            actions: 'completeOrder',
          },

          SUBORDER_UNCOMPLETE_ORDER: {
            actions: 'unCompleteOrder',
          },
        },
      },
    },
  },
  {
    actions: {
      getSettings: getSettings,
      spawnRepair: spawnRepair,
      removeRepair: removeRepair,
      completeRepair: completeRepair,
      unCompleteRepair: unCompleteRepair,
      spawnOrder: spawnOrder,
      removeOrder: removeOrder,
      completeOrder: completeOrder,
      unCompleteOrder: unCompleteOrder,
      incrementIndex: incrementIndex,
    },
  }
);

export default subOrdersMachine;
