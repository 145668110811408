import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import CustomerSearch from '../../../CustomerOrderDraft/components/CustomerData/CustomerSearch';
import {
  getCustomer,
  getCustomer_getCustomer,
} from '../../../../gql/customer/types/getCustomer';
import { CUSTOMER_DETAIL } from '../../../../gql/customer/queries';
import CustomerInformation from '../../../CustomerInformation';

export interface FormCustomerProps {
  name: string;
  initialValue?: getCustomer_getCustomer | null;
  onCustomerChange?: (customer?: getCustomer_getCustomer | null) => void;
}

const FormCustomer: React.FC<React.PropsWithChildren<FormCustomerProps>> = ({
  name,
  initialValue,
  onCustomerChange,
}) => {
  const [customer, setCustomer] =
    React.useState<getCustomer_getCustomer | null>();

  const { register, setValue } = useFormContext();

  const [getCustomerDetail] = useLazyQuery<getCustomer>(CUSTOMER_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCustomer(data.getCustomer);
      setValue(name, data.getCustomer.id);
    },
  });

  const handleRemoveSearch = () => {
    setCustomer(null);
  };

  React.useEffect(() => {
    register({ name: name, type: 'custom' });
  }, [register, name]);

  React.useEffect(() => {
    onCustomerChange && onCustomerChange(customer);
    if (!customer) {
      setValue(name, null);
      return;
    }

    setValue(name, customer?.id);
  }, [customer, setValue, name, onCustomerChange]);

  React.useEffect(() => {
    if (initialValue) {
      setCustomer(initialValue);
    }
  }, [initialValue, setCustomer]);

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {customer ? (
        <Box>
          <Box
            mt={1}
            p={1}
            bgcolor="background.default"
            borderRadius={1}
          >
            <Box display="flex" flex={1} justifyContent="flex-end">
              <IconButton onClick={handleRemoveSearch} size="large">
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <CustomerInformation customer={customer} />
          </Box>
        </Box>
      ) : (
        <CustomerSearch
          getCustomerDetail={getCustomerDetail}
          onSearchRemove={handleRemoveSearch}
        />
      )}
    </Box>
  );
};

FormCustomer.propTypes = {
  name: PropTypes.string.isRequired,
  onCustomerChange: PropTypes.func,
};
FormCustomer.defaultProps = {};

export default FormCustomer;
