import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { SEARCH_REPAIR } from '../../../gql/repair/queries';
import { useIntl } from 'react-intl';
import Paper from '../../../components/Paper';
import Typo from '../../../components/Typo';
import Button from '../../../components/Button';
import { REPAIR_CODE_EXPRESSION } from '../../../lib/constants';
import {
  searchRepair as searchRepairI,
  searchRepairVariables,
} from '../../../gql/repair/types/searchRepair';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchInput: {
      maxWidth: 320,
      margin: theme.spacing(2, 0),
    },
    button: {
      maxWidth: 180,
    },
  })
);

export interface RepairSearchProps {
  tableRef: any;
}

const RepairSearch: React.FC<React.PropsWithChildren<RepairSearchProps>> = ({ tableRef }) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState<string>('');
  const history = useHistory();
  const intl = useIntl();

  const [searchRepair, { loading, error }] = useLazyQuery<
    searchRepairI,
    searchRepairVariables
  >(SEARCH_REPAIR, {
    onCompleted: (data) => {
      history.push(`repairs/${data?.getRepair?.repairCode}`);
    },
    onError: () => {},
  });

  // trigger search on "Enter" to automatically search after scan
  const handleKeyPress = (v: React.KeyboardEvent<HTMLInputElement>) => {
    if (v.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    searchRepair({ variables: { repairCode: search } });
  };

  const handleInputChange = (input: string) => {
    // skip table search if full repairCode is provided (by scanner)
    if (!input.match(REPAIR_CODE_EXPRESSION)) {
      tableRef?.current?.setSearch(input);
      return;
    }
    setSearch(input);
  };

  return (
    <Container maxWidth="sm">
      <Paper padding className={classes.paper} isLoading={loading}>
        <Typo t="page.repair.searchTitle" variant="h5" align="center" />
        <TextField
          className={classes.searchInput}
          autoFocus
          fullWidth
          placeholder={intl.formatMessage({
            id: 'page.repair.searchPlaceholder',
          })}
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {loading && <CircularProgress size={30} />}
              </InputAdornment>
            ),
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) =>
            handleKeyPress(event)
          }
          error={!!error}
          // helperText={!!error && JSON.stringify(error, null, 2)}
        />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            fullWidth
            className={classes.button}
            t="app.common.search"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleSearch}
          />
        </Box>
      </Paper>
    </Container>
  );
};

RepairSearch.propTypes = {};
RepairSearch.defaultProps = {};

export default RepairSearch;
