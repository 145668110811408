import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';

import EditWorkStepCategoryForm from '../../../Form/EditWorkStepCategoryForm';
import Button from '../../../Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      minHeight: 150,
    },
  })
);

export interface DetailPanelProps {
  selectedCategory: any;
  onCategoryUpdate: (data: any) => void;
}

const DetailPanel: React.FC<React.PropsWithChildren<DetailPanelProps>> = ({
  selectedCategory,
  onCategoryUpdate,
}) => {
  const classes = useStyles();
  const [c, setC] = React.useState<any>();

  const handleOnSumbit = (data: any) => {
    onCategoryUpdate(data);
  };

  // workaround to get the form values to rerender
  // defeaultValues will only change if component rerenders
  React.useEffect(() => {
    if (selectedCategory?.id !== c?.id) {
      setC(selectedCategory);
    }
  }, [c, selectedCategory]);

  if (selectedCategory?.id === c?.id) {
    return (
      <>
        <Box className={classes.container} marginTop={3} p={2}>
          <EditWorkStepCategoryForm
            onSubmit={handleOnSumbit}
            category={selectedCategory}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button
            disableElevation
            disabled={selectedCategory?.isRootCategory}
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            form="workStepCategory-form"
          />
        </Box>
      </>
    );
  }

  return null;
};

DetailPanel.propTypes = {
  onCategoryUpdate: PropTypes.func.isRequired,
};
DetailPanel.defaultProps = {};

export default DetailPanel;
