import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import DialogBase from '..';
import { CREATE_PRODUCT } from '../../../gql/product/mutations';
import Button from '../../Button';
import CreateProductForm from '../../Form/CreateProductForm';
import { getPriceFloat } from '../../../lib/helper/numbers';
import {
  createProduct as createProductI,
  createProductVariables,
} from '../../../gql/product/types/createProduct';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: theme.spacing(1, 2),
      marginTop: theme.spacing(0),
    },
  })
);

export interface CreateProductProps {
  parentCategory: string | null;
  onCreated: () => void;
  onClose: () => void;
}

const CreateProduct: React.FC<React.PropsWithChildren<CreateProductProps>> = ({
  parentCategory = null,
  onCreated,
  onClose,
}) => {
  const classes = useStyles();
  const [createProduct, { loading }] = useMutation<
    createProductI,
    createProductVariables
  >(CREATE_PRODUCT, {
    onCompleted: (data) => {
      onCreated();
    },
    onError: () => {},
  });

  const handleCreateProduct = (data: any) => {
    const d = { ...data, price: getPriceFloat(data.price) };
    createProduct({ variables: { data: d } });
  };

  return (
    <DialogBase
      fullWidth
      scroll="paper"
      maxWidth="sm"
      open={!!parentCategory}
      onClose={onClose}
      isForm
      title="app.dialog.createProduct.title"
      isLoading={loading}
    >
      <div className={classes.container}>
        <CreateProductForm
          parentCategory={parentCategory || ''}
          onSubmit={handleCreateProduct}
          onClose={onClose}
        />
      </div>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          t="app.common.defaultSecondary"
        />
        <Button
          variant="contained"
          color="primary"
          t="app.common.defaultPrimary"
          type="submit"
          form="createProduct-form"
        />
      </DialogActions>
    </DialogBase>
  );
};

CreateProduct.propTypes = {
  parentCategory: PropTypes.string,
  onCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
CreateProduct.defaultProps = {
  parentCategory: null,
};

export default CreateProduct;
