import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';

import DialogBase from '..';
import Button from '../../Button';
import { CREATE_PRODUCT_CATEGORY } from '../../../gql/product/mutations';
import CreateProductCategoryForm from '../../Form/CreateProductCategoryForm';
import {
  createProductCategory as createProductCategoryI,
  createProductCategoryVariables,
} from '../../../gql/product/types/createProductCategory';
import { CreateProductCategoryInput } from '../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
  })
);

export interface CreateProductCategoryProps {
  parentCategory: string | null;
  onCreated: (data: any) => void;
  onClose: () => void;
}

const CreateProductCategory: React.FC<React.PropsWithChildren<CreateProductCategoryProps>> = ({
  parentCategory,
  onCreated,
  onClose,
}) => {
  const classes = useStyles();

  const [createProductCategory, { loading }] = useMutation<
    createProductCategoryI,
    createProductCategoryVariables
  >(CREATE_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      onCreated(data.createProductCategory);
    },
    onError: () => {},
  });

  const handleCreateProductCategory = (data: CreateProductCategoryInput) => {
    createProductCategory({ variables: { data } });
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      open={!!parentCategory}
      onClose={onClose}
      isForm
      title="app.dialog.workStepCategory.add.title"
      isLoading={loading}
    >
      <Box className={classes.container} m={2}>
        {parentCategory ? (
          <CreateProductCategoryForm
            parentCategory={parentCategory}
            onSubmit={handleCreateProductCategory}
          />
        ) : null}
      </Box>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          t="app.common.defaultSecondary"
        />
        <Button
          disableElevation
          variant="contained"
          color="primary"
          t="app.common.defaultPrimary"
          type="submit"
          form="createProductCategory-form"
        />
      </DialogActions>
    </DialogBase>
  );
};

CreateProductCategory.propTypes = {
  parentCategory: PropTypes.string,
  onCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
CreateProductCategory.defaultProps = {};

export default CreateProductCategory;
