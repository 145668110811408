import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import CustomerForm from '../../../../Form/CustomerForm';
import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER,
} from '../../../../../gql/customer/mutations/customer';
import { Customer } from '..';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateCustomerAddress,
  updateCustomerAddressVariables,
} from '../../../../../gql/customer/mutations/types/updateCustomerAddress';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createCustomerAddress,
  createCustomerAddressVariables,
} from '../../../../../gql/customer/mutations/types/createCustomerAddress';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createCustomer,
  createCustomerVariables,
} from '../../../../../gql/customer/mutations/types/createCustomer';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateCustomer,
  updateCustomerVariables,
} from '../../../../../gql/customer/mutations/types/updateCustomer';

export interface CustomerEditProps {
  customer?: Customer;
  handleClearCustomer: () => void;
  handleSetCustomer: (customerId: string) => void;
  handleCreateCustomer?: (data: Customer) => void;
}

const CustomerEdit: React.FC<React.PropsWithChildren<CustomerEditProps>> = ({
  customer: initial,
  handleClearCustomer,
  handleSetCustomer,
  handleCreateCustomer,
}) => {
  const [customer, setCustomer] = React.useState<Customer | undefined>(initial);

  const [updateCustomer, { loading }] = useMutation<
    updateCustomer,
    updateCustomerVariables
  >(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      setCustomer({
        ...customer,
        ...data.updateCustomer,
      } as Customer);
    },
    onError: () => {},
  });

  const [updateCustomerAddress, { loading: updateAddress }] = useMutation<
    updateCustomerAddress,
    updateCustomerAddressVariables
  >(UPDATE_CUSTOMER_ADDRESS, {
    onError: () => {},
  });

  const [createCustomerAddress, { loading: createAddress }] = useMutation<
    createCustomerAddress,
    createCustomerAddressVariables
  >(CREATE_CUSTOMER_ADDRESS, {
    onError: () => {},
  });

  /**
   * Update customer data and create or update address
   * @param updatedData
   */
  const handleCustomerupdate = (updatedData: Customer) => {
    if (updatedData.address) {
      // create customer address if none existing
      if (!customer?.address?.id) {
        createCustomerAddress({
          variables: {
            data: { ...updatedData?.address, customerId: customer?.id || '' },
          },
        });
        return;
      }

      // or update customer address
      updateCustomerAddress({
        variables: {
          data: {
            ...updatedData?.address,
            id: customer?.address?.id,
          },
        },
      });
    }

    // update customer
    const customerData = {
      ...updatedData,
      id: customer?.id || '',
    };

    delete customerData.address;

    updateCustomer({
      variables: { data: customerData },
    });
  };

  const [createCustomer] = useMutation<createCustomer, createCustomerVariables>(
    CREATE_CUSTOMER,
    {
      onCompleted: (data) => {
        if (!!handleCreateCustomer) {
          handleCreateCustomer(data.createCustomer);
          handleSetCustomer(data.createCustomer.id);
        }
      },
    }
  );

  const handleCustomerCreate = (data: Customer) => {
    createCustomer({
      variables: { data },
    });
  };

  return (
    <CustomerForm
      key={JSON.stringify(customer)}
      disabled={loading || updateAddress || createAddress}
      data={customer}
      onSubmit={customer ? handleCustomerupdate : handleCustomerCreate}
      onCancel={handleClearCustomer}
    />
  );
};

CustomerEdit.propTypes = {
  handleSetCustomer: PropTypes.func.isRequired,
  handleClearCustomer: PropTypes.func.isRequired,
};
CustomerEdit.defaultProps = {
  customer: undefined,
};

export default CustomerEdit;
