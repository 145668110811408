import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import Date from '../../Date';

export interface SMSNotificationProps {
  id: string;
  message: string;
  user?: {
    id: string;
    lastname: string;
    firstname: string;
  };
  createdAt: string;
}

const SMSNotification: React.FC<React.PropsWithChildren<SMSNotificationProps>> = ({
  id,
  message,
  user,
  createdAt,
}) => {
  return (
    <Box paddingBottom={2}>
      <Box
        bgcolor="primary.main"
        color="secondary.main"
        p={1}
        borderRadius={1}
      >
        <Typography>{message}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" paddingRight={1}>
        <Typography variant="caption">
          <Date
            variant="caption"
            date={createdAt}
            format="DD. MMMM YYYY HH:mm"
          />
          {` - ${user?.firstname} ${user?.lastname} `}
        </Typography>
      </Box>
    </Box>
  );
};

SMSNotification.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }),
};
SMSNotification.defaultProps = {};

export default SMSNotification;
