import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import PageContainer from '../../components/PageContainer';
import { ME } from '../../gql/user/queries';
import { me } from '../../gql/user/types/me';
import RepairProcessingProvider from '../../components/RepairProcessing';

export interface RepairPageProps {}

const RepairPage: React.FC<React.PropsWithChildren<RepairPageProps>> = () => {
  const { repairCode } = useParams<{ repairCode: string }>();

  const { data, loading } = useQuery<me>(ME);

  return (
    <PageContainer
      page="repair"
      titleParams={{ repairCode: repairCode }}
      loading={loading}
    >
      {data?.me && (
        <RepairProcessingProvider repairCode={repairCode} user={data.me} />
      )}
    </PageContainer>
  );
};

RepairPage.propTypes = {};
RepairPage.defaultProps = {};

export default RepairPage;
