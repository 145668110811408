import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import { FormattedMessage } from 'react-intl';

export interface FormRadioGroupProps {
  name: string;
  defaultValue?: string;
  children: React.ReactNode;
}

const FormRadioGroup: React.FC<React.PropsWithChildren<FormRadioGroupProps>> = ({
  name,
  children,
  defaultValue,
}) => {
  const { errors, control } = useFormContext();

  return (
    <FormControl variant="standard" error={!!errors[name]} hiddenLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        as={
          <RadioGroup data-test="form-radioGroup" row>
            {children}
          </RadioGroup>
        }
      />

      <FormHelperText>
        {errors[name] && <FormattedMessage id={errors[name].message} />}
      </FormHelperText>
    </FormControl>
  );
};

FormRadioGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};
FormRadioGroup.defaultProps = {
  defaultValue: undefined,
};

export default FormRadioGroup;
