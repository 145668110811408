import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';

import DialogBase from '..';
import Typo from '../../Typo';
import { UPDATE_PRODUCT_QUANTITY } from '../../../gql/product/mutations';
import RepairProductStatusSelect from '../../RepairProductStatusSelect';
import ButtonRow from '../../ButtonRow';
import { UPDATE_PRODUCT_ORDER } from '../../../gql/productOrder/mutations';
import {
  updateProductQuantity as updateProductQuantityI,
  updateProductQuantityVariables,
} from '../../../gql/product/types/updateProductQuantity';
import {
  updateProductOrder as updateProductOrderI,
  updateProductOrderVariables,
} from '../../../gql/productOrder/types/updateProductOrder';
import { ProductQuantityStatus } from '../../../types/graphql-global-types';

interface Product {
  id: string;
  status: ProductQuantityStatus;
  productOrderId?: string;
}

export interface UpdateProductStatusProps {
  product: Product;
  customerOrderId?: string;
  availableStati?: ProductQuantityStatus[];
  onClose: () => void;
  onCompleted: () => void;
  hideDelete?: boolean;
}

const UpdateProductStatus: React.FC<React.PropsWithChildren<UpdateProductStatusProps>> = ({
  product,
  customerOrderId,
  onClose,
  onCompleted,
  availableStati,
  hideDelete = false,
}) => {
  const [selected, setSelected] = React.useState<ProductQuantityStatus>(
    product.status
  );
  const [shouldDelete, setShouldDelete] = React.useState<boolean>(false);

  const [updateProductQuantity, { loading }] = useMutation<
    updateProductQuantityI,
    updateProductQuantityVariables
  >(UPDATE_PRODUCT_QUANTITY, {
    onCompleted: () => onCompleted(),
    onError: () => {},
  });

  const [updateProductOrder, { loading: loadingDelete }] = useMutation<
    updateProductOrderI,
    updateProductOrderVariables
  >(UPDATE_PRODUCT_ORDER, {
    onCompleted: () => onCompleted(),
    onError: () => {},
  });

  // set the value as selected
  const handleChange = (status: ProductQuantityStatus) => {
    setSelected(status);
  };

  // trigger mutation and close modal
  const handleSave = () => {
    if (shouldDelete && customerOrderId) {
      updateProductOrder({
        variables: {
          data: {
            id: product?.productOrderId || '',
            customerOrderId: parseInt(customerOrderId),
            removeProductIDs: [product.id],
          },
        },
      });
      return;
    }

    updateProductQuantity({
      variables: { data: { id: product.id, status: selected } },
    });
  };

  const checkDisablePrimary = () => {
    if (shouldDelete) {
      return false;
    }

    return product.status === selected;
  };

  return (
    <DialogBase
      title="app.dialog.product.updateStatus.title"
      open={!!product}
      onClose={onClose}
      isLoading={loading || loadingDelete}
      isForm
    >
      <Box
        p={3}
        minWidth={500}
        minHeight={200}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* status */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typo t="app.dialog.product.updateStatus.updateProduct" />
          <RepairProductStatusSelect
            status={selected}
            availableStati={availableStati}
            onChange={handleChange}
          />
        </Box>

        {/* Count */}
        {/* <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typo t="app.dialog.product.updateStatus.updateProduct" />
          <RepairProductStatusSelect
            status={selected}
            availableStati={availableStati}
            onChange={handleChange}
          />
        </Box> */}

        <Box display="flex" justifyContent="Space-between">
          {!hideDelete && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldDelete}
                  onChange={() => setShouldDelete(!shouldDelete)}
                  color="primary"
                />
              }
              label={<Typo t="app.common.delete" />}
            />
          )}
          <Box></Box>
          <ButtonRow
            isElevationDisabled
            isPrimaryDelete={shouldDelete}
            onPrimaryAction={handleSave}
            isPrimaryDisabled={checkDisablePrimary()}
            onSecondaryAction={onClose}
            primaryLabel={shouldDelete ? 'app.common.delete' : undefined}
          />
        </Box>
      </Box>
    </DialogBase>
  );
};

UpdateProductStatus.propTypes = {
  // product: PropTypes.shape({
  //   id: PropTypes.string.isRequired,
  //   status: PropTypes.string.isRequired,
  // }).isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  hideDelete: PropTypes.bool,
};
UpdateProductStatus.defaultProps = {
  hideDelete: false,
};

export default UpdateProductStatus;
