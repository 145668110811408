import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import Grid from '@mui/material/Grid';
import { GridRowParams } from '@mui/x-data-grid';

import PageContainer from '../../components/PageContainer';
import { CUSTOMER_DETAIL } from '../../gql/customer/queries';
import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER_ADDRESS,
} from '../../gql/customer/mutations/customer';
import CustomerForm from '../../components/Form/CustomerForm';
import EditBikeDialog from '../../components/Dialog/EditBike';
import PageActionButtons from '../../components/PageActionButtons';
import AddBikeDialog from '../../components/Dialog/CreateBike';
import WYSIWYGDialog from '../../components/Dialog/WYSISWYG';
import Button from '../../components/Button';
import Paper from '../../components/Paper';
import CustomerOrdersTable from '../../components/Table/Tables/CustomerOrdersTable';
import BikesList from '../../components/BikesList';
import {
  CustomerOrderStatus,
  UpdateCustomerInput,
} from '../../types/graphql-global-types';
import {
  getCustomer,
  getCustomer_getCustomer,
  getCustomer_getCustomer_bikes,
} from '../../gql/customer/types/getCustomer';
import {
  updateCustomer as updateCustomerI,
  updateCustomerVariables,
} from '../../gql/customer/mutations/types/updateCustomer';
import {
  updateCustomerAddress as updateCustomerAddressI,
  updateCustomerAddressVariables,
} from '../../gql/customer/mutations/types/updateCustomerAddress';
import {
  createCustomerAddress as createCustomerAddressI,
  createCustomerAddressVariables,
} from '../../gql/customer/mutations/types/createCustomerAddress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customerFieldPadding: {
      padding: theme.spacing(0, 1),
    },
    customerContainerPadding: {
      padding: theme.spacing(1),
    },
    customerAddressContainer: {
      marginTop: theme.spacing(3),
    },
    customerFormButtonRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
    },
    customerBikesContainer: {
      marginTop: theme.spacing(3),
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  })
);

const CustomerPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [EditBike, setEditBike] = useState<getCustomer_getCustomer_bikes>();
  const [addBike, setAddBike] = useState<string>();
  const [openNotes, setOpenNotes] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<getCustomer>(CUSTOMER_DETAIL, {
    variables: { id: id },
  });
  const customer = data?.getCustomer;

  const [updateCustomer, { loading: updateloading }] = useMutation<
    updateCustomerI,
    updateCustomerVariables
  >(UPDATE_CUSTOMER, {
    onError: () => {},
  });

  const [updateCustomerAddress] = useMutation<
    updateCustomerAddressI,
    updateCustomerAddressVariables
  >(UPDATE_CUSTOMER_ADDRESS, {
    onError: () => {},
  });

  const [createCustomerAddress, { loading: updateAddressLoading }] =
    useMutation<createCustomerAddressI, createCustomerAddressVariables>(
      CREATE_CUSTOMER_ADDRESS,
      {
        onError: () => {},
      }
    );

  /**
   * Update customer data and create or update address
   * @param updatedData
   */
  const handleCustomerupdate = (updatedData: getCustomer_getCustomer) => {
    if (updatedData.address) {
      // create customer address if none existing
      if (!customer?.address?.id) {
        createCustomerAddress({
          variables: {
            data: { ...updatedData?.address, customerId: id },
          },
        });
        return;
      }

      // or update customer address
      updateCustomerAddress({
        variables: {
          data: { ...updatedData?.address, id: customer?.address?.id },
        },
      });
    }

    // update customer
    const { address, ...createCustomerData } = updatedData;
    const customerData = { ...createCustomerData, id } as UpdateCustomerInput;

    updateCustomer({
      variables: { data: customerData },
    });
  };

  const handleAddBikeClose = () => {
    refetch();
    setAddBike(undefined);
  };

  const handleEditBikeClose = () => {
    refetch();
    setEditBike(undefined);
  };

  const handleSubmitNotes = (notes: RawDraftContentState) => {
    updateCustomer({ variables: { data: { id, notes: notes } } });
    setOpenNotes(false);
  };

  const handleRowClick = (rowData: GridRowParams) => {
    history.push(`/customerOrders/${rowData.row.id}`);
  };

  return (
    <PageContainer
      page="customer"
      titleParams={{
        firstname: customer?.firstname,
        lastname: customer?.lastname,
      }}
      loading={loading}
    >
      <WYSIWYGDialog
        open={openNotes}
        initialContent={data?.getCustomer?.notes}
        onSubmit={handleSubmitNotes}
        onCancel={() => setOpenNotes(false)}
      />
      <EditBikeDialog
        bike={EditBike}
        onClose={() => setEditBike(undefined)}
        onSubmit={handleEditBikeClose}
      />
      <AddBikeDialog
        customerId={addBike}
        onSubmit={handleAddBikeClose}
        onClose={() => setAddBike(undefined)}
      />
      <PageActionButtons hasNavigateBack>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          t="page.customer.button.notes"
          onClick={() => setOpenNotes(true)}
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          t="page.customer.button.addBike"
          onClick={() => setAddBike(id)}
        />
      </PageActionButtons>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper padding>
            <div>
              <CustomerForm
                key={JSON.stringify(customer)}
                fullWidth
                disabled={loading || updateloading || updateAddressLoading}
                data={customer}
                onSubmit={handleCustomerupdate}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <CustomerOrdersTable
            customerId={id}
            initialLimit={5}
            persistedKey="customerOrdersCustomer"
            onRowClick={handleRowClick}
            initialFilterModel={{
              [CustomerOrderStatus.OPEN]: true,
              [CustomerOrderStatus.INVOICED]: true,
              [CustomerOrderStatus.CANCELED]: true,
              [CustomerOrderStatus.COSTESTIMATION]: false,
            }}
          />
          <Paper padding className={classes.customerBikesContainer}>
            <BikesList
              isLoading={loading}
              onAdd={() => setAddBike(id)}
              bikes={customer?.bikes}
              onEdit={(bike: getCustomer_getCustomer_bikes) =>
                setEditBike(bike)
              }
            />
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.customerBikesContainer}></div>
    </PageContainer>
  );
};

CustomerPage.propTypes = {};
CustomerPage.defaultProps = {};

export default CustomerPage;
