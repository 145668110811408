import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useService } from '@xstate/react';
import { FormattedMessage } from 'react-intl';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { RawDraftContentState } from 'draft-js';

import Paper from '../../../../Paper';
import BikeSelection from './BikeSelection';
import Typo from '../../../../Typo';
import ButtonRow from '../../../../ButtonRow';
import WorkSteps from './WorkSteps';
import Overview from './Overview';
import ConfirmationDialog from '../../../../Dialog/ConfirmationDialog';
import SubOrderCompleted from '../components/SubOrderCompleted';
import WYSIWYGDialog from '../../../../Dialog/WYSISWYG';
import Button from '../../../../Button';
import { SubOrderType } from '../../../machines/subOrdersMachine/types';
import { RepairMachine } from '../../../machines/subOrders/repair/types';
import { getCustomer_getCustomer_bikes } from '../../../../../gql/customer/types/getCustomer';
import { getWorkStepDefinition_getWorkStepDefinition as WorkStepDefinition } from '../../../../../gql/workStep/types/getWorkStepDefinition';
import { CreateProduct } from '../../../machines/orderDraftMachine/types';
import {
  ProductQuantityStatus,
  SkillLevel,
} from '../../../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(2),
    },
    container: {
      marginBottom: theme.spacing(3),
      minHeight: 100,
    },
    unit: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
  })
);

export interface RepairProps {
  machine: RepairMachine;
  customerId?: string;
}

const Repair: React.FC<React.PropsWithChildren<RepairProps>> = ({
  machine,
  customerId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);

  const [state, transition, service] = useService(machine);
  const isCompleted = state.matches('completed');

  const { data, isValidating, completed } = state.context;

  const {
    workSteps,
    // products,
    skillLevel,
    estimatedPickupDate,
    bike,
    workloadSum,
    price,
    workloadRate,
    notes,
  } = data;

  const handleRemove = () => {
    transition('REMOVE_REPAIR', { id: machine.id });
  };

  const handleAddNotes = (notes: RawDraftContentState) => {
    transition('UPDATE_NOTES', { notes: notes });
    setOpenNotes(false);
  };

  const handleUpdateBike = (bike: getCustomer_getCustomer_bikes | null) => {
    transition('UPDATE_BIKE', { data: bike });
  };

  const handleAddWorkStep = (data: WorkStepDefinition) => {
    transition('ADD_WORKSTEP', { data });
  };

  const handleAddWorkStepProduct = (
    workStepId: string,
    data: CreateProduct
  ) => {
    transition('ADD_WORKSTEP_PRODUCT', {
      workStepId: workStepId,
      data,
    });
  };

  const handleRemoveWorkStepProduct = (
    workStepId: string,
    productId: string
  ) => {
    transition('REMOVE_WORKSTEP_PRODUCT', {
      workStepId,
      productId,
    });
  };

  const handleUpdateWorkStepProductStatus = (
    workStepId: string,
    productId: string,
    status: ProductQuantityStatus
  ) => {
    transition('UPDATE_WORKSTEP_PRODUCT_STATUS', {
      workStepId,
      productId,
      status,
    });
  };

  const handleUpdateWorkStepWorkLoad = (id: string, workload: number) => {
    transition('UPDATE_WORKLOAD', { id, workload });
  };

  const handleRemoveWorkStep = (id: string) => {
    transition('REMOVE_WORKSTEP', { id });
  };

  const handleUpdateWorkStepProductCount = (
    workStepId: string,
    productId: string,
    quantity: number
  ) => {
    transition('UPDATE_WORKSTEP_PRODUCT_COUNT', {
      workStepId,
      productId,
      quantity,
    });
  };

  const handleUpdateWorkStepNotes = (
    workStepId: string,
    notes: RawDraftContentState
  ) => {
    transition('UPDATE_WORKSTEP_NOTES', { workStepId, notes });
  };

  const handleUpdateRepairSkillLevel = (level: number) => {
    if (level === 1) {
      transition('SET_SKILLLEVEL', { skillLevel: SkillLevel.LEVEL1 });
    }
    if (level === 2) {
      transition('SET_SKILLLEVEL', { skillLevel: SkillLevel.LEVEL2 });
    }
    if (level === 3) {
      transition('SET_SKILLLEVEL', { skillLevel: SkillLevel.LEVEL3 });
    }
  };

  const handleUpdateExpectedPickupDate = (estimatedPickupDate: string) => {
    transition('SET_EXPECTED_PICKUP_DATE', { estimatedPickupDate });
  };

  // if customerID is reset transition to edit state
  React.useEffect(() => {
    if (!customerId) {
      if (!!bike) {
        transition('UPDATE_BIKE', { bike: null });
      }
      if (completed) {
        transition('UNCOMPLETE_REPAIR', { id: machine.id });
      }
    }
  }, [bike, completed, customerId, machine.id, transition]);

  React.useEffect(() => {
    service.onTransition((state) =>
      console.log('Repair TRANSITION: ' + state?.event?.type, state)
    );
  }, [service]);

  if (isCompleted)
    return (
      <>
        <ConfirmationDialog
          open={open}
          onConfirmed={handleRemove}
          onDeclined={() => setOpen(false)}
          message="app.customerOrderDraft.subOrders.repair.remove.confirmation"
        />
        <SubOrderCompleted
          subOrder={SubOrderType.REPAIR}
          infoText={`${bike?.brand ?? ''} ${bike?.model ?? ''}`}
          price={price}
          editRepair={() => transition('UNCOMPLETE_REPAIR', { id: machine.id })}
          removeRepair={() => setOpen(true)}
        />
      </>
    );

  return (
    <div data-test="repairData">
      <ConfirmationDialog
        open={open}
        onConfirmed={handleRemove}
        onDeclined={() => setOpen(false)}
        message="app.customerOrderDraft.subOrders.repair.remove.confirmation"
      />
      <WYSIWYGDialog
        open={openNotes}
        onSubmit={handleAddNotes}
        onCancel={() => setOpenNotes(false)}
        initialContent={notes ?? undefined}
      />
      <Paper padding data-test="repairData">
        <Box display="flex" flex={1} justifyContent="space-between" mb={2}>
          <Typo
            variant="h5"
            t="app.customerOrderDraft.subOrders.repair"
            gutterBottom
          />
          <Button
            data-test="repairButtonNotes"
            variant="outlined"
            color="primary"
            t="app.customerOrderDraft.subOrders.repair.notes"
            onClick={() => setOpenNotes(true)}
          />
        </Box>

        {/* ADD and EDIT customer bike */}
        <BikeSelection
          customerId={customerId}
          bikeId={bike?.id}
          handleUpdateBike={handleUpdateBike}
        />

        {/* WorkSteps selection */}
        <div className={classes.container}>
          <div className={classes.unit} data-test="workStepSelection">
            <Typo variant="h6" t="model.repair.workSteps" />
            <Divider className={classes.divider} />
            <WorkSteps
              // products={products}
              selected={workSteps}
              workloadRate={workloadRate}
              isValidating={isValidating}
              handleAddWorkStep={handleAddWorkStep}
              handleRemoveWorkStep={handleRemoveWorkStep}
              handleUpdateWorkStepWorkLoad={handleUpdateWorkStepWorkLoad}
              handleUpdateWorkStepNotes={handleUpdateWorkStepNotes}
              handleAddWorkStepProduct={handleAddWorkStepProduct}
              handleRemoveWorkStepProduct={handleRemoveWorkStepProduct}
              handleUpdateWorkStepProductStatus={
                handleUpdateWorkStepProductStatus
              }
              handleUpdateWorkStepProductCount={
                handleUpdateWorkStepProductCount
              }
            />
          </div>
        </div>

        {/* Product selection */}
        {/* <div className={classes.container}>
          <div className={classes.unit}>
            <Typo variant="h6" t="model.repair.products" />
            <Divider className={classes.divider} />

            <Products
              selected={products}
              transition={transition}
              isValidating={isValidating}
            />
          </div>
        </div> */}

        {/* Repair Overview */}
        <div className={classes.container} data-test="repairOverview">
          <Typo variant="h6" t="app.customerOrderDraft.overview.title" />
          <Divider className={classes.divider} />
          <Overview
            price={price || 0}
            workLoadTotal={workloadSum || 0}
            repairSkillLevel={skillLevel}
            estimatedPickupDate={estimatedPickupDate}
            handleUpdateRepairSkillLevel={handleUpdateRepairSkillLevel}
            handleUpdateExpectedPickupDate={handleUpdateExpectedPickupDate}
          />
        </div>

        {/* Save & Cancel Buttons */}
        <div data-test="repairButtonRow">
          <Box
            data-test="validationMessages"
            marginTop={6}
            marginBottom={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            color="error.main"
          >
            {!bike && (
              <code>
                <FormattedMessage id="app.customerOrderDraft.subOrders.repair.validation.noBike" />
              </code>
            )}
            {!workSteps.length && (
              <code>
                <FormattedMessage id="app.customerOrderDraft.subOrders.repair.validation.noWorkStep" />
              </code>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {/* <Button
              data-test="repairButtonNotes"
              variant="outlined"
              color="primary"
              t="app.customerOrderDraft.subOrders.repair.notes"
              onClick={() => setOpenNotes(true)}
            /> */}
            <ButtonRow
              isSecondaryDelete
              isElevationDisabled
              primaryLabel="app.customerOrderDraft.subOrders.repair.save"
              secondaryLabel="app.customerOrderDraft.subOrders.repair.remove"
              onPrimaryAction={() =>
                transition('COMPLETE_REPAIR', { id: machine.id })
              }
              isPrimaryDisabled={!bike || !workSteps.length}
              onSecondaryAction={() => setOpen(true)}
            />
          </Box>
        </div>
      </Paper>
    </div>
  );
};

Repair.propTypes = {
  customerId: PropTypes.string,
};
Repair.defaultProps = {
  customerId: undefined,
};

export default React.memo(Repair);
