import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { FormattedMessage } from 'react-intl';

import ProductRow from './ProductRow';
import Repair from './Repair';
import TableCell from './StyledTableCell';
import { formattedCurrency } from '../../lib/helper/numbers';
import { RepairStatus } from '../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      border: '1px solid',
      borderColor: theme.palette.divider,
    },
    tableHead: {
      backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    subTotals: {
      border: `1px solid ${theme.palette.divider}`,
    },
    total: {
      backgroundColor: theme.palette.action.hover,
    },
  })
);

export interface RepairI {
  id: string;
  repairCode: string;
  workSteps: WorkStepI[];
  workloadRate: number;
  products?: ProductI[];
  status?: RepairStatus;
}

export interface ProductOrderI {
  id: string;
  products: ProductI[];
  price: number;
}

export interface WorkStepI {
  id: string;
  workStepDefinition: {
    name: string;
  };
  setWorkload: number;
  requiredProducts?: ProductI[];
}

export interface ProductI {
  id: string;
  product: {
    id: string;
    name: string;
    price: number;
  };
  quantity: number;
}

export interface CostsTableProps {
  subTotal: number | null;
  taxAmount: number | null;
  tax: number;
  total: number | null;
  repairs?: RepairI[];
  productOrders?: ProductOrderI[];
  filterNonBillable?: boolean;
  showRepairCodeOnWorkStep?: boolean;
}

const CostsTable: React.FC<React.PropsWithChildren<CostsTableProps>> = ({
  repairs,
  productOrders,
  subTotal,
  taxAmount,
  tax,
  total,
  filterNonBillable = false,
  showRepairCodeOnWorkStep = false,
}) => {
  const classes = useStyles();

  let displayedRepairs = repairs;

  if (filterNonBillable) {
    displayedRepairs = repairs?.filter(
      (repair: RepairI) => repair.status === RepairStatus.WORK_DONE
    );
  }

  return (
    (<TableContainer>
      <Table className={classes.table} size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>
              <FormattedMessage id="app.customerOrderDraft.costEstimation.table.header.name" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="app.customerOrderDraft.costEstimation.table.header.quantity" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="app.customerOrderDraft.costEstimation.table.header.unit" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="app.customerOrderDraft.costEstimation.table.header.price" />
            </TableCell>
            <TableCell align="right">
              <FormattedMessage id="app.customerOrderDraft.costEstimation.table.header.total" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRepairs?.map((repair: RepairI) => (
            /* Repair Worksteps & Required Products */
            (<Repair
              key={repair.repairCode}
              workloadRate={repair.workloadRate}
              workSteps={repair.workSteps}
              products={repair.products}
              showRepairCodeOnWorkStep={
                showRepairCodeOnWorkStep ? repair.repairCode : ''
              }
            />)
          ))}
          {/* ProductOrders */}
          {productOrders?.map((productOrder: ProductOrderI) =>
            productOrder?.products.map((product: ProductI) => (
              <ProductRow
                key={product.id}
                product={product.product}
                quantity={product.quantity}
              />
            ))
          )}
          <TableRow className={classes.subTotals}>
            <TableCell colSpan={4}>
              <div>
                <FormattedMessage id="app.customerOrderDraft.costEstimation.overview.amount" />
              </div>
              <div>
                <FormattedMessage
                  id="app.customerOrderDraft.costEstimation.overview.tax"
                  values={{ tax }}
                />
              </div>
            </TableCell>

            <TableCell align="right">
              <div>{formattedCurrency(subTotal)}</div>
              <div>{formattedCurrency(taxAmount)}</div>
            </TableCell>
          </TableRow>

          <TableRow className={classes.total}>
            <TableCell colSpan={4}>
              <FormattedMessage id="app.customerOrderDraft.costEstimation.overview.total" />
            </TableCell>
            <TableCell align="right">{formattedCurrency(total)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>)
  );
};

CostsTable.propTypes = {
  repairs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      repairCode: PropTypes.string.isRequired,
      workloadRate: PropTypes.number.isRequired,
      workSteps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          workStepDefinition: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }).isRequired,
          setWorkload: PropTypes.number.isRequired,
          requiredProducts: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              product: PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
              }).isRequired,
              quantity: PropTypes.number.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  productOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
          }).isRequired,
          quantity: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ),
  subTotal: PropTypes.number.isRequired,
  taxAmount: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  filterNonBillable: PropTypes.bool,
  showRepairCodeOnWorkStep: PropTypes.bool,
};
CostsTable.defaultProps = {
  filterNonBillable: false,
  showRepairCodeOnWorkStep: false,
};

export default CostsTable;
