import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_BIKE = gql`
  mutation createCustomerBike($data: CreateBikeForExistingCustomerInput!) {
    createCustomerBike(data: $data) {
      bikes {
        id
        createdAt
        updatedAt
        type
        brand
        model
        color
        frameNumber
        notes
      }
    }
  }
`;

export const UPDATE_CUSTOMER_BIKE = gql`
  mutation updateCustomerBike($data: UpdateBikeInput!) {
    updateCustomerBike(data: $data) {
      bikes {
        id
        createdAt
        updatedAt
        type
        brand
        model
        color
        frameNumber
        notes
      }
    }
  }
`;
