import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PageContainer from '../../components/PageContainer';
import { GET_CUSTOMER_ORDER } from '../../gql/customerOrder/queries';
import PageActionButtons from '../../components/PageActionButtons';
import CustomerOrderOverview from './CustomerOderOverview';
import {
  CustomerOrderStatus,
  RepairStatus,
} from '../../types/graphql-global-types';
import { ProductQuantityStatus } from '../../types/graphql-global-types';
import UpdateProductStatus from '../../components/Dialog/UpdateProductStatus';
import CostEstimation from '../../components/CostEstimation';
import PrintCustomerOrder from '../../components/Dialog/PrintCustomerOrderDialog';
import { UPDATE_CUSTOMER_ORDER_COST_ESTIMATION } from '../../gql/customerOrder/mutations';
import CustomerOrderButtons from './CustomerOrderButtons';
import SMSNotificationDialog from '../../components/Dialog/SMSNotificationDialog';
import { HISTORY_CHANGETYPE_SMS } from '../../lib/constants';
import CheckoutPreviewDialog from '../../components/Dialog/CheckoutPreview';
import {
  getCustomerOrder,
  getCustomerOrderVariables,
  getCustomerOrder_getCustomerOrder_history,
  getCustomerOrder_getCustomerOrder_productOrders_products,
  getCustomerOrder_getCustomerOrder_repairs,
} from '../../gql/customerOrder/types/getCustomerOrder';
import {
  updateCustomerOrderCostEstimation,
  updateCustomerOrderCostEstimationVariables,
} from '../../gql/customerOrder/types/updateCustomerOrderCostEstimation';
import RepairsTable from './RepairsTable';
import ProductOrdersTable from './ProductOrdersTable';
import PauseOrCancelDialog from '../../components/Dialog/PauseOrCancelDialog';
import InfoBanner from '../../components/InfoBanner';
import { getLocation } from '../../gql/location/types/getLocation';
import { GET_LOCATION } from '../../gql/location/queries';

interface Product {
  id: string;
  status: ProductQuantityStatus;
}

const CustomerOrderPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<{ id: string }>();
  const [productQuantities, setProductQuantities] = React.useState<
    getCustomerOrder_getCustomerOrder_productOrders_products[]
  >([]);
  const [product, setProduct] = React.useState<Product>();
  const [checkoutPreview, setCheckoutPreview] = React.useState<string | null>();
  const [openCancel, setOpenCancel] =
    React.useState<CustomerOrderStatus.CANCELED>();
  const [unDoneRepairs, setUnDoneRepairs] = React.useState<
    getCustomerOrder_getCustomerOrder_repairs[] | null
  >();

  const [openPrintDialog, setOpenPrintDialog] = React.useState<boolean>(false);
  const [openSMSDialog, setOpenSMSDialog] = React.useState<boolean>(false);
  const costEstimateRef = React.useRef(null);

  const { data, loading, refetch } = useQuery<
    getCustomerOrder,
    getCustomerOrderVariables
  >(GET_CUSTOMER_ORDER, {
    variables: { id: parseInt(id) },
    fetchPolicy: 'no-cache',
  });

  const [updateCustomerOrder, { loading: loadingCostEstimation }] = useMutation<
    updateCustomerOrderCostEstimation,
    updateCustomerOrderCostEstimationVariables
  >(UPDATE_CUSTOMER_ORDER_COST_ESTIMATION, {
    onCompleted: () => refetch(),
    onError: () => {},
  });

  const { data: l } = useQuery<getLocation>(GET_LOCATION);

  const location = l?.getLocation;

  const customerOrder = data?.getCustomerOrder;
  const productOrders = data?.getCustomerOrder.productOrders;

  const handleProductOrderClick = (params: GridRowParams) => {
    if (
      customerOrder?.status === CustomerOrderStatus.CANCELED ||
      customerOrder?.status === CustomerOrderStatus.INVOICED
    )
      return;

    setProduct(params.row as Product);
  };

  const handleCloseProduct = () => {
    setProduct(undefined);
    refetch();
  };

  const handleCloseCheckoutPreview = () => {
    setCheckoutPreview(null);
    refetch();
  };

  const handleCheckout = () => {
    setCheckoutPreview(id);
  };

  const handleAcceptCostEstimation = () => {
    updateCustomerOrder({
      variables: {
        data: {
          id: parseInt(id),
          status: CustomerOrderStatus.OPEN,
        },
      },
    });
  };

  const handleCancelOrder = (cancelationReason: string) => {
    updateCustomerOrder({
      variables: {
        data: {
          id: parseInt(id),
          status: CustomerOrderStatus.CANCELED,
          cancelationReason,
        },
      },
    });
    setOpenCancel(undefined);
  };

  const handleUpdateInvoiceInfo = (invoiceInfo: string | null) => {
    updateCustomerOrder({
      variables: {
        data: {
          id: parseInt(id),
          invoiceInfo,
        },
      },
    });
  };

  React.useEffect(() => {
    if (productOrders) {
      let allProducts = productOrders.map((productOrder) =>
        productOrder?.products?.map((product) => ({
          ...product,
          productOrderId: productOrder.id,
        }))
      );

      if (allProducts.length) {
        setProductQuantities(
          allProducts.reduce(
            (
              acc: getCustomerOrder_getCustomerOrder_productOrders_products[],
              curr: any
            ) => curr ?? acc.concat(curr),
            []
          )
        );
      }
    }
  }, [productOrders]);

  React.useEffect(() => {
    if (customerOrder && Array.isArray(customerOrder.repairs)) {
      setUnDoneRepairs(
        customerOrder.repairs?.filter(
          (repair) =>
            repair.status !== RepairStatus.WORK_DONE &&
            repair.status !== RepairStatus.COMPLETED
          // && repair.status !== RepairStatus.CANCELED
        )
      );
    }
  }, [customerOrder]);

  return (
    <PageContainer
      page="customerOrder"
      titleParams={{ customerOrderId: id }}
      loading={loading || loadingCostEstimation}
    >
      {data ? (
        <>
          {customerOrder && (
            <>
              {checkoutPreview && (
                <CheckoutPreviewDialog
                  customerOrderId={checkoutPreview}
                  onSubmit={handleCloseCheckoutPreview}
                  onCancel={handleCloseCheckoutPreview}
                  unDoneRepairs={unDoneRepairs ?? undefined}
                  customer={customerOrder.customer}
                />
              )}
              <PrintCustomerOrder
                open={openPrintDialog}
                onClose={() => setOpenPrintDialog(false)}
                printData={customerOrder?.repairs}
                costEstimateRef={costEstimateRef}
              />
              {customerOrder?.customer?.phone && (
                <SMSNotificationDialog
                  open={openSMSDialog}
                  onClose={() => setOpenSMSDialog(false)}
                  onSubmit={() => refetch()}
                  phoneNumber={customerOrder?.customer?.phone}
                  customerOrderId={customerOrder?.id?.toString()}
                  history={customerOrder?.history.filter(
                    (
                      historyObject: getCustomerOrder_getCustomerOrder_history
                    ) => historyObject.changeType === HISTORY_CHANGETYPE_SMS
                  )}
                />
              )}
              {/* Needs to be mounted to be printable */}
              <div style={{ display: 'none' }}>
                <CostEstimation
                  printRef={costEstimateRef}
                  customerOrder={customerOrder}
                  location={location}
                />
              </div>
            </>
          )}
          <PauseOrCancelDialog
            type={openCancel}
            title="app.dialog.pauseOrCancelDialog.cancelCustomerOrder.title"
            message="app.dialog.pauseOrCancelDialog.cancelCustomerOrder.message"
            onSuccess={handleCancelOrder}
            onClose={() => setOpenCancel(undefined)}
          />

          {!!product && (
            <UpdateProductStatus
              product={product}
              availableStati={[
                ProductQuantityStatus.OPEN,
                ProductQuantityStatus.AVAILABLE,
                ProductQuantityStatus.ORDERED,
                // ProductQuantityStatus.CANCELED,
              ]}
              customerOrderId={id}
              onClose={() => setProduct(undefined)}
              onCompleted={handleCloseProduct}
            />
          )}

          <PageActionButtons hasNavigateBack>
            <CustomerOrderButtons
              status={customerOrder?.status}
              invoiceId={customerOrder?.invoiceId ?? undefined}
              onHandleCheckout={handleCheckout}
              onHandleAcceptCostEstimation={handleAcceptCostEstimation}
              onOpenConfirmCancel={() =>
                setOpenCancel(CustomerOrderStatus.CANCELED)
              }
              onOpenPrintDialog={() => setOpenPrintDialog(true)}
              onOpenSMSDialog={() => setOpenSMSDialog(true)}
              disableSMS={!customerOrder?.customer?.phone}
            />
          </PageActionButtons>

          <InfoBanner
            isVisible={customerOrder?.status === CustomerOrderStatus.CANCELED}
            isError
            title="page.customerOrder.infoBanner.title"
            message={customerOrder?.cancelationReason}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              {customerOrder ? (
                <CustomerOrderOverview
                  id={customerOrder.id}
                  createdAt={customerOrder.createdAt}
                  updatedAt={customerOrder.updatedAt}
                  status={customerOrder.status}
                  acceptedDate={customerOrder.acceptedDate}
                  createdUser={customerOrder.createdUser}
                  total={customerOrder.total}
                  subtotal={customerOrder.subtotal}
                  taxAmount={customerOrder.taxAmount}
                  customer={customerOrder.customer}
                  invoiceId={customerOrder.invoiceId}
                  invoiceDate={customerOrder.invoiceDate}
                  paymentMethod={customerOrder.paymentMethod}
                  invoiceNumber={customerOrder.invoiceNumber}
                  invoicedLocationId={customerOrder.invoicedLocationId}
                  loading={loading}
                  invoiceInfo={customerOrder.invoiceInfo}
                  handleUpdateInvoiceInfo={handleUpdateInvoiceInfo}
                />
              ) : null}
            </Grid>

            <Grid item xs={12} md={7}>
              <Box marginBottom={2}>
                <RepairsTable
                  repairs={customerOrder?.repairs}
                  loading={loading}
                />
              </Box>
              <ProductOrdersTable
                productOrders={productQuantities}
                loading={loading}
                onProductOrderClick={handleProductOrderClick}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div />
      )}
    </PageContainer>
  );
};

CustomerOrderPage.propTypes = {};
CustomerOrderPage.defaultProps = {};

export default CustomerOrderPage;
