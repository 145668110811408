import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Radio from '@mui/material/Radio';

import { SelectedItem, SelectionType } from '../../..';
import useCommonStyles from '../Items.styles';
import { getProductCategory_getProductCategory_products } from '../../../../../gql/product/types/getProductCategory';
import { formattedCurrency } from '../../../../../lib/helper/numbers';

export interface ProductProps {
  type: SelectionType.PRODUCT;
  product: getProductCategory_getProductCategory_products;
  onSelect: (item: SelectedItem) => void;
  selected?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
}

const Product: React.FC<React.PropsWithChildren<ProductProps>> = ({
  type,
  product,
  onSelect,
  selected = false,
  disabled = false,
  multiSelect = false,
}) => {
  const classes = useCommonStyles();

  return (
    <ButtonBase
      className={classes.container}
      onClick={() => onSelect({ id: product.id, name: product.name, type })}
      disabled={disabled}
    >
      <Box
        className={classes.top}
        // bgcolor={workStep.color ?? 'background.default'}
        bgcolor="background.default"
      >
        <Box className={classes.header}>
          <Typography className={classes.subTitle}>
            <b>{product.manufacturer}</b>
          </Typography>
          <Typography className={classes.price}>
            <b>{formattedCurrency(product.price)}</b>
          </Typography>
        </Box>
        <Typography className={classes.title}>
          <b>{product.name}</b>
        </Typography>
      </Box>
      {/* <Box className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          {product.description}
        </Typography>
      </Box>
      {multiSelect ? (
        <Box mt={-1} display="flex" width="100%" justifyContent="center">
          <Radio checked={selected} />
        </Box>
      ) : null} */}
    </ButtonBase>
  );
};

Product.propTypes = {
  onSelect: PropTypes.func.isRequired,
  // type: PropTypes.oneOf<SelectionType.PRODUCT>([SelectionType.PRODUCT])
  //   .isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool,
};
Product.defaultProps = {};

export default Product;
