import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { ConfigType } from 'dayjs';
import Typography from '@mui/material/Typography';

import NumberUpdateField from '../../../../../NumberUpdateField';
import {
  formattedCurrency,
  getNumberFromString,
} from '../../../../../../lib/helper/numbers';
import Typo from '../../../../../Typo';
import { SkillLevel } from '../../../../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: theme.spacing(3),
    },
    input: {
      marginLeft: theme.spacing(1),
      width: 140,
    },
    date: {
      marginLeft: theme.spacing(1),
      width: 200,
    },
  })
);

export interface OverviewProps {
  repairSkillLevel: SkillLevel;
  price: number;
  workLoadTotal: number;
  estimatedPickupDate?: string;
  handleUpdateRepairSkillLevel: (level: number) => void;
  handleUpdateExpectedPickupDate: (date: string) => void;
}

const Overview: React.FC<React.PropsWithChildren<OverviewProps>> = ({
  price,
  workLoadTotal,
  repairSkillLevel,
  estimatedPickupDate = undefined,
  handleUpdateRepairSkillLevel,
  handleUpdateExpectedPickupDate,
}) => {
  const classes = useStyles();

  const handleSkillLevelIncrease = () => {
    const current = getNumberFromString(repairSkillLevel);

    if (current > 2) return;
    handleUpdateRepairSkillLevel(current + 1);
  };

  const handleSkillLevelDecrease = () => {
    const current = getNumberFromString(repairSkillLevel);

    if (current === 1) return;
    handleUpdateRepairSkillLevel(current - 1);
  };

  const handlePickUpDateUpdate = (date: unknown) => {
    const estimatedPickupDate = dayjs(date as ConfigType)
      .startOf('day')
      .format();

    handleUpdateExpectedPickupDate(estimatedPickupDate);
  };

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div className={classes.contentContainer}>
          <div className={classes.input}>
            <NumberUpdateField
              label={'model.common.skillLevel'}
              value={getNumberFromString(repairSkillLevel)}
              handleValueDecrease={handleSkillLevelDecrease}
              handleValueIncrease={handleSkillLevelIncrease}
              maxValue={3}
            />
          </div>
        </div>

        <div className={classes.contentContainer}>
          <TextField
            data-test="repairWorkLoad"
            disabled
            className={classes.input}
            variant="outlined"
            value={workLoadTotal}
            label={<Typo t="model.repair.workLoad" />}
          />
        </div>

        <div className={classes.contentContainer}>
          <DatePicker
            data-test="MuiDatePicker"
            label={<Typo t="model.repair.estimatedPickupDate" />}
            value={estimatedPickupDate}
            onChange={(date) => handlePickUpDateUpdate(date)}
            // UPDATE FIX
            // minDate={new Date()}
            // format="DD. MMMM YYYY"
            // inputVariant="outlined"
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>

      <Typography variant="h5" color="primary" data-test="repairTotal">
        {formattedCurrency(price)}
      </Typography>
    </div>
  );
};

Overview.propTypes = {
  price: PropTypes.number.isRequired,
  workLoadTotal: PropTypes.number.isRequired,
  estimatedPickupDate: PropTypes.string,
  handleUpdateRepairSkillLevel: PropTypes.func.isRequired,
  handleUpdateExpectedPickupDate: PropTypes.func.isRequired,
};
Overview.defaultProps = {
  estimatedPickupDate: undefined,
};

export default React.memo(Overview);
