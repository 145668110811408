import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import useMediaQuery from '@mui/material/useMediaQuery';
import cslx from 'clsx';

import {
  GET_PRODUCT_CATEGORY,
  GET_REPAIR_PRODUCTS_ROOT_CATEGORY,
} from '../../gql/product/queries';
import CategoriesTreeView from '../CategoriesTreeView';
import CategoryDetail from './CategoryDetail';
import CreateProductCategoryDialog from '../Dialog/CreateProductCategory';
import { UPDATE_PRODUCT_CATEGORY } from '../../gql/product/mutations';
import CreateProductDialog from '../Dialog/CreateProduct';
import EditProductDialog from '../Dialog/EditProduct';
import {
  getProductCategory,
  getProductCategoryVariables,
} from '../../gql/product/types/getProductCategory';
import {
  handleCategoryUpdate,
  Category,
  Product,
} from '../../lib/helper/treeViewHelper';
import Paper from '../Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullHeight: {
      height: '100%',
      overflow: 'hidden',
    },
    rotate: {
      transform: 'rotate(90deg)',
    },
  })
);

export interface ProductsEditProps {}

const ProductsEdit: React.FC<React.PropsWithChildren<ProductsEditProps>> = () => {
  const classes = useStyles();
  const [treeData, setTreeData] = React.useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<Category>();
  const [openAddCategory, setOpenAddCategory] = React.useState<boolean>(false);
  const [openAddProduct, setOpenAddProduct] = React.useState<boolean>(false);
  const [editProduct, setEditProduct] = React.useState<Product>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  /**
   * Query rootCategories. Since we use only one -> take only the first one.
   */
  const { loading } = useQuery(GET_REPAIR_PRODUCTS_ROOT_CATEGORY, {
    onCompleted: (data) => {
      //set initial category
      setSelectedCategory(data?.getProductCategories[0]);
      // set initial treeData
      setTreeData(data?.getProductCategories[0].subCategories);
    },
    onError: () => {},
  });

  /**
   * Query Detail Information for a specific category
   */
  const [getProductCategoryDetail, { loading: detailLoading }] = useLazyQuery<
    getProductCategory,
    getProductCategoryVariables
  >(GET_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      handleCategoryUpdate(
        data.getProductCategory as Category,
        setTreeData,
        setSelectedCategory
      );
    },
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  /**
   * Mutate category
   */
  const [updateProductCategory, { loading: updateProductCategoryLoading }] =
    useMutation(UPDATE_PRODUCT_CATEGORY, {
      onCompleted: (data) => {
        handleCategoryUpdate(
          data.updateProductCategory,
          setTreeData,
          setSelectedCategory
        );
      },
      onError: () => {},
    });

  /**
   * Called after a Category was successfully added.
   * Refetches parentCategory and closes dialog
   */
  const handleCategoryAdded = () => {
    getProductCategoryDetail({ variables: { id: selectedCategory?.id ?? '' } });
    setOpenAddCategory(false);
  };

  /**
   * Called after a Product was successfully added.
   * Refetches parentCategory and closes dialog
   */
  const handleProductAdded = () => {
    getProductCategoryDetail({ variables: { id: selectedCategory?.id ?? '' } });
    setOpenAddProduct(false);
  };

  /**
   * Called after a Product was successfully edited.
   * Refetches parentCategory and closes dialog
   */
  const handleProductEdited = () => {
    getProductCategoryDetail({ variables: { id: selectedCategory?.id ?? '' } });
    setEditProduct(undefined);
  };

  /**
   * Called whan details of a category got updated. Triggers mutation.
   * @param data Category data
   */
  const onCategoryUpdate = (data: Category) => {
    updateProductCategory({ variables: { data } });
  };

  /**
   * Called to open AddCategoryDialog. wrapper of setState, passed down to child component
   */
  const handleAddCategory = () => {
    setOpenAddCategory(true);
  };

  /**
   * Called to open AddCategoryDialog. wrapper of setState, passed down to child component
   */
  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };

  const handleEditProduct = (product: Product) => {
    setEditProduct(product);
  };

  return (
    <>
      <CreateProductCategoryDialog
        parentCategory={openAddCategory ? selectedCategory?.id || null : null}
        onCreated={handleCategoryAdded}
        onClose={() => setOpenAddCategory(false)}
      />
      <CreateProductDialog
        parentCategory={openAddProduct ? selectedCategory?.id || null : null}
        onCreated={handleProductAdded}
        onClose={() => setOpenAddProduct(false)}
      />
      <EditProductDialog
        product={editProduct}
        onEdited={handleProductEdited}
        onClose={() => setEditProduct(undefined)}
      />
      <Grid container spacing={3} className={classes.fullHeight}>
        <Grid item xs={12} md={4}>
          <Paper padding fullHeight>
            <CategoriesTreeView
              title="page.worksteps.categories"
              isLoading={
                loading || detailLoading || updateProductCategoryLoading
              }
              treeData={treeData}
              onCategorySelect={(id: string) =>
                getProductCategoryDetail({ variables: { id } })
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <ArrowForwardIosIcon
              color="primary"
              fontSize="large"
              className={cslx({
                [classes.rotate]: !matches,
              })}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box height="100%">
            <CategoryDetail
              isLoading={
                loading || detailLoading || updateProductCategoryLoading
              }
              selectedCategory={selectedCategory}
              onCategoryUpdate={onCategoryUpdate}
              handleAddCategory={handleAddCategory}
              handleAddProduct={handleAddProduct}
              handleEditProduct={handleEditProduct}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ProductsEdit.propTypes = {};
ProductsEdit.defaultProps = {};

export default ProductsEdit;
