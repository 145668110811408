import { assign } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import {
  CreateProduct,
  CreateWorkStep,
} from '../../../orderDraftMachine/types';
import { RepairContext, RepairEvent } from '../types';

export const addWorkStep = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'ADD_WORKSTEP');

    return {
      ...ctx.data,
      workSteps: [
        ...ctx.data.workSteps,
        {
          ...e.data,
          setWorkload: e.data.expectedWorkload,
          requiredProducts: [],
        },
      ],
    };
  },
});

export const removeWorkStep = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'REMOVE_WORKSTEP');

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.filter(
        (workstep: CreateWorkStep) => workstep.id !== e.id
      ),
    };
  },
});

export const addWorkStepProduct = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'ADD_WORKSTEP_PRODUCT');

    const workStep = ctx.data.workSteps.find(
      (w: CreateWorkStep) => w.id === e.workStepId
    );

    if (!workStep) return ctx.data;

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((w: CreateWorkStep) => {
        return w.id === e.workStepId
          ? {
              ...w,
              requiredProducts: [...workStep.requiredProducts, { ...e.data }],
            }
          : w;
      }),
    };
  },
});

export const removeWorkStepProduct = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'REMOVE_WORKSTEP_PRODUCT');

    const workStep = ctx.data.workSteps.find(
      (w: CreateWorkStep) => w.id === e.workStepId
    );

    if (!workStep) return ctx.data;

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((w: CreateWorkStep) => {
        return w.id === e.workStepId
          ? {
              ...w,
              requiredProducts: workStep.requiredProducts.filter(
                (product: CreateProduct) => product.id !== e.productId
              ),
            }
          : w;
      }),
    };
  },
});

export const updateWorkStepProductCount = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_WORKSTEP_PRODUCT_COUNT');

    const workStep = ctx.data.workSteps.find(
      (w: CreateWorkStep) => w.id === e.workStepId
    );

    if (!workStep) return ctx.data;

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((w: CreateWorkStep) => {
        return w.id === e.workStepId
          ? {
              ...w,
              requiredProducts: workStep.requiredProducts.map(
                (product: CreateProduct) =>
                  product.id === e.productId
                    ? { ...product, quantity: e.quantity }
                    : product
              ),
            }
          : w;
      }),
    };
  },
});

export const updateWorkStepProductStatus = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_WORKSTEP_PRODUCT_STATUS');

    const workStep = ctx.data.workSteps.find(
      (w: CreateWorkStep) => w.id === e.workStepId
    );

    if (!workStep) return ctx.data;

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((w: CreateWorkStep) => {
        return w.id === e.workStepId
          ? {
              ...w,
              requiredProducts: workStep.requiredProducts.map(
                (product: CreateProduct) =>
                  product.id === e.productId
                    ? { ...product, status: e.status }
                    : product
              ),
            }
          : w;
      }),
    };
  },
});

export const updateWorkStepNotes = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_WORKSTEP_NOTES');

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((w: CreateWorkStep) => {
        return w.id === e.workStepId
          ? {
              ...w,
              notes: e.notes,
            }
          : w;
      }),
    };
  },
});
