import React from 'react';
import PropTypes from 'prop-types';
import TableBase from '../../TableBase';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_ORDERS } from '../../../../gql/customerOrder/queries';
import {
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

import DateRow from '../../Rows/DateRow';
import CustomerRow, { Customer } from '../../Rows/CustomerRow';
import NameRow, { NameRowName } from '../../Rows/NameRow';
import RepairListRow, { Repair } from '../../Rows/RepairListRow';
import {
  defaultRowsPerPageOptions,
  DEFAULT_CUSTOMER_ORDER_SORTMODEL,
} from '../../../../lib/constants';
import CustomerOrderStatusChip from '../../../CustomerOrderStatusChip';
import usePersistedState from '../../../../lib/helper/usePersistedState';
import { getActiveFilters } from '../../../../lib/helper/filterModel';
import CheckBoxGroup from '../../../CheckBoxGroup';
import {
  getCustomerOrders as getCustomerOrdersI,
  getCustomerOrdersVariables,
} from '../../../../gql/customerOrder/types/getCustomerOrders';
import { CustomerOrderStatus } from '../../../../types/graphql-global-types';

export interface CustomerOrdersTableProps {
  onRowClick?: (row: GridRowParams) => void;
  rowsPerPageOptions?: number[];
  ref?: any;
  customerId?: string;
  initialLimit?: number;
  persistedKey: string;
  initialFilterModel: any;
}

type TableHandle = {
  refetch: () => void;
};

const CustomerOrdersTable = React.forwardRef(
  (
    {
      onRowClick,
      rowsPerPageOptions,
      customerId = undefined,
      initialLimit = 10,
      persistedKey,
      initialFilterModel,
    }: CustomerOrdersTableProps,
    ref: React.Ref<TableHandle>
  ) => {
    const intl = useIntl();

    // persisted sortModel
    const [sortModel, setSortModel] = usePersistedState(
      `${persistedKey}SortModel`,
      DEFAULT_CUSTOMER_ORDER_SORTMODEL
    );

    // persisted filterModel
    const [filterModel, setFilterModel] = usePersistedState(
      `${persistedKey}FilterModel`,
      initialFilterModel
    );

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(initialLimit);
    const [debounce, setDebounce] = React.useState<boolean>(false);

    const [getCustomerOrders, { data, loading, refetch }] = useLazyQuery<
      getCustomerOrdersI,
      getCustomerOrdersVariables
    >(GET_CUSTOMER_ORDERS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        offset: 0,
        limit,
        field: sortModel?.[0]?.field,
        direction: sortModel?.[0]?.sort,
        customerId,
        status: getActiveFilters(filterModel) as CustomerOrderStatus[],
      },
      onCompleted: () => setDebounce(false),
    });

    const columns: GridColDef[] = [
      {
        headerName: 'ID',
        field: 'id',
        flex: 0.5,
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customerOrders.createdAt',
        }),
        field: 'createdAt',
        flex: 1,
        renderCell: (params: GridCellParams) => (
          <DateRow date={params.value as string} time />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customerOrders.customer',
        }),
        field: 'customer',
        flex: 1,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <CustomerRow customer={params.value as Customer} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customerOrders.createdUser',
        }),
        field: 'createdUser',
        flex: 1,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <NameRow name={params.value as NameRowName} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customerOrders.repairs',
        }),
        field: 'repairs',
        flex: 1,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <RepairListRow repairs={params.value as Repair[]} />
        ),
      },
      {
        headerName: intl.formatMessage({
          id: 'app.table.colDef.customerOrders.status',
        }),
        field: 'status',
        flex: 0.8,
        renderCell: (params: GridCellParams) => (
          <CustomerOrderStatusChip status={params.row.status} />
        ),
      },
    ];

    const handlePageDebounce = (page: number) => {
      if (debounce) return;
      setPage(page);
    };

    const handlePageChange = (page: number) => {
      handlePageDebounce(page);
    };

    const handlePageSizeChange = (pageSize: number) => {
      setLimit(pageSize);
    };

    //UPDATE FIX
    const handleSortModelChange = React.useCallback(
      (sortModel: GridSortModel) => {
        setSortModel(sortModel);
      },
      [setSortModel]
    );

    // updateFilterModel
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterModel({
        ...filterModel,
        [event.target.name]: event.target.checked,
      });
    };

    const handleRefetch = () => {
      refetch && refetch();
    };

    React.useImperativeHandle(ref, () => ({
      refetch() {
        handleRefetch();
      },
    }));

    React.useEffect(() => {
      // make sure request is debounced to prevent multiple requests
      if (!debounce) {
        let offset = 0;

        if (page > 0) {
          offset = page * limit;
        }

        getCustomerOrders({
          variables: {
            offset,
            limit,
            customerId,
            ...(customerId ? { locationId: null } : { locationId: undefined }),
            field: sortModel?.[0]?.field,
            direction: sortModel?.[0]?.sort,
            status: getActiveFilters(filterModel) as CustomerOrderStatus[],
          },
        });
      }
    }, [
      customerId,
      sortModel,
      debounce,
      getCustomerOrders,
      limit,
      page,
      filterModel,
    ]);

    // refetch
    React.useEffect(() => {
      refetch && refetch();
    }, [refetch]);

    return (
      <TableBase
        onReload={handleRefetch}
        onPaper
        title="app.table.colDef.customerOrders"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingOrder={['asc', 'desc']}
        columns={columns}
        rows={data?.getCustomerOrders.items || []}
        loading={loading}
        page={page}
        pageSize={limit}
        rowCount={data?.getCustomerOrders.totalCount || 0}
        onRowClick={onRowClick}
        onPageChange={handlePageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={handlePageSizeChange}
        FilterComponent={
          <CheckBoxGroup
            checkBoxes={Object.keys(filterModel).map((status: string) => ({
              checked: filterModel[status],
              name: status,
              onChange: handleChange,
              label: `model.repair.status.${status}`,
            }))}
          />
        }
      />
    );
  }
);

CustomerOrdersTable.propTypes = {
  onRowClick: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  customerId: PropTypes.string,
  initialLimit: PropTypes.number,
};

CustomerOrdersTable.defaultProps = {
  onRowClick: undefined,
  customerId: undefined,
  initialLimit: 10,
  rowsPerPageOptions: defaultRowsPerPageOptions,
};

export default React.memo(CustomerOrdersTable);
