import { createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { logout } from '../auth';
import SnackbarUtils from '../SnackbarUtils';

/**
 * connection to graphQl server
 */
export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
});

/**
 * handle automatic logout if server returns a 401
 */
export const logoutLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`);
      SnackbarUtils.error(`${networkError.name}: ${networkError.message}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // @apollo/client/link/retry
    }

    //console.warn(operation);
    //TODO: we should use operation data or more detailed error messages in the future
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // set field if as sometimes on 401 error the response header is not set correctly
        if (err?.extensions?.originalError?.statusCode === 401) 
          err.extensions.response = { statusCode: 401 }

        // Something is not right with our error handling, therefore i added this line to show the snackbar to the user
        err?.extensions?.originalError?.error && SnackbarUtils.error(err?.extensions?.originalError?.error)
        
        switch (err?.extensions?.response?.statusCode) {
          case '': {
            const t = `${err.message ?? 'default.unknown'}`;
            SnackbarUtils.error(t);
            break;
          }
          case 400: {
            const t = `${err.message ?? 'default.400'}`;
            SnackbarUtils.error(t);
            break;
          }
          case 401: {
            const t = `${err.message ?? 'default.401'}`;
            SnackbarUtils.error(t);
            logout();
            break;
          }
          case 'UNAUTHENTICATED': {
            const t = `${err.message ?? 'default.401'}`;
            SnackbarUtils.error(t);
            logout();
            break;
          }
          case 403: {
            const t = `${err.message ?? 'default.403'}`;
            SnackbarUtils.error(t);
            break;
          }
          case 404: {
            const t = `${err.message ?? 'default.404'}`;
            SnackbarUtils.warning(t);
            break;
          }
          case 409: {
            const t = `${err.message ?? 'default.409'}`;
            SnackbarUtils.error(t);
            break;
          }
        }
      }
    }
  }
);

/**
 * handle authentication via headers
 */
export const authMiddleware = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});
