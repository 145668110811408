import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import LocationIcon from '@mui/icons-material/LocationOn';

import { me_me } from '../../../../gql/user/types/me';
import Typo from '../../../Typo';
import { logout } from '../../../../lib/auth';
import { getLocation_getLocation } from '../../../../gql/location/types/getLocation';
import { Typography } from '@mui/material';
import UpdateLocationDialog from '../../../Dialog/UpdateLocation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userName: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    listItemIcon: {
      minWidth: 36,
    },
  })
);

export interface AppBarContentProps {
  title?: string;
  titleParams?: Record<string, any>;
  user?: me_me;
  location?: getLocation_getLocation;
  loading: boolean;
  isLoggedIn: boolean;
}

const AppBarContent: React.FC<React.PropsWithChildren<AppBarContentProps>> = ({
  title,
  titleParams,
  user,
  location,
  loading,
  isLoggedIn,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const userName: string = user
    ? `${user?.firstname?.charAt(0)}. ${user?.lastname} @`
    : '';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // UPDATE FIX
  const handleClose = (
    event: React.MouseEvent<EventTarget> | MouseEvent | TouchEvent
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleOpenProfile = (event: React.MouseEvent<EventTarget>) => {
    handleClose(event);
    history.push('/profile');
  };

  const handleOpenLocation = () => {
    setOpenLocation(true);
    setOpen(false);
  };

  const handleLogout = (event: React.MouseEvent<EventTarget>) => {
    logout();
    handleClose(event);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (!isLoggedIn)
    return (
      <Box flexGrow={1}>
        <Typo
          data-test="appBar-title"
          variant="h6"
          t={`${title}.title`}
          values={titleParams}
        />
      </Box>
    );

  return (
    <>
      <Box flexGrow={1}>
        <Typo
          data-test="appBar-title"
          variant="h6"
          t={`${title}.title`}
          values={titleParams}
        />
      </Box>

      {!loading ? (
        <>
          {openLocation ? (
            <UpdateLocationDialog
              open={openLocation}
              onClose={() => setOpenLocation(false)}
            />
          ) : null}
          <Button
            data-test="appBar-menu"
            ref={anchorRef}
            onClick={handleToggle}
            variant="outlined"
            className={classes.userName}
            color="secondary"
            endIcon={<ArrowDropDownIcon />}
            size="small"
          >
            <Box textAlign="left" minWidth={120}>
              <Typo
                fontSize={12}
                t={'app.appBar.user'}
                values={{ userName }}
                noWrap
              />
              <Typography fontWeight="bold">{location?.name ?? '-'}</Typography>
            </Box>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {/* Profile */}
                      <MenuItem
                        onClick={handleOpenProfile}
                        data-test="appBar-menu-item-profile"
                      >
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typo t="app.appbar.profile" />
                      </MenuItem>
                      {/* Locatiom */}
                      <MenuItem
                        onClick={handleOpenLocation}
                        data-test="appBar-menu-item-location"
                      >
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <LocationIcon fontSize="small" />
                        </ListItemIcon>
                        <Typo t="app.appbar.location" />
                      </MenuItem>

                      <MenuItem
                        onClick={handleLogout}
                        data-test="appBar-menu-item-logout"
                      >
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                          <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <Typo t="app.appBar.logout" />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="secondary" size={24} />
        </Box>
      )}
    </>
  );
};

AppBarContent.propTypes = {};
AppBarContent.defaultProps = {};

export default AppBarContent;
