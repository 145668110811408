import React from 'react';
import PropTypes from 'prop-types';
import { UpdateNoteInput } from '../../../types/graphql-global-types';
import { getNotes_getNotes_items } from '../../../gql/notes/types/getNotes';
import FormContainer from '..';
import FormWYSIWYG from '../Components/FormWYSIWYG';
import FormSetValue from '../Components/FormSetValue';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormAccordion, {
  FormAccordionDetails,
  FormAccordionSummary,
} from '../Components/FormAccordion';
import Typo from '../../Typo';
import FormCustomer from '../Components/FormCustomer';
import { getCustomer_getCustomer } from '../../../gql/customer/types/getCustomer';

export interface EditNoteFormProps {
  note?: getNotes_getNotes_items;
  onSubmit: (data: UpdateNoteInput) => void;
}

const EditNoteForm: React.FC<React.PropsWithChildren<EditNoteFormProps>> = ({ note, onSubmit }) => {
  const [selectedUser, setSelectedUser] =
    React.useState<getCustomer_getCustomer | null>();

  return (
    <FormContainer onSubmit={onSubmit} id="editNote-form">
      <FormSetValue name="id" value={note?.id} />
      <FormWYSIWYG name="content" initialContent={note?.content} />

      {/* Location */}

      {/* CustomerOrder */}

      {/* Customer */}
      <Box mt={1}>
        <FormAccordion>
          <FormAccordionSummary id="customerId">
            <Box display="flex" flex={1}>
              <Typo t="model.customer" />
            </Box>
            <Box mr={1} ml={1}>
              {selectedUser ? (
                <Typography>{`${selectedUser.firstname} ${selectedUser.lastname}`}</Typography>
              ) : null}
            </Box>
          </FormAccordionSummary>
          <FormAccordionDetails>
            <Box display="flex" flex={1}>
              <FormCustomer
                name="customerId"
                onCustomerChange={setSelectedUser}
                initialValue={
                  note?.customer as unknown as getCustomer_getCustomer
                }
              />
            </Box>
          </FormAccordionDetails>
        </FormAccordion>
      </Box>
    </FormContainer>
  );
};

EditNoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
EditNoteForm.defaultProps = {};

export default EditNoteForm;
