import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { WorkStep } from '../../../../Dialog/WorkStepDefinition';
import SkillLevelChip from '../../../../SkillLevelChip';
import Typo from '../../../../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 100,
      backgroundColor: '#fff',
    },
    colorIndicator: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      width: 40,
    },
  })
);

export interface WorkStepItemProps {
  workStep: WorkStep;
  handleEdit: (workStep: WorkStep) => void;
}

const WorkStepItem: React.FC<React.PropsWithChildren<WorkStepItemProps>> = ({
  workStep,
  handleEdit,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" p={1}>
      <Box
        className={classes.container}
        display="flex"
        flexGrow={1}
        borderRadius={1}
      >
        {!!workStep?.color ? (
          <Box
            className={classes.colorIndicator}
            style={{ backgroundColor: workStep?.color }}
          />
        ) : null}

        <Box display="flex" p={1} flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <b>{workStep.name}</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <SkillLevelChip type={workStep.skillLevel} />
              <Box ml={2} mr={2}>
                <Typo
                  t="page.worksteps.tabs.workSteps.aw"
                  values={{ aw: workStep.expectedWorkload }}
                  noWrap
                />
              </Box>
              <IconButton size="small" onClick={() => handleEdit(workStep)}>
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            mt={1}
            p={1}
            height={75}
            overflow="auto"
            bgcolor="background.default"
            borderRadius={1}
          >
            {workStep.description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

WorkStepItem.propTypes = {
  // workStep:
  handleEdit: PropTypes.func.isRequired,
};
WorkStepItem.defaultProps = {};

export default WorkStepItem;
