/**
 * get the first number from a string. If none is found returns 0.
 * @param s string
 */
export const getNumberFromString = (s: string): number => {
  const match = s?.match(/\d+/);

  return match ? parseInt(match[0]) : 0;
};

/**
 * returnes formatted currency string
 * @param value number
 */
export const formattedCurrency = (value: number | null): string => {
  //FIXME: Make intl

  if (value === null || value === undefined || isNaN(value)) return '-';

  // values are given in cents. We want to show in euros.
  const euroValue = value === 0 ? 0 : value / 100;

  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(euroValue);
};

/**
 * converts userinput string to a float
 * @param priceInput price input as string
 */
export const getPriceFloat = (priceInput: string): number => {
  if (!priceInput || !(typeof priceInput === 'string')) {
    return 0;
  }

  return Math.round(parseFloat(priceInput.replace(',', '.')) * 100);
};
