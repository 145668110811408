import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { appleTabsStyles } from './AppleStyleTabs.styles';
import { FormattedMessage } from 'react-intl';

interface TabI {
  /** react-intl translation id */
  label: string;
  disabled?: boolean;
}

export interface AppleStyleTabsProps {
  tabs: TabI[];
  current: number;
  setValue: (value: number) => void;
  accentBG?: boolean;
}

const AppleStyleTabs: React.FC<
  React.PropsWithChildren<AppleStyleTabsProps>
> = ({ tabs, current, setValue, accentBG = false }) => {
  const tabsStyles = appleTabsStyles.useTabs();
  const tabItemStyles = appleTabsStyles.useTabItem();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs
      classes={tabsStyles}
      value={current}
      onChange={handleChange}
      style={{
        backgroundColor: accentBG ? 'rgba(25,118,210, 0.1)' : undefined,
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.label}
          classes={tabItemStyles}
          disableRipple
          label={<FormattedMessage id={tab.label} />}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  );
};

AppleStyleTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired
  ).isRequired,
  current: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  accentBG: PropTypes.bool,
};
AppleStyleTabs.defaultProps = {};

export default AppleStyleTabs;
