import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SubdirectoryArrow from '@mui/icons-material/SubdirectoryArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typo from '../../../Typo';

export interface ContentContainerProps {
  children: React.ReactNode;
  title: string;
  loading?: boolean;
  historyDisabled?: boolean;
  onHistoryBack?: () => void;
}

const ContentContainer: React.FC<React.PropsWithChildren<ContentContainerProps>> = ({
  title,
  children,
  historyDisabled = false,
  loading = false,
  onHistoryBack = null,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      border={1}
      borderColor="grey.400"
      borderRadius={1}
      bgcolor="#fff"
      height={matches ? '60vh' : '30vh'}
      overflow="hidden"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="40px"
        p={2}
      >
        <Box display="flex">
          {!!onHistoryBack ? (
            <Box marginRight={1}>
              <IconButton
                size="small"
                color="primary"
                onClick={onHistoryBack}
                disabled={historyDisabled}
              >
                <SubdirectoryArrow />
              </IconButton>
            </Box>
          ) : null}
          <Typo variant="h6" color="primary" t={title} />
        </Box>
        {loading && <CircularProgress size={20} />}
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        overflow="scroll"
        height="100%"
        p={2}
      >
        {children}
      </Box>
    </Box>
  );
};

ContentContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onHistoryBack: PropTypes.func,
  loading: PropTypes.bool,
  historyDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
ContentContainer.defaultProps = {};

export default ContentContainer;
