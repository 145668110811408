import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';

import Typo from '../../../Typo';
import Paper from '../../../Paper';

import Button from '../../../Button';
import usePrint from '../../../../lib/helper/usePrint';
import {
  SubOrderProductOrder,
  SubOrderRepair,
} from '../../machines/subOrdersMachine/types';
import { CustomerDraftMachinesContext } from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    invoiceInfoField: {
      backgroundColor: '#fff',
    },
  })
);

export interface CustomerOrderOverviewProps {
  printRef: React.Ref<HTMLElement | undefined>;
}

const CustomerOrderOverview: React.FC<
  React.PropsWithChildren<CustomerOrderOverviewProps>
> = ({ printRef }) => {
  const classes = useStyles();
  const { orderDraftMachine, subOrdersMachine, customerMachine } =
    CustomerDraftMachinesContext();

  const [orderDraftState, transition] = orderDraftMachine;
  const [subOrdersState, subOrdersTransition] = subOrdersMachine;
  const [customerState] = customerMachine;

  // get all subOrders and check if one is completed
  const subOrders: (SubOrderRepair | SubOrderProductOrder)[] = [
    ...subOrdersState.context.repairs,
    ...subOrdersState.context.orders,
  ];

  const subOrderSelected = subOrders.some(
    (subOrder: SubOrderRepair | SubOrderProductOrder) => subOrder.completed
  );

  // check if customerMachine is in selected state
  const customerSelected = customerState.matches('selected');

  // check if customerOrderMachine is in costEstimation state
  const isCostEstimation = orderDraftState.matches('costEstimateCheck');
  const isCompleted = orderDraftState.matches('completed');
  const creatingCustomerOrder = orderDraftState.matches(
    'creatingCustomerOrder'
  );
  const invoiceInfo = orderDraftState.context.invoiceInfo ?? '';

  /**
   * handles costEstiation. creates customerOrder with isCostEstimation flag true
   */
  const handleCostEstimation = () => {
    const { repairs, orders } = subOrdersState.context;
    const { customerId } = customerState.context;

    transition('CREATE_CUSTOMER_ORDER', {
      customerId,
      repairs,
      orders,
      invoiceInfo,
    });
  };

  // removes isCostEstimation flag from order
  const handleCompleteOrder = () => {
    transition('ADD_CUSTOMER_ORDER');
  };

  const handlePrint = usePrint({
    printRef: printRef,
    isBrandedDefaultPaper: true,
  });

  const handleInvoiceInfoUpdate = (text: string) => {
    transition('UPDATE_INVOICE_INFO', { data: text });
  };

  return (
    <Paper padding>
      <Typo
        variant="h5"
        t="app.customerOrderDraft.overview.title"
        gutterBottom
      />
      <Box bgcolor="background.default" p={2} borderRadius={1}>
        <Stepper
          nonLinear
          orientation="vertical"
          activeStep={-1}
          className={classes.stepper}
        >
          <Step completed={customerSelected}>
            <StepButton>
              <Typo t="app.customerOrderDraft.overview.customerState" />
            </StepButton>
            {/* <StepContent TransitionProps={{ in: true }}>
            {customerSelected && <p>{customerState.context.customerId}</p>}
          </StepContent> */}
          </Step>
          <Step completed={subOrderSelected}>
            <StepButton>
              <Typo t="app.customerOrderDraft.overview.subOrdersState" />
            </StepButton>
            {/* <StepContent TransitionProps={{ in: true }}>
            {subOrderSelected && <p>Yup</p>}
          </StepContent> */}
          </Step>
          <Step>
            <StepButton>
              <Typo t="app.customerOrderDraft.overview.costEstimateState" />
            </StepButton>
          </Step>
        </Stepper>
      </Box>

      {/* invoice info */}
      {isCostEstimation || isCompleted ? null : (
        <Box
          display="flex"
          flexDirection="column"
          marginBottom={3}
          marginTop={2}
          // maxWidth={250}
        >
          <Box marginBottom={1}>
            <Typo
              t="app.customerOrderDraft.overview.invoiceInfo"
              variant="h6"
            />
          </Box>

          <Box bgcolor="background.default" p={2}>
            <TextField
              fullWidth
              label={
                <FormattedMessage id="app.customerOrderDraft.overview.invoiceInfo.label" />
              }
              multiline
              minRows={2}
              maxRows={4}
              className={classes.invoiceInfoField}
              value={invoiceInfo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleInvoiceInfoUpdate(event.target.value);
              }}
            />
          </Box>
        </Box>
      )}

      {/* actions */}
      <Box
        display="flex"
        flexDirection="column"
        marginBottom={3}
        marginTop={2}
        // maxWidth={250}
      >
        <Box marginBottom={1}>
          <Typo t="app.customerOrderDraft.overview.actions" variant="h6" />
        </Box>
        <Box bgcolor="background.default" p={2} borderRadius={1}>
          {isCostEstimation || isCompleted ? (
            <Box marginBottom={1}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                t="app.customerOrderDraft.overview.print"
                onClick={handlePrint}
              />
            </Box>
          ) : (
            <>
              <Box marginBottom={1}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  t="app.customerOrderDraft.overview.repair.add"
                  onClick={() => subOrdersTransition('SUBORDER_SPAWN_REPAIR')}
                />
              </Box>
              <Box>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  t="app.customerOrderDraft.overview.order.add"
                  onClick={() => subOrdersTransition('SUBORDER_SPAWN_ORDER')}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      {!isCostEstimation ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            marginBottom={1}
            color="error.main"
          >
            {!customerState?.context?.customerId && (
              <code>
                <FormattedMessage id="app.customerOrderDraft.overview.validation.noCustomer" />
              </code>
            )}
            {!subOrderSelected && (
              <code>
                <FormattedMessage id="app.customerOrderDraft.overview.validation.noSubOrder" />
              </code>
            )}
          </Box>
          <Button
            fullWidth
            disabled={
              !(customerSelected && subOrderSelected) || creatingCustomerOrder
            }
            onClick={handleCostEstimation}
            variant="contained"
            color="primary"
            t="app.customerOrderDraft.overview.primaryAction.costEstimation"
          />
        </>
      ) : (
        <Button
          fullWidth
          disabled={!(customerSelected && subOrderSelected)}
          onClick={handleCompleteOrder}
          variant="contained"
          color="primary"
          t="app.customerOrderDraft.overview.primaryAction.completeOrder"
        />
      )}
    </Paper>
  );
};

CustomerOrderOverview.propTypes = {};
CustomerOrderOverview.defaultProps = {};

export default CustomerOrderOverview;
