import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { Product } from '../../../../../lib/helper/treeViewHelper';
import { formattedCurrency } from '../../../../../lib/helper/numbers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 100,
      backgroundColor: '#fff',
    },
    colorIndicator: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      width: 40,
    },
  })
);

export interface ProductItemProps {
  product: Product;
  handleEditProduct: (product: Product) => void;
}

const ProductItem: React.FC<React.PropsWithChildren<ProductItemProps>> = ({
  product,
  handleEditProduct,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" p={1}>
      <Box
        className={classes.container}
        display="flex"
        flexGrow={1}
        borderRadius={1}
      >
        {/* <Box
          className={classes.colorIndicator}
          style={{ backgroundColor: product.color }}
        ></Box> */}
        <Box display="flex" p={1} flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" flex={1}>
              {product.manufacturer ? (
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography>
                    <i>{product.manufacturer}</i>
                  </Typography>
                </Box>
              ) : null}
              <Typography>
                <b>{product.name}</b>
              </Typography>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box ml={2} mr={2}>
                {formattedCurrency(product.price)}
              </Box>

              <IconButton
                size="small"
                onClick={() => handleEditProduct(product)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Box>

          <Box
            mt={1}
            p={1}
            height={75}
            overflow="auto"
            bgcolor="background.default"
            borderRadius={1}
          >
            {product.description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProductItem.propTypes = {
  handleEditProduct: PropTypes.func.isRequired,
};
ProductItem.defaultProps = {};

export default ProductItem;
