import React from 'react';
import PropTypes from 'prop-types';
import DialogBase from '..';
import { useQuery, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';

import {
  GET_WORKSTEP_DETAIL,
  GET_WORKSTEP_ROOT_CATEGORIES,
  GET_WORKSTEP_CATEGORY,
} from '../../../gql/workStep/queries';
import CategoryContent, { HistoryObject } from '../../CategoryContent';
import { SelectionType } from '../../CategoryContent';
import {
  getWorkStepDefinition,
  getWorkStepDefinitionVariables,
  getWorkStepDefinition_getWorkStepDefinition,
} from '../../../gql/workStep/types/getWorkStepDefinition';
import { CreateWorkStep } from '../../CustomerOrderDraft/machines/orderDraftMachine/types';
import {
  getWorkStepCategories,
  getWorkStepCategories_getWorkStepCategories_subCategories,
  getWorkStepCategories_getWorkStepCategories_workSteps,
} from '../../../gql/workStep/types/getWorkStepCategories';
import {
  getWorkStepCategory,
  getWorkStepCategoryVariables,
} from '../../../gql/workStep/types/getWorkStepCategory';

export interface AddWorkStepDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (workStep: getWorkStepDefinition_getWorkStepDefinition) => void;
  selected: getWorkStepDefinition_getWorkStepDefinition[] | CreateWorkStep[];
}

const AddWorkStepDialog: React.FC<React.PropsWithChildren<AddWorkStepDialogProps>> = ({
  open,
  onClose,
  onSubmit,
  selected,
}) => {
  const [onRootCategories, setOnRootCategories] = React.useState<boolean>(true);
  const [rootSubCategories, setRootSubCategories] = React.useState<
    getWorkStepCategories_getWorkStepCategories_subCategories[] | null
  >([]);
  const [rootWorkSteps, setRootWorkSteps] = React.useState<
    getWorkStepCategories_getWorkStepCategories_workSteps[] | null
  >([]);

  const { data, loading } = useQuery<getWorkStepCategories>(
    GET_WORKSTEP_ROOT_CATEGORIES
  );

  const [getCategoryDetail, { data: currentCategory, loading: detailLoading }] =
    useLazyQuery<getWorkStepCategory, getWorkStepCategoryVariables>(
      GET_WORKSTEP_CATEGORY
    );

  const [getWorkStepDetail, { loading: workStepLoading }] = useLazyQuery<
    getWorkStepDefinition,
    getWorkStepDefinitionVariables
  >(GET_WORKSTEP_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSubmit(data.getWorkStepDefinition);
    },
  });

  const onHistoryUpate = (history: HistoryObject[]) => {
    if (!history.length) {
      setOnRootCategories(true);
      return;
    }

    setOnRootCategories(false);
  };

  const getRootCategories = React.useCallback(() => {
    if (data) {
      setRootSubCategories(data?.getWorkStepCategories[0]?.subCategories);
    }
  }, [data]);

  const getRootWorkSteps = React.useCallback(() => {
    if (data) {
      setRootWorkSteps(data?.getWorkStepCategories[0]?.workSteps);
    }
  }, [data]);

  React.useEffect(() => {
    getRootCategories();
    getRootWorkSteps();
  }, [getRootCategories, getRootWorkSteps]);

  /**
   * rootCategories should be shown when Dialog us opened
   */
  React.useEffect(() => {
    if (open) {
      setOnRootCategories(true);
    }
  }, [open]);

  return (
    <DialogBase
      fullWidth
      maxWidth="xl"
      title="app.dialog.workStep.add.title"
      primaryAction={undefined}
      secondaryAction={() => onClose()}
      open={open}
      isLoading={loading || detailLoading || workStepLoading}
      keepMounted={false}
    >
      <Box display="flex" flexDirection="column" data-test="addWorkStepDialog">
        <Box>
          <CategoryContent
            onHistoryUpdate={onHistoryUpate}
            getCategoryDetail={getCategoryDetail}
            getItemDetail={getWorkStepDetail}
            subCategories={
              onRootCategories
                ? rootSubCategories
                : currentCategory?.getWorkStepCategory?.subCategories ?? []
            }
            workSteps={
              onRootCategories
                ? rootWorkSteps
                : currentCategory?.getWorkStepCategory?.workSteps ?? []
            }
            loading={loading || detailLoading}
            selected={selected}
            itemLabel="model.repair.workSteps"
            itemEmptyLabel="app.dialog.workStep.add.emptyWorkSteps"
            type={SelectionType.WORKSTEP}
          />
        </Box>
      </Box>
    </DialogBase>
  );
};

AddWorkStepDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
AddWorkStepDialog.defaultProps = {};

export default AddWorkStepDialog;
