import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';

import { RepairStatus } from '../../types/graphql-global-types';
import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      minWidth: 90,
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(0, 1),
    },
    canceled: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  })
);

export interface RepairStatusChipProps {
  status: RepairStatus;
  className?: string;
}

const RepairStatusChip: React.FC<React.PropsWithChildren<RepairStatusChipProps>> = ({
  status,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      className={clsx(classes.base, {
        [classes.canceled]: status === RepairStatus.CANCELED,
        [className]: !!className,
      })}
      label={status ? <Typo t={`model.repair.status.${status}`} /> : undefined}
      color="secondary"
    />
  );
};

RepairStatusChip.propTypes = {
  status: PropTypes.oneOf<RepairStatus>([
    RepairStatus.CANCELED,
    RepairStatus.COMPLETED,
    RepairStatus.IN_PROGRESS,
    RepairStatus.OPEN,
    RepairStatus.PAUSED,
    RepairStatus.WORK_DONE,
    RepairStatus.COSTESTIMATION,
    RepairStatus.INVOICED,
  ]).isRequired,
  className: PropTypes.string,
};
RepairStatusChip.defaultProps = {
  className: '',
};

export default RepairStatusChip;
