import { send } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import { RepairContext, RepairEvent } from '../types';

export const completeRepair = send<RepairContext, RepairEvent>(
  (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'COMPLETE_REPAIR');

    return {
      type: 'SUBORDER_COMPLETE_REPAIR',
      id: e.id,
      data: ctx.data,
    };
  },
  { to: 'subOrdersMachine' }
);

export const unCompleteRepair = send<RepairContext, RepairEvent>(
  (_: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UNCOMPLETE_REPAIR');

    return {
      type: 'SUBORDER_UNCOMPLETE_REPAIR',
      id: e.id,
    };
  },
  { to: 'subOrdersMachine' }
);

export const removeRepair = send<RepairContext, RepairEvent>(
  (_: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'REMOVE_REPAIR');

    return {
      type: 'SUBORDER_REMOVE_REPAIR',
      id: e.id,
    };
  },
  { to: 'subOrdersMachine' }
);
