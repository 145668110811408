import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

export interface FormWatchValueProps {
  watchName: string;
  onChange: (value: string) => void;
}

const FormWatchValue: React.FC<React.PropsWithChildren<FormWatchValueProps>> = ({
  watchName,
  onChange,
}) => {
  const { watch } = useFormContext();

  React.useEffect(() => {
    const w = watch(watchName, '');
    onChange(w);
  }, [onChange, watch, watchName]);

  return null;
};

FormWatchValue.propTypes = {
  watchName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
FormWatchValue.defaultProps = {};

export default FormWatchValue;
