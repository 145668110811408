import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';

import { CustomerOrderStatus } from '../../types/graphql-global-types';
import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      minWidth: 90,
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(0, 1),
    },
    canceled: {
      color: 'red',
      borderColor: 'red',
    },
  })
);

export interface CustomerOrderStatusChipProps {
  status: CustomerOrderStatus;
  className?: string;
}

const CustomerOrderStatusChip: React.FC<React.PropsWithChildren<CustomerOrderStatusChipProps>> = ({
  status,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      className={clsx(classes.base, {
        [classes.canceled]: status === CustomerOrderStatus.CANCELED,
        [className]: !!className,
      })}
      label={<Typo t={`model.customerOrder.status.${status}`} />}
      color="secondary"
    />
  );
};

CustomerOrderStatusChip.propTypes = {
  status: PropTypes.oneOf<CustomerOrderStatus>([
    CustomerOrderStatus.COSTESTIMATION,
    CustomerOrderStatus.OPEN,
    CustomerOrderStatus.INVOICED,
    CustomerOrderStatus.CANCELED,
  ]).isRequired,
  className: PropTypes.string,
};
CustomerOrderStatusChip.defaultProps = {
  className: undefined,
};

export default CustomerOrderStatusChip;
