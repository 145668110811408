import { gql } from '@apollo/client';

export const GET_CUSTOMER_ORDER = gql`
  query getCustomerOrder($id: Int!) {
    getCustomerOrder(id: $id) {
      id
      createdAt
      updatedAt
      acceptedDate
      total
      taxAmount
      status
      cancelationReason
      subtotal
      invoiceId
      invoiceDate
      invoiceInfo
      paymentMethod
      invoiceNumber
      invoicedLocationId
      history {
        id
        createdAt
        updatedAt
        entityId
        changeType
        summary
        user {
          id
          firstname
          lastname
        }
        details
      }
      customer {
        id
        gender
        lastname
        firstname
        phone
        phone2
        email
        address {
          id
          street1
          street2
          city
          postalCode
        }
      }
      createdUser {
        id
        firstname
        lastname
      }
      repairs {
        id
        status
        skillLevel
        repairCode
        estimatedPickupDate
        completedDate
        workloadRate
        notes
        locationId
        bike {
          id
          brand
          model
        }
        workSteps {
          id
          setWorkload
          workStepDefinition {
            id
            name
          }
          requiredProducts {
            id
            quantity
            product {
              id
              name
              price
            }
          }
        }
        products {
          id
          quantity
          product {
            id
            name
            price
          }
        }
      }
      productOrders {
        id
        createdAt
        updatedAt
        status
        price
        products {
          id
          quantity
          status
          product {
            id
            name
            price
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ORDER_CHECKOUT_PREVIEW = gql`
  query getCustomerOrderCheckoutPreview($id: Int!) {
    getCustomerOrderCheckoutPreview(id: $id) {
      id
      total
      taxAmount
      status
      subtotal
      repairs {
        id
        status
        repairCode
        workloadRate
        locationId
        workSteps {
          id
          setWorkload
          workStepDefinition {
            id
            name
          }
          requiredProducts {
            id
            quantity
            product {
              id
              name
              price
            }
          }
        }
        products {
          id
          quantity
          product {
            id
            name
            price
          }
        }
      }
      productOrders {
        id
        status
        price
        products {
          id
          quantity
          status
          product {
            id
            name
            price
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_ORDERS = gql`
  query getCustomerOrders(
    $customerId: String
    $offset: Int
    $limit: Int
    $field: CustomerOrderSortField
    $direction: OrderDirection
    $status: [CustomerOrderStatus!]
    $locationId: String
  ) {
    getCustomerOrders(
      customerId: $customerId
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
      status: $status
      locationId: $locationId
    ) {
      items {
        id
        status
        cancelationReason
        createdAt
        acceptedDate
        createdUser {
          id
          firstname
          lastname
        }
        customer {
          id
          gender
          lastname
          firstname
          phone
          phone2
          email
          address {
            id
            city
            postalCode
            street1
            street2
          }
        }
        repairs {
          id
          repairCode
          status
          bike {
            id
            brand
            model
          }
        }
      }
      totalCount
    }
  }
`;
