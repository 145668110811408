import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import Typo from '../../../../Typo';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    // color: 'rgba(0, 0, 0, 0.87)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: theme.spacing(1),
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&$selected': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    selected: {},
  })
);

export interface DrawerItemProps {
  selected: boolean;
  label: string;
  Icon: React.ReactElement;
  isMobile: boolean;
  path?: string;
  onClick?: () => void;
  dataTest?: string;
}

const DrawerItem: React.FC<React.PropsWithChildren<DrawerItemProps>> = ({
  selected,
  Icon,
  isMobile,
  label,
  path = undefined,
  onClick = undefined,
  dataTest = '',
}) => {
  const history = useHistory();
  const classes = useStyles();

  if (isMobile) {
    return (
      <ListItem
        data-test={dataTest}
        data-test-selected={selected ? dataTest : ''}
        button
        onClick={!!onClick ? onClick : () => history.push(path || '')}
        selected={selected}
        classes={{ root: classes.root, selected: classes.selected }}
      >
        <ListItemIcon color="inherit">{Icon}</ListItemIcon>
        <ListItemText>{<Typo t={label} />}</ListItemText>
      </ListItem>
    );
  }

  return (
    <LightTooltip
      arrow
      placement="right"
      title={<Typo data-test={`${dataTest}-tooltip`} t={label} />}
    >
      <ListItem
        button
        onClick={!!onClick ? onClick : () => history.push(path || '')}
        selected={selected}
        data-test={dataTest}
        data-test-selected={selected ? dataTest : ''}
        classes={{ root: classes.root, selected: classes.selected }}
      >
        <ListItemIcon>{Icon}</ListItemIcon>
      </ListItem>
    </LightTooltip>
  );
};

DrawerItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
  dataTest: PropTypes.string,
};
DrawerItem.defaultProps = {
  path: undefined,
  onClick: undefined,
  dataTest: '',
};

export default DrawerItem;
