import { Machine } from 'xstate';
import { SubOrderType } from '../../subOrdersMachine/types';
import {
  addProduct,
  removeProduct,
  updateProductCount,
  updateProductStatus,
  completeOrder,
  removeOrder,
  unCompleteOrder,
} from './actions/';
import {
  ProductOrderContext,
  ProductOrderEvent,
  ProductOrderSchema,
} from './types';

export const initialOrder = {
  type: SubOrderType.ORDER,
  completed: false,
  data: {
    products: [],
    notes: undefined,
  },
};

const productOrderMachine = Machine<
  ProductOrderContext,
  ProductOrderSchema,
  ProductOrderEvent
>(
  {
    id: 'order',
    initial: 'editing',
    context: initialOrder,
    states: {
      editing: {
        on: {
          COMPLETE_ORDER: {
            target: 'completed',
          },
          REMOVE_ORDER: {
            target: 'removed',
          },
          ADD_PRODUCT: {
            actions: ['addProduct'],
          },
          REMOVE_PRODUCT: {
            actions: ['removeProduct'],
          },
          UPDATE_PRODUCT_COUNT: {
            actions: ['updateProductCount'],
          },
          UPDATE_PRODUCT_STATUS: {
            actions: ['updateProductStatus'],
          },
        },
      },

      completed: {
        entry: 'completeOrder',
        on: {
          EDIT_ORDER: {
            target: 'editing',
            actions: 'unCompleteOrder',
          },
          REMOVE_ORDER: {
            target: 'removed',
          },
        },
      },

      removed: {
        entry: 'removeOrder',
      },
      error: {},
    },
  },
  {
    actions: {
      completeOrder: completeOrder,
      unCompleteOrder: unCompleteOrder,
      removeOrder: removeOrder,
      addProduct: addProduct,
      removeProduct: removeProduct,
      updateProductCount: updateProductCount,
      updateProductStatus: updateProductStatus,
    },
  }
);

export default productOrderMachine;
