import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from '..';
import EditWorkStepForm, { EditWorkStep } from '../../Form/EditWorkStepForm';

export interface EditWorkStepDialogProps {
  workStep: EditWorkStep;
  onClose: () => void;
  onSubmit: (data: EditWorkStep) => void;
}

const EditWorkStepDialog: React.FC<React.PropsWithChildren<EditWorkStepDialogProps>> = ({
  workStep,
  onClose,
  onSubmit,
}) => {
  return (
    <DialogBase
      title={'app.dialog.editWorkStep.title'}
      open={!!workStep}
      onClose={onClose}
      isForm
    >
      <EditWorkStepForm
        workStep={workStep}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </DialogBase>
  );
};

EditWorkStepDialog.propTypes = {
  // workStep:
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
EditWorkStepDialog.defaultProps = {};

export default EditWorkStepDialog;
