import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';

export { default as FormAccordionSummary } from './AccordionSummary';
export { default as FormAccordionDetails } from '@mui/material/AccordionDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionRoot: {
      boxShadow: 'none',
      borderColor: theme.palette.grey[400],
      border: '1px solid',
      borderRadius: 'borderRadius',
    },
  })
);

export interface FormAccordionProps {
  children: NonNullable<React.ReactNode>;
}

const FormAccordion: React.FC<React.PropsWithChildren<FormAccordionProps>> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box>
      <Accordion
        classes={{
          root: classes.accordionRoot,
        }}
      >
        {children}
      </Accordion>
    </Box>
  );
};

FormAccordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
FormAccordion.defaultProps = {};

export default FormAccordion;
