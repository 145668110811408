import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import DialogBase from '..';
import { CREATE_CUSTOMER_BIKE } from '../../../gql/customer/mutations/bike';
import CreateBikeDialogForm from '../../Form/CreateBikeDialogForm';
import Button from '../../Button';
import {
  createCustomerBike,
  createCustomerBikeVariables,
  createCustomerBike_createCustomerBike,
  createCustomerBike_createCustomerBike_bikes,
} from '../../../gql/customer/mutations/types/createCustomerBike';
import { CreateBikeForExistingCustomerInput } from '../../../types/graphql-global-types';

export interface CreateBikeDialogProps {
  customerId?: string;
  onClose: (bike?: createCustomerBike_createCustomerBike_bikes) => void;
  onSubmit?: () => void;
}

const CreateBikeDialog: React.FC<
  React.PropsWithChildren<CreateBikeDialogProps>
> = ({ onClose, onSubmit = undefined, customerId = undefined }) => {
  const [addBike, { loading }] = useMutation<
    createCustomerBike,
    createCustomerBikeVariables
  >(CREATE_CUSTOMER_BIKE, {
    onCompleted: (data) => handleClose(data.createCustomerBike),
    onError: () => {},
  });

  const handleSubmit = (data: CreateBikeForExistingCustomerInput) => {
    if (data?.customerId) {
      addBike({ variables: { data } });
    }
  };

  const handleClose = (bikes: createCustomerBike_createCustomerBike) => {
    // get most recent created customer bike
    const youngestBike = bikes?.bikes?.reduce((youngest, current) => {
      const youngestDate = new Date(youngest.createdAt);
      const currentDate = new Date(current.createdAt);

      if (currentDate > youngestDate) {
        return current;
      } else {
        return youngest;
      }
    });

    // return most recent bike
    !!onSubmit ? onSubmit() : onClose(youngestBike);
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      isLoading={loading}
      title="app.dialog.bike.add.title"
      primaryAction={undefined}
      secondaryAction={onClose}
      isForm
      open={!!customerId}
    >
      <Box bgcolor="background.default" borderRadius={1} p={2} m={2} mt={0}>
        <CreateBikeDialogForm
          customerId={customerId}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            form="createBike-form"
          />
        </DialogActions>
      </Box>
    </DialogBase>
  );
};

CreateBikeDialog.propTypes = {
  customerId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
CreateBikeDialog.defaultProps = {
  customerId: undefined,
};

export default CreateBikeDialog;
