import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_ORDER = gql`
  mutation createCustomerOrder($data: CreateCustomerOrder!) {
    createCustomerOrder(data: $data) {
      createdAt
      id
      total
      subtotal
      status
      cancelationReason
      taxAmount
      invoiceInfo
      customer {
        id
        lastname
        firstname
        phone
        address {
          id
          city
          postalCode
          street1
          street2
        }
      }
      createdUser {
        id
        firstname
        lastname
      }
      repairs {
        id
        repairCode
        estimatedPickupDate
        workloadRate
        bike {
          brand
          model
        }
        workSteps {
          id
          setWorkload
          notes
          workStepDefinition {
            id
            name
          }
          requiredProducts {
            id
            quantity
            product {
              id
              name
              price
            }
            productPrice
          }
        }
        products {
          id
          quantity
          product {
            id
            name
            price
          }
          productPrice
        }
      }
      productOrders {
        id
        products {
          id
          product {
            id
            name
            price
          }
          quantity
          productPrice
        }
        price
      }
    }
  }
`;

export const UPDATE_CUSTOMER_ORDER_COST_ESTIMATION = gql`
  mutation updateCustomerOrderCostEstimation($data: UpdateCustomerOrder!) {
    updateCustomerOrder(data: $data) {
      id
      status
      cancelationReason
    }
  }
`;
