import client from '../../../../lib/apolloClient';
import {
  GET_REPAIR,
  GET_REPAIR_PRICE_AND_WORKLOAD,
} from '../../../../gql/repair/queries';
import { getNumberFromString } from '../../../../lib/helper/numbers';
import { UPDATE_REPAIR } from '../../../../gql/repair/mutations';
import {
  UPDATE_WORKSTEP_IS_DONE,
  UPDATE_WORKSTEP,
  CREATE_WORKSTEP,
  DELETE_WORKSTEP,
} from '../../../../gql/workStep/mutations';
import { UPDATE_PRODUCT_QUANTITY } from '../../../../gql/product/mutations';
import {
  getRepair as getRepairI,
  getRepairVariables,
} from '../../../../gql/repair/types/getRepair';
import {
  getRepairPriceAndWorkload as getRepairPriceAndWorkloadI,
  getRepairPriceAndWorkloadVariables,
} from '../../../../gql/repair/types/getRepairPriceAndWorkload';
import {
  updateRepair as updateRepairI,
  updateRepairVariables,
} from '../../../../gql/repair/types/updateRepair';
import {
  updateWorkStep as updateWorkStepI,
  updateWorkStepVariables,
} from '../../../../gql/workStep/types/updateWorkStep';
import {
  setWorkStepIsDone,
  setWorkStepIsDoneVariables,
} from '../../../../gql/workStep/types/setWorkStepIsDone';
import {
  updateProductQuantity as updateProductQuantityI,
  updateProductQuantityVariables,
} from '../../../../gql/product/types/updateProductQuantity';
import {
  createWorkStep as createWorkStepI,
  createWorkStepVariables,
} from '../../../../gql/workStep/types/createWorkStep';
import {
  CreateWorkStep,
  SkillLevel,
  UpdateProductQuantity,
  UpdateRepair,
  UpdateWorkStep,
} from '../../../../types/graphql-global-types';
import {
  deleteWorkStep as deleteWorkStepI,
  deleteWorkStepVariables,
} from '../../../../gql/workStep/types/deleteWorkStep';
import { ApolloError } from '@apollo/client';

/**
 * get full repair
 * @param id string
 */
export const getRepair = (repairCode: string) => {
  return client
    .query<getRepairI, getRepairVariables>({
      query: GET_REPAIR,
      variables: { repairCode },
      fetchPolicy: 'network-only',
    })
    .then((result) => result?.data?.getRepair)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * get partial repair data after a workStep is updated
 * @param id string
 */
export const getRepairPriceAndWorkload = (repairCode: string) => {
  return client
    .query<getRepairPriceAndWorkloadI, getRepairPriceAndWorkloadVariables>({
      query: GET_REPAIR_PRICE_AND_WORKLOAD,
      variables: { repairCode },
      fetchPolicy: 'network-only',
    })
    .then((result) => result?.data?.getRepair)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * checks if the user has a sufficient skillLevel
 * @param repairLevel string
 * @param userLevel string
 */
export const checkUserSkillLevel = (
  repairLevel?: SkillLevel,
  userLevel?: SkillLevel
): boolean => {
  // one of the values is not given
  if (!repairLevel || !userLevel) {
    console.error(
      'repairLevel or userLevel undefined',
      'repairLevel: ',
      repairLevel,
      'userLevel: ',
      userLevel
    );
    return false;
  }

  // userLevel is too low
  if (getNumberFromString(userLevel) <= getNumberFromString(repairLevel))
    return false;

  // userLevel is good
  return true;
};

/**
 * Checks if the user the current mechanic
 * @param userId string
 * @param mechanicId string
 */
export const checkIfUserIsCurrentMechanic = (
  userId: string,
  mechanicId: string
): boolean => {
  return userId === mechanicId;
};

/**
 * Updates repair
 * @param data object
 */
export const updateRepair = (data: UpdateRepair) => {
  return client
    .mutate<updateRepairI, updateRepairVariables>({
      mutation: UPDATE_REPAIR,
      variables: { data: data },
    })
    .then((result) => result?.data?.updateRepair)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * Checks if a repair is in the expected status
 * @param repairStatus string
 * @param expected string | array of strings
 */
export const checkRepairStatus = (
  repairStatus: string,
  expected: string | string[]
): boolean => {
  if (Array.isArray(expected)) {
    return expected.includes(repairStatus);
  }

  return repairStatus === expected;
};

/**
 * updates a workStep by ID
 * @param id string
 * @param data object
 */
export const updateWorkStep = (data: UpdateWorkStep) => {
  return client
    .mutate<updateWorkStepI, updateWorkStepVariables>({
      mutation: UPDATE_WORKSTEP,
      variables: { data },
    })
    .then((result) => {
      return result?.data?.updateWorkStep;
    })
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * updates a workStepDone by ID
 * @param id string
 * @param data object
 */
export const updateWorkStepDone = (id: string, isDone: boolean) => {
  return client
    .mutate<setWorkStepIsDone, setWorkStepIsDoneVariables>({
      mutation: UPDATE_WORKSTEP_IS_DONE,
      variables: { data: { id: id, isDone: isDone } },
    })
    .then((result) => result?.data?.updateWorkStep)
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * updates a productQuantity by ID
 * @param data UpdateProductQuantity
 */
export const updateProductQuantity = (data: UpdateProductQuantity) => {
  return client
    .mutate<updateProductQuantityI, updateProductQuantityVariables>({
      mutation: UPDATE_PRODUCT_QUANTITY,
      variables: { data },
    })
    .then((result) => {
      return result?.data?.updateProductQuantity;
    })
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * creates a new workStep
 * @param repairCode string
 * @param data workStepData
 */
export const createWorkStep = (data: CreateWorkStep) => {
  return client
    .mutate<createWorkStepI, createWorkStepVariables>({
      mutation: CREATE_WORKSTEP,
      variables: { data: data },
    })
    .then((result) => {
      return result?.data?.createWorkStep;
    })
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};

/**
 * deletes a new workStep
 * @param id workStep id
 */
export const deleteWorkStep = (id: string) => {
  return client
    .mutate<deleteWorkStepI, deleteWorkStepVariables>({
      mutation: DELETE_WORKSTEP,
      variables: { id },
    })
    .then((result) => {
      return result?.data?.deleteWorkStep;
    })
    .catch((e: ApolloError) => {
      // exclude 409
      if (e.graphQLErrors[0]?.extensions?.statusCode !== 409) {
        throw e;
      }
      console.error('e', e);
      throw e;
    });
};

/**
 * updates the location of the repair
 * @param repairCode string
 * @param locationId string
 */
export const updateRepairLocation = (repairId: string, locationId: string) => {
  return client
    .mutate<updateRepairI, updateRepairVariables>({
      mutation: UPDATE_REPAIR,
      variables: { data: { id: repairId, locationId } },
    })
    .then((result) => {
      return result?.data?.updateRepair;
    })
    .catch((e) => {
      console.error('e', e);
      throw e;
    });
};
