import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';

import { ProductQuantityStatus } from '../../types/graphql-global-types';
import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      minWidth: 90,
      color: theme.palette.primary.main,
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(0, 1),
    },
    canceled: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  })
);

export interface RepairProductStatusChipProps {
  className?: string;
  status: ProductQuantityStatus;
}

const RepairProductStatusChip: React.FC<React.PropsWithChildren<RepairProductStatusChipProps>> = ({
  status,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.base, {
        [classes.canceled]: status === ProductQuantityStatus.CANCELED,
        [className]: !!className,
      })}
      size="small"
      label={<Typo t={`model.product.status.${status}`} />}
      color="secondary"
    />
  );
};

RepairProductStatusChip.propTypes = {
  status: PropTypes.oneOf<ProductQuantityStatus>([
    ProductQuantityStatus.OPEN,
    ProductQuantityStatus.AVAILABLE,
    ProductQuantityStatus.ORDERED,
    ProductQuantityStatus.INSTALLED,
    ProductQuantityStatus.CANCELED,
    ProductQuantityStatus.INVOICED,
  ]).isRequired,
  className: PropTypes.string,
};
RepairProductStatusChip.defaultProps = {
  className: '',
};

export default RepairProductStatusChip;
