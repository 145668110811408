import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import Grid from '@mui/material/Grid';

import Typo from '../../../../../Typo';
import Button from '../../../../../Button';
import RequiredProducts from './RequiredProducts';
import EditWorkStepDialog from '../../../../../Dialog/EditWorkStep';
import { EditWorkStep } from '../../../../../Form/EditWorkStepForm';
import VisibleCheck from '../../../../../VisibleCheck';
import ConfirmationDialog from '../../../../../Dialog/ConfirmationDialog';
import WYSIWYGDialog from '../../../../../Dialog/WYSISWYG';
import { getRepair_getRepair_workSteps } from '../../../../../../gql/repair/types/getRepair';
import { RepairProcessingMachineContext } from '../../../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:before': {
        display: 'none',
      },
    },
    accordion: {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(2, 0),
    },
    done: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    left: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    aw: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
    },
    cancel: {
      color: 'red',
      borderColor: 'red',
      '&:hover': {
        color: 'red',
        borderColor: 'red',
      },
    },
  })
);

export interface WorkStepProps {
  data: getRepair_getRepair_workSteps;
  editState: boolean;
  isLoading: boolean;
  isManager: boolean;
  canceled: boolean;
  invoiced: boolean;
  workDone: boolean;
}

const WorkStep: React.FC<React.PropsWithChildren<WorkStepProps>> = ({
  data,
  editState,
  isLoading,
  isManager,
  canceled,
  invoiced,
  workDone,
}) => {
  const classes = useStyles();
  const [, transition] = RepairProcessingMachineContext();

  const [open, setOpen] = React.useState<boolean>(!data.isDone);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [editWorkStep, setEditWorkStep] = React.useState<boolean>(false);
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);

  const handleDelete = () => {
    setConfirmDelete(true);
  };

  const onDeleteConfirmed = () => {
    transition('DELETE_WORKSTEP', { id: data.id });
    setConfirmDelete(false);
  };

  const handleSetDone = (value: boolean) => {
    transition('UPDATE_WORKSTEP_DONE', { id: data.id, isDone: value });
    setOpen(!value);
  };

  const handleEdit = (workStep: EditWorkStep) => {
    transition('UPDATE_WORKSTEP', {
      id: data.id,
      data: { setWorkload: parseInt(workStep?.setWorkload) },
    });
    setEditWorkStep(false);
  };

  const handleAddWorkStepNotes = (notes: RawDraftContentState) => {
    transition('UPDATE_WORKSTEP_NOTES', {
      id: data.id,
      data: { notes: notes },
    });
    setOpenNotes(false);
  };

  const getNotesBadge = () => {
    if (canceled || invoiced || workDone || !data?.notes) return undefined;

    if (data?.notes?.blocks?.length === 1 && !data?.notes?.blocks?.[0]?.text)
      return undefined;

    return <b>!</b>;
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmDelete}
        onConfirmed={onDeleteConfirmed}
        onDeclined={() => setConfirmDelete(false)}
        message="app.repairProcessing.content.confirmDeleteMessage"
      />
      {editWorkStep ? (
        <EditWorkStepDialog
          workStep={data as unknown as EditWorkStep}
          onClose={() => setEditWorkStep(false)}
          onSubmit={handleEdit}
        />
      ) : null}
      <WYSIWYGDialog
        open={openNotes}
        readOnly={data.isDone || canceled || invoiced || workDone}
        onSubmit={handleAddWorkStepNotes}
        onCancel={() => setOpenNotes(false)}
        initialContent={data.notes}
      />

      <Accordion
        classes={{ root: classes.root }}
        expanded={open || !data.isDone || invoiced || canceled}
        elevation={0}
        onChange={() => setOpen((prev) => !prev)}
        className={`${classes.accordion} ${data.isDone ? classes.done : ''}`}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.box}>
            <Typography variant="h6">
              {data?.workStepDefinition?.name}
            </Typography>
            <div className={classes.left}>
              <VisibleCheck
                isVisible={data.isDone && !workDone && !canceled && !invoiced}
              >
                <Button
                  t="app.repairProcessing.content.undoLabel"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSetDone(false)}
                  disabled={!editState}
                />
              </VisibleCheck>
              <Typo
                align="right"
                t="app.repairProcessing.content.workLoad"
                values={{ aw: data.setWorkload }}
                className={classes.aw}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.container}>
            {/* CHECKLIST and DESCRIPTION */}

            <RequiredProducts
              workStep={data.id}
              products={data.requiredProducts}
              editState={editState}
              disableActions={isLoading || data.isDone}
            />

            {/* {data?.description ? (
              <Typography>{data?.description}</Typography>
            ) : null} */}

            <Grid container spacing={2}>
              <Grid item sm={12} md={7}>
                {isManager && !canceled && !invoiced ? (
                  <Button
                    className={`${classes.button} ${classes.cancel}`}
                    variant="outlined"
                    t="app.repairProcessing.content.deleteLabel"
                    onClick={() => handleDelete()}
                    disabled={workDone}
                  />
                ) : null}
                <Badge
                  color="error"
                  badgeContent={getNotesBadge()}
                  className={classes.button}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    t="app.repairProcessing.content.notes"
                    onClick={() => setOpenNotes(true)}
                  />
                </Badge>
                <Button
                  className={`${classes.button}`}
                  variant="outlined"
                  color="primary"
                  t="app.repairProcessing.content.editLabel"
                  onClick={() => setEditWorkStep(true)}
                  disabled={isLoading || !editState || data.isDone}
                />
              </Grid>
              <Grid item sm={12} md={5}>
                <VisibleCheck isVisible={editState}>
                  <Box display="flex" flex={1} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      t={
                        data.requiredProducts?.length
                          ? 'app.repairProcessing.content.doneAndInstalledLabel'
                          : 'app.repairProcessing.content.doneLabel'
                      }
                      onClick={() => handleSetDone(true)}
                      disabled={isLoading || !editState || data.isDone}
                    />
                  </Box>
                </VisibleCheck>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

WorkStep.propTypes = {
  editState: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  canceled: PropTypes.bool.isRequired,
  workDone: PropTypes.bool.isRequired,
};
WorkStep.defaultProps = {};

export default WorkStep;
