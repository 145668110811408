import { gql } from '@apollo/client';

export const CHECKOUT = gql`
  mutation checkout($paymentMethod: PaymentMethod!, $customerOrderId: Float!) {
    checkout(paymentMethod: $paymentMethod, customerOrderId: $customerOrderId) {
      invoice_id
      invoice_mode
      invoice_timestamp
      invoice_payment
      invoice_total
      invoice_number
    }
  }
`;
