import { assign } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import { getRepairTotal, getRepairSkillLevel } from '../helpers';
import { RepairContext, RepairEvent } from '../types';

export {
  addWorkStep,
  removeWorkStep,
  addWorkStepProduct,
  removeWorkStepProduct,
  updateWorkStepProductCount,
  updateWorkStepNotes,
  updateWorkStepProductStatus,
} from './workStep';

export {
  updateWorkLoad,
  updateWorkLoadSum,
  updateWorkLoadPrice,
} from './workload';

export { completeRepair, unCompleteRepair, removeRepair } from './subOrder';

export const updateBike = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_BIKE');

    return {
      ...ctx.data,
      bike: e.data,
    };
  },
});

export const updateSkillLevel = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'SET_SKILLLEVEL');

    return {
      ...ctx.data,
      skillLevel: e.skillLevel,
    };
  },
});

export const updatePickUpDate = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'SET_EXPECTED_PICKUP_DATE');

    return {
      ...ctx.data,
      estimatedPickupDate: e.estimatedPickupDate,
    };
  },
});

export const updateRepairTotal = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext) => {
    const { workSteps, workloadPrice } = ctx.data;
    return {
      ...ctx.data,
      price: getRepairTotal(workSteps, workloadPrice),
    };
  },
});

export const updateRepairSkillLevel = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext) => {
    return {
      ...ctx.data,
      skillLevel: getRepairSkillLevel(ctx.data.workSteps),
    };
  },
});

export const IsValidating = assign<RepairContext, RepairEvent>({
  isValidating: true,
});

export const IsValidatingDone = assign<RepairContext, RepairEvent>({
  isValidating: false,
});

export const updateValidatedData = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'done.invoke.validateRepair');

    return {
      ...ctx.data,
      ...e.data,
    };
  },
});

export const updateNotes = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_NOTES');

    return {
      ...ctx.data,
      notes: e.notes,
    };
  },
});
