import React from 'react';
import PropTypes from 'prop-types';

import DialogBase from '..';
import EditProductQuantityStatusForm, {
  EditedProduct,
} from '../../Form/EditProductQuantityStatusForm';
import { getRepair_getRepair_workSteps_requiredProducts } from '../../../gql/repair/types/getRepair';

export interface EditProductQuantityStatusDialogProps {
  product: getRepair_getRepair_workSteps_requiredProducts;
  onClose: () => void;
  onSubmit: (data: EditedProduct) => void;
}

const EditProductQuantityStatusDialog: React.FC<React.PropsWithChildren<EditProductQuantityStatusDialogProps>> = ({ product, onClose, onSubmit }) => {
  return (
    <DialogBase
      title="app.dialog.editProduct.title"
      maxWidth="sm"
      fullWidth
      open={!!product}
      onClose={onClose}
      isForm
    >
      <EditProductQuantityStatusForm
        onClose={onClose}
        onSubmit={onSubmit}
        product={product}
      />
    </DialogBase>
  );
};

EditProductQuantityStatusDialog.propTypes = {
  // product: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
EditProductQuantityStatusDialog.defaultProps = {};

export default EditProductQuantityStatusDialog;
