import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { CustomerOrderStatus } from '../../../types/graphql-global-types';
import Button from '../../../components/Button';
import Typo from '../../../components/Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })
);

export interface CustomerOrderButtonsProps {
  status?: CustomerOrderStatus;
  invoiceId?: string;
  onHandleCheckout: () => void;
  onHandleAcceptCostEstimation: () => void;
  onOpenPrintDialog: () => void;
  onOpenConfirmCancel: () => void;
  onOpenSMSDialog: () => void;
  disableSMS: boolean;
}

const CustomerOrderButtons: React.FC<React.PropsWithChildren<CustomerOrderButtonsProps>> = ({
  status = undefined,
  invoiceId = undefined,
  onHandleCheckout,
  onHandleAcceptCostEstimation,
  onOpenConfirmCancel,
  onOpenPrintDialog,
  onOpenSMSDialog,
  disableSMS,
}) => {
  const classes = useStyles();
  if (status === CustomerOrderStatus.CANCELED) {
    return null;
  }

  if (status === CustomerOrderStatus.INVOICED && invoiceId) {
    return (
      <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://myhellocash.com/intern/cash-register/invoice/print?iid=${invoiceId}`}
        onClick={() => {
          window.open(
            `https://myhellocash.com/intern/cash-register/invoice/print?iid=${invoiceId}`,
            'Windows',
            'width=500,height=1000,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no'
          );
        }}
      >
        <Typo t="page.customerOrder.buttons.printInvoice" variant="button" />
      </a>
    );
  }

  if (status === CustomerOrderStatus.COSTESTIMATION) {
    return (
      <>
        <Box color="red" marginRight={2}>
          <Button
            variant="outlined"
            color="inherit"
            t="page.customerOrder.buttons.cancel"
            onClick={onOpenConfirmCancel}
          />
        </Box>
        <Box marginRight={2}>
          <Button
            variant="outlined"
            color="primary"
            t="app.customerOrderDraft.overview.print"
            onClick={onOpenPrintDialog}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          t="page.customerOrder.buttons.acceptCostEstimation"
          onClick={onHandleAcceptCostEstimation}
        />
      </>
    );
  }

  return (
    <>
      <Box color="red" marginRight={2}>
        <Button
          variant="outlined"
          color="inherit"
          t="page.customerOrder.buttons.cancel"
          onClick={onOpenConfirmCancel}
        />
      </Box>
      <Box marginRight={2}>
        <Button
          variant="outlined"
          color="primary"
          t="app.customerOrderDraft.overview.print"
          onClick={onOpenPrintDialog}
        />
      </Box>
      <Box marginRight={2}>
        <Button
          variant="outlined"
          color="primary"
          t="app.customerOrderDraft.overview.sms"
          onClick={onOpenSMSDialog}
          disabled={disableSMS}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        t="page.customerOrder.buttons.helloCash"
        onClick={onHandleCheckout}
      />
    </>
  );
};

CustomerOrderButtons.propTypes = {
  status: PropTypes.oneOf<CustomerOrderStatus>([
    CustomerOrderStatus.CANCELED,
    CustomerOrderStatus.INVOICED,
    CustomerOrderStatus.OPEN,
    CustomerOrderStatus.COSTESTIMATION,
  ]),
  invoiceId: PropTypes.string,
  onHandleCheckout: PropTypes.func.isRequired,
  onHandleAcceptCostEstimation: PropTypes.func.isRequired,
  onOpenConfirmCancel: PropTypes.func.isRequired,
  onOpenPrintDialog: PropTypes.func.isRequired,
  onOpenSMSDialog: PropTypes.func.isRequired,
  disableSMS: PropTypes.bool.isRequired,
};
CustomerOrderButtons.defaultProps = {
  status: undefined,
  invoiceId: undefined,
};

export default CustomerOrderButtons;
