import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useQuery, useReactiveVar } from '@apollo/client';

import { ME } from '../../../gql/user/queries';
import { me } from '../../../gql/user/types/me';
import { Role } from '../../../types/graphql-global-types';
import DrawerContent from './DrawerContent';
import { DRAWER_WIDTH, DRAWER_WIDTH_MOBILE } from '../../../lib/constants';
import AppBarContent from './AppBarContent';
import { isLoggedInVar } from '../../../lib/apolloClient/cache';
import { getLocation } from '../../../gql/location/types/getLocation';
import { GET_LOCATION } from '../../../gql/location/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      // minHeight: 64,
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up('md')]: {
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
        marginLeft: DRAWER_WIDTH,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: DRAWER_WIDTH_MOBILE,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      [theme.breakpoints.up('md')]: {
        width: DRAWER_WIDTH_MOBILE,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export interface PageNavigationProps {
  page: string;
  title: string;
  titleParams?: Record<string, any>;
}

const PageNavigation: React.FC<
  React.PropsWithChildren<PageNavigationProps>
> = ({ page, title, titleParams }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const { loading: userLoading, data: userData } = useQuery<me>(ME);

  const { data: locationData, loading: locationLoading } =
    useQuery<getLocation>(GET_LOCATION);

  const isManager = userData?.me?.role === Role.MANAGER;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {isLoggedIn ? (
            <IconButton
              data-test="appBar-burger-menu"
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <AppBarContent
            title={title}
            titleParams={titleParams}
            user={userData?.me}
            location={locationData?.getLocation}
            loading={userLoading || locationLoading}
            isLoggedIn={isLoggedIn}
          />
        </Toolbar>
      </AppBar>
      {isLoggedIn ? (
        <nav className={classes.drawer}>
          {/* mobile nav */}
          <Hidden mdUp>
            <Drawer
              data-test="drawer-mobile"
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {!userLoading ? (
                <DrawerContent
                  isManager={isManager}
                  page={page}
                  isMobile
                  handleDrawerToggle={handleDrawerToggle}
                />
              ) : null}
            </Drawer>
          </Hidden>

          {/* Desktop */}
          <Hidden mdDown>
            <Drawer
              data-test="drawer-desktop"
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {!userLoading ? (
                <DrawerContent isManager={isManager} page={page} />
              ) : null}
            </Drawer>
          </Hidden>
        </nav>
      ) : null}
    </>
  );
};

PageNavigation.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
PageNavigation.defaultProps = {};

export default PageNavigation;
