import React from 'react';

import PageContainer from '../../components/PageContainer';
import { NotesProvider } from './NotesContext';
import NotesTabs from './NotesTabs';
import NotesContainer from './NotesContainer';

export interface NotesProps {}

const Notes: React.FC<React.PropsWithChildren<NotesProps>> = () => {
  return (
    <PageContainer page="notes">
      <NotesProvider>
        <NotesTabs />
        <NotesContainer />
      </NotesProvider>
    </PageContainer>
  );
};

Notes.propTypes = {};
Notes.defaultProps = {};

export default Notes;
