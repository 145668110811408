import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import DialogBase from '..';
import Typo from '../../Typo';
import {
  CREATE_WORKSTEP_DEFINITION,
  UPDATE_WORKSTEP_DEFINITION,
} from '../../../gql/workStep/mutations';
import { UPDATE_PRODUCT_QUANTITY } from '../../../gql/product/mutations';
import {
  CreateProductQuantity,
  SkillLevel,
} from '../../../types/graphql-global-types';
import { ProductQty } from '../UpdateWorkStepDefinitionProducts/Products';
import WorkStepDefinitionForm from '../../Form/WorkStepDefinitionForm';
import UpdateWorkStepDefinitionProducts from '../UpdateWorkStepDefinitionProducts';

export interface ProposedProductCategory {
  id: string;
  name: string;
}
export interface WorkStep {
  id: string;
  isActive: boolean;
  name: string;
  description?: string | null;
  sequence?: number | null;
  color?: string | null;
  expectedWorkload: number;
  proposedProducts?: ProductQty[] | null;
  skillLevel: SkillLevel;
  checklist?: any | null;
  categories: string[];
  proposedProductCategory: ProposedProductCategory;
}

export interface WorkStepDefinitionProps {
  open: boolean;
  parentCategory?: string | null;
  workStepDefinition?: WorkStep;
  onCreated: () => void;
  onClose: () => void;
}

const WorkStepDefinition: React.FC<React.PropsWithChildren<WorkStepDefinitionProps>> = ({
  open,
  parentCategory,
  workStepDefinition,
  onCreated,
  onClose,
}) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [workStep, setWorkStep] = React.useState<WorkStep>();

  /**
   * Create WorkStepDefinition mutation
   */
  const [createWorkStepDefinition, { loading }] = useMutation(
    CREATE_WORKSTEP_DEFINITION,
    {
      onCompleted: (data) => {
        setWorkStep(data.createWorkStepDefinition);
        setActiveStep(1);
      },
      onError: () => {},
    }
  );

  /**
   * Update WorkStepDefinition mutation
   */
  const [updateWorkStepDefinition, { loading: updating }] = useMutation(
    UPDATE_WORKSTEP_DEFINITION,
    {
      onCompleted: (data) => {
        setWorkStep({ ...workStep, ...data.updateWorkStepDefinition });
        setActiveStep(1);
      },
      onError: () => {},
    }
  );

  /**
   * Update ProductQuanitity mutation
   */
  const [updateProductQuantity, { loading: productUpdating }] = useMutation(
    UPDATE_PRODUCT_QUANTITY,
    {
      onCompleted: (data) => {
        const updated = data.updateProductQuantity;

        // search for updated productQuantity id and update it
        const proposedProducts = workStep?.proposedProducts?.map(
          (product: any) =>
            product.id === updated.id
              ? { ...product, quantity: updated.quantity }
              : product
        );
        if (workStep && proposedProducts) {
          setWorkStep({ ...workStep, proposedProducts: proposedProducts });
        }
      },
      onError: () => {},
    }
  );

  /**
   * handles the form submit. creates new or updates existing workStepDefinition
   * @param workStep workstep
   */
  const handleSubmit = (workStep: any) => {
    const data = {
      ...workStep,
      expectedWorkload: parseInt(workStep.expectedWorkload),
    };

    if (!!workStepDefinition) {
      // update workStepDefinition
      updateWorkStepDefinition({ variables: { data } });
      return;
    }
    // create workStepDefinition
    createWorkStepDefinition({ variables: { data } });
  };

  /**
   * adds a new ProposedProduct to WorkStepDefinition
   * @param product CreateProductQuantity instance
   */
  const handleAddProduct = (product: CreateProductQuantity) => {
    updateWorkStepDefinition({
      variables: {
        data: { id: workStep?.id, addProposedProducts: [product] },
      },
    });
  };

  /**
   * updates the quantity of a productQuantity
   * @param id string productQuantity id
   * @param quantity int new quantity
   */
  const handleUpdateProduct = (id: string, quantity: number) => {
    updateProductQuantity({ variables: { data: { id, quantity } } });
  };

  /**
   * removes a proposedProduct by id
   * @param product string
   */
  const handleRemoveProduct = (product: string) => {
    updateWorkStepDefinition({
      variables: {
        data: { id: workStep?.id, removeProposedProductIDs: [product] },
      },
    });
  };

  /**
   * If the modal is opened with a WorkStepDefiniion its used to update the definition and not to create a new one
   */
  React.useEffect(() => {
    if (open && !!workStepDefinition) {
      setWorkStep(workStepDefinition);
    }
  }, [open, workStepDefinition]);

  return (
    <DialogBase
      fullWidth
      scroll="paper"
      maxWidth={activeStep === 0 ? 'sm' : 'lg'}
      open={open}
      onClose={!workStep ? onClose : onCreated}
      title={
        workStep
          ? 'app.dialog.workStepDefinition.update.title'
          : 'app.dialog.workStepDefinition.add.title'
      }
      isLoading={loading || updating || productUpdating}
      keepMounted={false}
      primaryActionLabel={
        activeStep === 0 ? 'app.common.defaultPrimary' : 'app.common.done'
      }
      primaryType={activeStep === 0 ? 'submit' : 'button'}
      primaryForm={activeStep === 0 ? 'workStepDefinition-form' : undefined}
      primaryAction={activeStep === 0 ? () => null : onCreated}
      secondaryAction={onClose}
    >
      <Stepper alternativeLabel activeStep={activeStep}>
        <Step>
          {/* UPDATE FIX */}
          <StepButton
          // onClick={() => setActiveStep(0)}
          //  completed={false}
          >
            <Typo
              t={
                workStep
                  ? 'app.dialog.workStepDefinition.update.steps.workStep'
                  : 'app.dialog.workStepDefinition.create.steps.workStep'
              }
            />
          </StepButton>
        </Step>
        <Step>
          {/* UPDATE FIX */}
          <StepButton
            onClick={() => setActiveStep(1)}
            // completed={false}
            // disabled={!workStep}
          >
            <Typo t="app.dialog.workStepDefinition.create.steps.proposedProducts" />
          </StepButton>
        </Step>
      </Stepper>

      {activeStep === 0 ? (
        <Box p={2} bgcolor="background.default" borderRadius={1}>
          <WorkStepDefinitionForm
            parentCategory={parentCategory || ''}
            workStep={workStep}
            onSubmit={handleSubmit}
          />
        </Box>
      ) : null}

      {activeStep === 1 ? (
        <UpdateWorkStepDefinitionProducts
          products={workStep?.proposedProducts}
          addProduct={handleAddProduct}
          updateProduct={handleUpdateProduct}
          removeProduct={handleRemoveProduct}
        />
      ) : null}
    </DialogBase>
  );
};

WorkStepDefinition.propTypes = {
  parentCategory: PropTypes.string,
  // workstep FIXME: PropType
  onCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
WorkStepDefinition.defaultProps = {};

export default WorkStepDefinition;
