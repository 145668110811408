import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import FormContainer from '..';
import FormWYSIWYG from '../Components/FormWYSIWYG';
import { CreateNoteInput } from '../../../types/graphql-global-types';
import FormCustomer from '../Components/FormCustomer';
import FormAccordion, {
  FormAccordionDetails,
  FormAccordionSummary,
} from '../Components/FormAccordion';
import Typo from '../../Typo';

export interface CreateNoteFormProps {
  onSubmit: (data: CreateNoteInput) => void;
}

const CreateNoteForm: React.FC<React.PropsWithChildren<CreateNoteFormProps>> = ({ onSubmit }) => {
  return (
    <FormContainer onSubmit={onSubmit} id="createNote-form">
      <FormWYSIWYG name="content" />

      {/* CustomerOrder */}

      {/* Customer */}
      <Box mt={1}>
        <FormAccordion>
          <FormAccordionSummary id="customerId">
            <Typo t="model.customer" />
          </FormAccordionSummary>
          <FormAccordionDetails>
            <Box display="flex" flex={1}>
              <FormCustomer name="customerId" />
            </Box>
          </FormAccordionDetails>
        </FormAccordion>
      </Box>
    </FormContainer>
  );
};

CreateNoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
CreateNoteForm.defaultProps = {};

export default CreateNoteForm;
