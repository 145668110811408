import React from 'react';

import PageContainer from '../../components/PageContainer';
import CustomerOrderDraft from '../../components/CustomerOrderDraft';

export interface NewCustomerOrderPageProps {}

const NewCustomerOrderPage: React.FC<React.PropsWithChildren<NewCustomerOrderPageProps>> = () => {
  return (
    <PageContainer page="newOrder">
      <CustomerOrderDraft />
    </PageContainer>
  );
};

NewCustomerOrderPage.propTypes = {};
NewCustomerOrderPage.defaultProps = {};

export default NewCustomerOrderPage;
