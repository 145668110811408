import { WorkStep } from '../../components/Dialog/WorkStepDefinition';

export interface Product {
  id: string;
  name: string;
  manufacturer: string;
  description: string;
  price: number;
  // categoryId: string;
  isActive: boolean;
  sequence?: number;
  color?: string;
}

export interface Category {
  id: string;
  name: string;
  subCategories?: Category[];
  products?: Product[];
  sequence?: number;
  color?: string | null;
  isActive: boolean;
  description: string | null;
  workSteps?: WorkStep[];
}

/**
 * Called to update the category tree after detail information of a category was fetched.
 * Recursive function that searches updated category in tree and updates it.
 * @param categoryData Category
 */
export const handleCategoryUpdate = (
  categoryData: Category,
  setTreeData: any,
  setSelectedCategory: any
) => {
  const getUpdatedCategoryTree = (
    tree: Category[],
    detailCategory: Category
  ) => {
    return tree.map(function checkCategory(c: Category): any {
      if (c.id === detailCategory.id) {
        return { ...c, ...detailCategory };
      }

      // check for subCategories
      if (c.subCategories?.length) {
        return {
          ...c,
          subCategories: c.subCategories.map(checkCategory),
        };
      }

      return c;
    });
  };

  // call recursive tree update
  setTreeData((pref: any) => getUpdatedCategoryTree(pref, categoryData));

  // set the updatedCategory as the currently selected category
  setSelectedCategory(categoryData);
};
