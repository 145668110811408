import { send } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import { ProductOrderContext, ProductOrderEvent } from '../types';

export const completeOrder = send<ProductOrderContext, ProductOrderEvent>(
  (ctx: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'COMPLETE_ORDER');

    return {
      type: 'SUBORDER_COMPLETE_ORDER',
      id: e.id,
      data: ctx.data,
    };
  },
  { to: 'subOrdersMachine' }
);

export const unCompleteOrder = send<ProductOrderContext, ProductOrderEvent>(
  (_: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'EDIT_ORDER');

    return {
      type: 'SUBORDER_UNCOMPLETE_ORDER',
      id: e.id,
    };
  },
  { to: 'subOrdersMachine' }
);

export const removeOrder = send<ProductOrderContext, ProductOrderEvent>(
  (_: ProductOrderContext, e: ProductOrderEvent) => {
    assertEventType(e, 'REMOVE_ORDER');

    return {
      type: 'SUBORDER_REMOVE_ORDER',
      id: e.id,
    };
  },
  { to: 'subOrdersMachine' }
);
