import { assign } from 'xstate';
import assertEventType from '../../../../../../lib/helper/xstateHelper';
import { CreateWorkStep } from '../../../orderDraftMachine/types';
import { getWorkLoadSum, getWorkloadPrice } from '../helpers';
import { RepairContext, RepairEvent } from '../types';

export const updateWorkLoad = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext, e: RepairEvent) => {
    assertEventType(e, 'UPDATE_WORKLOAD');

    return {
      ...ctx.data,
      workSteps: ctx.data.workSteps.map((workstep: CreateWorkStep) => {
        // get updatedworkSteps
        return workstep.id === e.id
          ? { ...workstep, setWorkload: e.workload }
          : workstep;
      }),
    };
  },
});

export const updateWorkLoadSum = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext) => {
    return {
      ...ctx.data,
      workloadSum: getWorkLoadSum(ctx.data.workSteps),
    };
  },
});

export const updateWorkLoadPrice = assign<RepairContext, RepairEvent>({
  data: (ctx: RepairContext) => {
    return {
      ...ctx.data,
      workloadPrice: getWorkloadPrice(
        ctx.data.workloadSum,
        ctx.data.workloadRate
      ),
    };
  },
});
