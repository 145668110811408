import React from 'react';

import WorkStep from './WorkStep';
import { RepairStatus, Role } from '../../../../../types/graphql-global-types';
import {
  getRepair_getRepair,
  getRepair_getRepair_workSteps,
} from '../../../../../gql/repair/types/getRepair';
import { me_me } from '../../../../../gql/user/types/me';

export interface WorkStepsProps {
  repairData?: getRepair_getRepair;
  user?: me_me;
  editState: boolean;
  isLoading: boolean;
}

const WorkSteps: React.FC<React.PropsWithChildren<WorkStepsProps>> = ({
  repairData,
  editState,
  isLoading,
  user,
}) => {
  const steps = repairData?.workSteps;
  const isManager = user?.role === Role.MANAGER || false;
  const canceled = repairData?.status === RepairStatus.CANCELED;
  const invoiced = repairData?.status === RepairStatus.INVOICED;
  const workDone = repairData?.status === RepairStatus.WORK_DONE;

  return (
    <>
      {steps?.map((step: getRepair_getRepair_workSteps) => (
        <WorkStep
          key={step.id}
          data={step}
          editState={editState}
          isLoading={isLoading}
          isManager={isManager}
          canceled={canceled}
          invoiced={invoiced}
          workDone={workDone}
        />
      ))}
    </>
  );
};

WorkSteps.propTypes = {};
WorkSteps.defaultProps = {};

export default WorkSteps;
