import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import { Redirect } from 'react-router-dom';

import PageContainer from '../../components/PageContainer';
import TextField from '../../components/Form/Components/FormTextField';
import FormContainer from '../../components/Form/index';
import { LOGIN_USER } from '../../gql/auth/mutations';
import { handleAuth } from '../../lib/auth';
import logo from '../../assets/img/logo.png';
import { identify } from '../../lib/logging';
import { isLoggedInVar } from '../../lib/apolloClient/cache';
import {
  loginMutation,
  loginMutationVariables,
} from '../../gql/auth/types/loginMutation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginPaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    loginImg: {
      height: 150,
    },
    loginActionButton: {
      marginTop: theme.spacing(2),
      width: '70%',
    },
  })
);

// interface LoginData {
//   email: string;
//   password: string;
// }

export interface LoginPageProps {}

const LoginPage: React.FC<React.PropsWithChildren<LoginPageProps>> = () => {
  const classes = useStyles();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const [loginUser, { loading }] = useMutation<
    loginMutation,
    loginMutationVariables
  >(LOGIN_USER, {
    onCompleted(data) {
      identify(data?.login?.user);
      handleAuth(data?.login?.accessToken);
    },
    onError() {}, // needs to be added so apollo doesn't cry about an unhandled rejection. Error handling happens in link tho.
  });

  /**
   * handles user login mutation
   */
  const handleSubmit = (data: loginMutationVariables) => {
    loginUser({
      variables: { email: data.email, password: data.password },
    });
  };

  // should redirect to home if user is logged in
  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  return (
    <PageContainer page="login" loading={loading}>
      <Box
        display="flex"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        marginTop="-64px"
      >
        <Container maxWidth="xs">
          <Grid container direction="column" alignItems="center">
            <Paper elevation={3} className={classes.loginPaper}>
              <FormContainer onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <img src={logo} className={classes.loginImg} alt="" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        'data-test': 'login_email',
                      }}
                      name="email"
                      label="page.login.email"
                      formInputOptions={{
                        required: {
                          value: true,
                          message: 'app.form.errors.required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        'data-test': 'login_pw',
                      }}
                      type="password"
                      name="password"
                      label="page.login.password"
                      formInputOptions={{
                        required: {
                          value: true,
                          message: 'app.form.errors.required',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      data-test="login_submit"
                      disableElevation
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.loginActionButton}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : (
                        <FormattedMessage id="page.login.primaryAction" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </FormContainer>
            </Paper>
          </Grid>
        </Container>
      </Box>
    </PageContainer>
  );
};

LoginPage.propTypes = {};
LoginPage.defaultProps = {};

export default LoginPage;
