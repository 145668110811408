import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ProductQuantityStatus } from '../../types/graphql-global-types';
import Typo from '../Typo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      minWidth: 250,
    },
  })
);

export interface RepairProductStatusSelectProps {
  status: ProductQuantityStatus;
  onChange: (status: ProductQuantityStatus) => void;
  disabled?: boolean;
  availableStati?: ProductQuantityStatus[];
}

const RepairProductStatusSelect: React.FC<React.PropsWithChildren<RepairProductStatusSelectProps>> = ({
  status,
  disabled,
  onChange,
  availableStati = undefined,
}) => {
  const classes = useStyles();

  // set the value as selected
  // UPDATE FIX
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as ProductQuantityStatus);
  };

  if (!availableStati) {
    availableStati = Object.values(ProductQuantityStatus);
  }

  return (
    <Select
      variant="outlined"
      margin="dense"
      value={status}
      onChange={handleChange}
      className={classes.select}
      disabled={disabled}
    >
      {availableStati.map((status: string) => (
        <MenuItem key={status} value={status}>
          <Typo t={`model.product.status.${status}`} />
        </MenuItem>
      ))}
    </Select>
  );
};

RepairProductStatusSelect.propTypes = {
  status: PropTypes.oneOf<ProductQuantityStatus>([
    ProductQuantityStatus.OPEN,
    ProductQuantityStatus.AVAILABLE,
    ProductQuantityStatus.INSTALLED,
    ProductQuantityStatus.ORDERED,
    ProductQuantityStatus.CANCELED,
    ProductQuantityStatus.ORDERED,
  ]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
RepairProductStatusSelect.defaultProps = {
  disabled: false,
};

export default RepairProductStatusSelect;
