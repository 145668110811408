import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useIntl } from 'react-intl';

import DialogBase from '..';

import {
  CustomerOrderStatus,
  RepairStatus,
} from '../../../types/graphql-global-types';
import Typo from '../../Typo';

export interface PauseOrCancelDialogProps {
  type?:
    | RepairStatus.PAUSED
    | RepairStatus.CANCELED
    | CustomerOrderStatus.CANCELED;
  title: string;
  message: string;
  onSuccess: (reason: string) => void;
  onClose: () => void;
}

const PauseOrCancelDialog: React.FC<React.PropsWithChildren<PauseOrCancelDialogProps>> = ({
  type,
  title,
  message,
  onSuccess,
  onClose,
}) => {
  const [reason, setReason] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  const CHAR_LIMIT = 200;

  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const handleSuccess = () => {
    if (!reason?.length) {
      setError('app.dialog.pauseOrCancelDialog.emptyReason');
    } else {
      onSuccess(reason);
      setError(undefined);
      setReason(undefined);
    }
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      open={!!type}
      title={title}
      secondaryAction={onClose}
      primaryAction={handleSuccess}
    >
      <Box
        p={2}
        mb={1}
        bgcolor="background.default"
        borderRadius={1}
      >
        <Box marginBottom={1}>
          <Typo t={message} />
        </Box>
        <FormControl variant="standard" error={!!error ?? false} fullWidth>
          <TextField
            fullWidth
            multiline
            error={!!error ?? false}
            rows={3}
            maxRows={3}
            value={reason}
            onChange={handleChange}
            placeholder="Begründung eingeben"
            variant="outlined"
            inputProps={{
              maxLength: CHAR_LIMIT,
              minLength: 1,
            }}
            style={{ backgroundColor: '#fff' }}
          />
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex">
              <FormHelperText>
                {`${reason?.length ?? '0'} / ${CHAR_LIMIT}`}
              </FormHelperText>
            </Box>
            <Box display="flex">
              <FormHelperText>
                {error ? intl.formatMessage({ id: error }) : undefined}
              </FormHelperText>
            </Box>
          </Box>
        </FormControl>
      </Box>
    </DialogBase>
  );
};

PauseOrCancelDialog.propTypes = {
  // type: PropTypes.oneOf<RepairStatus, CustomerOrderStatus>([
  //   RepairStatus.PAUSED,
  //   RepairStatus.CANCELED,
  //   CustomerOrderStatus.CANCELED
  // ]),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
PauseOrCancelDialog.defaultProps = {};

export default PauseOrCancelDialog;
