import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Typo from '../../../Typo';
import { SelectionType } from '../..';

export interface HistoryObject {
  id: string;
  name: string;
  type: SelectionType;
}

export interface HistoryProps {
  history: HistoryObject[];
  handleHistoryReset: (id: string) => void;
}

const History: React.FC<React.PropsWithChildren<HistoryProps>> = ({ history, handleHistoryReset }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom={2}>
        <Typo
          variant="h6"
          color="primary"
          t="app.dialog.workStepCategory.category"
        />
        <Breadcrumbs aria-label="breadcrumb">
          <HomeIcon />
          {history.length ? (
            history.map((h: HistoryObject, index: number) => (
              <Link
                key={h.id}
                color={index === history.length - 1 ? 'primary' : 'inherit'}
                onClick={() => handleHistoryReset(h.id)}
                underline="hover">
                {index === history.length - 1 ? (
                  <Typography>
                    <b>{h.name}</b>
                  </Typography>
                ) : (
                  <Typography>{h.name}</Typography>
                )}
              </Link>
            ))
          ) : (
            <Typo t="app.dialog.workStepCategory.emptyHistory" />
          )}
        </Breadcrumbs>
      </Box>
    </Box>
  );
};

History.propTypes = {
  handleHistoryReset: PropTypes.func.isRequired,
  // history: PropTypes.arrayOf({
  //   id: PropTypes.string.isRequired,
  //   name: PropTypes.string.isRequired,

  // }).isRequired,
};
History.defaultProps = {};

export default History;
