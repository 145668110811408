import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Typo from '../../../Typo';
import Date from '../../../Date';

export interface CreatedUpdatedDatesProps {
  createdAt?: string;
  updatedAt?: string;
}

const CreatedUpdatedDates: React.FC<React.PropsWithChildren<CreatedUpdatedDatesProps>> = ({
  createdAt,
  updatedAt,
}) => {
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Box marginRight={0.5}>
          <Typo t="app.form.workStepCategory.createdAt" align="right" />
        </Box>
        <Date date={createdAt} format="DD.MM.YYYY" align="right" />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box marginRight={0.5}>
          <Typo t="app.form.workStepCategory.updatedAt" align="right" />
        </Box>
        <Date date={updatedAt} format="DD.MM.YYYY" align="right" />
      </Box>
    </Box>
  );
};

CreatedUpdatedDates.propTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};
CreatedUpdatedDates.defaultProps = {
  createdAt: undefined,
  updatedAt: undefined,
};

export default CreatedUpdatedDates;
