import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import Typo from '../Typo';

export interface NumberUpdateFieldProps {
  label?: string;
  value: number;
  maxValue?: number;
  handleValueDecrease: any;
  handleValueIncrease: any;
  disabled?: boolean;
}

const NumberUpdateField: React.FC<React.PropsWithChildren<NumberUpdateFieldProps>> = ({
  label,
  value,
  maxValue,
  handleValueDecrease,
  handleValueIncrease,
  disabled = false,
}) => {
  return (
    <TextField
      data-test="numberUpdateField"
      label={label ? <Typo t={label} /> : ''}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={handleValueDecrease}
              edge="start"
              disabled={disabled || value === 1}
              color="primary"
              size="large">
              <RemoveIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleValueIncrease}
              edge="end"
              disabled={disabled || maxValue ? value === maxValue : false}
              color="primary"
              size="large">
              <AddIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

NumberUpdateField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  handleValueDecrease: PropTypes.func.isRequired,
  handleValueIncrease: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
NumberUpdateField.defaultProps = {
  maxValue: undefined,
  disabled: false,
};

export default NumberUpdateField;
