import { assign, spawn } from 'xstate';
import dayjs from 'dayjs';

import repairMachine, {
  initialRepair,
} from '../../subOrders/repair/repairMachine';
import {
  SubOrderRepair,
  SubOrdersContext,
  SubOrdersEvent,
  Setting,
} from '../types';
import assertEventType from '../../../../../lib/helper/xstateHelper';
import { RepairMachine } from '../../subOrders/repair/types';

const getFromSettings = (settings: Setting[] | null, setting: string) =>
  settings?.find((s: Setting) => s?.key === setting)?.value;

export const spawnRepair = assign<SubOrdersContext, SubOrdersEvent>({
  repairs: (ctx: SubOrdersContext) => {
    // get WorkloadRate
    const workloadRate = getFromSettings(
      ctx.tenantSettings,
      'defaultWorkloadRate'
    );

    //get defaultRepairTime
    const defaultRepairTime = getFromSettings(
      ctx.tenantSettings,
      'defaultRepairTime'
    );

    // calculate est. pickup date
    const estimatedPickupDate = dayjs()
      .add((defaultRepairTime as number) ?? 0, 'days')
      .startOf('day')
      .format();

    // build repair
    const newRepair: SubOrderRepair = {
      ...initialRepair,
      index: ctx.index,
      ref: spawn(
        repairMachine.withContext({
          ...initialRepair,
          data: {
            ...initialRepair.data,
            workloadRate: !!workloadRate ? +workloadRate : 0,
            estimatedPickupDate: estimatedPickupDate,
          },
        })
      ) as RepairMachine,
    };

    return ctx.repairs.concat(newRepair);
  },
});

export const removeRepair = assign<SubOrdersContext, SubOrdersEvent>({
  repairs: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_REMOVE_REPAIR');

    return ctx.repairs.filter(
      (repair: SubOrderRepair) => repair.ref.id !== e.id
    );
  },
});

export const completeRepair = assign<SubOrdersContext, SubOrdersEvent>({
  repairs: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_COMPLETE_REPAIR');

    return ctx.repairs.map((repair: SubOrderRepair) => {
      return repair.ref.id === e.id
        ? { ...repair, completed: true, data: e.data }
        : repair;
    });
  },
});

export const unCompleteRepair = assign<SubOrdersContext, SubOrdersEvent>({
  repairs: (ctx: SubOrdersContext, e: SubOrdersEvent) => {
    assertEventType(e, 'SUBORDER_UNCOMPLETE_REPAIR');

    return ctx.repairs.map((repair: SubOrderRepair) => {
      return repair.ref.id === e.id ? { ...repair, completed: false } : repair;
    });
  },
});
