import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import DialogBase from '..';
import PrintCustomerOrder from '../../PrintCustomerOrder';
import Typo from '../../Typo';
import SMSNotification from '../../SMSNotification';
import { createCustomerOrder_createCustomerOrder_repairs } from '../../../gql/customerOrder/types/createCustomerOrder';

export interface CreateCustomerOrderProps {
  open: boolean;
  costEstimationPrintRef: any;
  repairs: createCustomerOrder_createCustomerOrder_repairs[];
  customerOrderId: string;
  customerPhoneNumber?: string;
  onClose: () => void;
}

const CreateCustomerOrder: React.FC<React.PropsWithChildren<CreateCustomerOrderProps>> = ({
  open,
  customerOrderId,
  customerPhoneNumber = '',
  costEstimationPrintRef,
  repairs,
  onClose,
}) => {
  const intl = useIntl();

  const DEFAULT_MESSAGE = intl.formatMessage(
    {
      id: 'app.smsNotification.defualtMessage.created',
    },
    { customerOrderId: customerOrderId }
  );

  return (
    <DialogBase
      open={open}
      title="app.dialog.createCustomerOrder.title"
      hideSecondaryAction
      primaryAction={onClose}
      primaryActionLabel="app.common.done"
      secondaryAction={onClose}
    >
      <Box minWidth="450px" minHeight="200px">
        <Box paddingBottom={3}>
          <Typo
            gutterBottom
            variant="h5"
            t="app.dialog.createCustomerOrder.print"
          />
          <PrintCustomerOrder
            costEstimationPrintRef={costEstimationPrintRef}
            repairs={repairs}
          />
        </Box>

        <Typo
          gutterBottom
          variant="h5"
          t="app.dialog.createCustomerOrder.sms"
        />
        <SMSNotification
          phoneNumber={customerPhoneNumber}
          defaultMessage={DEFAULT_MESSAGE}
          customerOrderId={customerOrderId}
        />
      </Box>
    </DialogBase>
  );
};

CreateCustomerOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  customerOrderId: PropTypes.string.isRequired,
  customerPhoneNumber: PropTypes.string,
  costEstimationPrintRef: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};
CreateCustomerOrder.defaultProps = {
  customerPhoneNumber: '',
};

export default CreateCustomerOrder;
