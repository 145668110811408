import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';

import FormContainer from '..';
import FormSwitch from '../Components/FormSwitch';
import TextField from '../Components/FormTextField';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import FormColorPicker from '../Components/FormColorPicker';
import Typo from '../../Typo';
import FormSetValue from '../Components/FormSetValue';

interface Category {
  id: string;
  isActive: boolean;
  name: string;
  color?: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
  isRootCategory: boolean;
  isRepairProductsRootCategory: boolean;
}

export interface ProductCategoryFormProps {
  onSubmit: (data: any) => void;
  category?: Category;
}

const ProductCategoryForm: React.FC<React.PropsWithChildren<ProductCategoryFormProps>> = ({
  onSubmit,
  category,
}) => {
  return (
    <FormContainer onSubmit={onSubmit} id="productCategory-form">
      <FormSetValue name="id" value={category?.id} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <FormSwitch
            name="isActive"
            label={`app.form.workStepCategory.isActive.${
              category?.isActive ? 'true' : 'false'
            }`}
            defaultValue={category?.isActive}
            disabled={category?.isRepairProductsRootCategory}
          />
        </Box>
        <CreatedUpdatedDates
          createdAt={category?.createdAt}
          updatedAt={category?.updatedAt}
        />
      </Box>

      <TextField
        label="app.form.workStepCategory.name"
        name="name"
        defaultValue={category?.name}
        formInputOptions={{}}
        disabled={category?.isRepairProductsRootCategory}
      />

      <TextField
        label="app.form.workStepCategory.description"
        name="description"
        defaultValue={category?.description}
        multiline
        rows={3}
        formInputOptions={{}}
        disabled={category?.isRepairProductsRootCategory}
      />

      <Box>
        <FormColorPicker
          name="color"
          defaultValue={category?.color}
          showPreview
          previewName={category?.name}
          disabled={category?.isRepairProductsRootCategory}
        />
      </Box>

      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Box display="flex" alignItems="center">
          <WarningIcon />
          <Box marginLeft={1}>
            <Typo
              t="app.form.workStepCategory.isActiveHint"
              variant="caption"
            />
          </Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

ProductCategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    isRootCategory: PropTypes.bool.isRequired,
    isRepairProductsRootCategory: PropTypes.bool.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};
ProductCategoryForm.defaultProps = {};

export default ProductCategoryForm;
