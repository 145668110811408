import { gql } from '@apollo/client';

export const GET_REPAIR_PRODUCTS_ROOT_CATEGORY = gql`
  query getRepairProductsRoot {
    getProductCategories(isRepairProductsRootCategory: true) {
      id
      createdAt
      updatedAt
      isRepairProductsRootCategory
      name
      description
      isActive
      color
      sequence
      products {
        id
        isActive
        name
        price
        description
        manufacturer
        # sequence
        # color
      }
      subCategories {
        id
        isActive
        name
        createdAt
        updatedAt
        description
        sequence
        color
      }
    }
  }
`;

export const GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY = gql`
  query getRepairOneTimeProductsRoot {
    getProductCategories(isOneTimeProductsRootCategory: true) {
      id
    }
  }
`;

export const GET_PRODUCT_CATEGORY = gql`
  query getProductCategory($id: String!) {
    getProductCategory(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      sequence
      color
      isActive
      products {
        id
        isActive
        name
        price
        description
        manufacturer
        # sequence
        # color
      }
      subCategories {
        id
        isActive
        name
        createdAt
        updatedAt
        description
        sequence
        color
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: String!) {
    getProduct(id: $id) {
      id
      isActive
      name
      manufacturer
      description
      price
    }
  }
`;

export const GET_PRODUCT_QUANTITIES = gql`
  query getProductQuantities(
    $status: [ProductQuantityStatus!]
    $type: ProductQuantityEntityType
    $offset: Int
    $limit: Int
    $field: ProductQuantitySortField
    $direction: OrderDirection
  ) {
    getProductQuantities(
      status: $status
      type: $type
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
    ) {
      items {
        id
        createdAt
        customerOrderId
        product {
          id
          name
          price
          manufacturer
        }
        quantity
        status
        repairCode
      }
      totalCount
    }
  }
`;
