import React from 'react';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';

import CustomerData from './components/CustomerData';
import CustomerOrderOverview from './components/OrderOverview';
import SubOrders from './components/SubOrders';
import CostEstimation from '../CostEstimation';
import CreateCustomerOrder from '../Dialog/CreateCustomerOrder';
import { CustomerDraftMachinesContext } from '.';
import { useQuery } from '@apollo/client';
import { getLocation } from '../../gql/location/types/getLocation';
import { GET_LOCATION } from '../../gql/location/queries';

export interface OrderDraftProps {}

const OrderDraft: React.FC<React.PropsWithChildren<OrderDraftProps>> = () => {
  const history = useHistory();
  const costEstimateRef = React.useRef<HTMLElement>();

  const { orderDraftMachine } = CustomerDraftMachinesContext();

  const [state] = orderDraftMachine;

  const costEstimationData = state?.context?.costEstimationData;

  const isCostEstimation = state.matches('costEstimateCheck');
  const isCompleted = state.matches('completed');

  const { data } = useQuery<getLocation>(GET_LOCATION);

  const location = data?.getLocation;

  const handleDone = () => {
    history.push('/');
  };

  return (
    <>
      {isCompleted && costEstimationData && (
        <CreateCustomerOrder
          open
          onClose={handleDone}
          repairs={costEstimationData?.repairs}
          costEstimationPrintRef={costEstimateRef}
          customerOrderId={costEstimationData?.id?.toString()}
          customerPhoneNumber={costEstimationData?.customer?.phone || ''}
        />
      )}
      <Grid container spacing={3}>
        <Hidden lgUp>
          <Grid item xs={12} md={12} lg={4}>
            {/* Order Overview */}
            <CustomerOrderOverview printRef={costEstimateRef} />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={12} lg={8}>
          {costEstimationData && (isCostEstimation || isCompleted) ? (
            <CostEstimation
              printRef={costEstimateRef}
              customerOrder={costEstimationData}
              location={location}
            />
          ) : (
            <>
              {/* Customer Data */}
              <CustomerData />

              {/* SubOrders */}
              <SubOrders />
            </>
          )}
        </Grid>
        <Hidden lgDown>
          <Grid item xs={12} md={12} lg={4}>
            {/* Order Overview */}
            <CustomerOrderOverview printRef={costEstimateRef} />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

OrderDraft.propTypes = {};
OrderDraft.defaultProps = {};

export default OrderDraft;
