import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import { CustomerOrderStatus } from '../../../types/graphql-global-types';
import Paper from '../../../components/Paper';
import Typo from '../../../components/Typo';
// import CustomerOrderStatusChip from '../../../components/CustomerOrderStatusChip';
import Date from '../../../components/Date';
import { formattedCurrency } from '../../../lib/helper/numbers';
import CustomerInformation from '../../../components/CustomerInformation';
import CustomerOrderStatusChip from '../../../components/CustomerOrderStatusChip';
import { GET_LOCATION_NAME } from '../../../gql/location/queries';
import { getLocationName as getLocationNameI } from '../../../gql/location/types/getLocationName';
import { getCustomerOrder_getCustomerOrder_customer } from '../../../gql/customerOrder/types/getCustomerOrder';
import EditInvoiceInfoDialog from '../../../components/Dialog/EditInvoiceInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    t: {
      backgroundColor: 'green',
    },
    showLineBreak: {
      whiteSpace: 'pre-line',
    },
  })
);

interface User {
  id: string;
  firstname: string | null;
  lastname: string | null;
}

export interface CustomerOrderOverviewProps {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: CustomerOrderStatus;
  acceptedDate: string | null;
  createdUser: User;
  total: number | null;
  subtotal: number | null;
  taxAmount: number | null;
  customer: getCustomerOrder_getCustomerOrder_customer;
  loading: boolean;
  invoiceId: string | null;
  invoiceDate: string | null;
  paymentMethod: string | null;
  invoiceNumber: string | null;
  invoicedLocationId: string | null;
  invoiceInfo: string | null;
  handleUpdateInvoiceInfo: (invoiceInfo: string | null) => void;
}

const CustomerOrderOverview: React.FC<
  React.PropsWithChildren<CustomerOrderOverviewProps>
> = ({
  id,
  status,
  createdAt,
  updatedAt,
  acceptedDate,
  createdUser,
  total,
  subtotal,
  taxAmount,
  customer,
  loading,
  invoiceId,
  invoiceDate,
  paymentMethod,
  invoiceNumber,
  invoicedLocationId,
  invoiceInfo,
  handleUpdateInvoiceInfo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [editable, setEditable] = React.useState<boolean>(false);
  const [invoicedLocation, setInvoicedLocation] = React.useState<string>();
  const [openEditInvoiceInfo, setOpenEditInvoiceInfo] =
    React.useState<boolean>(false);

  const [getLocationName] = useLazyQuery<getLocationNameI>(GET_LOCATION_NAME, {
    onCompleted: (data) => setInvoicedLocation(data.getLocation.name),
  });

  const handleInvoiceInfoUpdate = (invoiceInfo: string | null) => {
    handleUpdateInvoiceInfo(invoiceInfo);
    setOpenEditInvoiceInfo(false);
  };

  React.useEffect(() => {
    if (invoicedLocationId) {
      getLocationName({ variables: { id: invoicedLocationId } });
    }
  }, [getLocationName, invoicedLocationId]);

  React.useEffect(() => {
    setEditable(
      status !== CustomerOrderStatus.CANCELED &&
        status !== CustomerOrderStatus.INVOICED
    );
  }, [status]);

  return (
    <>
      <EditInvoiceInfoDialog
        open={openEditInvoiceInfo}
        initialContent={invoiceInfo}
        onSubmit={handleInvoiceInfoUpdate}
        onCancel={() => setOpenEditInvoiceInfo(false)}
        title="app.customerOrderDraft.overview.invoiceInfo.label"
      />

      <Paper padding isLoading={loading}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typo variant="h5" t="app.customerOrder.overview.title" />
          {/* add when customerOrder status makes sense */}
          <CustomerOrderStatusChip status={status} />
        </Box>

        {/* Order Creation Section */}
        <Box className={classes.section} p={1}>
          <Typo
            variant="h6"
            t="app.customerOrder.overview.order.created.title"
          />
          <Divider className={classes.divider} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.createdAt" />
            <Date date={createdAt} format="DD. MMMM YYYY HH:mm" />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.createdUser" />
            <Typography align="right">
              {`${createdUser?.firstname ?? '-'}
           ${createdUser?.lastname ?? '-'}`}
            </Typography>
          </Box>
        </Box>

        {/* Order Details Section */}
        <Box className={classes.section} p={1} marginTop={2}>
          <Typo
            variant="h6"
            t="app.customerOrder.overview.order.details.title"
          />
          <Divider className={classes.divider} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.acceptedDate" />
            <Date date={acceptedDate} format="DD. MMMM YYYY HH:mm" />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.total" />
            <Typography>{formattedCurrency(total)}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.subtotal" />
            <Typography>{formattedCurrency(subtotal)}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo t="app.customerOrder.overview.taxAmount" />
            <Typography>{formattedCurrency(taxAmount)}</Typography>
          </Box>
        </Box>

        {/* InvoiceInfo Section */}
        <Box className={classes.section} p={1} marginTop={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo
              variant="h6"
              t="app.customerOrder.overview.order.invoiceInfo"
            />
            {editable ? (
              <IconButton
                onClick={() => setOpenEditInvoiceInfo(true)}
                size="large"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
            ) : null}
          </Box>
          <Divider className={classes.divider} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            boxSizing="border-box"
            overflow="scroll"
          >
            <Typography
              className={classes.showLineBreak}
              maxHeight={100}
              minHeight={24}
            >
              {invoiceInfo ?? '-'}
            </Typography>
          </Box>
        </Box>

        {status === CustomerOrderStatus.INVOICED && (
          <Box className={classes.section} p={1} marginTop={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="primary.main"
            >
              <Typo
                variant="h6"
                t="app.customerOrder.overview.order.invoice.title"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://myhellocash.com/intern/cash-register/invoice/search?q=${invoiceNumber}`}
              >
                <OpenInNewIcon fontSize="small" color="primary" />
              </a>
            </Box>

            <Divider className={classes.divider} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typo t="app.customerOrder.overview.invoiceDate" />
              <Date date={invoiceDate} format="DD. MMMM YYYY HH:mm" />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typo t="app.customerOrder.overview.paymentMethod" />
              <Typography>{paymentMethod}</Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typo t="app.customerOrder.overview.locationName" />
              <Typography>{invoicedLocation ?? '-'}</Typography>
            </Box>
          </Box>
        )}

        {/* Customer Section */}
        <Box className={classes.section} p={1} marginTop={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typo variant="h6" t="app.customerOrder.overview.customer.title" />
            <IconButton
              onClick={() => history.push(`/customers/${customer?.id}`)}
              size="large"
            >
              <OpenInNewIcon fontSize="small" color="primary" />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <CustomerInformation customer={customer} />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginTop={2}
        >
          <Box marginRight={1}>
            <Typo t="app.customerOrder.overview.updatedAt" />
          </Box>
          <Date date={updatedAt} format="DD. MMMM YYYY HH:mm" />
        </Box>
      </Paper>
    </>
  );
};

CustomerOrderOverview.propTypes = {
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  acceptedDate: PropTypes.string,
  total: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  taxAmount: PropTypes.number.isRequired,
  status: PropTypes.oneOf<CustomerOrderStatus>([
    CustomerOrderStatus.OPEN,
    CustomerOrderStatus.INVOICED,
    CustomerOrderStatus.CANCELED,
    CustomerOrderStatus.COSTESTIMATION,
  ]).isRequired,
  createdUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }).isRequired,
  // customer: PropTypes.shape({
  //   id: PropTypes.string.isRequired,
  //   firstname: PropTypes.string.isRequired,
  //   lastname: PropTypes.string.isRequired,
  //   email: PropTypes.string,
  //   phone: PropTypes.string,
  //   gender: PropTypes.oneOf<Gender>([
  //     Gender.DIVERSE,
  //     Gender.FEMALE,
  //     Gender.MALE,
  //   ]).isRequired,
  //   address: PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     postalCode: PropTypes.string.isRequired,
  //     city: PropTypes.string.isRequired,
  //     street1: PropTypes.string.isRequired,
  //     street2: PropTypes.string,
  //   }).isRequired,
  // }).isRequired,
  loading: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string,
  invoiceDate: PropTypes.string,
  invoiceNumber: PropTypes.string,
  invoicedLocationId: PropTypes.string,
};
CustomerOrderOverview.defaultProps = {};

export default CustomerOrderOverview;
