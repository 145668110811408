import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import {
  GET_PRODUCT_CATEGORY,
  GET_PRODUCT,
  GET_REPAIR_PRODUCTS_ROOT_CATEGORY,
} from '../../gql/product/queries';
import CategoryContent, { HistoryObject } from '../CategoryContent';
import { SelectionType } from '../CategoryContent';
import { getRepairProductsRoot } from '../../gql/product/types/getRepairProductsRoot';
import {
  getProductCategory,
  getProductCategoryVariables,
  getProductCategory_getProductCategory,
} from '../../gql/product/types/getProductCategory';
import {
  getProduct,
  getProductVariables,
  getProduct_getProduct,
} from '../../gql/product/types/getProduct';

export interface AddProductProps {
  onSubmit: (data: getProduct_getProduct) => void;
  multiSelect?: boolean;
  selectedCategoryId?: string;
}

const AddProduct: React.FC<React.PropsWithChildren<AddProductProps>> = ({
  onSubmit,
  multiSelect = false,
  selectedCategoryId = undefined,
}) => {
  const [onRootCategories, setOnRootCategories] = React.useState<boolean>(true);

  const [rootCategoryData, setRootCategoryData] =
    React.useState<getProductCategory_getProductCategory>();

  const [categoryData, setCategoryData] =
    React.useState<getProductCategory_getProductCategory>();

  const [getProductRootCategory, { data: root, loading: rootLoading }] =
    useLazyQuery<getRepairProductsRoot>(GET_REPAIR_PRODUCTS_ROOT_CATEGORY, {
      onCompleted: (data) => {
        setRootCategoryData(data.getProductCategories[0]);
      },
    });

  const [getProductCategoryDetail, { loading: categoryLoading }] = useLazyQuery<
    getProductCategory,
    getProductCategoryVariables
  >(GET_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      setCategoryData(data.getProductCategory);
      if (!rootCategoryData) {
        setRootCategoryData(data.getProductCategory);
      }
    },
  });

  const [getProductDetail, { loading: productLoading }] = useLazyQuery<
    getProduct,
    getProductVariables
  >(GET_PRODUCT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSubmit(data.getProduct);
    },
  });

  const onHistoryUpate = (history: HistoryObject[]) => {
    if (!history.length) {
      setOnRootCategories(true);
      return;
    }

    setOnRootCategories(false);
  };

  const getRootData = React.useCallback(() => {
    if (root) {
      setRootCategoryData(root.getProductCategories[0]);
    }
  }, [root]);

  // get initial category
  React.useEffect(() => {
    if (selectedCategoryId) {
      getProductCategoryDetail({ variables: { id: selectedCategoryId } });
      return;
    }

    getProductRootCategory();
  }, [getProductCategoryDetail, getProductRootCategory, selectedCategoryId]);

  // set root category. ugly but needed to be set due to
  // https://github.com/apollographql/apollo-client/issues/9338
  React.useEffect(() => {
    getRootData();
  }, [root, getRootData]);

  return (
    <CategoryContent
      subCategories={
        onRootCategories
          ? rootCategoryData?.subCategories
          : categoryData?.subCategories ?? []
      }
      products={
        onRootCategories
          ? rootCategoryData?.products
          : categoryData?.products ?? []
      }
      loading={categoryLoading || productLoading || rootLoading}
      getCategoryDetail={getProductCategoryDetail}
      getItemDetail={getProductDetail}
      itemLabel="model.repair.products"
      itemEmptyLabel="app.dialog.product.add.emptyProducts"
      type={SelectionType.PRODUCT}
      onHistoryUpdate={onHistoryUpate}
    />
  );
};

AddProduct.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  selectedCategoryId: PropTypes.string,
};
AddProduct.defaultProps = {};

export default AddProduct;
