import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import JsBarcode from 'jsbarcode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headless: {
      display: 'none',
    },
  })
);

export interface BarcodeProps {
  label: string;
  headless?: boolean;
  printRef: any;
}

const Barcode: React.FC<React.PropsWithChildren<BarcodeProps>> = ({ label, headless, printRef }) => {
  const classes = useStyles();
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef) {
      const ref = inputRef as any;
      JsBarcode(ref.current, label, {
        height: 50,
        width: 1.8,
        format: 'code128',
      });
    }
  }, [label]);

  if (headless)
    return (
      <div className={`${headless ? classes.headless : ''}`}>
        <div ref={printRef}>
          <svg ref={inputRef} />
        </div>
      </div>
    );

  return (
    <div ref={printRef}>
      <svg ref={inputRef} />
    </div>
  );
};

Barcode.propTypes = {
  label: PropTypes.string.isRequired,
  printRef: PropTypes.shape({}).isRequired,
  headless: PropTypes.bool,
};
Barcode.defaultProps = {
  headless: false,
};

export default Barcode;
