import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RepairStatus } from '../../../types/graphql-global-types';
import CheckBoxGroup from '../../../components/CheckBoxGroup';
import RepairsList from './RepairsList';
import usePersistedState from '../../../lib/helper/usePersistedState';

export interface RepairsTabProps {}

const RepairsTab: React.FC<React.PropsWithChildren<RepairsTabProps>> = () => {
  const [state, setState] = usePersistedState('tabRepairsFilterModel', {
    OPEN: true,
    IN_PROGRESS: true,
    WORK_DONE: true,
    PAUSED: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { OPEN, IN_PROGRESS, WORK_DONE, PAUSED } = state;

  return (
    <>
      <Box margin={1} overflow="scroll">
        <CheckBoxGroup
          checkBoxes={Object.keys(state).map((status: string) => ({
            checked: state[status],
            name: status,
            onChange: handleChange,
            label: `model.repair.status.${status}`,
          }))}
        />
      </Box>

      <Grid container spacing={3}>
        {OPEN && (
          <Grid item xs>
            <RepairsList status={[RepairStatus.OPEN]} />
          </Grid>
        )}
        {IN_PROGRESS && (
          <Grid item xs>
            <RepairsList status={[RepairStatus.IN_PROGRESS]} />
          </Grid>
        )}
        {WORK_DONE && (
          <Grid item xs>
            <RepairsList status={[RepairStatus.WORK_DONE]} />
          </Grid>
        )}
        {PAUSED && (
          <Grid item xs>
            <RepairsList status={[RepairStatus.PAUSED]} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

RepairsTab.propTypes = {};
RepairsTab.defaultProps = {};

export default RepairsTab;
