import React from 'react';
import PropTypes from 'prop-types';

export interface VisibleCheckProps {
  isVisible: boolean;
  children: React.ReactNode;
}

const VisibleCheck: React.FC<React.PropsWithChildren<VisibleCheckProps>> = ({ isVisible, children }) => {
  return isVisible ? <>{children}</> : null;
};

VisibleCheck.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isVisible: PropTypes.bool.isRequired,
};
VisibleCheck.defaultProps = {};

export default VisibleCheck;
