import { gql } from '@apollo/client';

export const UPDATE_WORKSTEP = gql`
  mutation updateWorkStep($data: UpdateWorkStep!) {
    updateWorkStep(data: $data) {
      id
      isDone
      setWorkload
      notes
      requiredProducts {
        id
        quantity
        status
        product {
          id
          name
          description
          price
        }
      }
    }
  }
`;

export const UPDATE_WORKSTEP_IS_DONE = gql`
  mutation setWorkStepIsDone($data: UpdateWorkStep!) {
    updateWorkStep(data: $data) {
      id
      isDone
      requiredProducts {
        id
        quantity
        status
        product {
          id
          name
          description
          price
        }
      }
    }
  }
`;

export const UPDATE_WORKSTEP_CATEGORY = gql`
  mutation updateWorkStepCategory($data: UpdateWorkStepCategory!) {
    updateWorkStepCategory(data: $data) {
      id
      updatedAt
      name
      description
      sequence
      color
      isActive
    }
  }
`;

export const CREATE_WORKSTEP_CATEGORY = gql`
  mutation createWorkStepCategory($data: CreateWorkStepCategory!) {
    createWorkStepCategory(data: $data) {
      id
      createdAt
      updatedAt
      name
      description
      sequence
      color
      isActive
    }
  }
`;

export const CREATE_WORKSTEP_DEFINITION = gql`
  mutation createWorkStepDefinition($data: CreateWorkStepDefinition!) {
    createWorkStepDefinition(data: $data) {
      id
      createdAt
      updatedAt
      name
      description
      sequence
      color
      isActive
      proposedProductCategory {
        id
        name
      }
      proposedProducts {
        id
        product {
          id
          name
          description
          price
        }
        quantity
      }
      expectedWorkload
      skillLevel
      categories {
        id
        name
      }
    }
  }
`;

export const UPDATE_WORKSTEP_DEFINITION = gql`
  mutation updateWorkStepDefinition($data: UpdateWorkStepDefinition!) {
    updateWorkStepDefinition(data: $data) {
      id
      updatedAt
      name
      description
      sequence
      color
      isActive
      categories {
        id
      }
      proposedProductCategory {
        id
        name
      }
      proposedProducts {
        id
        product {
          id
          name
          description
          price
        }
        quantity
      }
    }
  }
`;

export const CREATE_WORKSTEP = gql`
  mutation createWorkStep($data: CreateWorkStep!) {
    createWorkStep(data: $data) {
      id
      createdAt
      updatedAt
      notes
      workStepDefinition {
        id
        name
        description
        isActive
        skillLevel
      }
      isDone
      setWorkload
      requiredProducts {
        id
        quantity
        status
        product {
          id
          name
          description
          price
        }
      }
    }
  }
`;

export const DELETE_WORKSTEP = gql`
  mutation deleteWorkStep($id: String!) {
    deleteWorkStep(id: $id) {
      id
    }
  }
`;
