import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import Button from '../../Button';
import { ProductQuantityStatus } from '../../../types/graphql-global-types';
import FormSelect from '../Components/FormSelect';
import Typo from '../../Typo';
import FormSetValue from '../Components/FormSetValue';
import { getRepair_getRepair_workSteps_requiredProducts } from '../../../gql/repair/types/getRepair';

export interface EditedProduct {
  productQuantityId: string;
  name: string;
  productId: string;
  quantity: string;
  status: ProductQuantityStatus;
}
export interface EditProductFormProps {
  product: getRepair_getRepair_workSteps_requiredProducts;
  onClose: () => void;
  onSubmit: (data: EditedProduct) => void;
}

const EditProductForm: React.FC<React.PropsWithChildren<EditProductFormProps>> = ({
  product,
  onClose,
  onSubmit,
}) => {
  // hide CANCELLED status
  const productQuantityStatuses = Object.values(ProductQuantityStatus).filter(
    (status: ProductQuantityStatus) =>
      status !== ProductQuantityStatus.CANCELED &&
      status !== ProductQuantityStatus.INVOICED
  );

  return (
    <FormContainer onSubmit={onSubmit}>
      <FormSetValue name="productQuantityId" value={product?.id} />
      <FormSetValue name="productId" value={product?.product?.id} />

      <Box
        p={2}
        m={2}
        mt={0}
        bgcolor="background.default"
        borderRadius={1}
      >
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            {product?.product.name}
          </Typography>
        </Box>
        {/* <TextField
          disabled
          name="name"
          label="model.repair.product"
          defaultValue={product?.product.name ?? ''}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
          }}
        /> */}
        {/* <TextField
          disabled
          name="price"
          label="model.repair.product.price"
          defaultValue={product?.product.price ?? 0}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
            min: {
              value: 0.01,
              message: 'app.form.errors.min.one',
            },
            max: 9999,
          }}
        /> */}
        <TextField
          name="quantity"
          label="model.repair.product.quantity"
          defaultValue={product?.quantity ?? 1}
          type="number"
          inputProps={{
            step: '1',
          }}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
            min: {
              value: 1,
              message: 'app.form.errors.min.one',
            },
            max: 9999,
          }}
        />

        <Box marginTop={1}>
          <Typo t="model.product.status" />
        </Box>
        <FormSelect name="status" defaultValue={product?.status as string}>
          {productQuantityStatuses.map((status: string) => (
            <MenuItem key={status} value={status}>
              <Typo t={`model.product.status.${status}`} />
            </MenuItem>
          ))}
        </FormSelect>
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
          />
        </DialogActions>
      </Box>
    </FormContainer>
  );
};

EditProductForm.propTypes = {
  // product: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
EditProductForm.defaultProps = {};

export default EditProductForm;
