import React from 'react';
import PropTypes from 'prop-types';
import { ProductI, WorkStepI } from '..';
import WorkStepRow from '../WorkStepRow';
import ProductRow from '../ProductRow';

export interface RepairProps {
  workloadRate: number;
  workSteps: WorkStepI[];
  products?: ProductI[];
  showRepairCodeOnWorkStep?: string;
}

const Repair: React.FC<React.PropsWithChildren<RepairProps>> = ({
  workSteps,
  products,
  workloadRate,
  showRepairCodeOnWorkStep = '',
}) => {
  return (<>
    {workSteps?.map((workStep: WorkStepI) => (
      /* Repair Worksteps & Required Products */
      (<WorkStepRow
        key={workStep.id}
        id={workStep.id}
        name={
          !!showRepairCodeOnWorkStep
            ? `${workStep.workStepDefinition.name} [${showRepairCodeOnWorkStep}]`
            : workStep.workStepDefinition.name
        }
        workloadRate={workloadRate}
        setWorkload={workStep.setWorkload}
        requiredProducts={workStep.requiredProducts}
      />)
    ))}
    {products?.map((product: ProductI) => (
      /* Repair Products */
      (<ProductRow
        key={product.id}
        product={product.product}
        quantity={product.quantity}
      />)
    ))}
  </>);
};

Repair.propTypes = {
  workloadRate: PropTypes.number.isRequired,
  workSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      workStepDefinition: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      setWorkload: PropTypes.number.isRequired,
      requiredProducts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
          }).isRequired,
          quantity: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
      quantity: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  showRepairCodeOnWorkStep: PropTypes.string,
};
Repair.defaultProps = {
  products: undefined,
  showRepairCodeOnWorkStep: '',
};

export default Repair;
