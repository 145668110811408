import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import history from './lib/history';
import LoginPage from './pages/Login';
import IndexPage from './pages/Index';
import DashboardPage from './pages/Dashboard';
import NewCustomerOrderPage from './pages/NewCustomerOrder';
import CustomerPage from './pages/Customer';
import NewCustomerPage from './pages/Customer/NewCustomer';
import CustomersPage from './pages/Customers';
import RepairsPage from './pages/Repairs';
import RepairPage from './pages/Repair';
import WorkstepsPage from './pages/Worksteps';
import ProductsPage from './pages/Products';
import SettingsPage from './pages/Settings';
import CustomerOrderPage from './pages/CustomerOrder';
import ProfilePage from './pages/Profile';
import NotesPage from './pages/Notes';
import LocationsPage from './pages/Locations';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <ProtectedRoute path="/" exact>
          <IndexPage />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard" exact>
          <DashboardPage />
        </ProtectedRoute>
        <ProtectedRoute path="/customerOrders/new" exact>
          <NewCustomerOrderPage />
        </ProtectedRoute>
        <ProtectedRoute path="/customers" exact>
          <CustomersPage />
        </ProtectedRoute>
        <ProtectedRoute path="/customers/new" exact>
          <NewCustomerPage />
        </ProtectedRoute>
        <ProtectedRoute path="/customers/:id" exact>
          <CustomerPage />
        </ProtectedRoute>
        <ProtectedRoute path="/repairs/" exact>
          <RepairsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/repairs/:repairCode" exact>
          <RepairPage />
        </ProtectedRoute>
        <ProtectedRoute path="/settings" exact>
          <SettingsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/settings/worksteps" exact>
          <WorkstepsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/settings/products" exact>
          <ProductsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/settings/locations" exact>
          <LocationsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/customerOrders/:id" exact>
          <CustomerOrderPage />
        </ProtectedRoute>
        <ProtectedRoute path="/profile" exact>
          <ProfilePage />
        </ProtectedRoute>
        <ProtectedRoute path="/notes" exact>
          <NotesPage />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
}

export default App;
