import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';

import { getNotes_getNotes_items } from '../../../../gql/notes/types/getNotes';
import Paper from '../../../../components/Paper';
import Date from '../../../../components/Date';
import WYSIWYG from '../../../../components/WYSIWYG';
import EditNote from './EditNote';
import NoteComments from './NoteComments';
import { useNotes } from '../../NotesContext';
import { NoteStatus } from '../../../../types/graphql-global-types';
import NoteInfos from './NoteInfos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 460,
    },
    containerSM: {
      width: '100%',
    },
    avatar: {
      height: 32,
      width: 32,
      fontSize: 14,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    createdDate: {
      fontSize: 12,
      color: theme.palette.grey[400],
    },
    createdUser: {
      fontSize: 18,
    },
  })
);

export interface NotesProps {
  note: getNotes_getNotes_items;
}

const Notes: React.FC<React.PropsWithChildren<NotesProps>> = ({ note }) => {
  const classes = useStyles();
  const { updateNote, editNote } = useNotes();
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUpdateNote = () => {
    editNote(note);
  };

  const handleStatusUpdate = () => {
    if (note.status === NoteStatus.OPEN) {
      updateNote({
        variables: { data: { id: note.id, status: NoteStatus.DONE } },
      });
    }
    if (note.status === NoteStatus.DONE) {
      updateNote({
        variables: { data: { id: note.id, status: NoteStatus.OPEN } },
      });
    }
  };

  return (
    <Paper
      padding
      className={clsx(classes.container, {
        [classes.containerSM]: sm,
      })}
    >
      {/* Header */}
      <Box display="flex" alignItems="center">
        {/* <Avatar
          className={classes.avatar}
        >{`${note.user.firstname[0]}${note.user.lastname[0]}`}</Avatar> */}
        <Box ml={1} display="flex" flex={1} flexDirection="column">
          <Typography
            className={classes.createdUser}
            variant="h6"
            color="primary"
          >
            {`${note.user.firstname} ${note.user.lastname}`}
          </Typography>
          <Date
            className={classes.createdDate}
            date={note.createdAt}
            format="DD. MMMM YYYY HH:mm"
          />
        </Box>
        {/* <NoteStatusChip status={note.status} /> */}
        <EditNote
          status={note.status}
          onEdit={handleUpdateNote}
          onStatusUpdate={handleStatusUpdate}
        />
      </Box>

      {/* content */}
      <Box
        mt={2}
        pl={1}
        pr={1}
        minHeight={75}
        bgcolor="background.default"
        borderRadius={1}
        overflow="scroll"
      >
        <WYSIWYG initialContent={note.content} readOnly />
      </Box>

      {/* additional infos */}
      <NoteInfos
        location={note.location}
        customer={note.customer}
        customerOrderId={note.customerOrderId}
      />

      {/* comments */}
      <Box mt={2}>
        <NoteComments
          disabled={note.status === NoteStatus.DONE}
          comments={note.comments}
          noteId={note.id}
        />
      </Box>
    </Paper>
  );
};

Notes.propTypes = {};
Notes.defaultProps = {};

export default Notes;
