import React from 'react';
// import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Category } from '../../../../../lib/helper/treeViewHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 100,
      backgroundColor: '#fff',
    },
    colorIndicator: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      width: 40,
    },
  })
);

export interface SubCategoryItemProps {
  category: Category;
}

const SubCategoryItem: React.FC<React.PropsWithChildren<SubCategoryItemProps>> = ({ category }) => {
  const classes = useStyles();

  return (
    <Box display="flex" p={1}>
      <Box
        className={classes.container}
        display="flex"
        flexGrow={1}
        borderRadius={1}
      >
        <Box
          className={classes.colorIndicator}
          style={{ backgroundColor: category.color || undefined }}
        ></Box>

        <Box display="flex" p={1} flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <b>{category.name}</b>
            </Typography>
            {category.sequence}
            {/* <IconButton
              size="small"
            >
              <EditIcon />
            </IconButton> */}
          </Box>
          <Box
            mt={1}
            p={1}
            height={75}
            overflow="auto"
            bgcolor="background.default"
            borderRadius={1}
          >
            {category.description}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SubCategoryItem.propTypes = {
  // category:
};
SubCategoryItem.defaultProps = {};

export default SubCategoryItem;
