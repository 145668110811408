import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_QUANTITY = gql`
  mutation updateProductQuantity($data: UpdateProductQuantity!) {
    updateProductQuantity(data: $data) {
      id
      status
      quantity
      status
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      id
      name
      price
      description
      manufacturer
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($data: UpdateProductInput!) {
    updateProduct(data: $data) {
      id
      name
      price
      description
      manufacturer
    }
  }
`;

export const CREATE_ONETIME_PRODUCT = gql`
  mutation createOneTimeProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      id
      name
      price
      manufacturer
    }
  }
`;

export const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory($data: CreateProductCategoryInput!) {
    createProductCategory(data: $data) {
      id
      name
      createdAt
      updatedAt
      description
      isActive
      sequence
      color
      isRootCategory
      isRepairProductsRootCategory
      isOneTimeProductsRootCategory
    }
  }
`;

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation updateProductCategory($data: UpdateProductCategoryInput!) {
    updateProductCategory(data: $data) {
      id
      updatedAt
      name
      description
      sequence
      color
      isActive
    }
  }
`;
