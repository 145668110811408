import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { RawDraftContentState } from 'draft-js';

import Button from '../../../../../Button';
import AddWorkStepDialog from '../../../../../Dialog/AddWorkStep';
import Typo from '../../../../../Typo';
import WorkStep from './WorkStep';
import { DEFAULT_PRODUCT_STATUS } from '../../../../../../lib/constants';
import {
  CreateProduct,
  CreateWorkStep,
  ProposedProduct,
} from '../../../../machines/orderDraftMachine/types';
import { getWorkStepDefinition_getWorkStepDefinition as WorkStepDefinition } from '../../../../../../gql/workStep/types/getWorkStepDefinition';
import { ProductQuantityStatus } from '../../../../../../types/graphql-global-types';
import AddProductDialog from '../../../../../Dialog/AddProduct';
import { getProduct_getProduct } from '../../../../../../gql/product/types/getProduct';

export interface WorkStepsProps {
  selected: CreateWorkStep[]; // array of already selected Worksteps
  // products: CreateProduct[];
  workloadRate: number;
  isValidating: boolean;
  handleAddWorkStep: (data: WorkStepDefinition) => void;
  handleRemoveWorkStep: (id: string) => void;
  handleUpdateWorkStepWorkLoad: (id: string, workload: number) => void;
  handleUpdateWorkStepNotes: (
    workStepId: string,
    notes: RawDraftContentState
  ) => void;
  handleAddWorkStepProduct: (workStepId: string, data: CreateProduct) => void;
  handleRemoveWorkStepProduct: (workStepId: string, productId: string) => void;
  handleUpdateWorkStepProductStatus: (
    workStepId: string,
    productId: string,
    status: ProductQuantityStatus
  ) => void;
  handleUpdateWorkStepProductCount: (
    workStepId: string,
    productId: string,
    quantity: number
  ) => void;
}

const WorkSteps: React.FC<React.PropsWithChildren<WorkStepsProps>> = ({
  // products,
  selected,
  workloadRate,
  isValidating,
  handleAddWorkStep,
  handleRemoveWorkStep,
  handleUpdateWorkStepWorkLoad,
  handleUpdateWorkStepNotes,
  handleAddWorkStepProduct,
  handleRemoveWorkStepProduct,
  handleUpdateWorkStepProductStatus,
  handleUpdateWorkStepProductCount,
}) => {
  const [open, setOpen] = React.useState(false);
  const [proposedProductCategory, setProposedProductCategory] = React.useState<
    string | undefined
  >();

  const [workStepId, setWorkStepId] = React.useState<string>();

  /**
   * adds a workstep to the repair. checks if workstep has proposedProducts and also adds them
   * @param data workstep
   */
  const handleSubmitWorkStep = (data: WorkStepDefinition) => {
    handleAddWorkStep(data);

    // if workstep has proposedProducts check if product is already added and update quantity. If not add product
    if (data.proposedProducts) {
      data.proposedProducts.map((productQuantity: ProposedProduct) => {
        const proposedProduct = productQuantity.product;

        handleAddWorkStepProduct(data.id, {
          ...proposedProduct,
          quantity: productQuantity.quantity,
          status: DEFAULT_PRODUCT_STATUS,
        });

        return null;
      });
    }

    // close WorkStepDialog
    setOpen(false);

    // if proposedProductCategory open ProductsDialog
    if (!!data.proposedProductCategory) {
      setWorkStepId(data.id);

      setTimeout(() => {
        setProposedProductCategory(data?.proposedProductCategory?.id);
      }, 500);
    }
  };

  const handleAddProposedProduct = (product: getProduct_getProduct) => {
    if (workStepId) {
      handleAddWorkStepProduct(workStepId, {
        ...product,
        quantity: 1,
        status: DEFAULT_PRODUCT_STATUS,
      });
    }

    handleCloseProposedProduct();
  };

  const handleCloseProposedProduct = () => {
    setProposedProductCategory(undefined);
    setWorkStepId(undefined);
  };

  return (
    <Box display="flex" flexDirection="column">
      {/* add workStep dialog */}
      <AddWorkStepDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmitWorkStep}
        selected={selected}
      />

      {!!proposedProductCategory ? (
        <AddProductDialog
          open={!!proposedProductCategory}
          onClose={handleCloseProposedProduct}
          onSubmit={handleAddProposedProduct}
          selectedCategoryId={proposedProductCategory}
        />
      ) : null}

      {/* list of already selected worksteps */}
      {selected?.length ? (
        selected.map((workStep: CreateWorkStep) => (
          <WorkStep
            key={workStep.id}
            data={workStep}
            workloadRate={workloadRate}
            isValidating={isValidating}
            handleRemoveWorkStep={handleRemoveWorkStep}
            handleUpdateWorkLoad={handleUpdateWorkStepWorkLoad}
            handleAddWorkStepProduct={handleAddWorkStepProduct}
            handleRemoveWorkStepProduct={handleRemoveWorkStepProduct}
            handleUpdateWorkStepProductCount={handleUpdateWorkStepProductCount}
            handleUpdateWorkStepNotes={handleUpdateWorkStepNotes}
            handleUpdateWorkStepProductStatus={
              handleUpdateWorkStepProductStatus
            }
          />
        ))
      ) : (
        <Typo t="app.customerOrderDraft.subOrders.repair.noWorkSteps" />
      )}

      {/* Button to add more */}
      <Box alignSelf="flex-end">
        <Button
          data-test="workStepSelection-addWorkStep"
          t="app.customerOrderDraft.subOrders.repair.addWorkStep"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={isValidating}
          disableElevation
        />
      </Box>
    </Box>
  );
};

WorkSteps.propTypes = {
  workloadRate: PropTypes.number.isRequired,
  isValidating: PropTypes.bool.isRequired,
  handleAddWorkStep: PropTypes.func.isRequired,
  handleRemoveWorkStep: PropTypes.func.isRequired,
  handleUpdateWorkStepWorkLoad: PropTypes.func.isRequired,
  handleUpdateWorkStepNotes: PropTypes.func.isRequired,
  handleAddWorkStepProduct: PropTypes.func.isRequired,
  handleRemoveWorkStepProduct: PropTypes.func.isRequired,
  handleUpdateWorkStepProductStatus: PropTypes.func.isRequired,
  handleUpdateWorkStepProductCount: PropTypes.func.isRequired,
};
WorkSteps.defaultProps = {};

export default React.memo(WorkSteps);
