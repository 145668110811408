import React from 'react';
import Box from '@mui/material/Box';

import PageContainer from '../../components/PageContainer';
import AppleStyleTabs from '../../components/Tabs/AppleStyleTabs';
import TabPanel from '../../components/Tabs/TabPanel';
import CustomerOrdersTab from './CustomerOrdersTab';
import RepairsTab from './RepairsTab';
import ProductQuantitiesTab from './ProductQuantitiesTab';
import usePersistedState from '../../lib/helper/usePersistedState';

export interface DashboardPageProps {}

const DashboardPage: React.FC<React.PropsWithChildren<DashboardPageProps>> = () => {
  const [current, setCurrent] = usePersistedState('dashboardActiveTab', 0);

  return (
    <PageContainer page="dashboard">
      <AppleStyleTabs
        current={current}
        setValue={(value) => setCurrent(value)}
        tabs={[
          { label: 'app.dashboard.tabs.customerOrder' },
          { label: 'app.dashboard.tabs.repairs' },
          { label: 'app.dashboard.tabs.productQuantities' },
        ]}
      />
      {/* Dashbord content */}
      {/* Customer Order Panel */}
      <TabPanel index={current} value={0}>
        <Box marginTop={3}>
          <CustomerOrdersTab />
        </Box>
      </TabPanel>

      {/* Repairs Panel */}
      <TabPanel index={current} value={1}>
        <RepairsTab />
      </TabPanel>

      {/* ProductOrder Panel */}
      <TabPanel index={current} value={2}>
        <Box marginTop={3}>
          <ProductQuantitiesTab />
        </Box>
      </TabPanel>
    </PageContainer>
  );
};

DashboardPage.propTypes = {};
DashboardPage.defaultProps = {};

export default DashboardPage;
