import { GridRowParams } from '@mui/x-data-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';

import PageContainer from '../../components/PageContainer';
import RepairsTable from '../../components/Table/Tables/RepairsTable';
import { RepairStatus } from '../../types/graphql-global-types';
import RepairSearch from './RepairSearch';

export interface RepairsPageProps {}

const RepairsPage: React.FC<React.PropsWithChildren<RepairsPageProps>> = () => {
  const history = useHistory();
  const tableRef = React.useRef<any>();

  const handleRowClick = (params: GridRowParams) => {
    history.push(`/repairs/${params?.row?.repairCode}`);
  };

  return (
    <PageContainer page="repairs">
      <RepairSearch tableRef={tableRef} />
      <RepairsTable
        ref={tableRef}
        persistedKey="repairsOverview"
        initialLimit={5}
        onRowClick={handleRowClick}
        initialFilterModel={{
          [RepairStatus.OPEN]: true,
          [RepairStatus.IN_PROGRESS]: true,
          [RepairStatus.PAUSED]: true,
          [RepairStatus.WORK_DONE]: false,
        }}
      />
    </PageContainer>
  );
};

RepairsPage.propTypes = {};
RepairsPage.defaultProps = {};

export default RepairsPage;
