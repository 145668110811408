import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useQuery } from '@apollo/client';
import { RawDraftContentState } from 'react-draft-wysiwyg';

import SelectedItem from '../../../../../../SelectedItem';
import AddProductDialog from '../../../../../../Dialog/AddProduct';
import { GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY } from '../../../../../../../gql/product/queries';
import Button from '../../../../../../Button';
import CreateOneTimeProduct, {
  OneTimeProduct,
} from '../../../../../../Dialog/CreateOneTimeProduct';
import WYSIWYGDialog from '../../../../../../Dialog/WYSISWYG';
import { DEFAULT_PRODUCT_STATUS } from '../../../../../../../lib/constants';
import { ProductQuantityStatus } from '../../../../../../../types/graphql-global-types';
import {
  CreateProduct,
  CreateWorkStep,
} from '../../../../../machines/orderDraftMachine/types';
import { getProduct_getProduct } from '../../../../../../../gql/product/types/getProduct';
import { getRepairOneTimeProductsRoot } from '../../../../../../../gql/product/types/getRepairOneTimeProductsRoot';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    workStepContainer: {
      border: '1px solid #707070',
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.divider,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: '#fff',
    },
    requiredProduct: {
      marginLeft: theme.spacing(3),
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);

export interface WorkStepProps {
  data: CreateWorkStep;
  workloadRate: number;
  isValidating: boolean;
  handleRemoveWorkStep: (id: string) => void;
  handleUpdateWorkLoad: (id: string, workload: number) => void;
  handleUpdateWorkStepNotes: (
    workStepId: string,
    notes: RawDraftContentState
  ) => void;

  handleAddWorkStepProduct: (workStepId: string, data: CreateProduct) => void;
  handleRemoveWorkStepProduct: (workStepId: string, productId: string) => void;
  handleUpdateWorkStepProductStatus: (
    workStepId: string,
    productId: string,
    status: ProductQuantityStatus
  ) => void;
  handleUpdateWorkStepProductCount: (
    workStepId: string,
    productId: string,
    quantity: number
  ) => void;
}

const WorkStep: React.FC<React.PropsWithChildren<WorkStepProps>> = ({
  data,
  isValidating,
  workloadRate,
  handleUpdateWorkLoad,
  handleRemoveWorkStep,
  handleUpdateWorkStepProductCount,
  handleRemoveWorkStepProduct,
  handleAddWorkStepProduct,
  handleUpdateWorkStepNotes,
  handleUpdateWorkStepProductStatus,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openOneTime, setOpenOneTime] = React.useState<boolean>(false);
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);

  // get OneTimeProductRootCategory
  const {
    data: repairOneTimeRootCategory,
    loading: loadingOneTimeRootCategory,
  } = useQuery<getRepairOneTimeProductsRoot>(
    GET_REPAIR_ONE_TIME_PRODUCTS_ROOT_CATEGORY
  );
  const oneTimeRootCategoryId =
    repairOneTimeRootCategory?.getProductCategories[0]?.id;

  const handleCountUpdate = (id: string, quantity: number) =>
    handleUpdateWorkStepProductCount(data.id, id, quantity);

  const handleRemoveProduct = (id: string) =>
    handleRemoveWorkStepProduct(data.id, id);

  /**
   * adds a product to requiredProducts if not already been added. If already added, updates quantity + 1
   * @param product object
   */
  const handleAddProduct = (product: getProduct_getProduct) => {
    const alreadyAdded = data?.requiredProducts?.find(
      (p: CreateProduct) => p.id === product.id
    );

    if (alreadyAdded) {
      handleCountUpdate(product.id, alreadyAdded.quantity + 1);
    } else {
      handleAddWorkStepProduct(data.id, {
        ...product,
        quantity: 1,
        status: DEFAULT_PRODUCT_STATUS,
      });
    }

    setOpen(false);
  };

  /**
   * adds a OneTimeProduct to a WorkStep
   * @param data productData
   */
  const handleCreateOneTimeProduct = (product: OneTimeProduct) => {
    const alreadyAdded = data?.requiredProducts?.find(
      (p: CreateProduct) => p.id === product.id
    );

    if (alreadyAdded) {
      handleCountUpdate(product.id, alreadyAdded.quantity + 1);
    } else {
      handleAddWorkStepProduct(data.id, {
        ...product,
        quantity: product.quantity || 1,
        status: DEFAULT_PRODUCT_STATUS,
      });
    }
    setOpenOneTime(false);
  };

  const handleAddWorkStepNotes = (notes: RawDraftContentState) => {
    handleUpdateWorkStepNotes(data.id, notes);
    setOpenNotes(false);
  };

  const handleAvailableUpdate = (productId: string, isAvailable: boolean) => {
    const status = isAvailable
      ? ProductQuantityStatus.AVAILABLE
      : ProductQuantityStatus.OPEN;

    handleUpdateWorkStepProductStatus(data.id, productId, status);
  };

  return (
    <div className={classes.workStepContainer}>
      <AddProductDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAddProduct}
      />
      <CreateOneTimeProduct
        open={openOneTime && !loadingOneTimeRootCategory}
        rootCategory={oneTimeRootCategoryId}
        onClose={() => setOpenOneTime(false)}
        onSubmit={handleCreateOneTimeProduct}
      />
      <WYSIWYGDialog
        open={openNotes}
        onSubmit={handleAddWorkStepNotes}
        onCancel={() => setOpenNotes(false)}
        initialContent={data.notes ?? undefined}
      />

      {/* Render WorkStep */}
      <SelectedItem
        id={data.id}
        name={data.name}
        count={data.setWorkload}
        skillLevel={data.skillLevel}
        countRate={workloadRate}
        setCount={handleUpdateWorkLoad}
        RemoveItem={handleRemoveWorkStep}
        countLabel="model.repair.workLoad"
        isValidating={isValidating}
      />

      {/* Render List of requiredProducts */}
      {data.requiredProducts.length
        ? data.requiredProducts.map((product: CreateProduct) => (
            <div className={classes.requiredProduct} key={product.id}>
              <SelectedItem
                id={product.id}
                name={product.name}
                count={product.quantity}
                countRate={product.price}
                manufacturer={product.manufacturer}
                setCount={handleCountUpdate}
                RemoveItem={handleRemoveProduct}
                countLabel="model.repair.product.quantity"
                isValidating={isValidating}
                isAvailable={product.status === ProductQuantityStatus.AVAILABLE}
                onAvailableChange={handleAvailableUpdate}
              />
            </div>
          ))
        : null}

      {/* Add products button */}
      <Button
        disableElevation
        t="app.customerOrderDraft.subOrders.repair.addProduct"
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        disabled={isValidating}
      />
      <Button
        t="app.customerOrderDraft.subOrders.repair.addOneTimeProduct"
        onClick={() => setOpenOneTime(true)}
        variant="outlined"
        color="primary"
        className={classes.button}
        disabled={isValidating}
      />
      <Button
        variant="outlined"
        color="primary"
        t="app.customerOrderDraft.subOrders.repair.notes"
        className={classes.button}
        onClick={() => setOpenNotes(true)}
      />
    </div>
  );
};

WorkStep.propTypes = {
  workloadRate: PropTypes.number.isRequired,
  isValidating: PropTypes.bool.isRequired,
  handleRemoveWorkStep: PropTypes.func.isRequired,
  handleUpdateWorkLoad: PropTypes.func.isRequired,
  handleAddWorkStepProduct: PropTypes.func.isRequired,
  handleRemoveWorkStepProduct: PropTypes.func.isRequired,
  handleUpdateWorkStepProductCount: PropTypes.func.isRequired,
  handleUpdateWorkStepNotes: PropTypes.func.isRequired,
  handleUpdateWorkStepProductStatus: PropTypes.func.isRequired,
};
WorkStep.defaultProps = {};

export default React.memo(WorkStep);
