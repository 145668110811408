import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import usePrint from '../../../lib/helper/usePrint';
import Barcode from '../../Barcode';
import Button from '../../Button';

export interface PrintItemProps {
  label?: string | React.ReactElement;
  subLabel?: string | React.ReactElement;
  costEstimationPrintRef?: any;
  repairCode?: string;
}

const PrintItem: React.FC<React.PropsWithChildren<PrintItemProps>> = ({
  costEstimationPrintRef,
  label,
  subLabel,
  repairCode,
}) => {
  const itemRef = React.useRef(null);

  const handlePrint = usePrint({
    printRef: costEstimationPrintRef ? costEstimationPrintRef : itemRef,
    isBrandedDefaultPaper: !!costEstimationPrintRef,
    onAfterPrint: () => null,
  });

  return (
    <>
      {repairCode && <Barcode label={repairCode} headless printRef={itemRef} />}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={2}
        paddingY={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="64px"
        >
          <Typography>
            <b>{label}</b>
          </Typography>
          {subLabel && <Typography>{subLabel}</Typography>}
        </Box>

        <Button
          variant="outlined"
          color="primary"
          t="app.common.print"
          onClick={handlePrint}
        />
      </Box>
    </>
  );
};

PrintItem.propTypes = {
  repairCode: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subLabel: PropTypes.string,
};
PrintItem.defaultProps = {};

export default PrintItem;
