import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';

import DialogBase from '..';
import SMSNotification from '../../SMSNotification';
import SMSNotificationHistory from '../../SMSNotificationHistory';
import Typo from '../../Typo';
import { getCustomerOrder_getCustomerOrder_history } from '../../../gql/customerOrder/types/getCustomerOrder';

export interface SMSNotificationDialogProps {
  open: boolean;
  phoneNumber: string;
  customerOrderId: string;
  onClose: () => void;
  onSubmit: () => void;
  history?: getCustomerOrder_getCustomerOrder_history[];
}

const SMSNotificationDialog: React.FC<React.PropsWithChildren<SMSNotificationDialogProps>> = ({
  open,
  phoneNumber,
  customerOrderId,
  onClose,
  onSubmit,
  history,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = React.useState<boolean>(false);

  const DEFAULT_MESSAGE = intl.formatMessage(
    {
      id: 'app.smsNotification.defualtMessage.workDone',
    },
    {
      customerOrderId: customerOrderId,
    }
  );

  const smsNotificationRef = React.useRef<any>();

  const handleSendSMS = () => {
    if (smsNotificationRef) {
      smsNotificationRef?.current?.sendMessage();
      setLoading(true);
    }
  };

  const onSent = () => {
    setLoading(false);
    onSubmit();
  };

  return (
    <DialogBase
      open={open}
      title="app.dialog.smsNotification.title"
      primaryActionLabel="app.dialog.smsNotification.primary"
      primaryAction={handleSendSMS}
      secondaryAction={onClose}
      primaryDisabled={loading}
      maxWidth="md"
      fullWidth
    >
      <Box display="flex">
        {/* Left */}
        <Box display="flex" flex={1} flexDirection="column" height="350px">
          <Typo
            t="app.dialog.smsNotification.history"
            variant="h6"
            color="primary"
            bold
          />
          <Box display="flex" justifyContent="space-between" paddingBottom={2}>
            <Typo
              t="app.dialog.smsNotification.history.customerOrderID"
              values={{ customerOrderId: customerOrderId }}
              display="inline"
            />

            <Typo
              t="app.dialog.smsNotification.history.smsCount"
              values={{ smsCount: history?.length || 0 }}
              display="inline"
            />
          </Box>
          <SMSNotificationHistory history={history} />
        </Box>

        {/* Divider */}
        <Box display="flex" paddingX={2}>
          <Divider orientation="vertical" variant="fullWidth" />
        </Box>

        {/* Right */}
        <Box display="flex" flex={1} flexDirection="column">
          <Typo
            t="app.dialog.smsNotification.new"
            variant="h6"
            color="primary"
            bold
          />
          <SMSNotification
            phoneNumber={phoneNumber}
            defaultMessage={DEFAULT_MESSAGE}
            customerOrderId={customerOrderId}
            ref={smsNotificationRef}
            isDialog
            onSent={onSent}
          />
        </Box>
      </Box>
    </DialogBase>
  );
};

SMSNotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};
SMSNotificationDialog.defaultProps = {};

export default SMSNotificationDialog;
