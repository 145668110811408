import React from 'react';
import { useQuery } from '@apollo/client';

import PageContainer from '../../components/PageContainer';
import { ME } from '../../gql/user/queries';
import Dashboard from '../Dashboard';
import Repairs from '../Repairs';
import { Role } from '../../types/graphql-global-types';
import { me } from '../../gql/user/types/me';

export interface IndexPageProps {}

const IndexPage: React.FC<React.PropsWithChildren<IndexPageProps>> = () => {
  const { loading, data } = useQuery<me>(ME);

  if (loading) {
    return (
      <PageContainer page="index" loading>
        <div />
      </PageContainer>
    );
  }

  if (data?.me?.role === Role.MANAGER) {
    return <Dashboard />;
  }

  return <Repairs />;
};

IndexPage.propTypes = {};
IndexPage.defaultProps = {};

export default IndexPage;
