import React from 'react';
import PropTypes from 'prop-types';
import {
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';

import {
  getNotes,
  getNotesVariables,
  getNotes_getNotes_items,
} from '../../gql/notes/types/getNotes';
import { GET_NOTES } from '../../gql/notes/queries';
import {
  updateNote,
  updateNoteVariables,
} from '../../gql/notes/types/updateNote';
import { UPDATE_NOTE } from '../../gql/notes/mutations';
import { me } from '../../gql/user/types/me';
import { ME } from '../../gql/user/queries';
import EditNoteDialog from '../../components/Dialog/EditNote';
import CreateNoteDialog from '../../components/Dialog/CreateNote';
import { NoteStatus } from '../../types/graphql-global-types';

const NotesContext = React.createContext<
  | {
      notes?: getNotes;
      user?: me;
      updateNote: (
        options?:
          | MutationFunctionOptions<updateNote, updateNoteVariables>
          | undefined
      ) => void;
      editNote: (data: getNotes_getNotes_items) => void;
      refetch?: (variables?: Partial<getNotesVariables> | undefined) => void;
      onCreateNote: () => void;
      loading: boolean;
      rerender: number;
      setRerender: React.Dispatch<React.SetStateAction<number>>;
      status: NoteStatus;
      onStatusChange: (newStatus: NoteStatus) => void;
      page: number;
      limit: number;
      setPage: React.Dispatch<React.SetStateAction<number>>;
      onlyMyNotes: boolean;
      setOnlyMyNotes: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

const useNotes = () => {
  const context = React.useContext(NotesContext);
  // undefined typeguard
  // https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
};

export interface NotesProviderProps {
  children: React.ReactNode;
}

const NotesProvider: React.FC<React.PropsWithChildren<NotesProviderProps>> = ({ children }) => {
  const [status, setStatus] = React.useState<NoteStatus>(NoteStatus.OPEN);
  const [note, setNote] = React.useState<getNotes_getNotes_items>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [rerender, setRerender] = React.useState<number>(0);
  const [shouldReload, setShouldReload] = React.useState<boolean>(false);
  const [onlyMyNotes, setOnlyMyNotes] = React.useState(false);

  const [page, setPage] = React.useState<number>(0);
  const [limit] = React.useState<number>(20);

  const [updateNote, { loading: updateLoading }] = useMutation<
    updateNote,
    updateNoteVariables
  >(UPDATE_NOTE, {
    onCompleted: () => {
      refetch && refetch();
      setShouldReload(true);
    },
    onError: () => {},
  });

  // FIXME: User should be in a app context
  const { data: user, loading: userLoading } = useQuery<me>(ME);

  const [getNotes, { data: notes, loading, refetch }] = useLazyQuery<
    getNotes,
    getNotesVariables
  >(GET_NOTES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      limit,
      status: [status],
      onlyMyNotes,
    },
  });

  const onNoteUpdate = () => {
    setNote(undefined);
    refetch && refetch();
  };

  const handleNoteSubmit = () => {
    refetch && refetch();
    setOpen(false);
  };

  const onCreateNote = () => {
    setOpen(true);
  };

  const onStatusChange = (newStatus: NoteStatus) => {
    // start on first page if tab is changed
    setPage(0);
    setStatus(newStatus);
  };

  React.useEffect(() => {
    // handle refetch when needed
    if (shouldReload) {
      refetch && refetch({ status: [status] });
      return;
    }

    let offset = 0;

    if (page > 0) {
      offset = limit * page - limit;
    }

    getNotes({
      variables: {
        offset,
        limit,
        status: [status],
        onlyMyNotes,
      },
    });

    // eslint-disable-next-line
  }, [
    // refetch,
    onlyMyNotes,
    status,
    limit,
    page,
    shouldReload,
    getNotes,
  ]);

  // // refetch
  // React.useEffect(() => {
  //   refetch && refetch();
  // }, [refetch]);

  const value = {
    notes,
    user,
    loading: loading || userLoading || updateLoading,
    updateNote,
    refetch,
    onCreateNote,
    editNote: setNote,
    rerender,
    setRerender,
    status,
    onStatusChange,
    page,
    setPage,
    onlyMyNotes,
    setOnlyMyNotes,
    limit,
  };

  return (
    <NotesContext.Provider value={value}>
      <CreateNoteDialog
        open={open}
        onSubmit={handleNoteSubmit}
        onCancel={() => setOpen(false)}
      />
      <EditNoteDialog
        note={note}
        onSubmit={onNoteUpdate}
        onCancel={() => setNote(undefined)}
      />
      {children}
    </NotesContext.Provider>
  );
};

NotesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
NotesProvider.defaultProps = {};

export { NotesProvider, useNotes };
