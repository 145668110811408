import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';

import { CUSTOMERS_SEARCH } from '../../../../../gql/customer/queries';
import Button from '../../../../Button';
import {
  CustomerSearch as CustomerSearchI,
  CustomerSearchVariables,
  CustomerSearch_getCustomers_items,
} from '../../../../../gql/customer/types/CustomerSearch';
import {
  CustomerSortField,
  OrderDirection,
} from '../../../../../types/graphql-global-types';

interface Var {
  variables: {
    id: string;
  };
}
export interface CustomerSearchProps {
  handleSetCreateCustomer?: () => void;
  onSearchRemove?: () => void;
  getCustomerDetail: (variables: Var) => void;
}

const CustomerSearch: React.FC<React.PropsWithChildren<CustomerSearchProps>> = ({
  handleSetCreateCustomer = undefined,
  onSearchRemove = undefined,
  getCustomerDetail,
}) => {
  const [open, setOpen] = React.useState(false);

  const [getCustomer, { loading, data }] = useLazyQuery<
    CustomerSearchI,
    CustomerSearchVariables
  >(CUSTOMERS_SEARCH, {
    onError: () => { }
  });

  /**
   * fetches Customer data if search query length is >1
   * @param search string
   */
  const fetchCustomer = (search: string) => {
    if (search.length > 1) {
      getCustomer({
        variables: {
          lastname: search,
          firstname: search,
          offset: 0,
          limit: 100,
          field: CustomerSortField.lastname,
          direction: OrderDirection.asc,
        },
      });
    }
  };

  /**
   * handles customer selection
   * @param event event
   * @param values object
   */
  const selectCustomer = (values: CustomerSearch_getCustomers_items | null) => {
    if (values?.id) {
      getCustomerDetail({ variables: { id: values.id } });
    }
  };

  const handleSearchRemove = () => {
    onSearchRemove && onSearchRemove();
  };

  return (
    <Box display="flex" flex={1} data-test="customerSearch">
      <Box flex={1} bgcolor="#fff" borderRadius={1}>
        <Autocomplete
          fullWidth
          id="customer-search-input"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={data?.getCustomers.items || []}
          loading={loading}
          renderOption={(props, option: CustomerSearch_getCustomers_items) => {
            return (
              <Typography {...props} key={option.id}>
                {`${option.firstname} ${option.lastname}`}
              </Typography>
            );
          }}
          loadingText={
            <div data-test="serach-loading">
              <FormattedMessage id="app.customerOrderDraft.customer.search.loading" />
            </div>
          }
          noOptionsText={
            <div data-test="serach-noOptions">
              <FormattedMessage id="app.customerOrderDraft.customer.search.noOptions" />
            </div>
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option: CustomerSearch_getCustomers_items) =>
            `${option.firstname} ${option.lastname}`
          }
          onChange={(_, values) => selectCustomer(values)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<FormattedMessage id={'app.common.search'} />}
              InputLabelProps={{
                id: 'searchLabel',
              }}
              variant="outlined"
              onChange={(event) => fetchCustomer(event.target.value)}
              InputProps={{
                ...params.InputProps,
                onClick: handleSearchRemove,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
      {handleSetCreateCustomer ? (
        <Box display="flex" ml={2}>
          <Button
            data-test="search-newCustomer"
            t="app.customerOrderDraft.customer.newCustomer"
            color="primary"
            variant="contained"
            onClick={handleSetCreateCustomer}
            disableElevation
          />
        </Box>
      ) : null}
    </Box>
  );
};

CustomerSearch.propTypes = {
  handleSetCreateCustomer: PropTypes.func,
  getCustomerDetail: PropTypes.func.isRequired,
};
CustomerSearch.defaultProps = {};

export default CustomerSearch;
