import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

export interface DateRowProps {
  date?: string;
  time?: boolean;
}

const DateRow: React.FC<React.PropsWithChildren<DateRowProps>> = ({
  date,
  time,
}): React.ReactElement => {
  return date ? (
    <Typography>
      {dayjs(date).format(time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')}
    </Typography>
  ) : (
    <Typography>-</Typography>
  );
};

DateRow.propTypes = {
  date: PropTypes.string,
  time: PropTypes.bool,
};
DateRow.defaultProps = {
  time: false,
  date: undefined,
};

export default DateRow;
