import React from 'react';
import { GridRowParams } from '@mui/x-data-grid';

import CustomerOrdersTable from '../../../components/Table/Tables/CustomerOrdersTable';
import { useHistory } from 'react-router-dom';
import { CustomerOrderStatus } from '../../../types/graphql-global-types';

export interface CustomerOrdersTabsProps {}

const CustomerOrdersTabs: React.FC<React.PropsWithChildren<CustomerOrdersTabsProps>> = () => {
  const history = useHistory();

  const handleRowClick = (rowData: GridRowParams) => {
    history.push(`/customerOrders/${rowData.row.id}`);
  };

  return (
    <div>
      <CustomerOrdersTable
        onRowClick={handleRowClick}
        persistedKey="tabCustomerOrders"
        initialFilterModel={{
          [CustomerOrderStatus.OPEN]: true,
          [CustomerOrderStatus.INVOICED]: true,
          [CustomerOrderStatus.CANCELED]: true,
          [CustomerOrderStatus.COSTESTIMATION]: false,
        }}
      />
    </div>
  );
};

CustomerOrdersTabs.propTypes = {};
CustomerOrdersTabs.defaultProps = {};

export default CustomerOrdersTabs;
