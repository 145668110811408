import { Machine, assign } from 'xstate';
import assertEventType from '../../../../lib/helper/xstateHelper';
import { CustomerContext, CustomerEvent, CustomerSchema } from './types';

const customerMachine = Machine<CustomerContext, CustomerSchema, CustomerEvent>(
  {
    id: 'customer',
    initial: 'searching',
    context: {
      customerId: null,
    },
    states: {
      searching: {
        on: {
          CREATE_CUSTOMER: {
            target: 'creating',
          },
          SET_CUSTOMER: {
            target: 'selected',
            actions: 'set',
          },
        },
      },

      creating: {
        on: {
          SET_CUSTOMER: {
            target: 'selected',
            actions: 'set',
          },
          CLEAR_CUSTOMER: {
            target: 'searching',
            actions: 'clear',
          },
        },
      },

      editing: {
        on: {
          UPDATE_SUCCESS: {
            target: 'selected',
          },

          UPDATE_ERROR: {
            target: 'searching',
          },
        },
      },

      selected: {
        on: {
          UPDATE_CUSTOMER: {
            target: 'editing',
          },
          CLEAR_CUSTOMER: {
            target: 'searching',
            actions: 'clear',
          },
        },
      },
    },
  },
  {
    actions: {
      set: assign({
        customerId: (_, e) => {
          assertEventType(e, 'SET_CUSTOMER');
          return e.value;
        },
      }),
      clear: assign({
        customerId: (_, e) => {
          assertEventType(e, 'CLEAR_CUSTOMER');
          return null;
        },
      }),
    },
  }
);

export default customerMachine;
