import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../Link';
import NameRow from '../NameRow';

export interface Customer {
  lastname: string;
  firstname: string;
  id: string;
}

export interface CustomerRowProps {
  customer: Customer;
}

const CustomerRow: React.FC<React.PropsWithChildren<CustomerRowProps>> = ({ customer }) => {
  return (
    <Link
      to={`/customers/${customer.id}`}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      <NameRow name={customer} />
    </Link>
  );
};

CustomerRow.propTypes = {
  customer: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
CustomerRow.defaultProps = {};

export default CustomerRow;
