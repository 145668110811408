import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import PageNavigation from './PageNavigation';

export interface PageContainerProps {
  page: string;
  titleParams?: Record<string, any>;
  children: React.ReactNode;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      marginTop: theme.mixins.toolbar.minHeight,
      paddingTop: theme.spacing(2),
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
      },
    },
  })
);

/**
 * Container Component for Pages. Manages AppBar and Drawer, passes Page children to HTML main with styling
 */
const PageContainer: React.FC<React.PropsWithChildren<PageContainerProps>> = ({
  page,
  titleParams,
  children,
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flex={1} bgcolor="background.default">
      <PageNavigation
        page={page}
        title={`page.${page}`}
        titleParams={titleParams}
      />

      {/* Page Content */}
      <Box
        className={classes.page}
        component="main"
        display="flex"
        flexGrow={1}
        overflow="hidden"
        flexDirection="column"
        position="relative"
      >
        {loading && (
          <Box position="absolute" top={0} width="100%" marginLeft={-3}>
            <LinearProgress />
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};

PageContainer.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
};
PageContainer.defaultProps = {
  loading: false,
};

export default PageContainer;
