import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import FormSwitch from '../Components/FormSwitch';
import Box from '@mui/material/Box';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';
import FormNotes from '../Components/FormNotes';

export interface EditBikeDialogFormProps {
  bike?: any;
  onClose: () => void;
  onSubmit: any;
}

const EditBikeDialogForm: React.FC<React.PropsWithChildren<EditBikeDialogFormProps>> = ({
  bike,
  onClose,
  onSubmit,
}) => {
  return (
    <FormContainer onSubmit={onSubmit} id="editBike-form">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <FormSwitch
            name="isEbike"
            defaultValue={bike?.isEbike}
            label="model.bike.isEbike"
          />
        </Box>
        <CreatedUpdatedDates
          createdAt={bike?.createdAt}
          updatedAt={bike?.updatedAt}
        />
      </Box>

      <TextField
        name="brand"
        label="model.bike.brand"
        defaultValue={bike?.brand}
        formInputOptions={{}}
      />
      <TextField
        name="model"
        label="model.bike.model"
        defaultValue={bike?.model}
        formInputOptions={{}}
      />
      <TextField
        name="type"
        label="model.bike.type"
        defaultValue={bike?.type}
        formInputOptions={{}}
      />
      <TextField
        name="color"
        label="model.bike.color"
        defaultValue={bike?.color}
        formInputOptions={{}}
      />
      <TextField
        name="frameNumber"
        label="model.bike.frameNumber"
        defaultValue={bike?.frameNumber}
        formInputOptions={{}}
      />
      <FormNotes name="notes" defaultValue={bike?.notes} />
    </FormContainer>
  );
};

EditBikeDialogForm.propTypes = {
  bike: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
EditBikeDialogForm.defaultProps = {
  bike: undefined,
};

export default EditBikeDialogForm;
