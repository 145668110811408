import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CategoriesTreeView from '../CategoriesTreeView';
import useMediaQuery from '@mui/material/useMediaQuery';
import cslx from 'clsx';

import {
  GET_WORKSTEP_CATEGORY,
  GET_WORKSTEP_ROOT_CATEGORIES,
} from '../../gql/workStep/queries';
import CategoryDetail from './CategoryDetail';
import { UPDATE_WORKSTEP_CATEGORY } from '../../gql/workStep/mutations';
import CreateWorkStepCategoryDialog from '../Dialog/CreateWorkStepCategory';
import WorkStepDefinitionDialog, {
  WorkStep,
} from '../Dialog/WorkStepDefinition';
import {
  Category,
  handleCategoryUpdate,
} from '../../lib/helper/treeViewHelper';
import Paper from '../Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullHeight: {
      height: '100%',
      overflow: 'hidden',
    },
    rotate: {
      transform: 'rotate(90deg)',
    },
  })
);

export interface WorkstepEditProps {}

const WorkstepEdit: React.FC<React.PropsWithChildren<WorkstepEditProps>> = () => {
  const classes = useStyles();
  const [treeData, setTreeData] = React.useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<Category>();
  const [openAddCategory, setOpenAddCategory] = React.useState<boolean>(false);
  const [openAddWorkStep, setOpenAddWorkStep] = React.useState<boolean>(false);
  const [openEditWorkStep, setOpenEditWorkStep] =
    React.useState<boolean>(false);
  const [editWorkStep, setEditWorkStep] = React.useState<WorkStep>();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  /**
   * Query rootCategories. Since we use only one -> take only the first one.
   */
  const { loading } = useQuery(GET_WORKSTEP_ROOT_CATEGORIES, {
    onCompleted: (data) => {
      //set initial category
      setSelectedCategory(data?.getWorkStepCategories?.[0]);
      // set initial treeData
      setTreeData(data?.getWorkStepCategories?.[0]?.subCategories);
    },
    onError: () => {},
  });

  /**
   * Query Detail Information for a specific category
   */
  const [getProductCategoryDetail, { loading: detailLoading }] = useLazyQuery(
    GET_WORKSTEP_CATEGORY,
    {
      onCompleted: (data) => {
        handleCategoryUpdate(
          data.getWorkStepCategory,
          setTreeData,
          setSelectedCategory
        );
      },
      onError: () => {},
      fetchPolicy: 'network-only',
    }
  );

  /**
   * Mutate category
   */
  const [updateCategory, { loading: updateCategoryLoading }] = useMutation(
    UPDATE_WORKSTEP_CATEGORY,
    {
      onCompleted: (data) => {
        handleCategoryUpdate(
          data.updateWorkStepCategory,
          setTreeData,
          setSelectedCategory
        );
      },
      onError: () => {},
    }
  );

  /**
   * Called whan details of a category got updated. Triggers mutation.
   * @param data Category data
   */
  const onCategoryUpdate = (data: Category) => {
    updateCategory({ variables: { data: data } });
  };

  /**
   * Called after something was successfully added.
   * Refetches parentCategory and closes dialog
   */
  const handleOnCreated = () => {
    //refetch
    getProductCategoryDetail({ variables: { id: selectedCategory?.id } });

    //close dialogs
    setOpenAddCategory(false);
    setOpenAddWorkStep(false);
    setOpenEditWorkStep(false);
  };

  /**
   * Called to open AddCategoryDialog. wrapper of setState, passed down to child component
   */
  const handleAddCategory = () => {
    setOpenAddCategory(true);
  };

  const handleAddWorkStep = () => {
    setOpenAddWorkStep(true);
  };

  const handleEditWorkStep = (workStep: WorkStep) => {
    setEditWorkStep(workStep);
    setOpenEditWorkStep(true);
  };

  return (
    <>
      <CreateWorkStepCategoryDialog
        parentCategory={openAddCategory ? selectedCategory?.id || null : null}
        onCreated={handleOnCreated}
        onClose={() => setOpenAddCategory(false)}
      />
      {openAddWorkStep && (
        <WorkStepDefinitionDialog
          open={openAddWorkStep}
          parentCategory={selectedCategory?.id || null}
          workStepDefinition={undefined}
          onCreated={handleOnCreated}
          onClose={() => setOpenAddWorkStep(false)}
        />
      )}
      {openEditWorkStep && (
        <WorkStepDefinitionDialog
          open={openEditWorkStep}
          parentCategory={null}
          workStepDefinition={editWorkStep}
          onCreated={handleOnCreated}
          onClose={() => setOpenEditWorkStep(false)}
        />
      )}
      <Grid container spacing={3} className={classes.fullHeight}>
        <Grid item xs={12} md={4}>
          <Paper padding fullHeight>
            <CategoriesTreeView
              title="page.worksteps.categories"
              isLoading={loading || detailLoading || updateCategoryLoading}
              treeData={treeData}
              onCategorySelect={(id: string) =>
                getProductCategoryDetail({ variables: { id } })
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <ArrowForwardIosIcon
              color="primary"
              fontSize="large"
              className={cslx({
                [classes.rotate]: !matches,
              })}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box height="100%">
            <CategoryDetail
              isLoading={loading || detailLoading || updateCategoryLoading}
              selectedCategory={selectedCategory}
              onCategoryUpdate={onCategoryUpdate}
              handleAddCategory={handleAddCategory}
              handleAddWorkStep={handleAddWorkStep}
              handleEditWorkStep={handleEditWorkStep}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

WorkstepEdit.propTypes = {};
WorkstepEdit.defaultProps = {};

export default WorkstepEdit;
