import { assign } from 'xstate';
import {
  getRepair_getRepair_workSteps,
  getRepair_getRepair_workSteps_requiredProducts,
} from '../../../../gql/repair/types/getRepair';
import assertEventType from '../../../../lib/helper/xstateHelper';
import {
  RepairProcessingContext as RPContext,
  RepairProcessingEvent as RPEvent,
} from '../types';

export const setRepairData = assign<RPContext, RPEvent>({
  repairData: (_: RPContext, e: RPEvent) => {
    assertEventType(e, 'done.invoke.getRepairData');

    return e.data;
  },
});

// export const setUserSkillLevel = assign<RPContext, RPEvent>({
//   userSkilllevel: (_: RPContext, e: RPEvent) => {
//     assertEventType(e, '');

//     return e.skillLevel;
//   },
// });

export const updateRepairData = assign<RPContext, RPEvent>({
  repairData: (ctx: RPContext, e: any) => {
    return {
      ...ctx.repairData,
      ...e.data,
    };
  },
});

export const updateWorkStepDoneData = assign<RPContext, RPEvent>({
  repairData: (ctx: RPContext, e: RPEvent) => {
    assertEventType(e, 'done.invoke.updatingWorkStepDone');

    if (!ctx.repairData) return ctx.repairData; // lol

    return {
      ...ctx.repairData,
      workSteps: ctx.repairData?.workSteps.map(
        (w: getRepair_getRepair_workSteps) => {
          return w.id === e.data.id ? { ...w, ...e.data } : w;
        }
      ),
    };
  },
});

export const updateWorkStepData = assign<RPContext, RPEvent>({
  repairData: (ctx: RPContext, e: RPEvent) => {
    assertEventType(e, 'done.invoke.updatingWorkStep');
    if (!ctx.repairData) return ctx.repairData; // lol

    return {
      ...ctx.repairData,
      workSteps: ctx.repairData?.workSteps.map(
        (w: getRepair_getRepair_workSteps) => {
          return w.id === e.data.id ? { ...w, ...e.data } : w;
        }
      ),
    };
  },
});

export const updateProductQuantityData = assign<RPContext, RPEvent>({
  repairData: (ctx: RPContext, e: RPEvent) => {
    assertEventType(e, 'done.invoke.updatingProductQuantity');

    if (!ctx.repairData) return ctx.repairData;

    return {
      ...ctx.repairData,
      workSteps: ctx.repairData.workSteps.map(
        (w: getRepair_getRepair_workSteps) => {
          const requiredProducts = w.requiredProducts?.map(
            (r: getRepair_getRepair_workSteps_requiredProducts) => {
              return r.id === e.data.id ? { ...r, ...e.data } : r;
            }
          );
          return { ...w, requiredProducts };
        }
      ),
    };
  },
});

export const toggleIsRepairUpdating = assign<RPContext, RPEvent>({
  isRepairUpdating: (ctx: RPContext) => !ctx.isRepairUpdating,
});

export const toggleIsWorkStepUpdating = assign<RPContext, RPEvent>({
  isWorkStepUpdating: (ctx: RPContext) => !ctx.isWorkStepUpdating,
});
