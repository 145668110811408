import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';

import DialogBase from '..';
import Button from '../../Button';
import CreateNoteForm from '../../Form/CreateNoteForm';
import { CreateNoteInput } from '../../../types/graphql-global-types';
import {
  createNote as createNoteI,
  createNoteVariables,
} from '../../../gql/notes/types/createNote';
import { CREATE_NOTE } from '../../../gql/notes/mutations';

export interface CreateNoteDialogProps {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const CreateNoteDialog: React.FC<React.PropsWithChildren<CreateNoteDialogProps>> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const [createNote, { loading }] = useMutation<
    createNoteI,
    createNoteVariables
  >(CREATE_NOTE, {
    onCompleted: () => {
      onSubmit();
    },
    onError: () => {},
  });

  const handleCreateNote = (data: CreateNoteInput) => {
    createNote({ variables: { data } });
  };

  return (
    <DialogBase
      open={open}
      isLoading={loading}
      primaryAction={undefined}
      isForm
      fullWidth
      maxWidth="sm"
      title="app.dialog.createNote.title"
    >
      <Box
        bgcolor="background.default"
        borderRadius={1}
        p={2}
        m={2}
        mt={0}
      >
        <CreateNoteForm onSubmit={handleCreateNote} />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onCancel}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            form="createNote-form"
          />
        </DialogActions>
      </Box>
    </DialogBase>
  );
};

CreateNoteDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
CreateNoteDialog.defaultProps = {};

export default CreateNoteDialog;
