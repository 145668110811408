import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_LOCATION_NAME } from '../../../../gql/location/queries';
import { Typography } from '@mui/material';
import { getLocationName } from '../../../../gql/location/types/getLocationName';

export interface LocationRowProps {
  locationId: string;
}

const LocationRow: React.FC<React.PropsWithChildren<LocationRowProps>> = ({
  locationId,
}) => {
  const { data } = useQuery<getLocationName>(GET_LOCATION_NAME, {
    fetchPolicy: 'cache-first',
    variables: { id: locationId },
  });

  return <Typography>{data?.getLocation.name ?? '-'}</Typography>;
};

LocationRow.propTypes = {
  locationId: PropTypes.string.isRequired,
};
LocationRow.defaultProps = {};

export default LocationRow;
