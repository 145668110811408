import { Machine } from 'xstate';

import { OrderDraftContext, OrderDraftEvent, OrderDraftSchema } from './types';

import {
  createCustomerOrder,
  updateCustomerOrderCostEstimation,
} from './helpers';
import {
  spawnSubMachines,
  setCostEstimationData,
  setCostEstimationFalse,
  updateInvoiceInfo,
} from './actions';
import assertEventType from '../../../../lib/helper/xstateHelper';

const initialContext = {
  customerMachine: undefined,
  subOrdersMachine: undefined,
  costEstimationData: undefined,
  isCostEstimation: true,
  invoiceInfo: null,
};

const orderDraftMachine = Machine<
  OrderDraftContext,
  OrderDraftSchema,
  OrderDraftEvent
>(
  {
    context: initialContext,
    entry: 'spawnSubMachines',
    id: 'orderDraft',
    initial: 'editing',
    states: {
      editing: {
        on: {
          SHOW_COSTESTIMATION: {
            target: 'costEstimateCheck',
          },
          CREATE_CUSTOMER_ORDER: {
            target: 'creatingCustomerOrder',
          },
          UPDATE_INVOICE_INFO: {
            actions: 'updateInvoiceInfo',
            target: 'editing',
          },
        },
      },
      creatingCustomerOrder: {
        invoke: {
          src: (_, e) => {
            assertEventType(e, 'CREATE_CUSTOMER_ORDER');
            return createCustomerOrder(
              e.customerId,
              e.repairs,
              e.orders,
              e.invoiceInfo
            );
          },
          id: 'createCustomerOrder',
          onDone: [
            {
              actions: 'setCostEstimationData',
              target: 'costEstimateCheck',
            },
          ],
          onError: [
            {
              target: 'editing',
            },
          ],
        },
      },
      costEstimateCheck: {
        on: {
          EDIT_ORDER: {
            target: 'editing',
          },
          ADD_CUSTOMER_ORDER: {
            target: 'addCustomerOrder',
          },
        },
      },
      addCustomerOrder: {
        invoke: {
          src: (ctx, e) => {
            assertEventType(e, 'ADD_CUSTOMER_ORDER');
            return updateCustomerOrderCostEstimation(
              ctx?.costEstimationData?.id
            );
          },
          id: 'updateOrderCostEstimation',
          onDone: [
            {
              target: 'completed',
            },
          ],
          onError: [
            {
              target: 'costEstimateCheck',
            },
          ],
        },
      },
      completed: {
        entry: 'setCostEstimationFalse',
        type: 'final',
      },
    },
  },
  {
    actions: {
      spawnSubMachines: spawnSubMachines,
      setCostEstimationData: setCostEstimationData,
      setCostEstimationFalse: setCostEstimationFalse,
      updateInvoiceInfo: updateInvoiceInfo,
    },
  }
);

export default orderDraftMachine;
