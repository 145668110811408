import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import FormSetValue from '../Components/FormSetValue';
import Typo from '../../Typo';
import CreatedUpdatedDates from '../Components/CreatedAndUpdatedDates';

export interface ProductQty {
  quantity: string;
  name: string;
  price: string;
  categoryId: string;
}

export interface CreateProductFormProps {
  parentCategory: string;
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const CreateProductForm: React.FC<React.PropsWithChildren<CreateProductFormProps>> = ({
  parentCategory,
  onSubmit,
  onClose,
}) => {
  const today = new Date().toUTCString();

  return (
    <FormContainer onSubmit={onSubmit} id="createProduct-form">
      <FormSetValue name="categoryId" value={parentCategory} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          {/* Just display the switch but not include isActive value in form (not needed for creation, always true) */}
          <Switch color="primary" checked disabled />
          <label>
            <Typo
              t="app.form.workStepCategory.isActive.true"
              display="inline"
            />
          </label>
        </Box>
        <CreatedUpdatedDates createdAt={today} updatedAt={today} />
      </Box>

      <TextField
        label="app.form.workStepdefinition.product.name"
        name="name"
        defaultValue={''}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        label="app.form.workStepdefinition.product.manufacturer"
        name="manufacturer"
        defaultValue={''}
        formInputOptions={{
          required: {
            value: false,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        label="app.form.workStepdefinition.product.description"
        name="description"
        defaultValue={''}
        multiline
        rows={3}
        formInputOptions={{
          required: {
            value: false,
            message: 'app.form.errors.required',
          },
        }}
      />
      <TextField
        name="price"
        label="model.repair.product.price"
        defaultValue={0}
        type="number"
        inputProps={{
          step: '0.01',
        }}
        formInputOptions={{
          required: {
            value: true,
            message: 'app.form.errors.required',
          },
          min: {
            value: 0.01,
            message: 'app.form.errors.min.one',
          },
          max: 9999,
        }}
      />
    </FormContainer>
  );
};

CreateProductForm.propTypes = {
  parentCategory: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
CreateProductForm.defaultProps = {};

export default CreateProductForm;
