import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '../Button';
import cslx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overviewButtonRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    secondaryAction: {
      marginRight: theme.spacing(2),
    },
    secondaryDelete: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
    },
    primaryDelete: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

export interface ButtonRowProps {
  onPrimaryAction: any;
  onSecondaryAction?: any;
  isPrimaryDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  primaryType?: 'button' | 'reset' | 'submit' | undefined;
  isSecondaryDelete?: boolean;
  isPrimaryDelete?: boolean;
  isElevationDisabled?: boolean;
}

const ButtonRow: React.FC<React.PropsWithChildren<ButtonRowProps>> = ({
  onPrimaryAction,
  onSecondaryAction = undefined,
  primaryLabel = undefined,
  secondaryLabel = undefined,
  isPrimaryDisabled = false,
  isSecondaryDisabled = false,
  isSecondaryDelete = false,
  isElevationDisabled = false,
  isPrimaryDelete = false,
  primaryType = 'button',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.overviewButtonRow}>
      {onSecondaryAction && (
        <Button
          data-test="buttonRowSecondary"
          t={secondaryLabel || 'app.common.defaultSecondary'}
          className={cslx(classes.secondaryAction, {
            [classes.secondaryDelete]: isSecondaryDelete,
          })}
          variant="outlined"
          color="primary"
          onClick={onSecondaryAction}
          disabled={isSecondaryDisabled}
          disableElevation={isElevationDisabled}
        />
      )}
      <Button
        data-test="buttonRowPrimary"
        t={primaryLabel || 'app.common.defaultPrimary'}
        className={cslx({
          [classes.primaryDelete]: isPrimaryDelete,
        })}
        variant="contained"
        color="primary"
        disabled={isPrimaryDisabled}
        onClick={onPrimaryAction}
        type={primaryType}
        disableElevation={isElevationDisabled}
      />
    </div>
  );
};

ButtonRow.propTypes = {
  onPrimaryAction: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  isPrimaryDisabled: PropTypes.bool,
  isSecondaryDisabled: PropTypes.bool,
  isSecondaryDelete: PropTypes.bool,
  isElevationDisabled: PropTypes.bool,
};
ButtonRow.defaultProps = {
  primaryLabel: undefined,
  secondaryLabel: undefined,
  isPrimaryDisabled: false,
  isSecondaryDisabled: false,
  isSecondaryDelete: false,
  isElevationDisabled: false,
  onSecondaryAction: undefined,
};

export default ButtonRow;
