import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import LocationIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import DialogBase from '..';
import client from '../../../lib/apolloClient';
import { getLocations } from '../../../gql/location/types/getLocations';
import {
  GET_LOCATIONS_SELECT,
  GET_LOCATION_NAME,
} from '../../../gql/location/queries';
import {
  updateUserLocation,
  updateUserLocationVariables,
} from '../../../gql/user/types/updateUserLocation';
import { UPDATE_USER_LOCATION } from '../../../gql/user/mutations';
import Typo from '../../Typo';
import { getLocationName } from '../../../gql/location/types/getLocationName';
import FormHelperText from '@mui/material/FormHelperText';

export interface UpdateLocationDialogProps {
  open: boolean;
  onClose: () => void;
}

const UpdateLocationDialog: React.FC<UpdateLocationDialogProps> = ({
  open,
  onClose,
}) => {
  const [value, setValue] = React.useState('');
  const intl = useIntl();

  const { data: currLocation, loading: currLocationLoading } =
    useQuery<getLocationName>(GET_LOCATION_NAME, {
      onError: () => {},
    });

  const { data, loading } = useQuery<getLocations>(GET_LOCATIONS_SELECT, {
    onError: () => {},
  });

  const [updateUserLocation, { loading: loadingUpdateUser }] = useMutation<
    updateUserLocation,
    updateUserLocationVariables
  >(UPDATE_USER_LOCATION, {
    onCompleted: () => {
      updateUserLocationSuccess();
    },
    onError: () => {},
  });

  const updateUserLocationSuccess = async () => {
    // reset cache to remove data of other locations
    await client.resetStore();

    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      open={open}
      title="app.dialog.updateLocation.title"
      secondaryAction={onClose}
      primaryAction={() =>
        updateUserLocation({
          variables: { data: { defaultLocationId: value } },
        })
      }
      primaryDisabled={!value}
      isLoading={loading || currLocationLoading || loadingUpdateUser}
    >
      <Box mb={1}>
        <Box mb={2} bgcolor="background.default" borderRadius={1} p={2}>
          <Typo t="app.dialog.updateLocation.current" />
          <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
            <LocationIcon color="primary" />
            <Typography fontWeight="bold" marginLeft={0.5}>
              {currLocation?.getLocation.name}
            </Typography>
          </Box>
        </Box>

        <Box mb={2} bgcolor="background.default" borderRadius={1} p={2}>
          <FormControl
            disabled={loading || currLocationLoading || loadingUpdateUser}
          >
            <FormLabel id="demo-controlled-radio-buttons-group">
              <Typo t="app.dialog.updateLocation.new.subTitle" />
            </FormLabel>
            <Box ml={0.5}>
              <RadioGroup value={value} onChange={handleChange}>
                {!loading && data?.getLocations
                  ? data?.getLocations.map((location) => (
                      <FormControlLabel
                        disabled={location.id === currLocation?.getLocation.id}
                        key={location.id}
                        value={location.id}
                        control={<Radio size="small" />}
                        label={location.name}
                      />
                    ))
                  : null}
              </RadioGroup>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <WarningAmberIcon color="primary" />
              <FormHelperText>
                {intl.formatMessage({
                  id: 'app.dialog.updateLocation.new.disclaimer',
                })}
              </FormHelperText>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </DialogBase>
  );
};

UpdateLocationDialog.propTypes = {};
UpdateLocationDialog.defaultProps = {};

export default UpdateLocationDialog;
