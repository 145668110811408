import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { getLocation_getLocation } from '../../../gql/location/types/getLocation';

export interface LocationInformationProps {
  location: getLocation_getLocation;
}

const LocationInformation: React.FC<
  React.PropsWithChildren<LocationInformationProps>
> = ({ location }) => {
  return (
    <Box display="flex" flexDirection="column" textAlign="right">
      <Typography>{location.compName}</Typography>
      <Typography>{location.street1}</Typography>
      {!!location.street2 && <Typography>{location.street2}</Typography>}
      <Typography>{`${location.postalCode ?? ''} ${
        location.city ?? ''
      }`}</Typography>
      <Typography>{location.phone}</Typography>
      <Typography>{location.email}</Typography>
    </Box>
  );
};

LocationInformation.propTypes = {};
LocationInformation.defaultProps = {};

export default LocationInformation;
