import { gql } from '@apollo/client';

export const UPDATE_REPAIR = gql`
  mutation updateRepair($data: UpdateRepair!) {
    updateRepair(data: $data) {
      id
      status
      cancelationReason
      completedDate
      pauseReason
      notes
      locationId
      productQuantityStatusSummary
      mechanic {
        id
        firstname
        lastname
      }
    }
  }
`;

export const VALIDATE_REPAIR = gql`
  mutation validateRepair($data: CreateRepairSum!) {
    validateRepair(data: $data) {
      workloadPrice
      workloadRate
      workloadSum
      productSum
      price
    }
  }
`;
