import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { getNotes_getNotes_items_comments } from '../../../../../../gql/notes/types/getNotes';
import Date from '../../../../../../components/Date';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: 24,
      width: 24,
      fontSize: 12,
    },
    myAvatar: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    userInfo: {
      fontSize: 12,
      color: theme.palette.grey[400],
    },
  })
);

export interface NoteCommentProps {
  content: getNotes_getNotes_items_comments;
  isMyComment: boolean;
}

const NoteComment: React.FC<React.PropsWithChildren<NoteCommentProps>> = ({ content, isMyComment }) => {
  const classes = useStyles();

  if (isMyComment) {
    return (
      <Box mt={2} display="flex" flexDirection="column">
        {/* avatar and infos */}
        <Box display="flex" flexDirection="row" fontSize={12}>
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Date
              className={classes.userInfo}
              date={content.createdAt}
              format="DD. MMMM YYYY HH:mm"
            />
          </Box>
          <Box ml={1}>
            <Avatar
              className={`${classes.avatar} ${classes.myAvatar}`}
              color="primary"
            >
              {`${content.user.firstname[0]}${content.user.lastname[0]}`}
            </Avatar>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" mr={4}>
          {/* content */}
          <Box
            display="inline-block"
            p={1}
            pl={2}
            pr={2}
            bgcolor="background.default"
            borderRadius="12px"
            maxWidth="80%"
          >
            <Typography>{content.content}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {/* avatar and infos */}
      <Box display="flex" fontSize={12}>
        <Avatar className={classes.avatar}>
          {`${content.user.firstname[0]}${content.user.lastname[0]}`}
        </Avatar>
        <Box ml={1} display="flex" flex={1} alignItems="center">
          <Typography className={classes.userInfo}>
            {`${content.user.firstname} ${content.user.lastname} - `}
          </Typography>
          <Date
            className={classes.userInfo}
            date={content.createdAt}
            format="DD. MMMM YYYY HH:mm"
          />
        </Box>
      </Box>

      <Box ml={4}>
        {/* content */}
        <Box
          display="inline-block"
          p={1}
          pl={2}
          pr={2}
          bgcolor="grey.200"
          borderRadius="12px"
          maxWidth="80%"
        >
          <Typography>{content.content}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

NoteComment.propTypes = {
  // content: PropTypes.shape({}).isRequired,
  isMyComment: PropTypes.bool.isRequired,
};
NoteComment.defaultProps = {};

export default React.memo(NoteComment);
