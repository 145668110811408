import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import { UPDATE_CUSTOMER_BIKE } from '../../../gql/customer/mutations/bike';
import BikeEditDialogForm from '../../Form/EditBikeDialogForm';
import Button from '../../Button';
import { getCustomer_getCustomer_bikes } from '../../../gql/customer/types/getCustomer';
import DialogBase from '..';
import {
  updateCustomerBike,
  updateCustomerBikeVariables,
} from '../../../gql/customer/mutations/types/updateCustomerBike';

export interface EditBikeDialogProps {
  bike?: getCustomer_getCustomer_bikes;
  onClose: () => void;
  onSubmit?: () => void;
}

const EditBikeDialog: React.FC<React.PropsWithChildren<EditBikeDialogProps>> = ({
  bike,
  onClose,
  onSubmit = undefined,
}) => {
  const [updateBike, { loading }] = useMutation<
    updateCustomerBike,
    updateCustomerBikeVariables
  >(UPDATE_CUSTOMER_BIKE, {
    onCompleted: () => (!!onSubmit ? onSubmit() : onClose()),
    onError: () => {},
  });

  const handleSubmit = (data: updateCustomerBikeVariables) => {
    updateBike({ variables: { data: { ...data, id: bike?.id || '' } } });
  };

  return (
    <DialogBase
      fullWidth
      maxWidth="sm"
      isLoading={loading}
      title="app.dialog.bike.edit.title"
      primaryAction={undefined}
      secondaryAction={onClose}
      isForm
      open={!!bike}
    >
      <Box
        bgcolor="background.default"
        borderRadius={1}
        p={2}
        m={2}
        mt={0}
      >
        <BikeEditDialogForm
          bike={bike}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            form="editBike-form"
          />
        </DialogActions>
      </Box>
    </DialogBase>
  );
};

EditBikeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};
EditBikeDialog.defaultProps = {
  bike: undefined,
  onSubmit: undefined,
};

export default EditBikeDialog;
