import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 400,
    },
    toolbar: {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#fff',
    },
    editor: {
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#fff',
      height: 150,
      padding: theme.spacing(0, 2),
    },
    inline: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
      border: 'none',
      padding: 0,
      minHeight: 0,
    },
  })
);

export interface WYSIWYGProps {
  inDialog?: boolean;
  initialContent?: RawDraftContentState;
  readOnly?: boolean;
  onContentChange?: (content: RawDraftContentState) => void;
}

type TableHandle = {
  getContent: () => void;
};

const WYSIWYG = React.forwardRef(
  (
    {
      initialContent,
      inDialog = false,
      readOnly = false,
      onContentChange = undefined,
    }: WYSIWYGProps,
    ref: React.Ref<TableHandle>
  ) => {
    const classes = useStyles();
    const [contentState, setContentState] =
      React.useState<RawDraftContentState>();

    const onContentStateChange = (contentState: RawDraftContentState) => {
      if (onContentChange) onContentChange(contentState);
      setContentState(contentState);
    };

    React.useImperativeHandle(ref, () => ({
      getContent() {
        return contentState;
      },
    }));

    // FIXME: ugly fake typeguard.
    const tg = (tbd: any) => {
      if (!!tbd?.blocks && !!tbd?.entityMap) {
        return true;
      }
      return false;
    };

    return (
      <Editor
        toolbarHidden={readOnly}
        readOnly={readOnly}
        initialContentState={tg(initialContent) ? initialContent : undefined}
        onContentStateChange={onContentStateChange}
        wrapperClassName={classes.container}
        toolbarClassName={classes.toolbar}
        editorClassName={clsx({
          [classes.editor]: inDialog,
          [classes.inline]: !inDialog,
        })}
        toolbar={{
          options: [
            'inline',
            'blockType',
            // 'fontSize',
            'list',
            // 'textAlign',
            'link',
            // 'fontFamily',
            // 'colorPicker',
            // 'embedded',
            // 'emoji',
            // 'image',
            // 'remove',
            // 'history',
          ],
          inline: {
            options: [
              'bold',
              'italic',
              // 'underline',
              // 'strikethrough',
              // 'superscript',
              // 'monospace',
              // 'subscript',
            ],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3'],
          },
          link: { inDropdown: true },
          list: { inDropdown: true },
          // textAlign: { inDropdown: true },
          // history: { inDropdown: true },
        }}
      />
    );
  }
);

WYSIWYG.propTypes = {};
WYSIWYG.defaultProps = {};

export default WYSIWYG;
