import { gql } from '@apollo/client';

/**
 * Updates Password
 */
export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      id
    }
  }
`;

export const UPDATE_USER_LOCATION = gql`
  mutation updateUserLocation($data: UpdateUserInput!) {
    updateUser(data: $data) {
      defaultLocationId
    }
  }
`;
