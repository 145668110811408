import React from 'react';

import PageContainer from '../../components/PageContainer';
import SettingsList from './SettingsList';

export interface SettingsPageProps {}

const SettingsPage: React.FC<React.PropsWithChildren<SettingsPageProps>> = () => {
  return (
    <PageContainer page="settings">
      <SettingsList />
    </PageContainer>
  );
};

SettingsPage.propTypes = {};
SettingsPage.defaultProps = {};

export default SettingsPage;
