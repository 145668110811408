import React from 'react';
import { GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import TableBase from '../../../components/Table/TableBase';
import PriceRow from '../../../components/Table/Rows/PriceRow';
import RepairProductStatusChip from '../../../components/RepairProductStatusChip';
import { ProductQuantityStatus } from '../../../types/graphql-global-types';
import { getCustomerOrder_getCustomerOrder_productOrders_products } from '../../../gql/customerOrder/types/getCustomerOrder';

export interface ProductOrdersTableProps {
  productOrders?:
    | getCustomerOrder_getCustomerOrder_productOrders_products[]
    | null;
  loading: boolean;
  onProductOrderClick: (params: GridRowParams) => void;
}

const ProductOrdersTable: React.FC<
  React.PropsWithChildren<ProductOrdersTableProps>
> = ({ productOrders, loading, onProductOrderClick }) => {
  const intl = useIntl();

  const columns: GridColDef[] = [
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.productQuantities.name',
      }),
      field: 'product.name',
      flex: 2,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Typography>{params.row?.product?.name}</Typography>
      ),
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.productQuantities.quantity',
      }),
      field: 'quantity',
      flex: 0.8,
      sortable: true,
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.productQuantities.price',
      }),
      field: 'product.price',
      flex: 0.8,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <PriceRow price={params.row?.product?.price as number} />
      ),
    },
    {
      headerName: intl.formatMessage({
        id: 'app.table.colDef.productQuantities.status',
      }),
      field: 'status',
      flex: 1,
      sortable: true,
      renderCell: (params: GridCellParams) => (
        <RepairProductStatusChip
          status={params.value as ProductQuantityStatus}
        />
      ),
    },
  ];

  return (
    <TableBase
      onPaper
      title="app.table.colDef.productQuantities.order"
      columns={columns}
      rows={productOrders || []}
      loading={loading}
      rowsPerPageOptions={[3]}
      pageSize={3}
      rowCount={productOrders?.length || 0}
      onRowClick={onProductOrderClick}
      sortingMode="client"
      filterMode="client"
    />
  );
};

ProductOrdersTable.propTypes = {};
ProductOrdersTable.defaultProps = {};

export default ProductOrdersTable;
