import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { RawDraftContentState } from 'draft-js';
import IconButton from '@mui/material/IconButton';
import WYSIWYG from '../../../WYSIWYG';
import { useQuery } from '@apollo/client';

import Paper from '../../../Paper';
import Typo from '../../../Typo';
import SkillLevelChip from '../../../SkillLevelChip';
import RepairStatusChip from '../../../RepairStatusChip';
import Date from '../../../Date';
import { formattedCurrency } from '../../../../lib/helper/numbers';
import Button from '../../../Button';
import CustomerInformation from '../../../CustomerInformation';
import WYSIWYGDialog from '../../../Dialog/WYSISWYG';
import { RepairStatus } from '../../../../types/graphql-global-types';
import { GET_LOCATIONS } from '../../../../gql/location/queries';
import {
  getLocations,
  getLocations_getLocations,
} from '../../../../gql/location/types/getLocations';
import { getRepair_getRepair } from '../../../../gql/repair/types/getRepair';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    spaceTop: {
      marginTop: theme.spacing(1),
    },
    spaceBottom: {
      marginBottom: theme.spacing(1),
    },
  })
);

export interface RepairOverviewProps {
  repairData?: getRepair_getRepair;
  handleUpdateNotes: (notes: RawDraftContentState) => void;
  isManager: boolean;
  loading: boolean;
}

const RepairOverview: React.FC<
  React.PropsWithChildren<RepairOverviewProps>
> = ({ repairData, handleUpdateNotes, isManager, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState<getLocations_getLocations>();

  const handleNotesUpdate = (notes: RawDraftContentState) => {
    handleUpdateNotes(notes);
    setOpenNotes(false);
  };

  const { data } = useQuery<getLocations>(GET_LOCATIONS);

  React.useEffect(() => {
    setLocation(
      data?.getLocations.find(
        (location: getLocations_getLocations) =>
          location.id === repairData?.locationId
      )
    );
  }, [data, repairData]);

  React.useEffect(() => {
    setEditable(
      repairData?.status !== RepairStatus.CANCELED &&
        repairData?.status !== RepairStatus.INVOICED
    );
  }, [repairData]);

  return (
    <>
      <WYSIWYGDialog
        open={openNotes}
        initialContent={repairData?.notes}
        onSubmit={handleNotesUpdate}
        onCancel={() => setOpenNotes(false)}
      />

      <Paper padding>
        {/* Header */}
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" mr={2}>
            <Box display="flex">
              {repairData?.status && (
                <Box mb={1} mr={1}>
                  <RepairStatusChip status={repairData?.status} />
                </Box>
              )}
              {repairData?.skillLevel && (
                <SkillLevelChip type={repairData?.skillLevel} />
              )}
            </Box>

            <Box>
              <Typo t="app.repairProcessing.overview.title" variant="h5" />
            </Box>
          </Box>

          {/* OPEN CUSTOMER ORDER */}
          {isManager && (
            <Box overflow="scroll">
              <Button
                fullWidth
                startIcon={<OpenInNewIcon />}
                variant="outlined"
                color="primary"
                t="app.repairProcessing.buttonRow.customerOrder"
                onClick={() =>
                  history.push(`/customerorders/${repairData?.customerOrderId}`)
                }
              />
            </Box>
          )}
        </Box>
        <Box height="70vh" overflow="auto">
          {/* Repair Info */}
          <Box bgcolor="background.default" borderRadius={1} p={2}>
            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.createdUser" />
              <Typography align="right">
                {`${repairData?.customerOrder?.createdUser?.firstname ?? '-'} ${
                  repairData?.customerOrder?.createdUser?.lastname ?? '-'
                }`}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.mechanic" />
              <Typography align="right">
                {`${repairData?.mechanic?.firstname ?? '-'} ${
                  repairData?.mechanic?.lastname ?? '-'
                }`}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.acceptedDate" />
              <Date date={repairData?.createdAt} format="DD.MM.YY - HH:mm" />
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.completedDate" />
              <Date
                date={repairData?.completedDate}
                format="DD.MM.YY - HH:mm"
              />
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.estimatedPickupDate" />
              <Date date={repairData?.estimatedPickupDate} format="DD.MM.YY" />
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.price" />

              <Typography>
                {formattedCurrency(repairData?.price || 0)}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.workLoad" />
              <Typography align="right">{repairData?.workloadSum}</Typography>
            </div>
          </Box>

          {/* Notes */}
          <Box bgcolor="background.default" borderRadius={1} p={2} mt={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typo t="app.repairProcessing.overview.notes" variant="h6" />
              {repairData && editable ? (
                <IconButton onClick={() => setOpenNotes(true)} size="large">
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
              ) : null}
            </Box>

            <Divider className={classes.spaceBottom} />

            {repairData && !loading && !!repairData?.notes?.blocks[0]?.text ? (
              <Box boxSizing="border-box" overflow="scroll">
                <WYSIWYG readOnly initialContent={repairData?.notes} />
              </Box>
            ) : null}
          </Box>

          {/* Bike Info */}
          <Box bgcolor="background.default" borderRadius={1} p={2} mt={2}>
            <Typo t="app.repairProcessing.overview.bike" variant="h6" />
            <Divider className={classes.spaceBottom} />

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.bike.location" />
              <Typography align="right">{location?.name ?? '-'}</Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.bike.type" />
              <Typography align="right">
                {repairData?.bike?.type ?? '-'}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.bike.brand" />
              <Typography align="right">
                {repairData?.bike?.brand ?? '-'}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.bike.model" />
              <Typography align="right">
                {repairData?.bike?.model ?? '-'}
              </Typography>
            </div>

            <div className={classes.box}>
              <Typo t="app.repairProcessing.overview.bike.color" />
              <Typography align="right">
                {repairData?.bike?.color ?? '-'}
              </Typography>
            </div>
          </Box>

          {/* Contact & Customer Info */}
          <Box bgcolor="background.default" borderRadius={1} p={2} mt={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typo t="app.repairProcessing.overview.customer" variant="h6" />
              <IconButton
                onClick={() =>
                  history.push(`/customers/${repairData?.customer?.id}`)
                }
                size="large"
              >
                <OpenInNewIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>
            <Divider className={classes.spaceBottom} />
            <CustomerInformation customer={repairData?.customer} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

RepairOverview.propTypes = {
  isManager: PropTypes.bool.isRequired,
};
RepairOverview.defaultProps = {};

export default RepairOverview;
