import React from 'react';
import constate from 'constate';
import { useMachine } from '@xstate/react';

import { me_me } from '../../gql/user/types/me';
import RepairProcessing from './RepairProcessing';
import repairProcessingMachine from './machine/RepairProcessingMachine';

export interface RepairProcessingProviderProps {
  repairCode: string;
  user: me_me;
}

function useRepairProcessing({
  repairCode,
  user,
}: RepairProcessingProviderProps) {
  const machine = useMachine(repairProcessingMachine, {
    context: { repairCode, user },
  });

  const [, , service] = machine;

  React.useEffect(() => {
    service.onTransition((state) => console.log('TRANSITION', state));
    // .onEvent((event) => console.log('EVENT', event));
  }, [service]);

  return machine;
}

export const [
  RepairProcessingMachineProvider,
  RepairProcessingMachineContext,
]: any = constate(useRepairProcessing);

const RepairProcessingProvider: React.FC<
  React.PropsWithChildren<RepairProcessingProviderProps>
> = ({ repairCode, user }) => {
  return (
    <RepairProcessingMachineProvider repairCode={repairCode} user={user}>
      <RepairProcessing />
    </RepairProcessingMachineProvider>
  );
};

RepairProcessingProvider.propTypes = {};
RepairProcessingProvider.defaultProps = {};

export default RepairProcessingProvider;
