import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { SEND_SMS_NOTIFICATION } from '../../gql/notifcation/mutations';
import { Typography } from '@mui/material';
import {
  sendNotification,
  sendNotificationVariables,
} from '../../gql/notifcation/types/sendNotification';
import { SMS_CHARACTER_LIMIT } from '../../lib/constants';

export interface SMSNotificationProps {
  phoneNumber?: string;
  defaultMessage: string;
  customerOrderId: string;
  isDialog?: boolean;
  onSent?: (data: object) => void;
}

type NotificationHandle = {
  sendMessage: () => void;
};

const SMSNotification = React.forwardRef(
  (
    {
      defaultMessage,
      phoneNumber = '',
      customerOrderId,
      isDialog = false,
      onSent = () => null,
    }: SMSNotificationProps,
    ref: React.Ref<NotificationHandle>
  ) => {
    const [message, setMessage] = React.useState<string>(defaultMessage);
    const [success, setSuccess] = React.useState<string | null>(null);

    const [sendSMS, { loading }] = useMutation<
      sendNotification,
      sendNotificationVariables
    >(SEND_SMS_NOTIFICATION, {
      onCompleted: (data) => {
        setSuccess(data?.sendNotification?.status);
        onSent(data);
      },
      onError: () => {},
    });

    // make sending available for parent
    React.useImperativeHandle(ref, () => ({
      sendMessage() {
        handleSend();
      },
    }));

    const handleSend = () => {
      sendSMS({
        variables: {
          data: {
            phonenumber: phoneNumber,
            message: message,
            customerOrderId: customerOrderId,
          },
        },
      });
    };

    const handleMessageUpdate = (message: string) => {
      setMessage(message);
      setSuccess(null);
    };

    return (
      <Box>
        <TextField
          fullWidth
          disabled
          value={phoneNumber}
          margin="dense"
          variant="outlined"
          label={<FormattedMessage id="app.smsNotification.phone" />}
        />
        <TextField
          fullWidth
          label={<FormattedMessage id="app.smsNotification.message" />}
          disabled={!phoneNumber || loading}
          inputProps={{
            maxLength: SMS_CHARACTER_LIMIT,
          }}
          value={message}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleMessageUpdate(event.target.value)
          }
          margin="normal"
          variant="outlined"
          multiline
          rows={5}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="caption">
              {`${message.length} / ${SMS_CHARACTER_LIMIT} `}
              <FormattedMessage id="app.smsNotification.characters" />
            </Typography>
          </Box>
          {isDialog ? (
            <>
              {loading && <CircularProgress color="primary" size={24} />}
              {success && <FormattedMessage id="app.smsNotification.sent" />}
            </>
          ) : null}
        </Box>

        {isDialog ? null : (
          <Box paddingTop={1}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled={!phoneNumber || loading}
              onClick={handleSend}
            >
              <Box marginRight={2}>
                {success && <FormattedMessage id="app.smsNotification.sent" />}
                {!success && <FormattedMessage id="app.smsNotification.send" />}
              </Box>

              {loading && <CircularProgress color="primary" size={24} />}
              {success && <CheckCircleIcon />}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

SMSNotification.propTypes = {
  phoneNumber: PropTypes.string,
  defaultMessage: PropTypes.string.isRequired,
  isDialog: PropTypes.bool,
  onSent: PropTypes.func,
  customerOrderId: PropTypes.string.isRequired,
};
SMSNotification.defaultProps = {
  phoneNumber: '',
};

export default SMSNotification;
