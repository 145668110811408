import React from 'react';
import { GridRowParams } from '@mui/x-data-grid';

import { ProductQuantityStatus } from '../../../types/graphql-global-types';
import UpdateProductStatus from '../../../components/Dialog/UpdateProductStatus';
import ProductQuantitiesTable from '../../../components/Table/Tables/ProductQuantitiesTable';

interface Product {
  id: string;
  status: ProductQuantityStatus;
}

export interface ProductQuantitiesTabProps {}

const ProductQuantitiesTab: React.FC<React.PropsWithChildren<ProductQuantitiesTabProps>> = () => {
  const [product, setProduct] = React.useState<Product>();

  const handleRowClick = (params: GridRowParams) => {
    // const { id, status, customerOrderId } = params.row;
    // const product: Product = { id: id as string, status: status };
    setProduct(params.row as Product);
  };

  const handleOnCompleted = () => {
    setProduct(undefined);
    // refetch();
  };

  return (
    <>
      {!!product && (
        <UpdateProductStatus
          product={product}
          onClose={() => setProduct(undefined)}
          onCompleted={handleOnCompleted}
          availableStati={[
            ProductQuantityStatus.OPEN,
            ProductQuantityStatus.AVAILABLE,
            ProductQuantityStatus.ORDERED,
            // ProductQuantityStatus.INSTALLED,
          ]}
          hideDelete
        />
      )}
      <ProductQuantitiesTable
        onRowClick={handleRowClick}
        persistedKey="tabProductQuantities"
        initialFilterModel={{
          [ProductQuantityStatus.OPEN]: true,
          [ProductQuantityStatus.AVAILABLE]: true,
          [ProductQuantityStatus.ORDERED]: true,
          [ProductQuantityStatus.INSTALLED]: false,
        }}
      />
    </>
  );
};

ProductQuantitiesTab.propTypes = {};
ProductQuantitiesTab.defaultProps = {};

export default ProductQuantitiesTab;
