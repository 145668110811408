import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import ReloadIcon from '@mui/icons-material/Refresh';

import Typo from '../Typo';
import NoRowsOverlay from './NoRowsOverlay';
import LoadingOverlay from './LoadingOverlay';
import Hidden from '@mui/material/Hidden';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      overflow: 'hidden',
    },
    noPaper: {
      border: '1px solid',
      borderColor: theme.palette.grey[300],
    },
    paper: {
      boxShadow: theme.shadows[2],
    },
    gridContainer: {},
    grid: {
      display: 'flex',
      border: 'none',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      // margin: theme.spacing(1),
      // padding: theme.spacing(1),
      marginTop: 0,
    },
    width: {
      minWidth: 800,
    },
  })
);

export interface TableBaseProps extends DataGridProps {
  onPaper?: boolean;
  onReload?: () => void;
  title?: string;
  FilterComponent?: React.ReactNode;
}

const TableBase: React.FC<React.PropsWithChildren<TableBaseProps>> = ({
  onPaper,
  title,
  onReload,
  FilterComponent = undefined,
  ...tableProps
}) => {
  const classes = useStyles();

  const getLimit = () => {
    const tableHeight = 53 + 56;
    const rowheight = 52;

    if (tableProps.pageSize === 3) {
      return tableHeight + rowheight * 3;
    }
    if (tableProps.pageSize === 5) {
      return tableHeight + rowheight * 5;
    }
    if (tableProps.pageSize === 10) {
      return tableHeight + rowheight * 10;
    }

    return undefined;
  };

  return (
    <div
      className={clsx(classes.container, {
        [classes.paper]: onPaper,
        [classes.noPaper]: !onPaper,
      })}
    >
      {/* Header bar with title and actions */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        {title && (
          <Box display="flex" height={42} paddingRight={2} alignItems="center">
            <Typo variant="h5" t={title} />
          </Box>
        )}

        {/* Actions Toolbar */}
        <Box
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* Toolbar */}
          <Hidden smDown>
            <Box
              display="flex"
              alignItems="center"
              width={'100%'}
              overflow="scroll"
            >
              {!!FilterComponent && FilterComponent}
            </Box>
          </Hidden>
          {/* Reload */}
          {!!onReload && (
            <IconButton onClick={onReload} size="large">
              <ReloadIcon color="primary" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Hidden smUp>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          width={'100%'}
          overflow="scroll"
        >
          {!!FilterComponent && FilterComponent}
        </Box>
      </Hidden>

      <div
        style={{
          height: getLimit(),
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <DataGrid
          className={clsx(classes.grid, {
            [classes.width]: tableProps.rows.length,
          })}
          // autoHeight
          // showCellRightBorder
          // showColumnRightBorder
          pagination
          disableColumnMenu
          paginationMode="server"
          sortingMode="server"
          hideFooterSelectedRowCount
          components={{
            NoRowsOverlay: NoRowsOverlay,
            LoadingOverlay: LoadingOverlay,
          }}
          {...tableProps}
        />
      </div>
    </div>
  );
};

TableBase.propTypes = {
  onPaper: PropTypes.bool,
  onReload: PropTypes.func,
  title: PropTypes.string,
  FilterComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
TableBase.defaultProps = {
  onPaper: false,
  title: undefined,
  onReload: undefined,
  FilterComponent: undefined,
};

export default React.memo(TableBase);
