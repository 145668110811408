import { gql } from '@apollo/client';

export const GET_TENANT_SETTINGS = gql`
  query getTenantSettings {
    getTenant {
      id
      name
      settings {
        id
        key
        value
      }
    }
  }
`;
