import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import Typo from '../../../../../components/Typo';
import { getNotes_getNotes_items_comments } from '../../../../../gql/notes/types/getNotes';
import {
  addComment as addCommentI,
  addCommentVariables,
} from '../../../../../gql/notes/types/addComment';
import { useNotes } from '../../../NotesContext';
import NoteComment from './NoteComment';
import Button from '../../../../../components/Button';
import { ADD_NOTE_COMMENT } from '../../../../../gql/notes/mutations';

export interface NoteCommentsProps {
  comments: getNotes_getNotes_items_comments[];
  noteId: string;
  disabled: boolean;
}

const NoteComments: React.FC<React.PropsWithChildren<NoteCommentsProps>> = ({
  comments,
  noteId,
  disabled,
}) => {
  const [content, setContent] = React.useState<string>();
  const [showNewComment, setShowNewComment] = React.useState(false);

  const { user, refetch, rerender, setRerender } = useNotes();

  const commentsEndRef = React.useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollTo(
      0,
      commentsEndRef?.current?.scrollHeight -
        commentsEndRef?.current?.clientHeight
    );
  };

  const [addComment] = useMutation<addCommentI, addCommentVariables>(
    ADD_NOTE_COMMENT,
    {
      onCompleted: () => {
        refetch && refetch();
        setContent('');
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleAddComment = () => {
    if (content) {
      addComment({ variables: { data: { content, noteId } } });
    }
  };

  const toggleShowComment = () => {
    setRerender(rerender + 1);
    setShowNewComment((prev) => !prev);
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [comments]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typo
          variant="h6"
          color="primary"
          t="page.notes.note.comments.title"
          values={{ count: comments.length }}
        />
        {!disabled ? (
          <IconButton onClick={toggleShowComment} size="large">
            <AddIcon color="primary" />
          </IconButton>
        ) : null}
      </Box>

      {/* comments */}
      <div style={{ maxHeight: 230, overflow: 'scroll' }} ref={commentsEndRef}>
        {comments?.length && user?.me.id
          ? comments.map((comment) => (
              <NoteComment
                key={comment.id}
                content={comment}
                isMyComment={user.me.id === comment.user.id}
              />
            ))
          : null}
      </div>

      {/* new comment */}
      {!disabled
        ? showNewComment && (
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <TextField
                value={content}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="dense"
                multiline
                rows={3}
              />
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  t="page.notes.note.comments.new"
                  onClick={handleAddComment}
                />
              </Box>
            </Box>
          )
        : null}
    </Box>
  );
};

NoteComments.propTypes = {
  noteId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
NoteComments.defaultProps = {};

export default NoteComments;
