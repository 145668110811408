import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import Button from '../../Button';

export interface EditWorkStep {
  id: String;
  setWorkload: string;
  workStepDefinition: {
    name: string;
  };
}

export interface EditWorkStepFormProps {
  workStep: EditWorkStep;
  onClose: () => void;
  onSubmit: (data: EditWorkStep) => void;
}

const EditWorkStepForm: React.FC<React.PropsWithChildren<EditWorkStepFormProps>> = ({
  workStep,
  onClose,
  onSubmit,
}) => {
  return (
    <FormContainer onSubmit={onSubmit}>
      <Box
        p={2}
        m={2}
        mt={0}
        bgcolor="background.default"
        borderRadius={1}
      >
        <TextField
          disabled
          name="name"
          label="model.repair.workStep"
          defaultValue={workStep?.workStepDefinition?.name ?? ''}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
          }}
        />
        <TextField
          name="setWorkload"
          label="model.repair.workLoad"
          defaultValue={workStep?.setWorkload ?? 1}
          type="number"
          inputProps={{
            step: '1',
          }}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
            min: {
              value: 1,
              message: 'app.form.errors.min.one',
            },
            max: 9999,
          }}
        />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
          />
        </DialogActions>
      </Box>
    </FormContainer>
  );
};

EditWorkStepForm.propTypes = {
  // product: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
EditWorkStepForm.defaultProps = {
  workStep: undefined,
};

export default EditWorkStepForm;
