import { gql } from '@apollo/client';

/**
 * Me Query. Fetches all user data
 */
export const ME = gql`
  query me {
    me {
      id
      username
      firstname
      lastname
      role
      email
      skillLevel
      defaultLocationId
      tenantId
    }
  }
`;

/**
 * get user id
 */
export const GET_USER_ID = gql`
  query userId {
    me {
      id
    }
  }
`;

export const GET_USER_DEFAULT_LOCATION = gql`
  query getUserDefaultLocation {
    me {
      id
      defaultLocationId
    }
  }
`;
