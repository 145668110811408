import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import DialogBase from '..';
import CreateOneTimeProductDialogForm, {
  ProductQty,
} from '../../Form/CreateOneTimeProductDialogForm';
import { CREATE_ONETIME_PRODUCT } from '../../../gql/product/mutations';
import { getPriceFloat } from '../../../lib/helper/numbers';
import { ProductQuantityStatus } from '../../../types/graphql-global-types';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createOneTimeProduct,
  createOneTimeProductVariables,
  createOneTimeProduct_createProduct,
} from '../../../gql/product/types/createOneTimeProduct';

export interface OneTimeProduct extends createOneTimeProduct_createProduct {
  quantity?: number;
  status?: ProductQuantityStatus;
}

export interface CreateOneTimeProductProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: OneTimeProduct) => void;
  rootCategory?: string;
}

const CreateOneTimeProduct: React.FC<React.PropsWithChildren<CreateOneTimeProductProps>> = ({
  open,
  onClose,
  onSubmit,
  rootCategory,
}) => {
  const [quantity, setQuantity] = React.useState<number>();
  const [status, setStatus] = React.useState<ProductQuantityStatus>();

  const [createOneTimeProduct, { loading }] = useMutation<
    createOneTimeProduct,
    createOneTimeProductVariables
  >(CREATE_ONETIME_PRODUCT, {
    onCompleted: (data) => {
      onSubmit({ ...data?.createProduct, quantity, status });
    },
    onError: () => {},
  });

  const handleSubmit = (data: ProductQty) => {
    const status = data.isAvailable
      ? ProductQuantityStatus.AVAILABLE
      : ProductQuantityStatus.OPEN;

    // need to temp safe status & quantity in component state
    setQuantity(parseInt(data?.quantity));
    setStatus(status);

    // create Product mutation
    createOneTimeProduct({
      variables: {
        data: {
          categoryId: rootCategory || '',
          name: data.name,
          price: getPriceFloat(data?.price),
          manufacturer: data.manufacturer || undefined,
        },
      },
    });
  };

  return (
    <DialogBase
      open={open}
      isForm
      isLoading={loading}
      title="app.dialog.createOneTimeProduct"
      keepMounted={false}
      onClose={onClose}
    >
      <CreateOneTimeProductDialogForm
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </DialogBase>
  );
};

CreateOneTimeProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rootCategory: PropTypes.string,
};
CreateOneTimeProduct.defaultProps = {
  rootCategory: '',
};

export default CreateOneTimeProduct;
