import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import { GET_REPAIRS_DETAILED } from '../../../../gql/repair/queries';
import RepairListItem from './RepairListItem';
import Typo from '../../../../components/Typo';
import Paper from '../../../../components/Paper';
import {
  OrderDirection,
  RepairSortField,
  RepairStatus,
} from '../../../../types/graphql-global-types';
import {
  getRepairsDetailed,
  getRepairsDetailedVariables,
} from '../../../../gql/repair/types/getRepairsDetailed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    empty: {
      margin: theme.spacing(2, 0),
    },
    container: {
      minWidth: 300,
    },
  })
);

export interface RepairsListProps {
  status: RepairStatus[];
}

const RepairsList: React.FC<React.PropsWithChildren<RepairsListProps>> = ({ status }) => {
  const classes = useStyles();

  const { data, loading, refetch } = useQuery<
    getRepairsDetailed,
    getRepairsDetailedVariables
  >(GET_REPAIRS_DETAILED, {
    variables: {
      status: status,
      offset: 0,
      limit: 100,
      field: RepairSortField.createdAt,
      direction: OrderDirection.asc,
    },
    fetchPolicy: 'network-only',
  });

  const repairs = data?.getRepairs.items;

  return (
    <Paper padding isLoading={loading} fullHeight className={classes.container}>
      {/* Title */}
      <Typo t={`model.repair.status.${status}`} variant="h5" color="primary" />

      {/* Filter Row */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typo
          t="app.dashboard.repairs.sum"
          values={{ count: repairs?.length ?? '-' }}
        />
        <IconButton onClick={() => (refetch ? refetch() : null)} size="large">
          <RefreshIcon color="primary" />
        </IconButton>
      </Box>
      <Divider />

      {/* List */}
      <Box overflow="auto" maxHeight="65vh">
        {repairs?.length ? (
          repairs.map((repair) => (
            <RepairListItem
              key={repair.id}
              id={repair?.id}
              repairCode={repair?.repairCode}
              status={repair?.status}
              skillLevel={repair?.skillLevel}
              workSteps={repair?.workSteps}
              workloadSum={repair?.workloadSum}
              productQuantityStatusSummary={
                repair?.productQuantityStatusSummary
              }
            />
          ))
        ) : (
          <Typo t="app.dashboard.repairs.emptyList" className={classes.empty} />
        )}
      </Box>
    </Paper>
  );
};

RepairsList.propTypes = {};
RepairsList.defaultProps = {};

export default React.memo(RepairsList);
