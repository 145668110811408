import React from 'react';
import Box from '@mui/material/Box';

import Repair from './Repair';
import Order from './ProductOrder';
import {
  SubOrderProductOrder,
  SubOrderRepair,
  SubOrderType,
} from '../../machines/subOrdersMachine/types';
import { CustomerDraftMachinesContext } from '../..';
import { RepairMachine } from '../../machines/subOrders/repair/types';
import { ProductOrderMachine } from '../../machines/subOrders/productOrder/types';
import { Prompt } from 'react-router-dom';
import { useIntl } from 'react-intl';

export interface SubOrdersProps {}

const SubOrders: React.FC<React.PropsWithChildren<SubOrdersProps>> = () => {
  const { customerMachine, subOrdersMachine } = CustomerDraftMachinesContext();

  const [state] = subOrdersMachine;
  const [customerState] = customerMachine;

  const intl = useIntl();

  const customerId = customerState?.context?.customerId ?? undefined;

  const { repairs: r, orders: o } = state.context;

  const repairs = React.useMemo(() => r, [r]);
  const orders = React.useMemo(() => o, [o]);

  const subOrders = React.useMemo(
    () =>
      [...repairs, ...orders].sort(
        (
          a: SubOrderRepair | SubOrderProductOrder,
          b: SubOrderRepair | SubOrderProductOrder
        ) => a.index - b.index
      ),
    [orders, repairs]
  );

  return (
    <>
      <Prompt
        when={
          !!subOrders.some(
            (subOrder: SubOrderRepair | SubOrderProductOrder) =>
              subOrder.completed
          )
        }
        message={intl.formatMessage({
          id: 'app.customerOrderDraft.dialog.message',
        })}
      />
      {subOrders.map((subOrder: SubOrderRepair | SubOrderProductOrder) => {
        if (subOrder.type === SubOrderType.REPAIR) {
          return (
            <Box key={subOrder.ref.id} marginTop={3}>
              <Repair
                machine={subOrder.ref as RepairMachine}
                customerId={customerId}
              />
            </Box>
          );
        }
        if (subOrder.type === SubOrderType.ORDER) {
          return (
            <Box key={subOrder.ref.id} marginTop={3}>
              <Order machine={subOrder.ref as ProductOrderMachine} />
            </Box>
          );
        }

        return null;
      })}
    </>
  );
};

SubOrders.propTypes = {};
SubOrders.defaultProps = {};

export default React.memo(SubOrders);
