import React from 'react';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import InboxIcon from '@mui/icons-material/Inbox';

import MagicGridWrapper from '../../../components/MagicGridWrapper';
import Note from './Note';
import { useNotes } from '../NotesContext';
import Typo from '../../../components/Typo';

export interface NotesContainerProps {}

const NotesContainer: React.FC<React.PropsWithChildren<NotesContainerProps>> = () => {
  const { notes, rerender, loading } = useNotes();

  React.useEffect(() => {}, [notes, rerender]);

  if (loading) return null;

  if (!loading && notes?.getNotes.totalCount === 0) {
    return (
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <InboxIcon color="primary" fontSize="large" />
        <Box mt={1}>
          <Typo t="page.notes.empty" />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Hidden smDown>
        <Box pt={4} pb={4}>
          {notes?.getNotes?.items?.length ? (
            <MagicGridWrapper items={notes.getNotes.items.length}>
              {notes?.getNotes?.items?.map((note) => (
                <Note key={note.id} note={note} />
              ))}
            </MagicGridWrapper>
          ) : null}
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box pt={2} pb={2}>
          {notes?.getNotes?.items?.map((note) => (
            <Box pb={2} pl={1} pr={1} key={note.id}>
              <Note note={note} />
            </Box>
          ))}
        </Box>
      </Hidden>
    </Box>
  );
};

NotesContainer.propTypes = {};
NotesContainer.defaultProps = {};

export default NotesContainer;
