import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LaunchIcon from '@mui/icons-material/Launch';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import {
  ProductQuantityStatusSummary,
  RepairStatus,
  SkillLevel,
} from '../../../../../types/graphql-global-types';
import WorkStepsTable from './WorkStepsTable';
import Typo from '../../../../../components/Typo';
import { getRepairsDetailed_getRepairs_items_workSteps } from '../../../../../gql/repair/types/getRepairsDetailed';
import ProductQuantityStatusSummaryChip from './ProductQuantityStatusSummaryChip';

export interface RepairListItemProps {
  id: string;
  repairCode: string;
  status: RepairStatus;
  skillLevel: SkillLevel;
  workSteps: getRepairsDetailed_getRepairs_items_workSteps[];
  workloadSum: number;
  productQuantityStatusSummary: ProductQuantityStatusSummary;
}

// user react-router link
const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
  (props, ref) => (
    <RouterLink ref={ref} to={props.href || ''} {...props} role={undefined} />
  )
);

const RepairListItem: React.FC<
  React.PropsWithChildren<RepairListItemProps>
> = ({
  id,
  repairCode,
  status,
  skillLevel,
  workSteps,
  workloadSum,
  productQuantityStatusSummary,
}) => {
  const doneAw = workSteps?.reduce(
    (acc: number, curr: getRepairsDetailed_getRepairs_items_workSteps) => {
      return curr.isDone ? acc + curr.setWorkload : acc;
    },
    0
  );

  const percentage: number = parseInt(
    ((doneAw * 100) / workloadSum).toFixed(0)
  );

  return (
    <Box
      p={1}
      marginTop={2}
      display="flex"
      flexDirection="column"
      border={'1px solid'}
      borderColor="primary.main"
      borderRadius={1}
    >
      {/* Header Row */}
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{repairCode}</Typography>
        <div>
          <IconButton
            LinkComponent={LinkBehavior}
            href={`/repairs/${repairCode}`}
            size="large"
          >
            <LaunchIcon color="primary" />
          </IconButton>
        </div>
      </Box>
      {/* WorkSteps */}
      <WorkStepsTable workSteps={workSteps} />

      {/* Footer */}
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop={2}
      >
        {/* <SkillLevelChip type={skillLevel} /> */}
        <ProductQuantityStatusSummaryChip
          productQuantityStatusSummary={productQuantityStatusSummary}
        />
        <Typo
          t="app.dashboard.repairs.workStepsTable.isDonePercentage"
          values={{ percentage: percentage }}
          bold
        />
      </Box>
    </Box>
  );
};

RepairListItem.propTypes = {
  id: PropTypes.string.isRequired,
  repairCode: PropTypes.string.isRequired,
};
RepairListItem.defaultProps = {};

export default RepairListItem;
