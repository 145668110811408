import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SubCategoryItem from './SubCategoryItem';
import Typo from '../../../Typo';
import Button from '../../../Button';
import { Category } from '../../../../lib/helper/treeViewHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '68vh',
      overflow: 'hidden',
    },
    droppable: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      minHeight: 150,
      width: '100%',
      overflow: 'auto',
      padding: theme.spacing(1),
    },
  })
);

export interface SubCategoriesPanelProps {
  subCategories: Category[];
  handleAddCategory: () => void;
}

const SubCategoriesPanel: React.FC<React.PropsWithChildren<SubCategoriesPanelProps>> = ({
  subCategories,
  handleAddCategory,
}) => {
  const classes = useStyles();

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
  };

  return (
    <Box className={classes.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="workStepPanelList">
          {(provided) => (
            <div
              className={classes.droppable}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {subCategories.length ? (
                subCategories.map((subCategory: Category) => (
                  <Draggable
                    isDragDisabled //FIXME: disable DnD
                    key={subCategory.id}
                    draggableId={subCategory.id}
                    index={subCategory.sequence ?? 0}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SubCategoryItem category={subCategory} />
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <Typo t="page.worksteps.tabs.subCategories.empty" />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box display="flex" justifyContent="flex-end" marginTop={3}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          t="page.worksteps.tabs.subCategories.add"
          type="submit"
          onClick={() => handleAddCategory()}
        />
      </Box>
    </Box>
  );
};

SubCategoriesPanel.propTypes = {
  handleAddCategory: PropTypes.func.isRequired,
};
SubCategoriesPanel.defaultProps = {};

export default SubCategoriesPanel;
