import { Machine } from 'xstate';
import { SkillLevel } from '../../../../../types/graphql-global-types';
import { SubOrderType } from '../../subOrdersMachine/types';
// import {
//   addProduct,
//   removeProduct,
//   updateProductCount,
//   updateProductStatus,
// } from '../actions/product';
import {
  updateSkillLevel,
  updateBike,
  updatePickUpDate,
  addWorkStep,
  removeWorkStep,
  addWorkStepProduct,
  updateWorkLoad,
  removeWorkStepProduct,
  updateWorkStepProductCount,
  completeRepair,
  unCompleteRepair,
  removeRepair,
  updateWorkLoadSum,
  updateWorkLoadPrice,
  updateRepairTotal,
  updateRepairSkillLevel,
  IsValidating,
  IsValidatingDone,
  updateValidatedData,
  updateNotes,
  updateWorkStepNotes,
  updateWorkStepProductStatus,
} from './actions';

import { validateRepair } from './helpers';
import { RepairContext, RepairEvent, RepairSchema } from './types';

export const initialRepair = {
  type: SubOrderType.REPAIR,
  data: {
    bike: null,
    workSteps: [],
    products: [],
    skillLevel: SkillLevel.LEVEL1,
    estimatedPickupDate: '',
    notes: undefined,
    price: 0,
    workloadPrice: 0,
    workloadRate: 0,
    workloadSum: 0,
    workloadDiscount: 0,
    total: 0,
  },
  completed: false,
  isValidating: false,
};

const repairMachine = Machine<RepairContext, RepairSchema, RepairEvent>(
  {
    id: 'repair',
    initial: 'editing',
    context: initialRepair,
    states: {
      editing: {
        entry: 'IsValidating',
        invoke: [
          {
            id: 'validateRepair',
            src: (ctx) => validateRepair(ctx),
            onDone: [
              {
                actions: ['updateValidatedData', 'IsValidatingDone'],
              },
            ],
            onError: {
              target: 'error',
            },
          },
        ],
        on: {
          COMPLETE_REPAIR: {
            target: 'completed',
          },
          REMOVE_REPAIR: {
            target: 'removed',
          },
          UPDATE_BIKE: {
            actions: 'updateBike',
          },
          SET_SKILLLEVEL: {
            actions: 'updateSkillLevel',
          },
          SET_EXPECTED_PICKUP_DATE: {
            actions: 'updatePickUpDate',
          },
          ADD_WORKSTEP: {
            actions: ['addWorkStep', 'updateRepairSkillLevel'],
            target: 'editing',
          },
          REMOVE_WORKSTEP: {
            actions: ['removeWorkStep', 'updateRepairSkillLevel'],
            target: 'editing',
          },
          UPDATE_WORKLOAD: {
            actions: ['updateWorkLoad'],
            target: 'editing',
          },
          ADD_WORKSTEP_PRODUCT: {
            actions: ['addWorkStepProduct'],
            target: 'editing',
          },
          REMOVE_WORKSTEP_PRODUCT: {
            actions: ['removeWorkStepProduct'],
            target: 'editing',
          },
          UPDATE_WORKSTEP_PRODUCT_COUNT: {
            actions: ['updateWorkStepProductCount'],
            target: 'editing',
          },
          UPDATE_WORKSTEP_PRODUCT_STATUS: {
            actions: ['updateWorkStepProductStatus'],
            // target: 'editing', // needs no validation Check since there is no inventory to be checked.
          },
          // ADD_PRODUCT: {
          //   actions: ['addProduct'],
          //   target: 'editing',
          // },
          // REMOVE_PRODUCT: {
          //   actions: ['removeProduct'],
          //   target: 'editing',
          // },
          // UPDATE_PRODUCT_COUNT: {
          //   actions: ['updateProductCount'],
          //   target: 'editing',
          // },
          // UPDATE_PRODUCT_STATUS: {
          //   actions: ['updateProductStatus'],
          //   // target: 'editing',
          // },
          UPDATE_WORKSTEP_NOTES: {
            actions: ['updateWorkStepNotes'],
          },
          UPDATE_NOTES: {
            actions: ['updateNotes'],
          },
        },
      },
      completed: {
        entry: 'completeRepair',
        on: {
          UNCOMPLETE_REPAIR: {
            target: 'editing',
            actions: 'unCompleteRepair',
          },
          REMOVE_REPAIR: {
            target: 'removed',
          },
        },
      },
      removed: {
        entry: 'removeRepair',
      },
      error: {},
    },
  },
  {
    actions: {
      updateSkillLevel: updateSkillLevel,
      updateBike: updateBike,
      updatePickUpDate: updatePickUpDate,
      addWorkStep: addWorkStep,
      removeWorkStep: removeWorkStep,
      updateWorkLoad: updateWorkLoad,
      addWorkStepProduct: addWorkStepProduct,
      removeWorkStepProduct: removeWorkStepProduct,
      updateWorkStepProductCount: updateWorkStepProductCount,
      // addProduct: addProduct,
      // updateProductStatus: updateProductStatus,
      // removeProduct: removeProduct,
      // updateProductCount: updateProductCount,
      completeRepair: completeRepair,
      unCompleteRepair: unCompleteRepair,
      removeRepair: removeRepair,
      updateWorkLoadSum: updateWorkLoadSum,
      updateWorkLoadPrice: updateWorkLoadPrice,
      updateRepairTotal: updateRepairTotal,
      updateRepairSkillLevel: updateRepairSkillLevel,
      updateValidatedData: updateValidatedData,
      IsValidating: IsValidating,
      IsValidatingDone: IsValidatingDone,
      updateNotes: updateNotes,
      updateWorkStepNotes: updateWorkStepNotes,
      updateWorkStepProductStatus: updateWorkStepProductStatus,
    },
  }
);

export default repairMachine;
