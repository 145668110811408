import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { locale, messages } from '../intl';

/**
 * Utility to to use notistack outside of react components
 * We use it to enqueue Toasts from Apollo links
 * Props: https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
 */

// create intl provider to use react-intl outside of a component.
const cache = createIntlCache();

const intl = createIntl(
  {
    locale: locale,
    messages: messages[locale],
  },
  cache
);

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<
  React.PropsWithChildren<IProps>
> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

const snackBarUtils = {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(intl.formatMessage({ id: msg }), {
      variant,
      preventDuplicate: true,
    });
  },
};

export default snackBarUtils;
