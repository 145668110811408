import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '../../../Paper';
import Typo from '../../../Typo';
import WorkSteps from './WorkSteps';
import LinearProgress from '@mui/material/LinearProgress';
import {
  getRepair_getRepair,
  getRepair_getRepair_workSteps,
} from '../../../../gql/repair/types/getRepair';
import { me_me } from '../../../../gql/user/types/me';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    progress: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 6,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  })
);

export interface RepairContentProps {
  repairData?: getRepair_getRepair;
  user?: me_me;
  editState: boolean;
  isLoading: boolean;
}

const RepairContent: React.FC<React.PropsWithChildren<RepairContentProps>> = ({
  repairData,
  user,
  editState,
  isLoading,
}) => {
  const classes = useStyles();

  const totalAw = repairData?.workloadSum || 0;
  const doneAw =
    repairData?.workSteps?.reduce(
      (acc: number, curr: getRepair_getRepair_workSteps) => {
        return curr.isDone ? acc + curr.setWorkload : acc;
      },
      0
    ) || 0;

  const percentage: number = parseInt(((doneAw * 100) / totalAw).toFixed(0));

  return (
    <Paper padding>
      <LinearProgress
        variant="determinate"
        value={percentage || 0}
        className={classes.progress}
      />
      <div className={classes.box}>
        <Typo t="app.repairProcessing.content.title" variant="h5" />

        <Typo
          t="app.repairProcessing.content.aw"
          values={{
            percent: percentage,
            done: doneAw ?? '-',
            total: totalAw ?? '-',
          }}
        />
      </div>
      <Box
        p={2}
        mt={2}
        height="75vh"
        overflow="auto"
        bgcolor="background.default"
        borderRadius={1}
      >
        <WorkSteps
          repairData={repairData}
          user={user}
          editState={editState}
          isLoading={isLoading}
        />
      </Box>
    </Paper>
  );
};

RepairContent.propTypes = {};
RepairContent.defaultProps = {};

export default RepairContent;
