import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useIntl } from 'react-intl';

import { WorkStep } from '../../../Dialog/WorkStepDefinition';
import FormCategoryPicker from '../../Components/FormCategoryPicker';
import FormColorPicker from '../../Components/FormColorPicker';
import Typo from '../../../Typo';
import { Category } from '../../../../lib/helper/treeViewHelper';
import FormAccordion, {
  FormAccordionDetails,
  FormAccordionSummary,
} from '../../Components/FormAccordion';

export interface ContentAccordionProps {
  workStep?: WorkStep;
}

const ContentAccordion: React.FC<React.PropsWithChildren<ContentAccordionProps>> = ({ workStep }) => {
  const [selectedCategory, setSelectedCategory] = React.useState<Category>();
  const intl = useIntl();

  const handleCategoryChange = (category: Category) => {
    setSelectedCategory(category);
  };

  return (
    <Box>
      {/* ProposedProductCategory */}
      <FormAccordion>
        <FormAccordionSummary id="proposedProductCategory">
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="space-between"
          >
            <Typo t="app.form.workStepDefinition.product.proposedProductCategory" />

            <Chip
              size="small"
              label={
                selectedCategory?.name ||
                workStep?.proposedProductCategory?.name ||
                intl.formatMessage({
                  id: 'app.form.workStepDefinition.product.proposedProductCategory.empty',
                })
              }
            />
          </Box>
        </FormAccordionSummary>
        <FormAccordionDetails>
          <FormCategoryPicker
            name="proposedProductCategoryId"
            defaultCategory={workStep?.proposedProductCategory}
            onCategoryChange={handleCategoryChange}
          />
        </FormAccordionDetails>
      </FormAccordion>

      {/* Color */}
      <Box mt={1}>
        <FormAccordion>
          <FormAccordionSummary id="color">
            <Box
              display="flex"
              flex={1}
              justifyContent="space-between"
              alignItems="space-between"
            >
              <Typo t="app.form.workStepDefinition.product.color" />
              <Box
                borderRadius="20px"
                bgcolor={workStep?.color || undefined}
                height={20}
                width={20}
              />
            </Box>
          </FormAccordionSummary>
          <FormAccordionDetails>
            <FormColorPicker
              name="color"
              defaultValue={workStep?.color}
              previewName={workStep?.name}
              showPreview
              showLabel={false}
            />
          </FormAccordionDetails>
        </FormAccordion>
      </Box>
    </Box>
  );
};

ContentAccordion.propTypes = {};
ContentAccordion.defaultProps = {};

export default ContentAccordion;
