import React from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export interface LinkProps extends MuiLinkProps {
  t?: string;
  to?: string;
  onClick?: any;
  children?: any;
  className?: string;
}

const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
  t,
  to = '',
  onClick,
  children,
  ...props
}) => {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      onClick={onClick}
      {...props}
      underline="hover">
      {t && <FormattedMessage id={t} />}
      {children}
    </MuiLink>
  );
};

Link.propTypes = {};
Link.defaultProps = {};

export default Link;
