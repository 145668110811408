import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export interface AddressRowProps {
  address?:
    | { city?: string; postalCode?: string; street1?: string }
    | undefined;
}

/**
 * Renders a Address table row if address is provided or default no data text.
 */
const AddressRow: React.FC<React.PropsWithChildren<AddressRowProps>> = ({ address }) => {
  if (!address) return <FormattedMessage id="app.table.row.empty" />;

  return (
    <FormattedMessage
      id="app.table.row.address"
      values={{ street1: address?.street1, city: address?.city }}
    />
  );
};

AddressRow.propTypes = {
  address: PropTypes.shape({}),
};
AddressRow.defaultProps = {
  address: undefined,
};

export default AddressRow;
