import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { RawDraftContentState } from 'draft-js';

import WYSIWYG from '../../../WYSIWYG';

export interface FormWYSIWYGProps {
  name: string;
  initialContent?: RawDraftContentState;
}

const FormWYSIWYG: React.FC<React.PropsWithChildren<FormWYSIWYGProps>> = ({ name, initialContent }) => {
  const ref = React.useRef<any>();

  const { register, setValue } = useFormContext();

  const onValueChange = (content: RawDraftContentState) => {
    setValue(name, content);
  };

  React.useEffect(() => {
    register({ name: name, type: 'custom' });
    if (ref?.current) {
      setValue(name, ref?.current?.getContent());
    }
  }, [name, register, setValue, ref]);

  return (
    <WYSIWYG
      inDialog
      initialContent={initialContent}
      ref={ref}
      onContentChange={onValueChange}
    />
  );
};

FormWYSIWYG.propTypes = {
  name: PropTypes.string.isRequired,
};
FormWYSIWYG.defaultProps = {};

export default FormWYSIWYG;
