import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';

import Paper from '../../../../../Paper';
import Typo from '../../../../../Typo';
import { formattedCurrency } from '../../../../../../lib/helper/numbers';
import { SubOrderType } from '../../../../machines/subOrdersMachine/types';

export interface SubOrderCompletedProps {
  subOrder: SubOrderType;
  infoText: string;
  price: number;
  editRepair: () => void;
  removeRepair: () => void;
}

const SubOrderCompleted: React.FC<React.PropsWithChildren<SubOrderCompletedProps>> = ({
  subOrder,
  infoText,
  price,
  editRepair,
  removeRepair,
}) => {
  return (
    <Paper padding>
      <Box display="flex" justifyContent="space-between">
        {/* LEFT */}
        <Box display="flex" flexDirection="column">
          {subOrder === SubOrderType.REPAIR && (
            <Typo t="app.customerOrderDraft.subOrders.repair" variant="h5" />
          )}
          {subOrder === SubOrderType.ORDER && (
            <Typo t="app.customerOrderDraft.subOrders.order" variant="h5" />
          )}
          <Typography>{infoText}</Typography>
        </Box>

        {/* RIGHT */}
        <Box display="flex" alignItems="center">
          <Box marginRight={2}>
            <Typography variant="h6" color="primary">
              <b>{formattedCurrency(price)}</b>
            </Typography>
          </Box>
          <div>
            <IconButton onClick={editRepair} size="large">
              <EditIcon />
            </IconButton>
          </div>
          <Box color="red">
            <IconButton color="inherit" onClick={removeRepair} size="large">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

SubOrderCompleted.propTypes = {
  // subOrder: PropTypes.oneOf(['REPAIR', 'PRODUCT_ORDER']).isRequired,
  infoText: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  editRepair: PropTypes.func.isRequired,
  removeRepair: PropTypes.func.isRequired,
};
SubOrderCompleted.defaultProps = {};

export default SubOrderCompleted;
