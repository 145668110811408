import React from 'react';
import Box from '@mui/material/Box';

import Typo from '../Typo';
import SMSNotification from './SMSNotification';
import { getCustomerOrder_getCustomerOrder_history } from '../../gql/customerOrder/types/getCustomerOrder';

export interface SMSNotificationHistoryProps {
  history?: getCustomerOrder_getCustomerOrder_history[];
}

const SMSNotificationHistory: React.FC<React.PropsWithChildren<SMSNotificationHistoryProps>> = ({
  history,
}) => {
  return (
    <Box height="100%" overflow="scroll">
      {!history ||
        (!history.length && (
          <Typo t="app.dialog.smsNotification.history.empty" />
        ))}
      <Box>
        {history &&
          history.map(
            (historyObject: getCustomerOrder_getCustomerOrder_history) => (
              <SMSNotification
                key={historyObject?.id}
                id={historyObject?.id}
                message={historyObject?.details?.message}
                createdAt={historyObject?.createdAt}
                user={historyObject?.user ?? undefined}
              />
            )
          )}
      </Box>
    </Box>
  );
};

SMSNotificationHistory.propTypes = {};
SMSNotificationHistory.defaultProps = {};

export default SMSNotificationHistory;
