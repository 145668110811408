import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReplayIcon from '@mui/icons-material/Replay';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
// import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Button from '../../../Button';
import PageActionButtons from '../../../PageActionButtons';
import { RepairStatus } from '../../../../types/graphql-global-types';
import VisibleCheck from '../../../VisibleCheck';
import LocationSelect, { LocationUpdateType } from '../../../LocationSelect';
import {
  getRepair_getRepair,
  getRepair_getRepair_workSteps,
} from '../../../../gql/repair/types/getRepair';
import PauseOrCancelDialog from '../../../Dialog/PauseOrCancelDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
      minWidth: 150,
    },
    cancel: {
      color: 'red',
      borderColor: 'red',
      '&:hover': {
        color: 'red',
        borderColor: 'red',
      },
    },
  })
);

/**
 * legend:
 * curr. mechanic:  |  repair status:  |  options:
 * ------------------------------------------------------------------
 * yes              | OPEN             |  startWork
 * no               | OPEN             |  takeOverWork (was canceled)
 * yes              | IN_PROGRESS      |  pause, workDone (if all done)
 * no               | IN_PROGRESS      |  takeOverWork,
 * yes              | PAUSED           |  unPause, workDone (if all done)
 * no               | PAUSED           |  takeOverWork (and unPause),
 *
 */

export interface ActionButtonsRowProps {
  stateValue: string | object;
  repairData?: getRepair_getRepair;
  isManager: boolean;
  userId?: string;
  onAddWorkStep: () => void;
  handleUpdateRepairStatus: (status: RepairStatus) => void;
  handleUpdateRepairStatusAndMechanic: (
    status: RepairStatus,
    mechanicId: string,
    pauseReason?: string,
    cancelationReason?: string
  ) => void;
  handleUpdateRepairLocation: (locationId?: string) => void;
}

const ActionButtonsRow: React.FC<React.PropsWithChildren<ActionButtonsRowProps>> = ({
  stateValue,
  repairData,
  isManager,
  userId,
  onAddWorkStep,
  handleUpdateRepairStatus,
  handleUpdateRepairStatusAndMechanic,
  handleUpdateRepairLocation,
}) => {
  const classes = useStyles();
  const [openPause, setOpenPause] = React.useState<RepairStatus.PAUSED>();
  const [openCancel, setOpenCancel] = React.useState<RepairStatus.CANCELED>();

  const updating = stateValue === 'updatingRepair';

  const open = repairData?.status === RepairStatus.OPEN;
  const inProgress = repairData?.status === RepairStatus.IN_PROGRESS;
  const paused = repairData?.status === RepairStatus.PAUSED;
  const canceled = repairData?.status === RepairStatus.CANCELED;
  const invoiced = repairData?.status === RepairStatus.INVOICED;
  const workDone = repairData?.status === RepairStatus.WORK_DONE;

  const isCurrentMechanic = repairData?.mechanic?.id === userId;

  const allDone = repairData?.workSteps?.every(
    (step: getRepair_getRepair_workSteps) => step.isDone
  );

  const handlePauseRepair = (pauseReason: string) => {
    handleUpdateRepairStatusAndMechanic(
      RepairStatus.PAUSED,
      userId || '',
      pauseReason,
      undefined
    );
    setOpenPause(undefined);
  };

  const handleCancelRepair = (cancelationReason: string) => {
    handleUpdateRepairStatusAndMechanic(
      RepairStatus.CANCELED,
      userId || '',
      undefined,
      cancelationReason
    );
    setOpenCancel(undefined);
  };

  return (
    <PageActionButtons hasNavigateBack>
      <PauseOrCancelDialog
        key="pause"
        type={openPause}
        title="app.dialog.pauseOrCancelDialog.pauseRepair.title"
        message="app.dialog.pauseOrCancelDialog.pauseRepair.message"
        onSuccess={handlePauseRepair}
        onClose={() => setOpenPause(undefined)}
      />

      <PauseOrCancelDialog
        key="cancel"
        type={openCancel}
        title="app.dialog.pauseOrCancelDialog.cancelRepair.title"
        message="app.dialog.pauseOrCancelDialog.cancelRepair.message"
        onSuccess={handleCancelRepair}
        onClose={() => setOpenCancel(undefined)}
      />

      {updating && <CircularProgress color="primary" />}

      <VisibleCheck isVisible={isManager && !canceled && !invoiced}>
        <div className={classes.button}>
          <LocationSelect
            locationUpdateType={LocationUpdateType.REPAIR_LOCATION}
            locationId={repairData?.locationId ?? undefined}
            onSubmit={handleUpdateRepairLocation}
          />
        </div>
      </VisibleCheck>

      {/* ADD WORKSTEP */}
      <VisibleCheck
        isVisible={isManager && !canceled && !invoiced && !workDone}
      >
        <Box display="flex">
          <Button
            startIcon={<AddIcon />}
            className={`${classes.button}`}
            variant="outlined"
            color="primary"
            t="app.repairProcessing.buttonRow.addWorkStep"
            onClick={() => onAddWorkStep()}
          />
          <Box marginLeft={2} marginRight={1} display="flex">
            <Divider orientation="vertical" flexItem />
          </Box>
        </Box>
      </VisibleCheck>

      {/* CANCEL REPAIR */}
      <VisibleCheck isVisible={isManager && !canceled && !invoiced}>
        <Button
          startIcon={<StopIcon />}
          className={`${classes.button} ${classes.cancel}`}
          variant="outlined"
          color="primary"
          t="app.repairProcessing.buttonRow.cancel"
          onClick={() => setOpenCancel(RepairStatus.CANCELED)}
        />
      </VisibleCheck>

      {/* START WORKING AGAIN ON REPAIR */}
      <VisibleCheck isVisible={isManager && workDone}>
        <Button
          startIcon={<ReplayIcon />}
          className={classes.button}
          variant="outlined"
          color="primary"
          t="app.repairProcessing.buttonRow.unWorkDone"
          onClick={() =>
            handleUpdateRepairStatusAndMechanic(
              RepairStatus.IN_PROGRESS,
              userId || ''
            )
          }
        />
      </VisibleCheck>

      {/* UNCANCEL REPAIR */}
      {/* 
        uncanceling is disabled since, canceled is a final state. i will leave the funcitonality tho
      {isManager && canceled && (
        <Button
          startIcon={<SettingsBackupRestoreIcon />}
          className={`${classes.button} ${classes.cancel}`}
          variant="outlined"
          color="primary"
          t="app.repairProcessing.buttonRow.unCancel"
          onClick={() => updateRepairStatus('OPEN')}
        />
      )} */}

      {/* PAUSE WORK */}
      <VisibleCheck
        isVisible={
          (isManager && inProgress) || (isCurrentMechanic && inProgress)
        }
      >
        <Button
          startIcon={<PauseIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          t="app.repairProcessing.buttonRow.pause"
          onClick={() => setOpenPause(RepairStatus.PAUSED)}
        />
      </VisibleCheck>

      {/* UNPAUSE WORK */}
      <VisibleCheck isVisible={paused && isCurrentMechanic}>
        <Button
          startIcon={<ReplayIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          t="app.repairProcessing.buttonRow.unPause"
          onClick={() =>
            handleUpdateRepairStatusAndMechanic(
              RepairStatus.IN_PROGRESS,
              userId || ''
            )
          }
        />
      </VisibleCheck>

      {/* TAKEOVER WORK */}
      <VisibleCheck isVisible={!isCurrentMechanic && (inProgress || paused)}>
        <Button
          startIcon={<SwapHorizIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          t="app.repairProcessing.buttonRow.takeOver"
          onClick={() =>
            handleUpdateRepairStatusAndMechanic(
              RepairStatus.IN_PROGRESS,
              userId || ''
            )
          }
        />
      </VisibleCheck>

      {/* START WORK */}
      <VisibleCheck isVisible={open}>
        <Button
          startIcon={<PlayArrowIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          t="app.repairProcessing.buttonRow.start"
          onClick={() =>
            handleUpdateRepairStatusAndMechanic(
              RepairStatus.IN_PROGRESS,
              userId || ''
            )
          }
        />
      </VisibleCheck>

      {/* WORK_DONE */}
      <VisibleCheck isVisible={inProgress && isCurrentMechanic}>
        <Button
          startIcon={<DoneAllIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          t="app.repairProcessing.buttonRow.workDone"
          onClick={() => handleUpdateRepairStatus(RepairStatus.WORK_DONE)}
          disabled={!allDone}
        />
      </VisibleCheck>
    </PageActionButtons>
  );
};

ActionButtonsRow.propTypes = {};
ActionButtonsRow.defaultProps = {};

export default ActionButtonsRow;
