import React from 'react';
import Box from '@mui/material/Box';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import CategoriesTreeView from '../../../CategoriesTreeView';
import {
  Category,
  handleCategoryUpdate,
} from '../../../../lib/helper/treeViewHelper';
import {
  GET_PRODUCT_CATEGORY,
  GET_REPAIR_PRODUCTS_ROOT_CATEGORY,
} from '../../../../gql/product/queries';
import {
  getProductCategory,
  getProductCategoryVariables,
} from '../../../../gql/product/types/getProductCategory';
import { ProposedProductCategory } from '../../../Dialog/WorkStepDefinition';
import { useIntl } from 'react-intl';

export interface FormCategoryPickerProps {
  name: string;
  defaultCategory?: ProposedProductCategory;
  onCategoryChange: (category: Category) => void;
}

const FormCategoryPicker: React.FC<React.PropsWithChildren<FormCategoryPickerProps>> = ({
  name,
  defaultCategory,
  onCategoryChange,
}) => {
  const [treeData, setTreeData] = React.useState<Category[]>([]);
  const [, setSelectedCategory] = React.useState<Category>();
  const { register, setValue } = useFormContext();

  const intl = useIntl();

  const removeCategory = {
    id: 'null',
    name: intl.formatMessage({
      id: 'app.form.workStepDefinition.product.proposedProductCategory.empty',
    }),
  };

  /**
   * Query rootCategories. Since we use only one -> take only the first one.
   */
  const { loading } = useQuery(GET_REPAIR_PRODUCTS_ROOT_CATEGORY, {
    onCompleted: (data) => {
      //set initial category
      setSelectedCategory(data?.getProductCategories[0]);

      // set initial treeData
      setTreeData(data?.getProductCategories[0].subCategories);
    },
    onError: () => {},
  });

  /**
   * Query Detail Information for a specific category
   */
  const [getProductCategoryDetail, { loading: detailLoading }] = useLazyQuery<
    getProductCategory,
    getProductCategoryVariables
  >(GET_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      handleCategoryUpdate(
        data.getProductCategory as Category,
        setTreeData,
        setSelectedCategory
      );
      onCategoryChange(data.getProductCategory as Category);
    },
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  const handleCategorySelection = (id: string) => {
    // handle the case where user wants to delete the proposedProductCategory
    if (id === 'null') {
      setValue(name, null);
      onCategoryChange(removeCategory as Category);
      return;
    }

    // set value in form
    setValue(name, id);

    // fetch category detail
    getProductCategoryDetail({ variables: { id } });
  };

  React.useEffect(() => {
    register({ name: name, type: 'custom' });
  }, [register, name]);

  return (
    <Box height="200px" overflow="auto" width="100%">
      <CategoriesTreeView
        isLoading={loading || detailLoading}
        onCategorySelect={handleCategorySelection}
        treeData={treeData}
        selectedId={defaultCategory?.id}
        hasRemoveCategory={true}
        height={200}
      />
    </Box>
  );
};

FormCategoryPicker.propTypes = {};
FormCategoryPicker.defaultProps = {};

export default FormCategoryPicker;
