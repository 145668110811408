import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Link from '../../../Link';

export interface Repair {
  id: string;
  repairCode: string;
}

export interface RepairListRowProps {
  repairs: Repair[];
}

const RepairListRow: React.FC<React.PropsWithChildren<RepairListRowProps>> = ({ repairs }) => {
  if (!repairs.length) return null;

  return (
    <Box overflow="scroll" display="flex">
      {repairs.map((r: Repair, index: number) => (
        <Box key={r.repairCode} marginRight="4px">
          <Link
            onClick={(event: any) => {
              event.stopPropagation();
            }}
            to={`/repairs/${r.repairCode}`}
          >
            {r.repairCode}
          </Link>
          {index < repairs.length - 1 ? ', ' : null}
        </Box>
      ))}
    </Box>
  );
};

RepairListRow.propTypes = {
  repairs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      repairCode: PropTypes.string.isRequired,
    }).isRequired // TODO: Check why needs to be required. And also what actually happens if repairs are empty
  ).isRequired,
};
RepairListRow.defaultProps = {};

export default RepairListRow;
