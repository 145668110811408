import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';

import DialogBase from '..';
import EditNoteForm from '../../Form/EditNoteForm';
import {
  updateNote as updateNoteI,
  updateNoteVariables,
} from '../../../gql/notes/types/updateNote';
import { UPDATE_NOTE } from '../../../gql/notes/mutations';
import { getNotes_getNotes_items } from '../../../gql/notes/types/getNotes';
import { UpdateNoteInput } from '../../../types/graphql-global-types';
import Button from '../../Button';

export interface EditNoteDialogProps {
  note?: getNotes_getNotes_items;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditNoteDialog: React.FC<React.PropsWithChildren<EditNoteDialogProps>> = ({
  note,
  onSubmit,
  onCancel,
}) => {
  const [updateNote, { loading }] = useMutation<
    updateNoteI,
    updateNoteVariables
  >(UPDATE_NOTE, {
    onCompleted: () => {
      onSubmit();
    },
    onError: () => {},
  });

  const handleUpdateNote = (data: UpdateNoteInput) => {
    updateNote({ variables: { data } });
  };

  return (
    <DialogBase
      open={!!note}
      isLoading={loading}
      primaryAction={undefined}
      isForm
      fullWidth
      maxWidth="sm"
      title="app.dialog.createNote.title"
    >
      <Box
        bgcolor="background.default"
        borderRadius={1}
        p={2}
        m={2}
        mt={0}
      >
        <EditNoteForm note={note} onSubmit={handleUpdateNote} />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onCancel}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            form="editNote-form"
          />
        </DialogActions>
      </Box>
    </DialogBase>
  );
};

EditNoteDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
EditNoteDialog.defaultProps = {};

export default EditNoteDialog;
