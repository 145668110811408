import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import { FormattedMessage } from 'react-intl';

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { ProductQuantityStatusSummary } from '../../../../../../types/graphql-global-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      paddingLeft: theme.spacing(0.5),
    },
  })
);

export interface ProductQuantityStatusSummaryChipProps {
  productQuantityStatusSummary: ProductQuantityStatusSummary;
}

const ProductQuantityStatusSummaryChip: React.FC<
  React.PropsWithChildren<ProductQuantityStatusSummaryChipProps>
> = ({ productQuantityStatusSummary }) => {
  const classes = useStyles();

  const getStatusColor = (status: ProductQuantityStatusSummary) => {
    switch (status) {
      case ProductQuantityStatusSummary.ALL_AVAILABLE:
        return 'success';
      case ProductQuantityStatusSummary.ALL_INSTALLED:
        return 'success';
      case ProductQuantityStatusSummary.ALL_ORDERED_OR_AVAILABLE:
        return 'warning';
      case ProductQuantityStatusSummary.SOME_OPEN:
        return 'error';
      case ProductQuantityStatusSummary.NO_PRODUCTS:
        return 'default';

      default:
        return undefined;
    }
  };

  const getStatusIcon = (status: ProductQuantityStatusSummary) => {
    switch (status) {
      case ProductQuantityStatusSummary.ALL_INSTALLED:
        return <DoneAllOutlinedIcon />;
      case ProductQuantityStatusSummary.ALL_AVAILABLE:
        return <DoneOutlinedIcon />;
      case ProductQuantityStatusSummary.ALL_ORDERED_OR_AVAILABLE:
        return <ShoppingCartCheckoutIcon />;
      case ProductQuantityStatusSummary.SOME_OPEN:
        return <ProductionQuantityLimitsIcon />;
      case ProductQuantityStatusSummary.NO_PRODUCTS:
        return <RemoveShoppingCartOutlinedIcon />;

      default:
        return undefined;
    }
  };

  return (
    <Chip
      className={classes.chip}
      variant="filled"
      size="small"
      label={
        <FormattedMessage
          id={`app.dashboard.repairs.productQuantityStatusSummary.${productQuantityStatusSummary}`}
        />
      }
      icon={getStatusIcon(productQuantityStatusSummary)}
      color={getStatusColor(productQuantityStatusSummary)}
    />
  );
};

ProductQuantityStatusSummaryChip.propTypes = {};
ProductQuantityStatusSummaryChip.defaultProps = {};

export default ProductQuantityStatusSummaryChip;
