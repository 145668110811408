import { gql } from '@apollo/client';

/**
 * Me Query. Fetches all user data
 */
export const ALL_CUSTOMERS = gql`
  query CustomersOverview(
    $isActive: Boolean
    $lastname: String
    $firstname: String
    $offset: Int
    $limit: Int
    $field: CustomerSortField!
    $direction: OrderDirection
  ) {
    getCustomers(
      isActive: $isActive
      lastname: $lastname
      firstname: $firstname
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
    ) {
      items {
        id
        createdAt
        gender
        lastname
        firstname
        phone
        phone2
        email
        address {
          city
          street1
        }
      }
      totalCount
    }
  }
`;

export const CUSTOMERS_SEARCH = gql`
  query CustomerSearch(
    $lastname: String
    $firstname: String
    $offset: Int
    $limit: Int
    $field: CustomerSortField!
    $direction: OrderDirection
  ) {
    getCustomers(
      isActive: true
      lastname: $lastname
      firstname: $firstname
      offset: $offset
      limit: $limit
      field: $field
      direction: $direction
    ) {
      items {
        id
        lastname
        firstname
      }
      totalCount
    }
  }
`;

export const CUSTOMER_DETAIL = gql`
  query getCustomer($id: String!) {
    getCustomer(id: $id) {
      id
      gender
      lastname
      firstname
      phone
      phone2
      email
      notes
      address {
        id
        city
        postalCode
        street1
        street2
      }
      bikes {
        id
        createdAt
        updatedAt
        type
        brand
        model
        color
        frameNumber
        isEbike
        notes
      }
    }
  }
`;
