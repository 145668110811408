import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

import FormContainer from '..';
import TextField from '../Components/FormTextField';
import Button from '../../Button';
import FormSwitch from '../Components/FormSwitch';
import { ProductQuantityStatus } from '../../../types/graphql-global-types';

export interface ProductQty {
  id: string;
  quantity: string;
  name: string;
  price: string;
  categoryId: string;
  isAvailable: boolean;
  status: ProductQuantityStatus;
  manufacturer?: string | null;
}

export interface CreateOneTimeProductDialogFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
  product?: ProductQty;
}

const CreateOneTimeProductDialogForm: React.FC<React.PropsWithChildren<CreateOneTimeProductDialogFormProps>> = ({ onSubmit, onClose, product }) => {
  return (
    <FormContainer onSubmit={onSubmit}>
      <Box
        p={2}
        m={2}
        mt={0}
        bgcolor="background.default"
        borderRadius={1}
      >
        <FormSwitch
          name="isAvailable"
          defaultValue={product?.isAvailable}
          label="model.product.status.isAvailable"
        />

        <TextField
          name="manufacturer"
          label="model.repair.product.manufacturer"
          defaultValue={product?.manufacturer ?? ''}
          formInputOptions={{
            required: {
              value: false,
            },
          }}
        />

        <TextField
          name="name"
          label="model.repair.product"
          defaultValue={product?.name ?? ''}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
          }}
        />

        <TextField
          name="price"
          label="model.repair.product.price"
          defaultValue={product?.price ?? 0}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
            min: {
              value: 0.01,
              message: 'app.form.errors.min.one',
            },
            max: 9999,
          }}
        />
        <TextField
          name="quantity"
          label="model.repair.product.quantity"
          defaultValue={product?.quantity ?? 1}
          type="number"
          inputProps={{
            step: '1',
          }}
          formInputOptions={{
            required: {
              value: true,
              message: 'app.form.errors.required',
            },
            min: {
              value: 1,
              message: 'app.form.errors.min.one',
            },
            max: 9999,
          }}
        />
      </Box>
      <Box mb={1} mr={1}>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            t="app.common.defaultSecondary"
          />
          <Button
            variant="contained"
            color="primary"
            t="app.common.defaultPrimary"
            type="submit"
            disableElevation
          />
        </DialogActions>
      </Box>
    </FormContainer>
  );
};

CreateOneTimeProductDialogForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
CreateOneTimeProductDialogForm.defaultProps = {};

export default CreateOneTimeProductDialogForm;
